// utils/themeStore.ts
import { proxy } from 'valtio';
import { Style } from './types';
import { applyStyleObject } from './mapStore';
import { cNoteConfig } from './constants';

// this store might be deleted in the future
// for now, it helps us store values related to the theme
// default values for the background and text color
// the colors for the color palette in the color selection component
// even styleObjects and shortcuts can live here

// when there is a theme collection in our backend, we might communicate directly with the backend
// or use this store as an intermediary to reduce the number of requests to the backend

interface ThemeStore {
  canvasBackgroundColor: string;
  defaultColor: string; // applied to text, borders (assuming only a border thickness is added wihout color specification) and the MapSyncing Lottie animation
  defaultItemWidth: number;
  defaultLinkColor: string;

  styleObjects: Style[];

  // fontFamily:"Nunito"  // TODO    []    apply the font family to Slate
  fontSize: string;
  defaultAreaStyleObject: Style;
  colorPalette: string[]; // array of hex strings, empty slots will be null
}

const initialThemeState: ThemeStore = {
  canvasBackgroundColor: '#1E1D1D',
  defaultColor: '#ffffff',
  defaultItemWidth: cNoteConfig.MINWIDTH,
  fontSize: '16',
  defaultLinkColor: '#7091E6',

  styleObjects: [
    {
      border: {
        thickness: '_3',
        color: '#3050d6',
      },
      fontSize: 18,
      textAlignment: 'center',
      indentation: '_0',
      customWidth: 204.0,
      assignedHotkey: ['mod+alt+Digit1'],
    },
    // Add styleId property to other style objects
    {
      border: {
        isSquared: true,
        thickness: '_1',
        color: '#fff',
      },
      fontSize: 12,
      textAlignment: 'left',
      assignedHotkey: ['mod+alt+Digit2'],
    },
    {
      border: {
        thickness: '_1',
        color: '#3050d4',
        isSquared: true,
      },
      fontSize: 12,
      textAlignment: 'left',

      formattingOfTheEntireNote: {
        isItalic: true,
      },
      assignedHotkey: ['mod+alt+Digit3'],
    },
    {
      fontSize: 28,
      textColor: '#fff',
      formattingOfTheEntireNote: {
        isBold: true,
      },
      assignedHotkey: ['mod+alt+Digit4'],
    },
    {
      textColor: '#d73636',
      textAlignment: 'left',
      indentation: '_0',
      assignedHotkey: ['mod+alt+Digit5'],
    },
    {
      border: {
        thickness: '_2',
        color: '#fb9a12',
      },
      fontSize: 14,
      textAlignment: 'center',
      indentation: '_0',
      assignedHotkey: ['mod+alt+Digit6'],
    },
    {
      border: {
        thickness: '_2',
        color: '#d73636',
      },
      fontSize: 14,
      textAlignment: 'center',
      indentation: '_0',
      assignedHotkey: ['mod+alt+Digit7'],
    },
    {
      border: {
        thickness: '_2',
        isSquared: true,
      },
      textColor: '#000000',
      fillColor: '#ffffff',
      indentation: '_0',
      assignedHotkey: ['mod+alt+Digit8'],
      formattingOfTheEntireNote: {
        isBold: true,
      },
    },
    {
      border: {
        thickness: '_2',
        color: '#d73636',
        hasDropShadow: true,
      },
      customWidth: 179,
      textAlignment: 'center',
      fontSize: 19,

      assignedHotkey: ['mod+alt+Digit9'],
    },
    {
      overwriteOptions: {
        affectFontStyle: true,
        affectBorder: true,
        affectAlignment: true,
        affectColor: true,
        affectSize: true,
        affectNoteBody: true,
        affectFade: true,
        affectSticky: true,
      },
      assignedHotkey: ['mod+alt+Digit0'],
    },
  ],

  defaultAreaStyleObject: {
    border: {
      thickness: '_2',
      color: '#ffffff',
    },
  },

  colorPalette: [
    '#D6455B',
    '#FC9A13',
    '#3150D5',
    '#181818',
    '#C4C4C4',
    '#FDFBF7',
    '#323333',
    '#8D46FD',
    '#FAEDCF',
    '#DBFAA5',
    '#ebd9fd',
    '#D73637',
    'empty',
    'empty',
    'empty',
    'empty',
    'empty',
    'empty',
    'empty',
    'empty',
  ],
};
export const themeStore = proxy<ThemeStore>(initialThemeState);

export function getDefaultColor() {
  return themeStore.defaultColor;
}

export function reorderColorPalette(
  previousPosition: number,
  newPosition: number
) {
  const colorPalette = themeStore.colorPalette;
  const color = colorPalette[previousPosition];
  colorPalette.splice(previousPosition, 1);
  colorPalette.splice(newPosition, 0, color);
}

export const getStyleHotkeyMatch = (key: string, mapId: string) => {
  const hotKey = initialThemeState.styleObjects.find(item => {
    if (item.assignedHotkey) {
      return item.assignedHotkey[0] === `mod+alt+${key}`;
    }
  });

  if (hotKey) {
    applyStyleObject(hotKey, mapId);
    return true;
  }
  return false;
};
