import { useFlag } from '@unleash/proxy-client-react';
import { ReactNode } from 'react';
import { currentMapStateStore } from 'utils/stores/mapStore';
import selectedContentsStore from 'utils/stores/Selection/selectedContentStore';
import { useSnapshot } from 'valtio';

type ChildProps = {
  lastInteraction: Date;
} & Record<string, any>;

type RenderSelectedContentProps = {
  mapID: string;
  ID: string;
  renderChild: (props: any) => ReactNode;
  childprops: ChildProps;
};

function useSelectionSnapShot(ID: string, mapID): boolean {
  const useSelectionv2 = useFlag('mapmap_selectionv2');
  let selected = false;

  if (useSelectionv2) {
    const selectionState = useSnapshot(selectedContentsStore.selected);

    if (selectionState.size >= 1) {
      selected = !!selectionState.get(ID);
    }
  } else {
    const selectedChanges = useSnapshot(currentMapStateStore[mapID]).selected;
    selected = selectedChanges.some(selected => selected === ID);
  }
  return selected;
}

export function RenderSelectedContent({
  mapID,
  ID,
  renderChild,
  childprops,
}: RenderSelectedContentProps) {
  const isSelected = useSelectionSnapShot(ID, mapID);

  const renderProp = {
    ...childprops,

    isSelected,
  };

  return (
    <div
      style={{
        width: 'max-content',
        position: 'relative',
      }}
    >
      {renderChild(renderProp)}
    </div>
  );
}
