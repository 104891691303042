import { useEffect, useRef } from 'react';
import { hotKeyStateStore } from 'utils/stores/hotKeyStatesStore';

const useAltkey = () => {
  const modifierKey = 'Alt';
  const handleAtkeyDown = (e: React.KeyboardEvent) => {
    if (e.key === modifierKey) {
      hotKeyStateStore.current.isAltActive = true;
    }
  };

  const handleAltKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === modifierKey) {
      hotKeyStateStore.current.isAltActive = false;
    }
  };
  return {
    handleAltKeyUp,
    handleAtkeyDown,
  };
};

export default useAltkey;
