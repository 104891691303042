import { useEffect } from "react";
import { PreviewData } from "utils/stores/types";

interface HoverWrapperProps {
    children: React.ReactNode;
    preview?: PreviewData;
    drag?: any
    radius?: number,
    width?: number,
    height?: number
    classToEffect?: string,
    hoverEffectStyle: any,
    removeHoverEffectStyle?: any
}

export const HoverWrapper = ({ radius = 32, width, height, ...props }: HoverWrapperProps) => {
    const classToHover = document.querySelector(`.${props.classToEffect}`) as HTMLElement
    const newStyles = { ...props.hoverEffectStyle };

    const toggleHover = (mouseIn: boolean) => {
        if (!classToHover) {
            console.error(`${classToHover} is null or undefined`);
            return;
        }

        if (mouseIn) {
            Object.keys(newStyles).forEach((styleProperty) => {
                classToHover.style.setProperty(styleProperty, newStyles[styleProperty]);
                classToHover.style.setProperty('transition', 'all 0.3s ease-in-out');
            });
            return
        }

        if (!mouseIn) {
            if (props?.removeHoverEffectStyle) {
                Object.keys(props.removeHoverEffectStyle).forEach((styleProperty) => {
                    classToHover.style.setProperty(styleProperty, props.removeHoverEffectStyle[styleProperty]);
                });
            } else {
                Object.keys(newStyles).forEach((styleProperty) => {
                    classToHover.style.setProperty(styleProperty, '');
                });
            }
        }
    }

    useEffect(() => {
        
    }, [])

    return (
        <div
            className={`preview-con`}
            onMouseOver={() => toggleHover(true)}
            onMouseLeave={() => toggleHover(false)}
            style={{
                width: `${width ? width + radius : 0 + radius}px`,
                height: `${height ? height + radius : 0 + radius}px`,
                display: 'flex',
                overflow: 'visible',
                justifyContent: 'center',
            }}
        >
            {props.children}      
        </div>
    )
}