import { hotKeysStore } from 'utils/stores/hotKeysStore';

export const isShortcutUsed = (shortcut: string) => {
  const allShortcuts = {
    ...hotKeysStore.itemHotkeys,
    ...hotKeysStore.themeHotkeys,
    ...hotKeysStore.mapHotkeys,
    ...hotKeysStore.globalHotkeys,
  };

  const normalizeShortcut = (s: string) => s.toLowerCase().replace(/\s+/g, '');

  const normalizedInput = normalizeShortcut(shortcut);
  const normalizedShortcuts = Object.keys(allShortcuts).map(normalizeShortcut);

  return normalizedShortcuts.includes(normalizedInput);
};
