// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type NavigationTravelPointerRightIconProps =
  React.ComponentProps<"svg"> & {
    title?: string;
  };

export function NavigationTravelPointerRightIcon(
  props: NavigationTravelPointerRightIconProps
) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 24 24"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M11 2a1 1 0 00-1 1v2h2V3a1 1 0 00-1-1zm-1 18v-7h2v7h1a1 1 0 110 2H9a1 1 0 110-2h1z"
        }
        fill={"currentColor"}
      ></path>

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M15.525 7.019c-.073-.015-.158-.019-.592-.019H8c-.48 0-.79 0-1.026.017-.228.015-.315.042-.357.06a1 1 0 00-.54.54c-.018.042-.045.129-.06.357C6 8.21 6 8.52 6 9s0 .79.017 1.026c.015.228.042.315.06.357a1 1 0 00.54.54c.042.018.129.045.357.06C7.21 11 7.52 11 8 11h6.933c.434 0 .52-.004.592-.019a1 1 0 00.244-.08c.067-.033.137-.08.484-.341l.587-.44c.45-.337.732-.55.928-.726.156-.14.193-.204.198-.214a.5.5 0 000-.36c-.005-.01-.042-.073-.198-.214-.196-.176-.479-.39-.928-.726l-.587-.44c-.347-.26-.417-.308-.484-.34a1 1 0 00-.244-.081zm2.442 2.16v.001zM15 5c.33 0 .621 0 .909.056.253.05.498.131.73.243.264.128.497.303.76.5l.054.041.62.465c.406.304.762.571 1.032.814.282.254.564.561.725.975a2.5 2.5 0 010 1.812c-.161.414-.443.721-.725.975-.27.243-.626.51-1.031.814l-.62.465-.054.04c-.264.198-.497.373-.76.5a3.001 3.001 0 01-.731.244C15.62 13 15.33 13 15 13H7.968c-.439 0-.817 0-1.13-.021a3.072 3.072 0 01-.986-.207 3 3 0 01-1.624-1.624 3.07 3.07 0 01-.207-.986C4 9.85 4 9.471 4 9.032v-.064c0-.439 0-.817.021-1.13.023-.33.072-.66.207-.986a3 3 0 011.624-1.624 3.07 3.07 0 01.986-.207C7.15 5 7.529 5 7.968 5H15z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default NavigationTravelPointerRightIcon;
/* prettier-ignore-end */
