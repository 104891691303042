import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useDebouncedValue } from '@mantine/hooks';
import { StyleShortcut } from 'components/themeAndStyleSettings/style/StyleSection';
import {
  useDeleteFromMapStyleShortcuts,
  useDeleteFromMyGlobalStyleShortcuts,
  useUpdateSingleGlobalStyleShortcut,
  useUpdateSingleMapStyleShortcut,
} from 'api/mutations';
import { useParams } from 'react-router-dom';
// import { useAddGlobalStyleShortcut } from 'api/mutations';

export const useStyleSetting = (
  styleItem: StyleShortcut,
  sectionTitle: string
) => {
  const mapId = useParams().mapId;
  const [active, setActive] = useState(styleItem.isActive || false);
  const [item, setItem] = useState<StyleShortcut | null>(styleItem || null);
  const [initialItem, setInitialItem] = useState<StyleShortcut | null>(
    styleItem || null
  );
  const [itemTitle, setItemTitle] = useState(item?.name || '');
  const [isFormModified, setIsFormModified] = useState(false);

  const handleSaveShortcut = (key, shortcut) => {
    if (shortcut) {
      const shortcutExists =
        item?.shortcut === shortcut || item?.alternativeShortcut === shortcut;

      if (shortcutExists) {
        toast.error('Shortcut combination already taken!');
        return;
      }

      setItem(prevItem => ({
        ...prevItem,
        [key]: shortcut ? shortcut : item?.shortcut,
      }));
    }
  };

  const {
    assignedHotkey = '',
    customWidth = '',
    label = '',
    ...remainingStyles
  } = item?.styleObject ? JSON.parse(item.styleObject) : {};

  const [css, setCss] = useState(JSON.stringify(remainingStyles, null, 2));
  const [debouncedCss] = useDebouncedValue(css, 500);
  const [debouncedTitle] = useDebouncedValue(itemTitle, 500);

  // const { mutateAsync: addGlobalStyles, isPending } = useAddGlobalStyleShortcut(
  //   item._id
  // );

  const deleteGlobalStyleShortcut = useDeleteFromMyGlobalStyleShortcuts();
  const deleteMapStyleShortcut = useDeleteFromMapStyleShortcuts();

  const updateMapStyleShortcut = useUpdateSingleMapStyleShortcut();
  const updateGlobalStyleShortcut = useUpdateSingleGlobalStyleShortcut();

  const handleSave = async () => {
    // ! Might need to add other fields here
    // const { id, ...remainingItem } = item;

    if (!item.id) return;

    // console.log(remainingItem);
    const finalData = {
      shortcut: item,
    };

    const mutationData = {
      mapId,
      shortcutId: item.id,
      data: finalData,
    };

    if (sectionTitle === 'User Global') {
      updateGlobalStyleShortcut.mutateAsync(mutationData).then(() => {
        // Save the item and reset the isFormModified state
        setInitialItem(item);
        setIsFormModified(false);
      });
    }

    if (sectionTitle === 'Map') {
      updateMapStyleShortcut.mutateAsync(mutationData).then(() => {
        // Save the item and reset the isFormModified state
        setInitialItem(item);
        setIsFormModified(false);
      });
    }

    // await addGlobalStyles(remainingItem);
  };

  const handleDeleteStyleShortcut = (styleShortcut: StyleShortcut) => {
    if (!styleShortcut) return;

    if (sectionTitle === 'User Global') {
      const finalData = {
        shortcutIds: [styleShortcut.id],
      };
      deleteGlobalStyleShortcut.mutate(finalData);
    }

    if (sectionTitle === 'Map') {
      deleteMapStyleShortcut.mutate({ mapId, shortcutId: styleShortcut.id });
    }
  };

  useEffect(() => {
    if (debouncedCss && item) {
      try {
        const newStyle = JSON.parse(debouncedCss);
        const existingStyles = JSON.parse(item.styleObject || '{}');
        setItem(prevItem => ({
          ...prevItem,
          styleObject: JSON.stringify({ ...existingStyles, ...newStyle }),
        }));
      } catch (error) {
        toast.error('Error updating style object');
      }
    }
  }, [debouncedCss, item]);

  useEffect(() => {
    setItem(prevItem => ({
      ...prevItem,
      name: debouncedTitle,
    }));
  }, [debouncedTitle]);

  useEffect(() => {
    if (JSON.stringify(item) !== JSON.stringify(initialItem)) {
      setIsFormModified(true);
    } else {
      setIsFormModified(false);
    }
  }, [item, initialItem]);

  return {
    active,
    setActive,
    item,
    setItem,
    itemTitle,
    setItemTitle,
    handleSaveShortcut,
    assignedHotkey,
    customWidth,
    label,
    css,
    setCss,
    isFormModified,
    handleSave,
    handleDeleteStyleShortcut,
    deleteLoading:
      deleteGlobalStyleShortcut.isPending || deleteMapStyleShortcut.isPending,
    updateLoading:
      updateMapStyleShortcut.isPending || updateGlobalStyleShortcut.isPending,
    // requestLoading: isPending,
  };
};
