import { Matrix } from 'CoreComponents/Canvas/InfiniteCanvas';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { emptyNoteItem } from 'utils/constants';
import { handleCopy } from 'utils/copyCutAndPaste/copy';
import { handleCut } from 'utils/copyCutAndPaste/cut';
import pasteFn from 'utils/copyCutAndPaste/paste';
import { getTranslateFromMatrix } from 'utils/helpers/Canvas/clamp-zoom';
import getSelectedIDs from 'utils/helpers/Selection/selectionUtils';
import { useKeyDownLayout } from 'utils/hooks/hotKeys/useKeyDownLayout';
import useHighlightMap from 'utils/hooks/useHighlightMap';
// import usePaste from 'utils/hooks/usePaste';
import { createNewItem } from 'utils/mapStoreFN/mapStoreFN_items';
import {
  moveItemInStackDown,
  moveItemInStackUp,
  selectOrDeselectItemsInStackDown,
  selectOrDeselectItemsInStackUp,
} from 'utils/mapStoreFN/mapStoreFN_stacks';
import { toggleThemeModal } from 'utils/stores/dashboardStore';
import { gestureStore, setIsEditing } from 'utils/stores/gestureStore';
import { HotkeysProps } from 'utils/stores/hotKeysStore';
import {
  currentMapContentStore,
  currentMapStateStore,
  getMap,
  getSelectedNoteIDAndStackAtPosition,
  updateItemOrAreaStyle,
} from 'utils/stores/mapStore';
import { redo, undo } from 'utils/stores/proxyWithHistory';
import { setMapshotMode } from 'utils/stores/mapshotStore';

// ? From RenderSelector.tsx File
// mod + shift + a (Create area)
// shift + r  (Reset stacks)

// ? From useStackItems.tsx File
// alt + mod + s (Stack items)

export default function useMapHotkeys() {
  const { mapId } = useParams();

  const { highlightEntireMap } = useHighlightMap(mapId);

  // const { handlePaste } = usePaste(mapId);

  const canvasTransform = currentMapStateStore[mapId]?.canvas?.CanvasTransform;

  const currentTranslate = getTranslateFromMatrix(new Matrix(canvasTransform));

  const [viewportWidth] = useState(window.innerWidth);
  const [viewportHeight] = useState(window.innerHeight);

  function createNewNoteInStack(e: KeyboardEvent, isShiftPressed?: boolean) {
    setIsEditing(true);

    const { stackID, itemID } = getSelectedNoteIDAndStackAtPosition(mapId);
    if (stackID && itemID) {
      createNewItem(
        stackID,
        emptyNoteItem,
        mapId,
        itemID,
        isShiftPressed,
        false
      );
    }
  }

  function toggleNewMapStyleModal() {
    const selectedItems = getSelectedIDs();
    if (!selectedItems.length) return;

    if (selectedItems.length > 1) {
      toast.error(`You can't add styles for multiple items`);
      return;
    }

    currentMapStateStore[mapId].openNewStyleShortcut =
      !currentMapStateStore[mapId].openNewStyleShortcut;
  }

  const defaultMapKeys: HotkeysProps = {
    Cut: {
      shortcut: 'mod+x',
      alternativeShortcut: null,
      action: () => handleCut(mapId),
      shouldPreventDefault: true,
      isCustomizable: true,
      description:
        'Cut a selected item and save to the clipboard for pasting elsewhere.',
    },
    Copy: {
      shortcut: 'mod+c',
      alternativeShortcut: null,
      action: () => handleCopy(mapId),
      shouldPreventDefault: true,
      isCustomizable: true,
      description:
        'Copy a selected item and save to the clipboard for pasting elsewhere.',
    },

    Paste: {
      shortcut: 'mod+v',
      alternativeShortcut: null,
      action: e => {
        const activeElement = document.activeElement;
        const tag = activeElement.tagName.toLowerCase();

        if (tag !== 'input') {
          e.preventDefault();
          e.stopPropagation();
          pasteFn(mapId);
        }
      },
      shouldPreventDefault: false,
      isCustomizable: true,
      description: 'Paste copied or cut items from MapMap or external sources',
    },

    Undo: {
      shortcut: 'mod+z',
      alternativeShortcut: null,
      action: e => {
        const activeElement = document.activeElement;
        const tag = activeElement.tagName.toLowerCase(); // Get the tag name in lowercase

        // if (tag !== 'input') {
        e.preventDefault();
        e.stopPropagation();
        undo(getMap(mapId));
        // }
      },
      shouldPreventDefault: false,
      isCustomizable: true,
      description: 'Undo previous actions',
    },
    Redo: {
      shortcut: 'mod+shift+z',
      alternativeShortcut: null,
      action: () => redo(getMap(mapId)),
      shouldPreventDefault: true,
      isCustomizable: true,
      description: 'Redo last undone actions',
    },

    'Screenshot Section': {
      shortcut: 'mod+shift+m',
      alternativeShortcut: null,
      action: () => setMapshotMode(true),
      shouldPreventDefault: true,
      isCustomizable: true,
      description: 'Take a screenshot of the entire map file',
    },

    'Highlight Map': {
      shortcut: 'mod+a',
      alternativeShortcut: null,
      action: () => highlightEntireMap(),
      shouldPreventDefault: true,
      isCustomizable: true,
      ignore: ['INPUT', 'TEXTAREA'],
      description: 'Select every item on the map file',
    },

    //   'Stack Items': {
    //     shortcut: ['mod+alt+s'],
    // alternativeShortcut: null,
    // action: ()=>  null,
    //     shouldPreventDefault: true,
    //     isCustomizable: true,
    //   },

    'Create New Note Below': {
      shortcut: 'mod+enter',
      alternativeShortcut: null,
      action: createNewNoteInStack,
      shouldPreventDefault: true,
      isCustomizable: true,
      description: 'Create a new note item under a selected item',
    },

    'Create New Note Above': {
      shortcut: 'mod+shift+enter',
      alternativeShortcut: null,
      action: (e: KeyboardEvent) => createNewNoteInStack(e, true),
      shouldPreventDefault: true,
      isCustomizable: true,
      description: 'Create a new note item above a selected item',
    },
    'Wrap Text': {
      shortcut: 'mod+alt+shift+R',
      alternativeShortcut: null,
      action: (e: KeyboardEvent) => {
        const selected = currentMapStateStore[mapId].selected;

        selected &&
          selected.forEach(ID => {
            updateItemOrAreaStyle(
              ID,
              { wrap: true, customWidth: null },
              mapId,
              null,
              null,
              true
            );
          });
      },
      shouldPreventDefault: true,
      isCustomizable: true,
    },

    /*'Save Map': {
      shortcut: ['mod+shift+m'],
      alternativeShortcut: null,
      action: () =>
        send({ type: EVENTS.SAVEMAP /!*, trigger: TRIGGERS.MANUAL*!/ }),
      shouldPreventDefault: true,
      isCustomizable: true,
    },*/

    'Move item up in a stack': {
      shortcut: 'mod+arrowUp',
      alternativeShortcut: null,
      action: () => {
        moveItemInStackUp(
          getSelectedIDs(),
          currentMapContentStore[mapId].value.stacks
        );
      },
      shouldPreventDefault: true,
      isCustomizable: true,
    },

    'Move item down in a stack': {
      shortcut: 'mod+arrowDown',
      alternativeShortcut: null,
      action: () => {
        moveItemInStackDown(
          getSelectedIDs(),
          currentMapContentStore[mapId].value.stacks
        );
      },
      shouldPreventDefault: true,
      isCustomizable: true,
    },

    'Select item down in a stack': {
      shortcut: 'shift+arrowDown',
      alternativeShortcut: null,
      action: () => {
        selectOrDeselectItemsInStackDown(
          getSelectedIDs(),
          currentMapContentStore[mapId].value.stacks,
          mapId
        );
      },
      shouldPreventDefault: true,
      isCustomizable: true,
    },

    'Select item up in a stack': {
      shortcut: 'shift+arrowUp',
      alternativeShortcut: null,
      action: () => {
        selectOrDeselectItemsInStackUp(
          getSelectedIDs(),
          currentMapContentStore[mapId].value.stacks,
          mapId
        );
      },
      shouldPreventDefault: true,
      isCustomizable: true,
    },

    'Theme Shortcut Modal': {
      shortcut: 'mod+shift+s',
      alternativeShortcut: null,
      action: () => toggleThemeModal(),
      isCustomizable: true,
      shouldPreventDefault: true,
      description:
        'Open Style shortcuts modal for both general and map style shortcuts',
    },

    // Temporary Shortcut to be removed
    'Open Add Style Shortcut': {
      shortcut: 'mod+shift+y',
      alternativeShortcut: null,
      action: () => {
        toggleNewMapStyleModal();
      },
      shouldPreventDefault: true,
      isCustomizable: true,
      description: 'Open modal used to add a style to the style shortcuts',
    },

    // 'Show Map Search': {
    //   shortcut: 'mod+shift+o',
    // alternativeShortcut: null,
    // alternativeShortcut: null,
    // action: () => toggleMapFolderSearchView(),
    //   shouldPreventDefault: true,
    //   isCustomizable: true,
    // },
  };

  return { defaultMapKeys };
}

// These are other hotkeys used on the map, that don't generally need to be
// grouped with the ones above
export const useArrowKeysForSelectedItems = (callback: Function) => {
  const keys = ['ArrowDown', 'ArrowUp'];
  const preventDefault = 'preventDefault';

  return useKeyDownLayout(callback, keys, preventDefault);
};

export const useBackspaceForDelete = (callback: Function) => {
  const keys = ['Backspace'];
  const preventDefault = undefined;

  return useKeyDownLayout(callback, keys, preventDefault);
};
