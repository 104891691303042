import axios from 'axios';
import React from 'react';
// import { ProProUserButton } from 'propro-shared-components';

const TestAuth = () => {
  const handleAuth = async () => {
    try {
      const response = await axios.post('/api/auth');
      console.log('response:', response.data);

      if (response.data.redirectUrl) {
        window.location.href = response.data.redirectUrl;
      }
    } catch (error) {
      console.error('Error during authentication:', error);
    }
  };

  return (
    <div className="grid place-items-center w-full h-[100vh]">
      {/* <ProProUserButton /> */}
      <button className="bg-blue-500 text-black p-4" onClick={handleAuth}>
        Signup/Login
      </button>
    </div>
  );
};

export default TestAuth;
