import QuickAccess from 'PlasmicComponents/QuickAccess';
import { toggleQuickAccessModal } from 'utils/stores/dashboardStore';

import QuickAccessToSection from './sidebar/TopSection';
import QuickAccessModalItems from './modal/QuickAccesItems';

export default function QuickAccessModal() {
  return (
    <div
      onClick={toggleQuickAccessModal}
      className="fixed z-50 w-full h-full flex justify-center"
    >
      <div
        style={{
          zIndex: 100,
          margin: 'auto',
        }}
        className="h-fit max-h-[80vh]"
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <QuickAccess
          topSectionSlot={<QuickAccessToSection isInModal />}
          quickAccessItems={<QuickAccessModalItems />}
          isModal
        />
      </div>
    </div>
  );
}
