import getSelectedIDs from 'utils/helpers/Selection/selectionUtils';
import { getArea } from 'utils/mapStoreFN/mapStoreFN_areas';
import { getParentIdFromItemID } from 'utils/mapStoreFN/mapStoreFN_stacks';
import selectedContentsStore, {
  SelectedContent,
} from 'utils/stores/Selection/selectedContentStore';
import { currentMapContentStore, getStackData } from 'utils/stores/mapStore';
import { StackData } from 'utils/stores/types';

export const checkBothStacksAndAreas = (
  selectedIDs: string[],
  stackIds: string[],
  mapId: string
) => {
  const allStacksComplete = stackIds.every(stackId =>
    areAllItemsSelectedInStack(getStackData(stackId, mapId))
  );

  const allAreaData = selectedIDs
    .filter(id => id.includes('area_'))
    .map(id => getArea(id, mapId));

  const allAreasComplete = allAreaData.every(area =>
    areAllStackItemsSelectedInArea(area.encompassingStacks, selectedIDs, mapId)
  );

  return allAreaData?.length
    ? allStacksComplete && allAreasComplete
    : allStacksComplete;
};

const areAllItemsSelectedInStack = (stack: StackData) => {
  const selectedIDs = getSelectedIDs();

  return stack.items.every(item => selectedIDs.includes(item.itemId));
};

function getMapStore(mapId: string) {
  return currentMapContentStore[mapId];
}

export const extractAreasIdsAndItemsIds = (mapId: string) => {
  let itemsAndAreas: SelectedContent[] = [];

  const allStacks = getMapStore(mapId).value.stacks;
  const allItems = [];
  if (allStacks.length) {
    for (let i = 0; i < allStacks.length; i++) {
      const stack = allStacks[i];
      stack.items.forEach(item =>
        allItems.push({
          contentID: item.itemId,
          type: item.type,
          parentType: 'stack' as 'stack',
          parentID: stack.stackId,
        })
      );
    }

    if (allItems?.length) itemsAndAreas.push(...allItems.map(item => item));
  }

  const allAreas = getMapStore(mapId).value.areas.map(area => {
    return {
      contentID: area.areaId,
      type: 'Area' as 'Area',
      parentType: 'area' as 'area',
      parentID: area.areaId,
    };
  });

  if (allAreas.length) itemsAndAreas.push(...allAreas);

  return itemsAndAreas;
};

export const areArraysEqual = (array1: string[], array2: string[]) => {
  if (array1.length !== array2.length) return false;

  // Create copies of arrays to avoid modifying the original arrays
  const arr1Copy = [...array1];
  const arr2Copy = [...array2];

  // Sort the copies of arrays
  arr1Copy.sort();
  arr2Copy.sort();

  // Compare sorted arrays element by element
  for (let i = 0; i < arr1Copy.length; i++) {
    if (arr1Copy[i] !== arr2Copy[i]) return false;
  }

  return true;
};

export const getUnselectedItems = (
  selectedContent: SelectedContent[],
  mapId: string
) => {
  if (!selectedContent.length)
    return {
      hasUnselectedItems: false,
      unselectedItems: [],
      stackIds: [],
    };

  let allUnselectedItems = [];

  const onlyItems = selectedContent.filter(
    selected => selected.type === 'Note'
  );

  const stackIds = Array.from(
    new Set(onlyItems.map(selected => selected.parentID))
  );

  stackIds.forEach(stackId => {
    const stackData = getStackData(stackId, mapId);

    if (stackData) {
      // if stack is contained in area
      if (stackData.isContainedInArea !== 'none') {
        const areaId = stackData.isContainedInArea;
        const areaData = getArea(areaId, mapId);
        //   When there are stacks within an area with unselected items
        //   and when the present stackData has all it's items selected
        if (
          !areAllStackItemsSelectedInArea(
            areaData.encompassingStacks,
            selectedContent.map(selected => selected.contentID),
            mapId
          ) &&
          areAllItemsSelectedInStack(stackData)
        ) {
          areaData.encompassingStacks.forEach(stackId => {
            const newStackData = getStackData(stackId, mapId);
            if (newStackData) {
              const items = processItems(newStackData);
              allUnselectedItems = [...allUnselectedItems, ...items];
            }
            return;
          });
        } else {
          const items = processItems(stackData);
          allUnselectedItems = [...allUnselectedItems, ...items];
        }
      } else {
        const items = processItems(stackData);
        allUnselectedItems = [...allUnselectedItems, ...items];
      }
    }
  });

  return {
    hasUnselectedItems: allUnselectedItems.length > 0,
    unselectedItems: allUnselectedItems,
    stackIds: [],
  };
};

export const areAllStackItemsSelectedInArea = (
  stackIds: string[],
  selectedIDs: string[],
  mapId: string
) => {
  const allStacks = Array.from(
    new Set(stackIds.map(stackId => getStackData(stackId, mapId)))
  );

  const items = allStacks.flatMap(stack => stack.items);
  return items.every(item => selectedIDs.includes(item.itemId));
};

const processItems = (stackData: StackData, returnSelected?: boolean) => {
  const selectedIDs = getSelectedIDs();

  return stackData.items
    .filter(item =>
      returnSelected
        ? selectedIDs.includes(item.itemId)
        : !selectedIDs.includes(item.itemId)
    )
    .map(item => {
      return {
        contentID: item.itemId,
        type: item.type as 'Note',
        parentType: 'stack' as 'stack',
        parentID: stackData.stackId,
      };
    });
};

// ? This temporary for the older selection implementation
export const getSelectedContentV1 = (mapId: string) => {
  const selectedIDs = getSelectedIDs();

  const selectedContent: SelectedContent[] = [];

  const stackIds = Array.from(
    new Set(
      selectedIDs
        .filter(id => id.includes('item_'))
        .map(itemId => {
          return getParentIdFromItemID(itemId);
        })
    )
  );

  const areaIds = Array.from(
    new Set(selectedIDs.filter(id => id.includes('area_')))
  );

  if (stackIds.length) {
    stackIds.forEach(stackId => {
      const stackData = getStackData(stackId, mapId);

      const items = processItems(stackData, true);

      selectedContent.push(...items);
    });
  }

  if (areaIds.length) {
    areaIds.forEach(areaId => {
      selectedContent.push({
        contentID: areaId,
        type: 'Area' as 'Area',
        parentType: 'area' as 'area',
        parentID: areaId,
      });
    });
  }

  return {
    selectedIDs,
    selectedContent,
  };
};
