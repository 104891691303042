import { RenderElementProps, RenderLeafProps } from 'slate-react';
import { LinkElement, ImageElement } from './slatetypes';
import { themeStore } from 'utils/stores/themeStore';
import { Descendant } from 'slate';
import { SlateReactPresentation } from 'slate-react-presentation';
import { ItemData, NoteItemData } from 'utils/stores/types';
import { styled } from '@stitches/react';
import React from 'react';

export function Link(props: RenderElementProps) {
  return (
    <a
      {...props.attributes}
      target="_blank"
      href={(props.element as LinkElement).url}
      style={{
        color: themeStore.defaultLinkColor,
      }}
      onClick={e => {
        if (e.metaKey) {
          window.open((props.element as LinkElement).url, '_blank');
        }
      }}
    >
      {props.children}
    </a>
  );
}

export function Image(props: RenderElementProps) {
  return (
    <div
      {...props.attributes}
      style={{
        width: '100%',
      }}
    >
      <span style={{ display: 'none' }}>{props.children}</span>
      <img
        src={(props.element as ImageElement).url}
        style={{
          minWidth: '50px',
          width: '100%',
          height: 'auto',
          objectFit: 'fill',
        }}
        onDragStart={event => event.preventDefault()}
      />
    </div>
  );
}

export function ParagraphElement(props: RenderElementProps) {
  return (
    <p
      {...props.attributes}
      style={{
        fontWeight: 'inherit',
        padding: ' 0px 2px 2px 2px',
      }}
    >
      {props.children}
    </p>
  );
}

export function Elements(Element: RenderElementProps) {
  switch (Element.element.type) {
    case 'Link':
      return <Link {...Element} />;
    case 'Image':
      return <Image {...Element} />;
    case 'Paragraph':
      return <ParagraphElement {...Element} />;

    default:
      return <ParagraphElement {...Element} />;
  }
}

export const Leaf = ({ attributes, children, leaf }: RenderLeafProps) => {
  if (leaf.isBold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.isItalic) {
    children = <em>{children}</em>;
  }

  if (leaf.isUnderlined) {
    children = <u>{children}</u>;
  }
  if (leaf.highlight) {
    children = (
      <span
        style={{
          backgroundColor: '#FF7F50',
          padding: '0px 2px',
        }}
      >
        {children}
      </span>
    );
  }

  return <span {...attributes}>{children}</span>;
};

const SlateViewStyle = styled('div', {
  cursor: 'inherit',
  WebkitUserSelect: 'none',
  userSelect: 'none',
  width: '100%',
  fontSize: `inherit`,
  wordBreak: 'break-word',
});

function LeafWrapper({ children }: { children: any }) {
  return <span key={'leaf'}>{children}</span>;
}

type SlateProps = {
  slateContent: Descendant[];
  item: ItemData;
  hide: boolean;
};

export function SlateView({ slateContent, item, hide }: SlateProps) {
  let id = 0;
  return (
    <SlateViewStyle
      css={{
        display: hide ? 'none' : 'unset',
        color: item?.style?.textColor || themeStore.defaultColor,
        fontWeight: item?.style?.formattingOfTheEntireNote?.isBold
          ? 'bold'
          : 'initial',
        fontStyle: item?.style?.formattingOfTheEntireNote?.isItalic
          ? 'italic'
          : 'normal',
        textDecoration: item?.style?.formattingOfTheEntireNote?.isUnderlined
          ? 'underline'
          : '',
        borderRadius: (item as NoteItemData)?.style?.border?.isSquared
          ? '0px'
          : '1em',
      }}
    >
      <SlateReactPresentation
        key={'prevesentation'}
        value={slateContent}
        LeafWrapper={'span'}
        renderElement={props => {
          return <Elements {...props} key={`el${id++}`} />;
        }}
        renderLeaf={props => {
          return <Leaf {...props} key={`le${id++}`} />;
        }}
      />
    </SlateViewStyle>
  );
}

function shouldRerender(prev: SlateProps, next: SlateProps) {
  if (prev.item.lastInteraction !== next.item.lastInteraction) {
    return false;
  }
  if (prev.hide !== next.hide) {
    return false;
  }

  true;
}

export const SlateViewMemo = React.memo(SlateView, shouldRerender);
