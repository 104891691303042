import { CSSProperties } from 'react';
import './GeneralSkeleton.css';

interface GeneralSkeletonProps {
  width?: number;
  height: number;
  borderRadius?: number;
  style?: CSSProperties;
}

export default function GeneralSkeleton({
  width,
  height,
  borderRadius = 0,
  style,
}: GeneralSkeletonProps) {
  return (
    <div
      style={{
        width: `${width ? `${width}px` : '100%'}`,
        height: `${height}px`,
        borderRadius: `${borderRadius}px`,
        backgroundColor: '#9ca3af',
        opacity: 0.001,
        animation: 'pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite',
        ...style,
      }}
    />
  );
}
