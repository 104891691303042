import { useHotkeys } from '@mantine/hooks';
import { ColorPicker } from 'antd';
import type { Color } from 'antd/es/color-picker';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import {
  AnchorPosition,
  currentMapContentStore,
  currentMapStateStore,
  updateItemOrAreaStyle,
} from 'utils/stores/mapStore';
import { themeStore } from 'utils/stores/themeStore';
import { ItemData } from 'utils/stores/types';
import useESC from 'utils/hooks/hotKeys/useESC';
import { RenderPostion } from 'utils/hooks/useAnchor';
import { anchorMode } from 'utils/stores/mapMode/modesStore';
import getSelectedIDs from 'utils/helpers/Selection/selectionUtils';

type ColorItem = {
  color: string;
  id: number;
};

type ColorItemProps = {
  id: number;
  color: string;
  isSelected: boolean;
  onChange: (color: Color, id: number) => void;
  mapID: string;
  openColorPicker: boolean;
  setSelected: React.Dispatch<React.SetStateAction<number>>;
  setOpenColorPicker: React.Dispatch<React.SetStateAction<number | null>>;
};

/**
 * Global value that is updated if the user makes a
 * change to the themeStoreColorPalete
 * this allows us to keep updating the component palete and only
 * make change to the valtio themestore if the value changes.
 * ensuring we don't trigger a re-render of any component calling a snapShot of it
 */

let updateThemeStore: null | ColorItem[] = null;

const getStyles = (ID: string, mapId: string) => {
  //TODO improve this function
  let style = {
    BORDER: 'inherit',
    FILL: 'inherit',
    TEXT: 'inherit',
    CLOSE: '',
  };

  if (ID && (ID || '').includes('area_')) {
    for (let area of currentMapContentStore[mapId].value.areas) {
      if (area.areaId === ID) {
        style.BORDER = area.style?.border?.color || 'inherit';
        style.FILL = area.style?.fillColor || 'inherit';
        style.TEXT = area.style?.textColor || 'inherit';
        break;
      }
    }
  } else {
    for (let stack of currentMapContentStore[mapId].value.stacks) {
      const itemData: ItemData | undefined = stack.items.find(
        item => item.itemId === ID
      );
      if (itemData) {
        style.BORDER = itemData.style?.border?.color || 'inherit';
        style.FILL = itemData.style?.fillColor || 'inherit';
        style.TEXT = itemData.style?.textColor || 'inherit';
        break;
      }
    }
  }

  return style;
};

function applyChanges(color: string, mapId: string) {
  const getSelected: string[] = getSelectedIDs();
  const action = anchorMode.anchorComponent;
  if (getSelected.length === 0) return;
  switch (action) {
    case 'BORDER':
      getSelected.forEach(item => {
        updateItemOrAreaStyle(
          item,
          {
            border: {
              color: color,
            },
          },
          mapId,
          undefined,
          {
            path: 'border.color',
            value: color,
          },
          false,
          {
            border: {
              thickness: '_2',
            },
          }
        );
      });
      break;
    case 'FILL':
      getSelected.forEach(item => {
        updateItemOrAreaStyle(
          item,
          {
            fillColor: color,
          },
          mapId
        );
      });
      break;
    case 'TEXT':
      getSelected.forEach(item => {
        updateItemOrAreaStyle(
          item,
          {
            textColor: color,
          },
          mapId
        );
      });
      break;
    default:
      return;
  }

  anchorMode.reset();
  if (updateThemeStore === null) return;

  // update the themestoreColorPallet with the new colorPicker value if the user updates it or made a change
  themeStore.colorPalette = updateThemeStore.map(
    pickerValue => pickerValue.color
  );
  updateThemeStore = null;

  return;
}

export function ColorItem({
  id,
  color,
  isSelected,
  onChange,

  mapID,
  setSelected,
  openColorPicker,
  setOpenColorPicker,
}: ColorItemProps) {
  const btnRef = useRef<HTMLButtonElement>(null);

  // set focus on the colorSelector
  useEffect(() => {
    if (isSelected) {
      btnRef.current && btnRef.current.focus();
    }
  }, []);
  return (
    <ColorPicker
      key={id}
      value={color || '#000'}
      defaultValue={color || '#000'}
      onChange={color => {
        onChange(color, id);
      }}
      onChangeComplete={color => {
        // applyChanges(color.toHexString(), mapID);
      }}
      open={openColorPicker}
    >
      <button
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();

          if (!e.metaKey) {
            setOpenColorPicker(id);
            setSelected(id);
          }

          if (e.metaKey && color !== 'empty') {
            applyChanges(color, mapID);
            anchorMode.reset();
          } else if (color === 'empty') {
            setOpenColorPicker(id);
          }
          return;
        }}
        id={`colorPicker${id}`}
        ref={btnRef}
        className={` h-[20px] w-[20px]
           `}
        style={{
          outline: isSelected ? '2.5px solid #D73637' : 'none',
          borderColor: 'none',
          background: color || 'black',
          borderRadius: '2px',
          border: '1px solid #fff',
        }}
      />
    </ColorPicker>
  );
}

export function ColorItemLarge({
  color,

  onChange,
  mapId,
}: {
  color: string;

  mapId: string;
  onChange: (
    color: string,

    mapId: string
  ) => void;
}) {
  const [currentColor, setCurrentColor] = useState(color || '#fff');
  return (
    <div className={` w-fit h-fit  rounded-[8px] mx-0.5 flex my-0.5 `}>
      <ColorPicker
        placement="top"
        trigger={'hover'}
        value={currentColor}
        defaultFormat={'hex'}
        onChange={color => {
          setCurrentColor(color.toHexString());
        }}
        onChangeComplete={color => {
          const formatColor = color.toHexString();

          onChange(formatColor, mapId);
        }}
      >
        <button
          className="w-[40px] h-[45px]"
          style={{
            backgroundColor: currentColor,
            borderRadius: '4px',
            border: '1px solid #fff',
          }}
        />
      </ColorPicker>
    </div>
  );
}

type SelectedItemProps = {
  BORDER: string;
  FILL: string;
  TEXT: string;
  CLOSE: string;
};

export function ColorSelectionWrapper({
  renderChild,
  mapId,
  anchorPosition,
  lastSelectedID,
  renderPosition,
}: {
  renderChild: (props: RenderColorSelectorProps) => ReactNode;
  mapId: string;
  anchorPosition: AnchorPosition;
  lastSelectedID: string;
  renderPosition: RenderPostion;
}) {
  const colorSelectorRef = useRef(null);

  const renderStyle = useMemo(
    () => getStyles(lastSelectedID, mapId),
    [lastSelectedID]
  );

  return (
    <div
      ref={colorSelectorRef}
      id={'Color-selector'}
      style={{
        position: 'fixed',

        left: `${anchorPosition.x}px`,
        top: `${anchorPosition.midY || anchorPosition.y}px`,
        transform: `${
          renderPosition == 'Left'
            ? 'translate(-105%, -50%)'
            : 'translate(5%, -50%)'
        }`,
        transition: 'left 0.2s linear, top 0.2s linear',
        zIndex: '300',
      }}
    >
      {renderChild({ mapId, selectedItem: renderStyle, renderPosition })}
    </div>
  );
}

type RenderColorSelectorProps = {
  selectedItem: SelectedItemProps;
  mapId: string;
  renderPosition: RenderPostion;
  onColorChange?: (color: string) => void;
};

export function RenderColorSelector(props: RenderColorSelectorProps) {
  const [openColorPicker, setOpenColorPicker] = useState<null | number>(null);
  const [selected, setSelected] = useState(0);
  const { dispatch } = useESC();
  const [colors, setColors] = useState<ColorItem[]>(
    themeStore.colorPalette.map((color, index) => ({
      color,
      id: index,
    }))
  );

  const togglePicker = () => {
    setOpenColorPicker(selected);
  };

  const updateColorPallete = (color: Color, id: number) => {
    const updateArray = [...colors];
    updateArray[id].color = color.toHexString();

    setColors(updateArray);
    updateThemeStore = updateArray;
  };
  useHotkeys([
    [
      'ArrowUp',
      e => {
        e.stopPropagation();
        e.preventDefault();
        if (selected - 10 >= 0) {
          setSelected(selected - 10);
          return;
        } else {
          return;
        }
      },
    ],
    [
      'ArrowDown',
      e => {
        e.stopPropagation();
        e.preventDefault();
        if (selected + 10 <= 19) {
          setSelected(selected + 10);
          return;
        } else {
          return;
        }
      },
    ],
    [
      'ArrowRight',
      e => {
        e.stopPropagation();
        e.preventDefault();
        if (selected < 19) {
          setSelected(selected + 1);
          return;
        } else {
          setSelected(0);
        }
      },
    ],
    [
      'ArrowLeft',
      e => {
        e.stopPropagation();
        e.preventDefault();
        if (selected > 0) {
          setSelected(selected - 1);
          return;
        }
      },
    ],

    [
      'meta+enter',
      e => {
        e.stopPropagation();
        e.preventDefault();
        togglePicker();
      },
    ],
    [
      'ctrl+enter',
      e => {
        e.stopPropagation();
        e.preventDefault();
        togglePicker();
      },
    ],

    [
      'Enter',
      e => {
        e.stopPropagation();
        e.preventDefault();

        if (colors[selected].color !== 'empty') {
          applyChanges(
            colors[selected].color,

            props.mapId
          );
          anchorMode.reset();

          return;
        } else {
          togglePicker();
        }
      },
      ,
    ],
  ]);

  return (
    <div
      className="flex  items-center h-fit  p-0.2 bg-transparent  rounded-[4px]"
      onKeyDown={e => {
        if (e.key === 'Escape') {
          e.stopPropagation();
          const closeFunc = dispatch('color selector')!;

          closeFunc(setOpenColorPicker, openColorPicker, props.mapId);
        }
      }}
    >
      {props.renderPosition === 'right' && (
        <CaretLeftOutlined
          style={{
            color: '#332F30',
            margin: '-4px',
          }}
        />
      )}
      <div
        className={`flex rounded-[3px] p-0.5 ${
          props.renderPosition === 'Left' ? 'flex-row-reverse' : 'flex-row'
        }`}
        style={{
          backgroundColor: '#332F30',
        }}
      >
        <ColorItemLarge
          onChange={applyChanges}
          key={'main'}
          color={''}
          mapId={props.mapId}
        />
        <div
          className={` grid grid-cols-10 gap-1 w-[240px] justify-items-center content-center `}
        >
          {colors.map(color => (
            <ColorItem
              key={color.id}
              color={color.color}
              id={color.id}
              isSelected={color.id === selected}
              onChange={(color, id) => {
                updateColorPallete(color, id);
                if (props.onColorChange)
                  props?.onColorChange(color.toHexString());
              }}
              setSelected={setSelected}
              mapID={props.mapId}
              openColorPicker={openColorPicker === color.id}
              setOpenColorPicker={setOpenColorPicker}
            />
          ))}
        </div>
      </div>
      {props.renderPosition === 'Left' && (
        <CaretRightOutlined
          style={{
            color: '#332F30',
            margin: '-4px',
          }}
        />
      )}
    </div>
  );
}

/**
 ** NOTE
 * RenderColorSelector has a useState to track the user color selection process
 * having the usestate inside the color selection meant that
 * whenever the user move across the colors getStyle runs which gets the item from valtio store
 * this is not a good implementation hence the reason for abstracting the Color tabs into
 * RenderColorSelection and pushing the setSelected state down
 ** FLOW
 * Render ColorSelector
 * extract itemProps
 * pass it to RenderColorSelector (Since this is need just once)
 ** RenderColorSelector - FLOW
 *  user can update setSelected contineously
 * avoiding the expensive getStyle process.
 */

export function ColorSelection({
  lastSelectedID,
  anchorPosition,
  renderPosition,
  mapId,
}: any) {
  return (
    <>
      <ColorSelectionWrapper
        anchorPosition={anchorPosition}
        lastSelectedID={lastSelectedID}
        mapId={mapId}
        renderPosition={renderPosition}
        renderChild={props => <RenderColorSelector {...props} />}
      />
    </>
  );
}
