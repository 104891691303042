import { useEffect } from 'react';
import { hotKeyStateStore } from 'utils/stores/hotKeyStatesStore';

const MouseMoveListener = () => {
  const handleMouseMove = (e: MouseEvent) => {
    if (!hotKeyStateStore.current.resetOnMouseMove) return;

    hotKeyStateStore.current.isShiftActive = e.shiftKey;
    hotKeyStateStore.current.isAltActive = e.altKey;
    hotKeyStateStore.current.isMetaActive = e.metaKey;
    hotKeyStateStore.current.isCtrlActive = e.ctrlKey;
    hotKeyStateStore.current.isModActive = e.metaKey || e.ctrlKey;
  };

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return null;
};

export default MouseMoveListener;
