import {
  applyStyleToSelectedItems,
  getNextTextAlignment,
  textAlignments,
} from 'utils/helpers/itemStyleOperations';
import getSelectedIDs from 'utils/helpers/Selection/selectionUtils';
import { getStackAndItem } from 'utils/mapStoreFN/mapStoreFN_stacks';
import { setNextStyleValue } from 'utils/mapStoreFN/mapStoreFN_style';
import { anchorMode } from 'utils/stores/mapMode/modesStore';
import { applyStyleObject } from 'utils/stores/mapStore';

export default function useItemStyleOperations(mapId: string) {
  //Function used to apply the text alignment to an item/group of selected items
  const applyTextAlignment = (direction: 'ArrowLeft' | 'ArrowRight') => {
    applyStyleToSelectedItems(mapId, itemData => {
      const alignment = itemData.style?.textAlignment || 'left';
      const presentAlignment =
        textAlignments[alignment as keyof typeof textAlignments];
      const styleValue =
        getNextTextAlignment(presentAlignment, direction) || 'left';
      return { propertyValue: styleValue, propertyName: 'textAlignment' };
    });
  };

  //Function used to apply (increment or decrement) font size to an item/group of selected items
  const applyFontSize = (action: 'increment' | 'decrement') => {
    const increase = action === 'increment';

    applyStyleToSelectedItems(mapId, itemData => {
      const fontSize = Number(itemData.style?.fontSize) || 12; // ( 12px default value for an item)

      let newFontSize;
      if (fontSize === undefined) {
        newFontSize = increase ? 16 : 8;
      } else {
        newFontSize = increase ? fontSize + 4 : fontSize - 4;
      }

      newFontSize = Math.min(Math.max(newFontSize, 8), 72);

      return { propertyName: 'fontSize', propertyValue: newFontSize };
    });
  };

  // Function used to apply a fade to an item/group of selected items
  const applyFade = (passedItemId?: string) => {
    const selectedItemIds = getSelectedIDs();

    if (selectedItemIds.length > 1) {
      const isAllItemsFaded = selectedItemIds
        .filter(id => id.includes('item_'))
        .every(itemId => {
          const { itemData: item } = getStackAndItem(itemId, mapId);
          return item.style.faded;
        });

      applyStyleToSelectedItems(
        mapId,
        () => {
          return { propertyName: 'faded', propertyValue: !isAllItemsFaded };
        },
        false,
        ''
      );
    } else {
      applyStyleToSelectedItems(
        mapId,
        itemData => {
          const currentState = itemData?.style?.faded;
          return { propertyName: 'faded', propertyValue: !currentState };
        },
        false,
        passedItemId || ''
      );
    }
  };

  //Function used to apply boldness to an item/group of selected items
  const applyBold = () => {
    applyStyleToSelectedItems(mapId, itemData => {
      const currentState = itemData.style.formattingOfTheEntireNote?.isBold;
      console.log(currentState);

      return {
        propertyName: 'formattingOfTheEntireNote',
        propertyValue: {
          ...itemData.style.formattingOfTheEntireNote,
          isBold: !currentState,
        },
      };
    });
  };

  //Function used to apply italics to an item/group of selected items
  const applyItalic = () => {
    applyStyleToSelectedItems(mapId, itemData => {
      const currentState = itemData.style.formattingOfTheEntireNote?.isItalic;
      return {
        propertyName: 'formattingOfTheEntireNote',
        propertyValue: {
          ...itemData.style.formattingOfTheEntireNote,
          isItalic: !currentState,
        },
      };
    });
  };

  //Function used to apply text underline to an item/group of selected items
  const applyUnderline = () => {
    applyStyleToSelectedItems(mapId, itemData => {
      const currentState =
        itemData.style.formattingOfTheEntireNote?.isUnderlined;
      return {
        propertyName: 'formattingOfTheEntireNote',
        propertyValue: {
          ...itemData.style.formattingOfTheEntireNote,
          isUnderlined: !currentState,
        },
      };
    });
  };

  const applyGlowyBorder = () => {
    applyStyleToSelectedItems(
      mapId,
      itemData => {
        const currentState = itemData.style.isGlowing;
        return { propertyName: 'isGlowing', propertyValue: !currentState };
      },
      true // affect areas too
    );
  };

  const changeItemToTask = () => {
    applyStyleToSelectedItems(mapId, itemData => {
      const currentState = itemData.style.isTask;
      return { propertyName: 'isTask', propertyValue: !currentState };
    });
  };

  const makeItemHeadline = () => {
    const styleObject = {
      fontSize: 28,
      textColor: '#fff',
      formattingOfTheEntireNote: {
        isBold: true,
      },
      assignedHotkey: ['mod+alt+Digit4'],
    };
    applyStyleObject(styleObject, mapId);
  };

  // Color style selection Functions
  const triggerBorderColorSelection = () => {
    if (getSelectedIDs().length === 0) return;
    if (anchorMode.anchorComponent === 'BORDER') {
      anchorMode.reset();
      return;
    }

    anchorMode.activate('BORDER');
  };

  const triggerTextColorSelection = () => {
    if (getSelectedIDs().length === 0) return;
    if (anchorMode.anchorComponent === 'TEXT') {
      anchorMode.reset();
      return;
    }

    anchorMode.activate('TEXT');
  };
  const triggerFillColorSelection = () => {
    if (getSelectedIDs().length === 0) return;
    if (anchorMode.anchorComponent === 'FILL') {
      return anchorMode.reset();
    }

    anchorMode.activate('FILL');
  };
  const triggerBorderThicknessSelection = () => {
    if (getSelectedIDs().length === 0) return;
    if (anchorMode.anchorComponent === 'borderWeightSelector')
      return anchorMode.reset();
    anchorMode.activate('borderWeightSelector');
  };

  const forwardIndentation = () => {
    setNextStyleValue(mapId, 'indentation', 'next');
  };

  const backwardIndentation = () => {
    setNextStyleValue(mapId, 'indentation', 'prev');
  };

  return {
    applyTextAlignment,
    applyItalic,
    applyFontSize,
    applyFade,
    applyBold,
    triggerBorderColorSelection,
    triggerTextColorSelection,
    triggerFillColorSelection,
    triggerBorderThicknessSelection,
    forwardIndentation,
    backwardIndentation,
    applyUnderline,
    applyGlowyBorder,
    changeItemToTask,
    makeItemHeadline,
  };
}
