// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type ArrowsChevronLeftIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function ArrowsChevronLeftIcon(props: ArrowsChevronLeftIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 24 24"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M14.293 5.293a1 1 0 011.497 1.32l-.083.094L10.415 12l5.292 5.293a1 1 0 01.083 1.32l-.083.094a1 1 0 01-1.32.083l-.094-.083-6-6a1 1 0 01-.083-1.32l.083-.094 6-6z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default ArrowsChevronLeftIcon;
/* prettier-ignore-end */
