import React, { useState } from 'react';
import { ChevronUp } from 'lucide-react';
import { cn } from 'lib/utils';

import './themeStyle.scss';

interface AccordionProps extends React.HTMLAttributes<HTMLDivElement> {}

const Accordion: React.FC<AccordionProps> = ({ children, ...props }) => (
  <div className="accordion" {...props}>
    {children}
  </div>
);

interface AccordionItemProps extends React.HTMLAttributes<HTMLDivElement> {}

const AccordionItem = React.forwardRef<HTMLDivElement, AccordionItemProps>(
  ({ children, className, ...props }, ref) => (
    <div ref={ref} className={cn(className)} {...props}>
      {children}
    </div>
  )
);

interface AccordionTriggerProps extends React.HTMLAttributes<HTMLDivElement> {
  onClick?: () => void;
  isOpen?: boolean;
}

const AccordionTrigger = React.forwardRef<
  HTMLDivElement,
  AccordionTriggerProps
>(({ children, onClick, isOpen, className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      'flex items-center justify-between py-2 font-medium cursor-pointer transition-all hover:underline',
      className
    )}
    onClick={onClick}
    {...props}
  >
    {children}
    <ChevronUp
      className={cn('h-4 w-4 chevron', {
        'rotate-180': isOpen,
      })}
    />
  </div>
));

interface AccordionHeaderProps extends React.HTMLAttributes<HTMLDivElement> {}

const AccordionHeader = React.forwardRef<HTMLDivElement, AccordionHeaderProps>(
  ({ children, className, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(
        'py-2 font-medium rounded-b-xl data-[state=closed]:rounded-b-xl',
        className
      )}
      {...props}
    >
      {children}
    </div>
  )
);

interface AccordionContentProps extends React.HTMLAttributes<HTMLDivElement> {
  isOpen?: boolean;
  heading: {
    personal: boolean;
    title: string;
    ownersName?: string;
  };
}

const AccordionContent = React.forwardRef<
  HTMLDivElement,
  AccordionContentProps
>(({ children, isOpen, className, heading, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('overflow-hidden transition-all', className)}
    data-state={isOpen ? 'open' : 'closed'}
    {...props}
  >
    <AccordionHeader
      style={{
        fontSize: '20px',
      }}
      className="px-2 font-semibold"
    >
      {heading.personal ? (
        <div className="font-medium">
          {heading.title} <span className="font-bold">Style Shortcuts</span>
        </div>
      ) : !heading.personal && heading.ownersName ? (
        <div>
          Styles created by the{' '}
          <span style={{ color: '#78E08F' }} className="font-bold">
            {heading?.ownersName}{' '}
          </span>
          for "
          <span style={{ color: '#78E08F' }} className="font-bold">
            {heading?.title}
          </span>
          "
        </div>
      ) : (
        <div>{heading.title}</div>
      )}
    </AccordionHeader>
    {children}
  </div>
));

export {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionHeader,
  AccordionContent,
};
