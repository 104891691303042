/***
 *
 *   MENU
 *   Display an overlay menu anywhere in your application by calling
 *   context.menu.show() with an object containing the following params
 *
 *   PARAMS
 *   title: title of the menu (string, optional)
 *   width: width of the menu (number > 0 & < 1, optional)
 *   position: the position the menu is aligned ("center" | "left" | "right", optional)
 *   backdropDarkening: darkness level of the menu underlay (number > 0 & < 1, optional)
 *   backgroundColor: color of the menu (string, optional)
 *   children: children of the menu wrapper (ReactNode, optional)
 *   stickyBottom: boolean to make the menu sticky to the bottom of the screen (boolean, optional)
 *
 **********/

import { ViewContext } from 'components/lib';
import { ReactNode, useContext } from 'react';
import { CSSTransition } from 'react-transition-group';
import useMenuOverlay from '../../hooks/useMenuOverlay';
import './menu.scss';

export interface OverlayMenuWrapperProps {
  title?: string;
  width?: number;
  maxWidth?: number;
  position?: 'center' | 'right' | 'left';
  backdropDarkening?: number;
  backgroundColor?: string;
  borderRadius?: number;
  children?: ReactNode;
  closeModal?: () => void;
  stickyBottom?: ReactNode;
  fitContentHeight?: boolean;
}

export function OverlayMenuWrapper(props: OverlayMenuWrapperProps) {
  const context = useContext(ViewContext);
  const {
    computePosition,
    computeWidth,
    computeBackdropOpacity,
    computeBackgroundColor,
  } = useMenuOverlay(props);

  return (
    <CSSTransition in appear timeout={0} classNames="modal">
      <div
        className="modal"
        style={{
          background: `rgba(0,0,0,${computeBackdropOpacity()})`,
        }}
        onClick={e => {
          e.target === e.currentTarget && context?.menu?.hide();
          if (props.closeModal) props.closeModal();
        }}
      >
        <div
          className={`${!props.fitContentHeight ? 'menu-content' : 'fit-content'} ${computePosition()} overflow-hidden !items-center !justify-center flex flex-col`}
          style={{
            maxWidth: props.maxWidth,
            width: props.maxWidth ? '100%' : computeWidth(),
          }}
          onClick={e => e.stopPropagation()}
        >
          <div
            style={{
              background: computeBackgroundColor(),
              borderRadius: props.borderRadius
                ? `${props.borderRadius}px`
                : '5px',
            }}
            className="p-3 overflow-hidden h-screen flex-1 flex flex-col !justify-center !items-center"
          >
            <div className="text-lg font-semibold find"> {props.title} </div>
            <div className="h-full flex-1 w-full relative overflow-y-scroll">
              {props?.children}
              {props.stickyBottom && (
                <div className="sticky-bottom">{props.stickyBottom}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
}
