import { proxy } from 'valtio';

import { FolderData, FolderTree, MapData, StackData } from 'utils/stores/types';
import { devtools } from 'valtio/utils';

interface DashboardState {
  searchModal: Boolean;
  quickAccessModal: Boolean;
  textSearchModal: Boolean;
  showIconAndEmojiSelector: Boolean;
  showShortCutModal: Boolean;
  newMapModal: Boolean;
  showIconAndEmojiSelectorOnModal: Boolean;
  themeStyleShortcutModal: Boolean;
  breadcrumbs: any[];
  selectedFolderIds: string[];
  selectedMapIds: string[];
  largeItems: {
    itemToRename: FolderData | MapData;
    itemToChangeIcon: FolderData | MapData;
  };
}

export const dashboardStore = proxy<DashboardState>({
  searchModal: false,
  quickAccessModal: false,
  textSearchModal: false,
  newMapModal: false,
  showIconAndEmojiSelector: false,
  showIconAndEmojiSelectorOnModal: false,
  showShortCutModal: false,
  themeStyleShortcutModal: false, // TODO: remove this from this store
  breadcrumbs: [],
  selectedFolderIds: [],
  selectedMapIds: [],
  largeItems: {
    itemToRename: null,
    itemToChangeIcon: null,
  },
});

export const folderSearchQueries = proxy({
  mapFolderSearch: '',
});

export function updateBreadcrumbs(breadcrumb: any[]) {
  dashboardStore.breadcrumbs = breadcrumb;
}

export function toggleIconAndEmojiSelector() {
  dashboardStore.showIconAndEmojiSelector =
    !dashboardStore.showIconAndEmojiSelector;
}

export function toggleIconAndEmojiSelectorOnModal() {
  dashboardStore.showIconAndEmojiSelectorOnModal = !dashboardStore.showIconAndEmojiSelectorOnModal;
}

export const toggleShortCutModal = () => {
  if (dashboardStore.showShortCutModal)
    return (dashboardStore.showShortCutModal = false);
  dashboardStore.showShortCutModal = true;
};

export const toggleNewMapModal = () => {
  if (dashboardStore.newMapModal) return dashboardStore.newMapModal = false
  dashboardStore.newMapModal = true
}

export function toggleMapFolderSearchView() {
  dashboardStore.searchModal = !dashboardStore.searchModal;
}

export function toggleThemeModal() {
  dashboardStore.themeStyleShortcutModal =
    !dashboardStore.themeStyleShortcutModal;
}

export function toggleQuickAccessModal() {
  dashboardStore.quickAccessModal = !dashboardStore.quickAccessModal;
}

export function setMapFolderSearchQuery(q: string) {
  folderSearchQueries.mapFolderSearch = q;
}

export const findAncestors = (
  folderId: string,
  folderTree: { folders: FolderData[]; maps?: MapData[] },
  currentAncestors = []
) => {
  let result: FolderData[] | undefined;

  folderTree?.folders.forEach(folder => {
    if (result) return;

    if (folder.folderId === folderId) {
      result = [...currentAncestors, folder];
      return;
    }

    if (folder.subfolders.length > 0) {
      const ancestors = findAncestors(
        folderId,
        { folders: folder.subfolders },
        [...currentAncestors, folder]
      );
      if (ancestors) {
        result = [...ancestors];
        return;
      }
    }
  });

  return result;
};

// export const findFolderForMap = (mapId, folder: FolderData) => {
//   let currentFolder: string;

  
// }

// const findMapFolder = (mapId, folderStructure: FolderTree) => { 
//   let currentFolderId = '';

//   if (folderStructure.root.maps) {

//     folderStructure.root.maps.forEach(map => {
//       if(map.mapId === mapId) currentFolderId = 'root'
//     })
    
//   }



//   return currentFolderId
// }

// ** MAP-TEXT-SEARCH **//
interface mapTextSearchHistoryProps {
  query: string;
  result: StackData | null;
}

export const mapTextSearchHistory = proxy<mapTextSearchHistoryProps>({
  query: '',
  result: null,
});

export function toggleMapTextSearchModal() {
  dashboardStore.textSearchModal = !dashboardStore.textSearchModal;
}
// ******************* //

// enable redux devtools extension, disable type checking errors on next line, to enable build step in vercel
const unsub = devtools(dashboardStore, {
  name: 'dasboardStore',
  enabled: true,
  trace: true,
});
