import React, { useState } from 'react';
import { LiveProvider, LiveEditor } from 'react-live';
import { PseudoItemPreview } from 'CoreComponents/Item/ItemPreview';
import { Button } from 'ShadcnComponents/Button';
import { Switch } from 'ShadcnComponents/Switch';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'ShadcnComponents/DropdownMenu';
import '../themeStyle.scss';
import { Input } from 'ShadcnComponents/Input';
import SingleShortcutItem from 'components/shortCutOverview/SingleShortcutItem';
import { useStyleSetting } from 'utils/hooks/themeAndStyles/useStyleSettings';
import { StyleShortcut } from './StyleSection';
import { Trash2 } from 'lucide-react'; // Import the delete icon

interface SingleStyleSettingItemProps {
  styleItem: StyleShortcut;
  canDelete: boolean;
  sectionTitle: string;
}

export default function SingleStyleSettingItem({
  styleItem,
  canDelete,
  sectionTitle,
}: SingleStyleSettingItemProps) {
  const {
    active,
    setActive,
    item,
    setItem,
    itemTitle,
    setItemTitle,
    handleSaveShortcut,
    css,
    setCss,
    isFormModified,
    handleSave,
    handleDeleteStyleShortcut,
    deleteLoading,
    updateLoading,
  } = useStyleSetting(styleItem, sectionTitle);

  const [isHovered, setIsHovered] = useState(false);

  if (!item) return null;

  return (
    <div
      className="relative"
      onMouseEnter={() => canDelete && setIsHovered(true)}
      onMouseLeave={() => canDelete && setIsHovered(false)}
    >
      {isHovered && canDelete && (
        <Button
          onClick={() => handleDeleteStyleShortcut(styleItem)}
          loading={deleteLoading}
          variant="destructive"
          style={{
            width: 'fit-content',
          }}
          className="h-6 !px-1 absolute top-2 right-2 text-white hover:text-white transition-colors ease-in-out duration-200"
        >
          <Trash2 className="w-4 h-4" />
        </Button>
      )}
      <div className="w-full flex space-x-4 items-center px-2 py-3 rounded-xl bg-black min-h-[25dvh] text-white">
        {/* Preview */}
        <div
          style={{
            background: '#1E1D1D',
            color: 'white',
            maxWidth: '45%',
          }}
          className="h-full flex-1 rounded-xl flex justify-center items-center p-4"
        >
          <div style={{ maxWidth: '85%' }}>
            <PseudoItemPreview item={item} />
          </div>
        </div>

        {/* Style details */}
        <div className="grid gap-3 flex-1 whitespace-normal">
          <div className="flex space-x-4 items-center">
            <div style={{ maxWidth: '40%' }} className="w-full">
              Active:
            </div>
            <div className="w-fit">
              <Switch
                checked={active}
                onCheckedChange={() => {
                  setActive(!active);
                  setItem(prevItem => ({ ...prevItem, isActive: !active }));
                }}
                style={{
                  width: '42px',
                  background: !active ? '#171717' : '#13a734',
                }}
              />
            </div>
          </div>

          <div className="flex space-x-4 items-center">
            <div style={{ maxWidth: '40%' }} className="w-full">
              Name:
            </div>
            <TitleItem title={itemTitle} onChange={setItemTitle} />
          </div>

          <div className="flex space-x-4 items-center">
            <div style={{ maxWidth: '40%' }} className="w-full">
              Shortcut:
            </div>
            <SingleShortcutItem
              handleSaveShortcut={shortcut =>
                handleSaveShortcut('shortcut', shortcut)
              }
              key={'shortcut'}
              shortcut={item.shortcut || ''}
            />
          </div>

          <div className="flex space-x-4 items-center">
            <div style={{ maxWidth: '40%' }} className="w-full">
              Alternative Shortcut:
            </div>
            <SingleShortcutItem
              handleSaveShortcut={shortcut =>
                handleSaveShortcut('alternativeShortcut', shortcut)
              }
              key={'alternativeShortcut'}
              shortcut={item.alternativeShortcut || ''}
            />
          </div>

          <div className="flex space-x-4 items-center">
            <div style={{ maxWidth: '40%' }} className="w-full">
              Style Object:
            </div>
            <div className="w-fit">
              <DropdownMenu>
                <DropdownMenuTrigger>
                  <Button
                    style={{ width: 'fit-content', background: '#4b5563' }}
                    className="h-8"
                  >
                    show code
                    <span className="text-primary ml-2">{`{ - }`}</span>
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  className="border-none text-white"
                  style={{
                    zIndex: '1000',
                    width: 'fit-content',
                    background: '#1E1D1D',
                  }}
                >
                  <DropdownMenuLabel>Edit CSS</DropdownMenuLabel>
                  <DropdownMenuSeparator style={{ background: '#BBF7D0' }} />
                  <div
                    style={{
                      maxWidth: '350px',
                      maxHeight: '280px',
                      overflow: 'scroll',
                    }}
                  >
                    <LiveProvider
                      code={css}
                      noInline
                      scope={{ setCss }}
                      transformCode={code => `setCss(\`${code}\`)`}
                    >
                      <LiveEditor
                        style={{
                          background: '#000000',
                        }}
                        className="live-editor"
                        onChange={newCode => setCss(newCode)}
                      />
                    </LiveProvider>
                  </div>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-3 flex justify-center">
        {isFormModified && (
          <Button
            style={{
              background: '#78E08F',
              color: 'white',
              width: 'fit-content',
            }}
            loading={updateLoading}
            onClick={handleSave}
          >
            Save Changes
          </Button>
        )}
      </div>
    </div>
  );
}

export function TitleItem({ title, onChange }) {
  return (
    <div className="w-fit text-sm">
      <Input
        value={title}
        onChange={e => {
          onChange(e.target.value);
        }}
        autoFocus={false}
        type="text"
        className="style-title-input px-1 max-w-md focus:outline-none w-[170px]"
      />
    </div>
  );
}
