// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type SystemInfoCircleIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function SystemInfoCircleIcon(props: SystemInfoCircleIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 24 24"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12zm18 0a8 8 0 10-16 0 8 8 0 0016 0zm-7.99-5a1 1 0 01.117 1.993L12 9a1 1 0 01-.117-1.993L12.01 7zm.983 4.883A1 1 0 0012 11h-1l-.117.007A1 1 0 0011 13v3l.007.117A1 1 0 0012 17h1l.117-.007A1 1 0 0014 16l-.007-.117a1 1 0 00-.876-.876L13 15v-3l-.007-.117z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default SystemInfoCircleIcon;
/* prettier-ignore-end */
