import { hotKeyStateStore } from 'utils/stores/hotKeyStatesStore';
// import { updateMapMode } from 'utils/stores/mapmodesStore';

const useIsModPressed = () => {
  const modifierKey = ['Meta', 'Control'];

  const handleModKeyDown = (e: KeyboardEvent) => {
    if (modifierKey.includes(e.key)) {
      // updateMapMode('insert', true);
      hotKeyStateStore.current.isModActive = true;
    }
    if (e.metaKey) hotKeyStateStore.current.isMetaActive = true;
    if (e.ctrlKey) hotKeyStateStore.current.isCtrlActive = true;
  };

  const handleModKeyUp = (e: KeyboardEvent) => {
    if (modifierKey.includes(e.key)) {
      // updateMapMode('insert', false);
      hotKeyStateStore.current.isModActive = false;
    }
    if (!e.metaKey) hotKeyStateStore.current.isMetaActive = false;
    if (!e.ctrlKey) hotKeyStateStore.current.isCtrlActive = false;
  };

  const handleKeyCombination = (e: KeyboardEvent) => {
    if (!modifierKey.includes(e.key) && hotKeyStateStore.current.isModActive) {
      // updateMapMode('insert', false);
      hotKeyStateStore.current.isModActive = false;
    }
    if (!e.metaKey) hotKeyStateStore.current.isMetaActive = false;
    if (!e.ctrlKey) hotKeyStateStore.current.isCtrlActive = false;
  };

  return {
    handleModKeyDown,
    handleModKeyUp,
    handleKeyCombination,
  };
};

export default useIsModPressed;
