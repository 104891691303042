import { getTranslateFromMatrix } from 'utils/helpers/Canvas/clamp-zoom';
import { EVENTS } from './mapSynincincStateMachineTypes';
import { currentMapStateStore } from 'utils/stores/mapStore';
import { Matrix } from 'CoreComponents/Canvas/canvas';
import uploadUrl from 'utils/helpers/getImageUrl';
import { takeMapshotForPreview } from 'utils/stores/mapshotStore';
import { initialCanvasState } from 'CoreComponents/Canvas/InfiniteCanvas';

interface MapSyncContext {
  retryCount: number;
  syncing: boolean;
  trigger?: string;
  mapId: string;
  error?: string;
}

interface StateMachineParams {
  setContext: (update: Partial<MapSyncContext>) => void;
  send: (event: any) => void;
  context: MapSyncContext;
  event: any;
}

interface SyncResults {
  lastSyncAndInteraction: {
    lastSync: string;
    lastInteraction: string;
  };
  loadedMap: any;
  relevantStackObjects: any[];
}

export const mapSyncHelpers = {
  defineStates: (
    syncMap: any,
    refetchAll: () => Promise<any>,
    mapId: string
  ) => ({
    idle: {
      on: { [EVENTS.COMPAREMAPS]: 'compareMaps', [EVENTS.SAVEMAP]: 'saveMap' },
      effect: ({ setContext, context }: StateMachineParams) => {
        if (context.syncing) setContext({ syncing: false });
      },
    },
    compareMaps: {
      on: {
        [EVENTS.LOADMAP]: 'loadMap',
        [EVENTS.SAVEMAP]: 'saveMap',
        [EVENTS.MERGEMAPS]: 'mergeMaps',
        [EVENTS.IDLE]: 'idle',
        [EVENTS.ERROR]: 'error',
      },
      effect: ({ send, setContext, context }: StateMachineParams) => {
        setContext({ syncing: true, trigger: context.trigger });
        refetchAll().then(results =>
          send(mapSyncHelpers.determineNextEvent(results, mapId))
        );
      },
    },
    loadMap: {
      on: { [EVENTS.IDLE]: 'idle', [EVENTS.ERROR]: 'error' },
      effect: ({ send }: StateMachineParams) =>
        syncMap.load().then(() => send(EVENTS.IDLE)),
    },
    saveMap: {
      on: { [EVENTS.IDLE]: 'idle', [EVENTS.ERROR]: 'error' },
      effect: ({ send }: StateMachineParams) =>
        syncMap.save(mapId).then(() => send(EVENTS.IDLE)),
    },
    mergeMaps: {
      on: { [EVENTS.IDLE]: 'idle', [EVENTS.SAVEMAP]: 'saveMap' },
      effect: ({ send }: StateMachineParams) =>
        syncMap.merge(mapId).then(() => send(EVENTS.SAVEMAP)),
    },
    error: {
      on: {
        RETRYCOMPAREMAPS: {
          target: 'compareMaps',
          guard: ({ context }: { context: MapSyncContext }) =>
            context.retryCount < 3,
        },
        RETRYLOADMAPS: {
          target: 'loadMap',
          guard: ({ context }: { context: MapSyncContext }) =>
            context.retryCount < 3,
        },
        RETRYSAVEMAPS: {
          target: 'saveMap',
          guard: ({ context }: { context: MapSyncContext }) =>
            context.retryCount < 3,
        },
        RETRYMERGEMAPS: {
          target: 'mergeMaps',
          guard: ({ context }: { context: MapSyncContext }) =>
            context.retryCount < 3,
        },
        IDLE: { target: 'idle' },
      },
      effect: ({ setContext, event, context, send }: StateMachineParams) => {
        setContext({ retryCount: context.retryCount + 1 });
        if (context.retryCount === 3 || !event.retry) {
          setContext({ retryCount: 0, error: '' });
        } else {
          setTimeout(
            () => {
              event.retryMethod(send, event.prev);
            },
            2000 * Math.pow(2, context.retryCount)
          );
        }
      },
    },
  }),
  determineNextEvent(results: SyncResults, mapId: string): string {
    const { lastSyncAndInteraction, loadedMap, relevantStackObjects } = results;

    // Check if the data is loaded and valid
    if (
      !lastSyncAndInteraction ||
      !loadedMap ||
      relevantStackObjects.length === 0
    ) {
      return EVENTS.ERROR;
    }

    // Convert string dates to Date objects
    const lastSyncDate = new Date(lastSyncAndInteraction.lastSync);
    const lastInteractionDate = new Date(
      lastSyncAndInteraction.lastInteraction
    );
    const now = new Date();

    // Determine the time elapsed since the last interaction
    const timeSinceLastInteraction =
      now.getTime() - lastInteractionDate.getTime();

    // If the last interaction was more than a day ago, sync the maps
    if (timeSinceLastInteraction > 24 * 60 * 60 * 1000) {
      return EVENTS.SAVEMAP;
    }

    // If the map data is outdated, load new data
    if (loadedMap.isOutdated) {
      return EVENTS.LOADMAP;
    }

    // Check if there are updates needed based on relevant stack objects
    const updatesNeeded = relevantStackObjects.some(obj => obj.needsUpdate);
    if (updatesNeeded) {
      return EVENTS.MERGEMAPS;
    }

    // Default to no action needed
    return EVENTS.IDLE;
  },
};

export const saveMapScreenshot = (() => {
  let isExecuting = false;
  let timeout: NodeJS.Timeout | null = null;

  return (mapId: string) => {
    if (currentMapStateStore[mapId]?.preview?.lastUpdated) {
      const lastUpdatedDate = new Date(
        currentMapStateStore[mapId]?.preview?.lastUpdated
      ).getTime();
      const today = Date.now();
      const diffInHours = (today - lastUpdatedDate) / (1000 * 60 * 60);

      if (diffInHours < 24) return;
    }

    const takeScreenshot = async () => {
      if (isExecuting) return;
      isExecuting = true;

      try {
        const canvasTransform =
          currentMapStateStore[mapId]?.canvas?.CanvasTransform;
        const currentTranslate = getTranslateFromMatrix(
          new Matrix(canvasTransform)
        );

        const screenshotCallback = async (dataUri: string) => {
          try {
            dataUri = dataUri.split(',')[1].trim();

            const url = await uploadUrl({ ImageBase64: dataUri });

            if (url === 'error') return;
            const lastUpdated = new Date().toISOString();

            currentMapStateStore[mapId].preview = {
              url,
              lastUpdated,
            };
          } catch (e) {
            // Do nothing
          }
        };

        takeMapshotForPreview(
          mapId,
          initialCanvasState,
          currentTranslate,
          {
            x: currentTranslate.x + window.innerWidth,
            y: currentTranslate.y + window.innerHeight,
          },
          window.innerWidth,
          window.innerHeight,
          true,
          dataUri => {
            screenshotCallback(dataUri);
          }
        );
      } finally {
        isExecuting = false;
      }
    };

    // Clear any existing timeout
    if (timeout) {
      clearTimeout(timeout);
    }

    // Set new timeout
    timeout = setTimeout(takeScreenshot, 1000);
  };
})();
