import { useEffect } from "react";
import { ClipboardStore } from "utils/stores/mapmapClipboardStore";

export default function detectVisibilityChange() {
    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.hidden) { 
                ClipboardStore.clipboard = null;
                console.log("Visibility gone")
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);
}