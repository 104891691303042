import * as React from 'react';
import {
  PlasmicStackItemPlaceholder,
  DefaultStackItemPlaceholderProps,
} from './plasmic/map_map/PlasmicStackItemPlaceholder';
import { HTMLElementRefOf } from '@plasmicapp/react-web';
import '../CoreComponents/Item/item.css';

export interface StackItemPlaceholderProps
  extends DefaultStackItemPlaceholderProps {
  itemHeight?: number;
  itemWidth?: number;
  style?: React.CSSProperties;
}

function StackItemPlaceholder_(
  props: StackItemPlaceholderProps,
  ref: HTMLElementRefOf<'div'>
) {
  const { itemHeight, itemWidth, style, ...rest } = props;

  return (
    <PlasmicStackItemPlaceholder
      root={{
        ref,
        style: {
          width: 'inherit',
          ...style,
        },
      }}
      className="stack-item-placeholder"
      {...rest}
    />
  );
}

const StackItemPlaceholder = React.forwardRef(StackItemPlaceholder_);
export default StackItemPlaceholder;
