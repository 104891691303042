/*
DOMMatrix is a built-in JavaScript object that represents a 4x4 matrix, which is commonly used for transformations in 2D and 3D space. It's part of the Geometry Interfaces module of the Web API.  The DOMMatrix object can represent 2D transformations like translation (moving along the X and Y axes), rotation, and scaling, as well as more complex 3D transformations.  Here are some of the methods and properties of DOMMatrix:
a, b, c, d, e, f: These properties represent the values of a 2D matrix.

a: This is the scale factor along the x-axis. When you scale an element, this value changes proportionally.
b: This is the scale factor along the y-axis. When you scale an element, this value changes proportionally.
c: This represents the skew on the x-axis. It's used when you want to skew or distort the element along the x-axis.
d: This represents the skew on the y-axis. It's used when you want to skew or distort the element along the y-axis.
e: This is the translation along the x-axis. It's used when you want to move (translate) the element along the x-axis.
f: This is the translation along the y-axis. It's used when you want to move (translate) the element along the y-axis.


m11 to m44: These properties represent the values of a 3D matrix.
translateSelf(tx, ty, tz): This method applies a 3D translation transformation to the matrix.
scaleSelf(scaleX, scaleY, scaleZ): This method applies a 3D scaling transformation to the matrix.
rotateSelf(rotX, rotY, rotZ): This method applies a 3D rotation transformation to the matrix.
invertSelf(): This method inverts the matrix.
multiplySelf(otherMatrix): This method multiplies the matrix by another matrix.
 */

export function getScaleFromMatrix(m: DOMMatrix) {
  return Math.hypot(m.a, m.b); // It returns the scale value, which is calculated as the hypotenuse of m.a and m.b.
}

export function getTranslateFromMatrix(m: DOMMatrix) {
  return {
    x: m.e,
    y: m.f,
  };
}

export function getNormalizedCoordinates(
  canvasTransform: string,
  x: number,
  y: number
) {
  const inverseMatrix = new DOMMatrix(canvasTransform).inverse();
  return inverseMatrix.transformPoint(new DOMPoint(x, y)); // the cursor is always offset by 32px
}
