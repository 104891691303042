import { useEffect } from 'react';

type TargetKey = {
  [Key: string]: {
    down: Function;
    up: Function;
  };
};

export function useKeyHandlers(
  targetKey: TargetKey,
  handleKeyCombination: (e: KeyboardEvent) => void
) {
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (targetKey[e.key]?.down) {
        targetKey[e.key].down(e);
      }
      handleKeyCombination(e);
    };

    const handleKeyUp = (e: KeyboardEvent) => {
      if (targetKey[e.key]?.up) {
        targetKey[e.key].up(e);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [targetKey, handleKeyCombination]);
}
