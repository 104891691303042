import { proxy } from 'valtio';
import { ContextMenuContext } from './types';

// This Store is used for anything that we need to share across the app
// that doesn't fit into a dedicated store (yet)

interface ContextMenuForItemProps {
  position: { x: number; y: number };
  itemPassed: any;
  context: ContextMenuContext;
  mode?: 'updatingEmojiAndIcon';
}

/**
 * Add flag name you want to make global
 */

type Flags = 'mapmap_selectionv2';

type GlobalFlags = {
  [Key in Flags]: boolean;
};

interface GeneralStore {
  // while we can expect there to be only one map being created/loaded at a time,
  // we store them as arrays just to be on the safe side
  mapsBeingCreated: string[];
  mapsBeingLoaded: string[];
  openContextMenuForItem: ContextMenuForItemProps | undefined;
  mapID: string | null;
  flags?: GlobalFlags;
  mousePosition: {
    mouse: {
      x: number;
      y: number;
    };
  };
  frequentlUsedIcons: string[]
}

export const generalStore = proxy<GeneralStore>({
  mapsBeingCreated: [],
  mapsBeingLoaded: [],
  mapID: null,
  openContextMenuForItem: undefined,

  mousePosition: {
    mouse: {
      x: 0,
      y: 0,
    },
  },
  frequentlUsedIcons: []
});

export const setContextMenuForItem = (
  context: ContextMenuForItemProps | undefined
) => {
  generalStore.openContextMenuForItem = context;
};

export function addMapBeingCreated(mapId: string) {
  if (!generalStore.mapsBeingCreated.includes(mapId)) {
    generalStore.mapsBeingCreated = [...generalStore.mapsBeingCreated, mapId];
  }
}

export function removeMapBeingCreated(mapId: string) {
  generalStore.mapsBeingCreated = generalStore.mapsBeingCreated.filter(
    id => id !== mapId
  );
}

export function addMapBeingLoaded(mapId: string) {
  if (!generalStore.mapsBeingLoaded.includes(mapId)) {
    generalStore.mapsBeingLoaded = [...generalStore.mapsBeingLoaded, mapId];
  }
}

export function removeMapBeingLoaded(mapId: string) {
  generalStore.mapsBeingLoaded = generalStore.mapsBeingLoaded.filter(
    id => id !== mapId
  );
}
