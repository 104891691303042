import { useGesture } from '@use-gesture/react';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  getScaleFromMatrix,
  getTranslateFromMatrix,
} from 'utils/helpers/Canvas/clamp-zoom';
import { useKeyDownLayout } from 'utils/hooks/hotKeys/useKeyDownLayout';
import { useKeyUpLayout } from 'utils/hooks/hotKeys/useKeyUpLayout';
import useDropOnCanvas from 'utils/hooks/useDropOnCanvas';
import {
  gestureStore,
  setIsPanning,
  setZoomLevel,
  zoomLevel,
} from 'utils/stores/gestureStore';
import {
  currentMapStateStore,
  detectOverScroll,
  zoomToContent,
} from 'utils/stores/mapStore';
import { subscribe, useSnapshot } from 'valtio';
import handleCreateNewNote from '../../utils/helpers/handleDoubleClickEmptyArea';

import { debounce } from 'lodash';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { hotKeyStateStore } from 'utils/stores/hotKeyStatesStore';

import { modeStyles, updateMapMode } from 'utils/stores/mapmodesStore';

import { MAX_ZOOM, MIN_ZOOM_FACTOR, ZOOM_SPEED_FACTOR } from 'utils/constants';
import { useCustomGesture } from 'utils/helpers/InfiniteCanvas/useCustomGesture';
import { dashboardStore } from 'utils/stores/dashboardStore';
import { mapshotStore } from 'utils/stores/mapshotStore';
import { generalStore } from 'utils/stores/generalStore';
import { currentMode, Mode } from 'utils/stores/mapMode/modesStore';
import { themeStore } from 'utils/stores/themeStore';

export const Matrix = DOMMatrix ?? WebKitCSSMatrix;
export let initialCanvasState = {
  initialCanvasPosition: { x: 0, y: 0 },
  initialCanvasTransform: '',
  scale: 0,
};
export let canvasStateAfterScroll = {
  canvasPositionAfterScroll: { x: 0, y: 0 },
  canvasTransformAfterSCroll: '',
};

const InfiniteCanvas = ({
  children,
  isCreatingArea,
  selectoRef,
  className,
  canvasTransform,
  setIsModPress,
  ...rest
}: any) => {
  const canvasRef = useRef<HTMLDivElement | null>(null);
  const mapId = useParams().mapId!;

  const location = useLocation();
  const navigate = useNavigate();

  // Stack position being part of the map url
  const searchParams = new URLSearchParams(location.search);
  const stackParam = searchParams.get('stack');

  const snapshotStoreSnapshot = useSnapshot(mapshotStore);
  const currentMapState = useSnapshot(currentMapStateStore[mapId]);
  const { isEditing, isPanning } = useSnapshot(gestureStore);
  const [gestureStarted, setGestureStarted] = useState(false);
  const canDragCanvas = useRef<boolean>(false);

  const [modeStyle, setModeStyle] = useState<React.CSSProperties>({});
  const dashboardStateStore = useSnapshot(dashboardStore);

  const elementsContainer = useRef(null);

  // this is for horizontal scrolling while the shift key is held
  const [scrollAxis, setScrollAxis] = useState<'x' | 'y' | 'lock' | undefined>(
    undefined
  );

  // zoomLevel
  const currentScale = useRef<number | null>(null);
  // viewport position
  const currentTranslate = useRef<{ x: number; y: number } | null>(null);

  const [, setShowZoomToContentBtn] = useState(false);
  const [overScrollDistance, setOverScrollDistance] = useState(
    detectOverScroll(
      initialCanvasState.initialCanvasPosition,
      canvasStateAfterScroll.canvasPositionAfterScroll,
      zoomLevel.level
    )
  );

  const [viewportWidth] = useState(window.innerWidth);
  const [viewportHeight] = useState(window.innerHeight);

  useEffect(() => {
    currentScale.current = getScaleFromMatrix(new Matrix(canvasTransform));
    currentTranslate.current = getTranslateFromMatrix(
      new Matrix(canvasTransform)
    );

    initialCanvasState.initialCanvasPosition = getTranslateFromMatrix(
      new Matrix(canvasTransform)
    );
    initialCanvasState.initialCanvasTransform = canvasTransform;
    initialCanvasState.scale = currentScale.current;

    canvasStateAfterScroll.canvasPositionAfterScroll = getTranslateFromMatrix(
      new Matrix(canvasTransform)
    );
    canvasStateAfterScroll.canvasTransformAfterSCroll = canvasTransform;
  }, []);

  useEffect(() => {
    if (overScrollDistance > 3000) {
      displayToast();
    } else if (overScrollDistance === 0) setShowZoomToContentBtn(false);

    setShowZoomToContentBtn(false);

    return () => {
      displayToast.cancel();
    };
  }, [overScrollDistance]);

  const displayToast = debounce(() => {
    toast(
      <button onClick={performZoomToContent}>
        Click here to Zoom to content!
      </button>,
      {
        position: 'bottom-right',
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        autoClose: 5000,
        type: 'info',
      }
    );
    setShowZoomToContentBtn(true);
  }, 3000);

  const [, drop] = useDropOnCanvas(mapId, gestureStarted);

  // Not to have a prevent default on other input components when pressing the space bar
  const allowSpaceBarPress =
    dashboardStateStore.textSearchModal ||
    dashboardStateStore.searchModal ||
    isEditing;

  useKeyDownLayout(
    handlePressSpace,
    [' '],
    allowSpaceBarPress ? 'allowDefault' : 'preventDefault'
  );

  function handlePressSpace(e: KeyboardEvent) {
    if (isEditing) return;
    setIsPanning(true);
    // allow dragging
    canDragCanvas.current = true;
    updateMapMode('canDragItem', false);
    // canDragItem.current = false;
    canvasRef.current!.style.cursor = 'grab';
  }

  useKeyUpLayout(
    handleReleaseSpace,
    [' '],
    isEditing ? 'allowDefault' : 'preventDefault'
  );

  function handleReleaseSpace(e: KeyboardEvent) {
    // stop dragging when space bar is released
    setIsPanning(false);
    canDragCanvas.current = false;
    updateMapMode('canDragItem', true);
    // canDragItem.current = true;
    canvasRef.current!.style.cursor = 'auto';
  }

  useGesture(
    {
      onPinchStart: e => {
        setGestureStarted(true);
      },
      onPinch: ({
        event,
        memo,
        origin: [pinchOriginX, pinchOriginY],
        offset: [d],
      }) => {
        event.preventDefault();

        // Initialize memo if it's not already set
        if (!memo && elementsContainer.current) {
          memo = setMemo(elementsContainer);
        }

        let displacementX = (memo.transformOriginX - pinchOriginX) / memo.scale;
        let displacementY = (memo.transformOriginY - pinchOriginY) / memo.scale;

        let movementDistance = d - memo.initialOffsetDistance;

        const matrix = `matrix(${
          MIN_ZOOM_FACTOR / MAX_ZOOM + d / ZOOM_SPEED_FACTOR
        }, 0, 0, ${MIN_ZOOM_FACTOR / MAX_ZOOM + d / ZOOM_SPEED_FACTOR}, ${
          memo.bounds.x + (displacementX * movementDistance) / ZOOM_SPEED_FACTOR
        },  ${
          memo.bounds.y + (displacementY * movementDistance) / ZOOM_SPEED_FACTOR
        })`;

        elementsContainer.current.style.transform = matrix;

        // * update zoom level on pinch
        setZoomLevel(getScaleFromMatrix(new Matrix(matrix)));

        return memo;
      },

      onPinchEnd: e => {
        setGestureStarted(false);
      },
      onWheelStart: e => {
        setGestureStarted(true);
      },
      onWheel: e => {
        if (e.pinching || e.ctrlKey || e.metaKey) {
          return;
        }

        if (e.shiftKey) {
          setScrollAxis('x');
        }
        const zoomLevel = getScaleFromMatrix(
          new Matrix(currentMapStateStore[mapId].canvas?.CanvasTransform)
        );

        const xTranslate = e.lastOffset[0] - e.movement[0];
        const yTranslate = e.lastOffset[1] - e.movement[1];

        elementsContainer.current.style.transform = `matrix(${zoomLevel}, 0, 0, ${zoomLevel}, ${xTranslate},  ${yTranslate})`;
      },
      onWheelEnd: () => {
        currentMapStateStore[mapId].canvas.CanvasTransform =
          elementsContainer.current.style.transform;

        canvasStateAfterScroll.canvasPositionAfterScroll =
          getTranslateFromMatrix(
            new Matrix(elementsContainer.current.style.transform)
          );
        canvasStateAfterScroll.canvasTransformAfterSCroll =
          elementsContainer.current.style.transform;

        setOverScrollDistance(
          detectOverScroll(
            initialCanvasState.initialCanvasPosition,
            canvasStateAfterScroll.canvasPositionAfterScroll,
            zoomLevel.level
          )
        );

        setGestureStarted(false);
        setScrollAxis(undefined);
      },
      onDragStart: () => {
        setGestureStarted(true);
        if (!canDragCanvas.current) return;
        canvasRef.current!.style.cursor = 'grabbing';
      },
      onDrag: e => {
        if (!canDragCanvas.current) return;

        const zoomLevel = getScaleFromMatrix(
          new Matrix(currentMapStateStore[mapId].canvas?.CanvasTransform)
        );

        const xTranslate = e.lastOffset[0] + e.movement[0];
        const yTranslate = e.lastOffset[1] + e.movement[1];

        const newMatrixValue = `matrix(${zoomLevel}, 0, 0, ${zoomLevel}, ${xTranslate},  ${yTranslate})`;

        currentMapStateStore[mapId].canvas.CanvasTransform = newMatrixValue;

        elementsContainer.current.style.transform = newMatrixValue;
      },
      onDragEnd: e => {
        setGestureStarted(false);

        if (isPanning) {
          canvasRef.current!.style.cursor = 'grab';
        }

        // Apply this cursor only if the current mode is default
        // else use the applied mode store cursor
        if (currentMode.activeMode.name === 'DEFAULT') {
          canvasRef.current!.style.cursor = canDragCanvas.current
            ? 'grab'
            : 'auto';
        }

        canvasStateAfterScroll.canvasPositionAfterScroll =
          getTranslateFromMatrix(
            new Matrix(elementsContainer.current.style.transform)
          );

        canvasStateAfterScroll.canvasTransformAfterSCroll =
          elementsContainer.current.style.transform;

        setOverScrollDistance(
          detectOverScroll(
            initialCanvasState.initialCanvasPosition,
            canvasStateAfterScroll.canvasPositionAfterScroll,
            zoomLevel.level
          )
        );
      },
    },
    {
      // eventOptions: { passive: false },
      target: canvasRef,
      pinch: {
        offsetBounds: { min: 1 / MAX_ZOOM, max: ZOOM_SPEED_FACTOR * MAX_ZOOM },
        scaleBounds: { min: 1 / MAX_ZOOM, max: ZOOM_SPEED_FACTOR * MAX_ZOOM },
        modifierKey: ['metaKey', 'ctrlKey'],
        from: () => {
          const initScale = getScaleFromMatrix(
            new Matrix(elementsContainer.current.style.transform)
          );

          const scale =
            (initScale - MIN_ZOOM_FACTOR / MAX_ZOOM) * ZOOM_SPEED_FACTOR;
          return [scale, 0];
        },
      },
      wheel: {
        axis: scrollAxis,
        from: () => {
          const initTranslate = getTranslateFromMatrix(
            new Matrix(currentMapStateStore[mapId].canvas?.CanvasTransform)
          );
          return [initTranslate.x, initTranslate.y];
        },
      },
      drag: {
        axis: scrollAxis,
        from: () => {
          const initTranslate = getTranslateFromMatrix(
            new Matrix(currentMapStateStore[mapId].canvas?.CanvasTransform)
          );
          return [initTranslate.x, initTranslate.y];
        },
      },
    }
  );
  // gestures that manipulate the viewport
  // by updating the canvasTransform, which is of type DOMMatrix
  useCustomGesture(
    mapId,
    setGestureStarted,
    setOverScrollDistance,
    setScrollAxis,
    canDragCanvas,
    canvasRef,
    elementsContainer,
    isPanning,
    scrollAxis
  );

  const performZoomToContent = () => {
    zoomToContent(mapId, initialCanvasState);
    currentTranslate.current = getTranslateFromMatrix(
      new Matrix(initialCanvasState.initialCanvasTransform)
    );
    setOverScrollDistance(
      detectOverScroll(
        initialCanvasState.initialCanvasPosition,
        initialCanvasState.initialCanvasPosition,
        zoomLevel.level
      )
    );
  };

  function getCombinedStyles(modes) {
    return modes.reduce((acc, mode) => {
      const styleForMode = modeStyles[mode];
      if (styleForMode) {
        // Merge each style into the accumulator
        Object.keys(styleForMode).forEach(key => {
          acc[key] = styleForMode[key]; // This assumes that later modes can override earlier ones
        });
      }
      return acc;
    }, {});
  }

  const zoomAndCenterToStackPosition = zoomLevel => {
    const stack = JSON.parse(decodeURIComponent(stackParam));

    const centerX = viewportWidth / 2;
    const centerY = viewportHeight / 2;

    const translateX = centerX - stack.x * zoomLevel;
    const translateY = centerY - stack.y * zoomLevel;

    const newMatrixValue = `matrix(${zoomLevel}, 0, 0, ${zoomLevel}, ${translateX}, ${translateY})`;

    elementsContainer.current.style.transform = newMatrixValue;
    currentMapStateStore[mapId].canvas.CanvasTransform = newMatrixValue;
    currentTranslate.current = getTranslateFromMatrix(
      new Matrix(newMatrixValue)
    );

    searchParams.delete('stack');
    const newUrl = `${location.pathname}${
      searchParams.toString() ? `?${searchParams.toString()}` : ''
    }`;
    navigate(newUrl, { replace: true });
  };

  // This function initializes the position of the canvas items
  function initializeCanvasPosition() {
    const zoomLevel = getScaleFromMatrix(
      new Matrix(currentMapStateStore[mapId].canvas?.CanvasTransform)
    );

    const initTranslate = getTranslateFromMatrix(
      new Matrix(currentMapStateStore[mapId].canvas?.CanvasTransform)
    );

    const newMatrixValue = `matrix(${zoomLevel}, 0, 0, ${zoomLevel}, ${initTranslate.x},  ${initTranslate.y})`;

    elementsContainer.current.style.transform = newMatrixValue;
  }

  useEffect(() => {
    initializeCanvasPosition();
  }, []);

  useEffect(() => {
    if (stackParam) {
      zoomAndCenterToStackPosition(2);
    }
  }, [stackParam]);

  useEffect(() => {
    const triggerRerender: Mode[] = ['INSERT', 'DEFAULT', 'DUPLICATE'];

    /**
     * manually trigger  a re -render for selected nodes
     *
     * listen to currentMode changes using a subscriber function
     *
     * triggerRerender holds the modes that should trigger a rerender.
     *
     * update the useState hook to trigger a component re render
     *
     * unsubsribe on when the components unmounts
     *
     */
    const unsubcribe = subscribe(currentMode, () => {
      if (triggerRerender.includes(currentMode.activeMode.name)) {
        setModeStyle(currentMode.style);
      }
    });
    return () => unsubcribe();
  }, []);

  return (
    // <DndProvider backend={HTML5Backend}>
    <div
      id="selecto-selectable"
      ref={_node => {
        const node = drop(_node) as never;
        canvasRef.current = node;
      }}
      onMouseMove={e => {
        generalStore.mousePosition.mouse.x = e.clientX;
        generalStore.mousePosition.mouse.y = e.clientY;
      }}
      style={{
        backgroundColor: themeStore.canvasBackgroundColor,
        ...modeStyle,
      }}
      className={`absolute top-0 bottom-0 left-0 right-0 overflow-hidden touch-none  ${className}`}
      onDoubleClick={e => {
        handleCreateNewNote(
          e,
          new Matrix(currentMapState.canvas?.CanvasTransform),
          mapId,
          undefined,
          hotKeyStateStore.current.isShiftActive,
          selectoRef
        );
        if (currentMapStateStore[mapId].isEmptyMap === true)
          currentMapStateStore[mapId].isEmptyMap = false;
      }}
      {...rest}
    >
      <div
        ref={elementsContainer}
        // added the id for the sake of hotkeys implementation
        id="stacks"
        // style={{ width: '100%', height: '100%' }}
        className="absolute w-max h-max will-change-transform  "
      >
        {children}
      </div>
    </div>
  );
};

export default InfiniteCanvas;

export const setMemo = elementsContainer => {
  const matrix = new Matrix(elementsContainer.current.style.transform);

  const bcr = elementsContainer.current.getBoundingClientRect();
  const scale = getScaleFromMatrix(matrix);

  return {
    initialOffsetDistance:
      (scale - MIN_ZOOM_FACTOR / MAX_ZOOM) * ZOOM_SPEED_FACTOR,
    transformOriginX: bcr.x + bcr.width / 2,
    transformOriginY: bcr.y + bcr.height / 2,
    bounds: bcr,
    scale,
  };
};
