import { OverlayMenuWrapperProps } from 'CoreComponents/Overlays/OverlayMenuWrapper';

export default function useMenuOverlay(props: OverlayMenuWrapperProps) {
  function computePosition() {
    return props?.position === 'left'
      ? 'menu-content-left'
      : props?.position === 'right'
        ? 'menu-content-right'
        : 'menu-content-center';
  }

  function computeWidth() {
    let width = props?.width;
    if (!width || typeof width !== 'number' || width > 1 || width < 0.1)
      width = 0.3;
    return `${width * 100}%`;
  }

  function computeBackdropOpacity() {
    if (
      (!props?.backdropDarkening && props?.backdropDarkening !== 0) ||
      props?.backdropDarkening > 1 ||
      props?.backdropDarkening < 0
    )
      return 0.5;
    return props?.backdropDarkening;
  }

  function computeBackgroundColor() {
    if (!props?.backgroundColor) return 'white';
    return props?.backgroundColor;
  }

  return {
    computePosition,
    computeWidth,
    computeBackdropOpacity,
    computeBackgroundColor,
  };
}
