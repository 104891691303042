import getSelectedIDs from 'utils/helpers/Selection/selectionUtils';
import {
  AnchorPosition,
  currentMapStateStore,
  getItemNormalisedPositon,
} from 'utils/stores/mapStore';
import selectedContentsStore from 'utils/stores/Selection/selectedContentStore';

import { useSnapshot } from 'valtio';
export type RenderPostion = 'Left' | 'right';

type AnchorRenderValue = {
  anchorPosition: null | AnchorPosition;
  lastSelectedID: null | string;
  renderPosition: null | RenderPostion;
  canrenderAnchor: boolean;
};

function useAchorWithSelectionV2(mapID: string) {
  const selected = useSnapshot(selectedContentsStore.selected);

  if (selected.size === 0) {
    return {
      anchorPosition: null,
      lastSelectedID: null,
      renderPosition: null,
      canrenderAnchor: false,
    };
  }

  const selectedContent = getSelectedIDs(mapID).slice(-1)[0];

  const { position, getDOMRef, RightPosition } = getItemNormalisedPositon(
    selectedContent,
    mapID
  );

  const renderConentent = getRenderPosition(getDOMRef, position, RightPosition);
  let anchorPosition = renderConentent?.anchorPosition;
  let renderPosition = renderConentent?.renderPosition;

  return {
    anchorPosition,
    lastSelectedID: selectedContent,
    renderPosition,
    canrenderAnchor: true,
  };
}

function useAchorWithSelectionV1(mapID: string): AnchorRenderValue {
  const selected = useSnapshot(currentMapStateStore[mapID].selected);
  if (selected.length === 0) {
    return {
      anchorPosition: null,
      lastSelectedID: null,
      renderPosition: null,
      canrenderAnchor: false,
    };
  }

  const selectedID = selected.slice(-1)[0];

  const { position, getDOMRef, RightPosition } = getItemNormalisedPositon(
    selectedID,
    mapID
  );

  const renderConentent = getRenderPosition(getDOMRef, position, RightPosition);
  let anchorPosition = renderConentent?.anchorPosition;
  let renderPosition = renderConentent?.renderPosition;

  return {
    anchorPosition,
    lastSelectedID: selectedID,
    renderPosition,
    canrenderAnchor: true,
  };
}

export default function useAnchor(
  mapID: string,
  useSelectionV2: boolean
): AnchorRenderValue {
  // trigger a rerender when any mapstatStore changes
  if (useSelectionV2) {
    const anchorParams = useAchorWithSelectionV2(mapID);
    return anchorParams;
  } else {
    const anchorParam = useAchorWithSelectionV1(mapID);
    return anchorParam;
  }
}

function getRenderPosition(
  targetContent: DOMRect,
  leftPosition: AnchorPosition,
  RightPosition: AnchorPosition
): { anchorPosition: AnchorPosition; renderPosition: RenderPostion } | null {
  if (!targetContent) return null;
  const targetPositionOnWindow = targetContent.left >= 200;

  if (targetPositionOnWindow) {
    return {
      renderPosition: 'Left',
      anchorPosition: leftPosition,
    };
  } else {
    return {
      renderPosition: 'right',
      anchorPosition: RightPosition,
    };
  }
}
