import { ClipboardStore } from 'utils/stores/mapmapClipboardStore';
import { processSelectedItems } from './copyCutHelpers';
import { resetSelected } from 'utils/stores/mapStore';

export function handleCopy(mapId: string) {
  ClipboardStore.action = 'copy';
  const { stringifiedData } = processSelectedItems(mapId);
  ClipboardStore.clipboard = stringifiedData;
  resetSelected(mapId);
}
