import { useEffect, useRef } from 'react';
import { hotKeyStateStore } from 'utils/stores/hotKeyStatesStore';

const useIsShiftPressed = () => {
  const handleShiftKeyDown = (e: React.KeyboardEvent) => {
    const modifierKey = 'Shift';
    if (e.key === modifierKey) {
      hotKeyStateStore.current.isShiftActive = true;
    }
  };

  const handleShiftKeyUp = (e: React.KeyboardEvent) => {
    const modifierKey = 'Shift';

    if (e.key === modifierKey) {
      hotKeyStateStore.current.isShiftActive = false;
    }
  };

  return {
    handleShiftKeyDown,
    handleShiftKeyUp,
  };
};

export default useIsShiftPressed;
