import { Editor, Transforms, deleteFragment, getFragment, Range } from 'slate';
import {
  ActionHotkeyValues,
  CustomEditor,
  LinkElement,
  Mark,
} from './slatetypes';
import {
  createItemId,
  createNewItem,
  extractTypeAndParentFromItemID,
} from 'utils/mapStoreFN/mapStoreFN_items';
import { NoteItemData } from 'utils/stores/types';
import { getMap, resetSelected } from 'utils/stores/mapStore';
import { createStackInArea } from 'utils/mapStoreFN/mapStoreFN_areas';
import { ReactNode } from 'react';
import { redo, undo } from 'utils/stores/proxyWithHistory';
import { creatingAreaMode } from 'utils/stores/mapMode/modesStore';
import { emptyNoteItem } from 'utils/constants';
import { themeStore } from 'utils/stores/themeStore';

type ErrorIcons = {
  invalidLink: ReactNode;
  NotSelectedText: ReactNode;
};

export async function handleAddLinkOrText(editor: CustomEditor) {
  const textOrUrl = await navigator.clipboard.readText();
  const urlPattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol (optional)
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', // fragment locator
    'i'
  );

  const isLink = urlPattern.test(textOrUrl);
  if (isLink) {
    return addLinkNode(editor, textOrUrl);
  }

  if (!isLink) {
    return insertText(editor, textOrUrl);
  }
  return;
}

export function insertText(editor: CustomEditor, text: string) {
  Transforms.insertText(editor, text);
  return;
}

export function addLinkNode(editor: CustomEditor, url: string) {
  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  // if the user did not select any text extract the domain name
  const domainPattern = /^(?:https?:\/\/)?(?:www\.)?([^\/]+)/i;
  const match = url.match(domainPattern);

  console.log(match);

  const link: LinkElement = {
    type: 'Link',
    url,
    children: isCollapsed ? [{ text: match[1] }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }

  return;
}
export const isMarkActive = (editor: CustomEditor, format: Mark) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

export const toggleMarkorNode = (editor: CustomEditor, format: Mark) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
  //
};

export function splitMote(
  editor: CustomEditor,
  parentID: string,
  mapID: string,
  noteID: string
) {
  if (!editor.selection) {
    return {
      message: 'split offset not found',
      state: 'error',
    };
  }

  const cursorPosition = editor.selection.anchor;

  Transforms.select(editor, {
    anchor: cursorPosition,
    focus: Editor.end(editor, []),
  });
  const selectedNode = getFragment(editor);

  const itemId = createItemId('stack', 'Default');

  const newNote: NoteItemData = {
    itemId,
    style: {
      customWidth: themeStore.defaultItemWidth,
    },
    content: {
      textContent: null,
      slateContent: selectedNode,
    },

    position: { x: 0, y: 0 },
    lastInteraction: new Date(),
    delete: false,
    type: 'Note',
    connections: [],
  };
  deleteFragment(editor);

  createNewItem(parentID, newNote, mapID, noteID);

  return {
    message: 'done',
    state: 'success',
  };
}

export function newNote(
  event: React.KeyboardEvent,
  parentID: string,
  mapID: string,
  noteID: string
) {
  if (creatingAreaMode.areaAndStack) {
    createStackInArea({ x: 0, y: 0 }, parentID, mapID);
    return;
  }

  const itemType = extractTypeAndParentFromItemID(noteID);
  if (itemType.itemType === 'Default') {
    createNewItem(
      parentID,
      emptyNoteItem,
      mapID,
      noteID,
      event.shiftKey,
      false
    );
  }

  return;
}

export function slateActions(
  event: React.KeyboardEvent,
  action: ActionHotkeyValues,
  editor: CustomEditor,
  mapID: string,
  parentID: string,
  noteID: string,
  errorIcons: ErrorIcons
) {
  switch (action) {
    case 'Paste': {
      handleAddLinkOrText(editor);
      // addLinkNode(editor, errorIcons);
      break;
    }
    case 'SplitNote': {
      splitMote(editor, parentID, mapID, noteID);
      break;
    }
    case 'NewNote': {
      newNote(event, parentID, mapID, noteID);
      break;
    }
    case 'Undo': {
      undo(getMap(mapID));
      break;
    }
    case 'Redo': {
      redo(getMap(mapID));
      break;
    }
    case 'ExitEditMode': {
      break;
    }

    default:
      return;
  }
}
