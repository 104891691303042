import {
  currentMapStateStore,
  getIndex,
  currentMapContentStore,
  storeStatus,
  addItemToSelected,
  removeItemFromSelected,
  // updateLastInteraction,
} from 'utils/stores/mapStore';
import { ItemData, StackData } from 'utils/stores/types';
import { v4 as uuidv4 } from 'uuid';
import { getArea, updateEncompassingStacks } from './mapStoreFN_areas';
import { createItemId } from './mapStoreFN_items';
import getSelectedIDs from 'utils/helpers/Selection/selectionUtils';
import { areaStateStore } from 'utils/stores/components/areaStore';
import { containedInArea } from 'utils/helpers/Areas/areaUtils';

// ....................getters..................

export const getParentIdFromItemID = (itemID: string) => {
  const stackID = document
    .getElementById(itemID)
    ?.getAttribute('data-parentid');
  return stackID;
};

export function getStackfromValtioStore(itemID: string, mapID: string) {
  let stackData: StackData | null = null;
  let itemData: ItemData | null = null;

  const contentStore = currentMapContentStore[mapID];

  stackData = contentStore?.value?.stacks.find(stack => {
    return stack.items.find(item => {
      if (item.itemId === itemID) {
        item.lastInteraction = new Date();
        itemData = item;
        return true;
      }
    });
  });

  reRenderStack(stackData);

  // come up with a logic that allows area handle this

  return {
    stackData,
    itemData,
  };
}

export const getStackAndItemFromValtio = (stackID: string, mapID: string) => {
  const stackData = currentMapContentStore[mapID].value.stacks.find(
    ({ stackId }) => stackId === stackID
  );
  reRenderStack(stackData);
  return stackData;
};

export function getStack(stackID: string, mapID: string): StackData | null {
  const stackAndItem = getStackAndItemFromValtio(stackID, mapID);

  return stackAndItem;
}

export function reRenderStack(stackData: StackData) {
  if (stackData) stackData.lastInteraction = new Date();
}

export function getStackAndItem(itemID: string, mapID: string) {
  return getStackfromValtioStore(itemID, mapID);
}

export function getStacksInArea(areaID: string, mapId: string) {
  const areaData = getArea(areaID, mapId);

  if (!areaData) return;

  let resolvedStacks = [];
  const encompassingStacks = areaData.encompassingStacks;

  for (let index = 0; index < encompassingStacks.length; index++) {
    const stackId = encompassingStacks[index];

    const stack = getStackAndItemFromValtio(stackId, mapId);

    resolvedStacks.push(stack);
  }

  return resolvedStacks;
}

// ....................setters...............
export const updateStackPosition = (
  stackId: string,
  newPosition: { x?: number; y?: number },
  mapId: string,
  zoomLevel: number,
  useOffSet?: boolean,
  areaID = 'none'
) => {
  const stack = getStack(stackId, mapId);

  if (!stack) return;
  if (useOffSet) {
    newPosition = {
      x: stack.position.x + newPosition.x / zoomLevel,
      y: stack.position.y + newPosition.y / zoomLevel,
    };
  }

  stack.position = {
    x: newPosition.x || stack.position.x,
    y: newPosition.y || stack.position.y,
  };
  stack.isContainedInArea = areaID;
  // updateLastInteraction(stackId, mapId);
};

export const updateStackPositionAndFilter = (
  stackId: string,
  newPosition: { x?: number; y?: number },
  mapId: string,
  filterItems: string[]
) => {
  const stack = currentMapContentStore[mapId].value.stacks.find(
    s => s.stackId === stackId
  );

  if (!stack) return;

  stack.position = {
    x: newPosition.x || stack.position.x,
    y: newPosition.y || stack.position.y,
  };

  if (filterItems.length > 0) {
    stack.items = [
      ...stack.items.filter(item => !filterItems.includes(item.itemId)),
    ];
  }
  // updateLastInteraction(stackId, mapId);
};

export const createNewStackOrDuplicate = (
  position: { x: number; y: number },
  items: ItemData[],
  mapId: string,
  areaId?: string,
  isTitleNote?: boolean,
  hasReturnValue?: boolean, // To return the newStackData
  createDuplicate?: boolean, // For creating a new stack from duplicating
  useStackID?: string
) => {
  const lastSyncFromMapStore = currentMapStateStore[mapId].lastSync;

  // if it's not duplication action remove the item from previous stack
  const extractItemsID = items.map(item => item.itemId);
  !createDuplicate && removeStackOrItems(extractItemsID, mapId);

  // mapping through the item and removing the indention
  // reason - the indentation cause the dropped item move away from the cursor position due to
  // the margin being added directly to the resizableComponent

  const newItems = items.map(item => ({
    ...item,
    position,
    connections: createDuplicate ? [] : item.connections, // empty connection array if it's a dublicating action.
    itemId: createDuplicate ? createItemId() : item.itemId, // create new ID if it's dublicating action.
    lastInteraction: new Date(),
    style: createDuplicate
      ? { ...item.style }
      : {
          ...item.style,
        },
  }));

  const newStack: StackData = {
    stackId: useStackID || 'stack_' + uuidv4(),
    isContainedInArea: areaId || 'none',
    position,
    items: newItems,
    connections: [],
    isTitleNote: isTitleNote || false,
    lastInteraction: new Date(),
    lastSync: lastSyncFromMapStore,
    delete: false,
  };
  if (areaStateStore.store.state === 'pending' && areaStateStore.store.area) {
    /**
     * here we run a simple function to get which stacks are within the area
     * and update the areaStateStore with those stacks
     */

    if (
      containedInArea(areaStateStore.store.area, {
        stackID: newStack.stackId,
        stackPosition: newStack.position,
      })
    ) {
      areaStateStore.store.task.push({
        actions: 'MultipleDrop',
        targetAreaID: 'multipleDrop',
        position,
        stackid: newStack.stackId,
      });

      /**
       * update the newStack.isContained in area  with the areaID
       */

      newStack.isContainedInArea = areaStateStore.store.area.areaId;
    }
  }
  currentMapContentStore[mapId].value.stacks.push(newStack);

  return hasReturnValue && newStack;
};

// .....................Delete functions.......................

export const removeStackOrItems = (
  itemIDs: string[],
  mapID: string,
  targetStackID?: string
) => {
  const stackID = targetStackID || getParentIdFromItemID(itemIDs[0]);
  if (!stackID) return;

  let stackIndex = getIndex(stackID, mapID);
  // use the index here to retrieve stackObject as well as delete it.
  const stackData = currentMapContentStore[mapID].value.stacks[stackIndex];

  if (!stackData) return;

  const deleteEntireStack = itemIDs.length === stackData.items.length;

  if (deleteEntireStack) {
    currentMapContentStore[mapID].value.stacks.splice(stackIndex, 1);
    stackData &&
      stackData.isContainedInArea !== 'none' &&
      updateEncompassingStacks(
        'REMOVE',
        mapID,
        [stackData.stackId],
        stackData.isContainedInArea,
        1 // force removal of the stack
      );
  } else {
    stackData.items = [
      ...stackData.items.filter(item => !itemIDs.includes(item.itemId)),
    ];
  }
};

export function getStackAndItemIndex(
  currentSelectedItems: string[],
  currentStackState: any,
  collectFirst: boolean
) {
  if (currentSelectedItems.length === 0) return null;

  const currentSelectedItem = collectFirst
    ? currentSelectedItems[0]
    : currentSelectedItems[currentSelectedItems.length - 1];

  const filteredStack = currentStackState.filter((stack: StackData) =>
    stack.items.find(i => i.itemId === currentSelectedItem)
  );

  const currentStack: StackData = filteredStack[0];

  const newItems = [...currentStack.items];
  const itemIndex = newItems.findIndex(
    item => item.itemId === currentSelectedItem
  );

  return { currentStack, itemIndex, newItems };
}

export function swapItems(newItems: any[], itemIndex: number, offset: number) {
  if (itemIndex + offset >= 0 && itemIndex + offset < newItems.length) {
    [newItems[itemIndex], newItems[itemIndex + offset]] = [
      newItems[itemIndex + offset],
      newItems[itemIndex],
    ];
  }
}

export function moveItemInStack(
  currentSelectedItems: string[],
  currentStackState: any,
  offset: number
) {
  const result = getStackAndItemIndex(
    currentSelectedItems,
    currentStackState,
    true
  );

  if (!result) return;

  const { currentStack, itemIndex, newItems } = result;

  swapItems(newItems, itemIndex, offset);

  currentStack.items = newItems;
  reRenderStack(currentStack);
}

export function moveItemInStackUp(
  currentSelectedItems: string[],
  currentStackState: any
) {
  moveItemInStack(currentSelectedItems, currentStackState, -1);
}

export function moveItemInStackDown(
  currentSelectedItems: string[],
  currentStackState: any
) {
  moveItemInStack(currentSelectedItems, currentStackState, 1);
}

export function selectOrDeselectItemsInStackUp(
  currentSelectedItems: string[],
  currentStackState: any,
  mapId
) {
  const selectedItems = getSelectedIDs();

  const result = getStackAndItemIndex(
    currentSelectedItems,
    currentStackState,
    false
  );

  if (!result) return;

  let { currentStack, itemIndex } = result;

  if (itemIndex > 0) {
    const nextItemToSelect = currentStack.items[itemIndex - 1];
    if (!selectedItems.includes(nextItemToSelect.itemId)) {
      addItemToSelected(
        {
          contentID: nextItemToSelect.itemId,
          parentID: currentStack.stackId,
          parentType: 'stack',
          type: 'Note',
        },
        mapId
      );
    } else {
      if (itemIndex >= 0) {
        const itemToDeselect = currentStack.items.find(
          item => item.itemId === selectedItems[selectedItems.length - 1]
        );
        removeItemFromSelected(
          {
            contentID: itemToDeselect.itemId,
            parentID: currentStack.stackId,
            parentType: 'stack',
            type: 'Note',
          },
          mapId
        );
      }
    }
  }
}

export function selectOrDeselectItemsInStackDown(
  currentSelectedItems: string[],
  currentStackState: any,
  mapId
) {
  const result = getStackAndItemIndex(
    currentSelectedItems,
    currentStackState,
    false
  );
  const selectedItems = getSelectedIDs();

  if (!result) return;

  let { currentStack, itemIndex } = result;

  if (itemIndex < currentStack.items.length - 1) {
    const nextItemToSelect = currentStack.items[itemIndex + 1];
    if (!selectedItems.includes(nextItemToSelect.itemId)) {
      addItemToSelected(
        {
          contentID: nextItemToSelect.itemId,
          parentID: currentStack.stackId,
          parentType: 'stack',
          type: 'Note',
        },
        mapId
      );
    } else {
      if (itemIndex < currentStack.items.length - 1) {
        const itemToDeselect = currentStack.items.find(
          item => item.itemId === selectedItems[selectedItems.length - 1]
        );
        removeItemFromSelected(
          {
            contentID: itemToDeselect.itemId,
            parentID: currentStack.stackId,
            parentType: 'stack',
            type: 'Note',
          },
          mapId
        );
      }
    }
    addItemToSelected(
      {
        contentID: nextItemToSelect.itemId,
        parentID: currentStack.stackId,
        parentType: 'stack',
        type: 'Note',
      },
      mapId
    );
  }
}
