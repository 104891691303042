import { toast } from "react-toastify";

export function normalizeURL(targetURL: string, baseURL?: string): string {
  // If the target URL starts with "//", assume "https" protocol
  if (targetURL.startsWith("//")) {
    targetURL = "https:" + targetURL;
  }
  // If the target URL is a relative path, append it to the base URL
  else if (targetURL.startsWith("/") && baseURL) {
    targetURL = baseURL + targetURL;
  }
  // If the target URL lacks a protocol, assume "https"
  else if (!targetURL.startsWith("http://") && !targetURL.startsWith("https://")) {
    targetURL = "https://" + targetURL;
  }

  try {
    // Attempt to parse the URL to validate it
    const parsedURL = new URL(targetURL);
    return parsedURL.href;
  } catch (error) {
    // If there's an error, log it and return the original target URL
    toast(
      'Please provide a valid url',
      {
        position: 'bottom-right',
        closeOnClick: true,
        pauseOnHover: true,
        autoClose: 4000,
        type: 'info',
      }
    );
    console.error("Failed to parse URL:", error);
    return targetURL;
  }
}

export function constructYoutubeEmbedLink(youtubeLink: string) {
  if (!youtubeLink.includes("youtube.com") || !youtubeLink.includes("youtu.be"))
    toast(
      'Please provide a youtube video link',
      {
        position: 'bottom-right',
        closeOnClick: true,
        pauseOnHover: true,
        autoClose: 4000,
        type: 'info',
      }
    )
  // Check if the link is already an embed link
  if (youtubeLink.includes("youtube.com/embed/")) {
    return youtubeLink;
  }

  // Extract the video ID from the link
  let videoId;
  if (youtubeLink.includes("youtu.be/")) {
    videoId = youtubeLink.split("youtu.be/")[1].split("?")[0];
  } else {
    videoId = youtubeLink.split("v=")[1];
  }

  // Construct the embed link
  const embedLink = `https://www.youtube.com/embed/${videoId}`;

  return embedLink;
}

export function reversetoYoutubeLink(youtubembedLink: string) {
  // Check if the link is an embed link
  if (!youtubembedLink.includes("youtube.com/embed/")) {
    toast(
      'Please provide a YouTube embed link',
      {
        position: 'bottom-right',
        closeOnClick: true,
        pauseOnHover: true,
        autoClose: 4000,
        type: 'info',
      }
    );
    return youtubembedLink;
  }

  // Extract the video ID from the embed link
  if (!youtubembedLink.includes("youtube.com/embed/")) return
  const videoId = youtubembedLink.split("youtube.com/embed/")[1];

  // Construct the normal YouTube link
  const youtubeLink = `https://www.youtube.com/watch?v=${videoId}`;

  return youtubeLink;
}
