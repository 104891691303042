import React from 'react';
import {
  QuickAccessMapItem,
  QuickAccessFolderItem,
  useQuickAccessData,
} from '../SharedQuickAccessComponents';
import { useNavigate } from 'react-router-dom';
import GeneralSkeleton from 'components/loader/skeletons/GeneralSkeleton/GeneralSkeleton';

export function QuickAccessItems() {
  const navigate = useNavigate();
  const [expandedFolderGroups, setExpandedFolderGroups] = React.useState([]);
  const { folders, maps, handleItemSelection, structureLoading } =
    useQuickAccessData();

  return (
    <>
      {!folders.length && !maps.length && !structureLoading ? (
        <div className="pt-3 text-xs text-center w-full px-2">
          Empty Quick Access Items
        </div>
      ) : (
        <>
          {structureLoading ? (
            <div className="grid gap-2 w-full pt-2">
              {[...Array(4)].map((_, idx) => {
                return (
                  <GeneralSkeleton
                    key={idx}
                    height={27}
                    style={{ borderRadius: '8px' }}
                  />
                );
              })}
            </div>
          ) : (
            <div>
              {folders.map(folder => (
                <QuickAccessFolderItem
                  key={folder.folderId}
                  folder={folder}
                  expandedFolderGroups={expandedFolderGroups}
                  setExpandedFolderGroups={setExpandedFolderGroups}
                  isInSidebar={true}
                  onSelect={handleItemSelection}
                />
              ))}
              {maps.map(map => (
                <QuickAccessMapItem
                  key={map.mapId}
                  map={map}
                  onSelect={handleItemSelection}
                  handleClick={() => navigate(`/map/${map.mapId}`)}
                  isInSidebar={true}
                />
              ))}
            </div>
          )}
        </>
      )}
    </>
  );
}
