import { Matrix } from 'CoreComponents/Canvas/InfiniteCanvas';
import { useParams } from 'react-router-dom';
import { getScaleFromMatrix } from 'utils/helpers/Canvas/clamp-zoom';

import { transformedMouseCordinates } from 'CoreComponents/Canvas/canvasUtils';
import { createNewArea } from 'utils/mapStoreFN/mapStoreFN_areas';
import { createItemId } from 'utils/mapStoreFN/mapStoreFN_items';
import { currentMapStateStore } from 'utils/stores/mapStore';
import { NoteItemData, Style } from 'utils/stores/types';
import { v4 as uuidv4 } from 'uuid';
import { useSnapshot } from 'valtio';
import { themeStore } from 'utils/stores/themeStore';
import { generalStore } from 'utils/stores/generalStore';
import { creatingAreaMode } from 'utils/stores/mapMode/modesStore';
import { emptyNoteItem } from 'utils/constants';

let mousePosition: {
  x: number;
  y: number;
} = {
  x: 0,
  y: 0,
};

type Size = {
  height: number;
  width: number;
};

type Position = {
  x: number;
  y: number;
};

function creatTitleNote(position: Position, mapID: string): NoteItemData {
  const isStackHeadline = creatingAreaMode.areaAndStack;
  return {
    ...(emptyNoteItem as NoteItemData),
    position: position,
    type: 'Note',
    style: isStackHeadline
      ? {
          ...themeStore.styleObjects[0],
          fillColor: themeStore.canvasBackgroundColor,
        }
      : {},
    itemId: createItemId('area', 'titleNote'),
    lastInteraction: new Date(),
  };
}

function applyAreaSize(mapID: string, width?: number, height?: number): Size {
  const curentMapState = currentMapStateStore[mapID];
  const isStackAndArea = creatingAreaMode.areaAndStack;

  const zoomLevel = getScaleFromMatrix(
    new Matrix(curentMapState.canvas?.CanvasTransform)
  );
  if (isStackAndArea) {
    return {
      width: 100 / zoomLevel,
      height: 100 / zoomLevel,
    };
  }

  if (width && height) {
    return {
      width: width / zoomLevel,
      height: height / zoomLevel,
    };
  }

  return {
    width: 400,
    height: 400,
  };
}

/**
 * Creats an area object
 *
 */

class Area {
  areaId: string;
  size: Size;
  position: Position;
  titleNote: NoteItemData;
  encompassingStacks: string[];
  lastInteraction: Date;
  lastSync: Date;
  isAreaAndStack: boolean;
  style: Style;
  delete: boolean;
  stamps: string[];

  constructor(
    mapID: string,
    isAreaAndTitle: boolean,
    position: Position,
    size?: { width: number; height: number }
  ) {
    this.areaId = 'area_' + uuidv4();
    this.titleNote = isAreaAndTitle ? creatTitleNote(position, mapID) : null;
    this.position = position;
    this.lastInteraction = new Date();
    this.lastSync = new Date();
    this.style = {};
    this.stamps = [];
    this.encompassingStacks = [];
    this.size = applyAreaSize(mapID, size?.width, size?.height);
    this.delete = false;
  }
}

export const createArea = (
  areaAndTitle: boolean,
  mapId: string,
  position: Position = mousePosition,
  width?: number,

  height?: number
) => {
  const newPosition = transformedMouseCordinates(position, mapId);

  const area = new Area(mapId, areaAndTitle, newPosition, { width, height });

  area.style = creatingAreaMode.areaAndStack
    ? {}
    : {
        border: {
          thickness: '_3',
          color: '#f0ead6',
          isSquared: true,
        },
      };
  createNewArea(mapId, area);
};

const CreateArea = ({ hideBox }: { hideBox?: boolean }) => {
  const mapId = useParams().mapId!;
  const {
    mouse: { x, y },
  } = useSnapshot(generalStore.mousePosition);

  mousePosition.x = x;
  mousePosition.y = y;

  const currentMapState = useSnapshot(currentMapStateStore[mapId]);

  const zoomLevel = getScaleFromMatrix(
    new Matrix(currentMapState.canvas?.CanvasTransform)
  );

  return (
    <>
      <AreaText position={{ x: x, y: y }} anchorText={hideBox} />
      {!hideBox && (
        <div
          style={{
            width: `${400 * zoomLevel}px`,
            height: `${400 * zoomLevel}px`,
            border: `2px dashed #f0ead6`,
            position: 'absolute',
            top: y,
            left: x,
            zIndex: '20',
            pointerEvents: 'none',
            display: 'grid',
            placeContent: 'center',
            color: '#f0ead6',
            opacity: '0.7',
          }}
        />
      )}
    </>
  );
};

type AreaTextProps = {
  position: { x: number; y: number };
  anchorText: boolean;
};

/**
 * A ui helper text component
 *
 * @param position
 * -- x:number
 * -- y: number
 * @property {Object} cacheMouse - Cached mouse position
 * @property {number} cacheMouse.x - The x-coordinate of the cached mouse position
 * @property {number} cacheMouse.y - The y-coordinate of the cached mouse position
 *
 * @returns Div element
 */

function AreaText(props: AreaTextProps) {
  const cacheMousePosition = !props.anchorText && props.position;
  return (
    <div
      style={{
        position: 'absolute',
        width: 'fit-content',
        height: 'auto',
        zIndex: '30',
        top: `${cacheMousePosition.y}px`,
        left: `${cacheMousePosition.x}px`,
        pointerEvents: 'none',
        transform: 'translateY(-30px)',
      }}
    >
      <p
        style={{
          backgroundColor: 'rgba(255, 255, 255, 0.2)',
          backdropFilter: 'blur(10px)',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          borderRadius: '8px',
          color: 'white',
          position: 'absolute',
          width: 'max-content',
          fontSize: '12px',
          padding: '2px 8px',
        }}
      >
        Drag to create a custom area - hold alt to add a title note
      </p>
    </div>
  );
}
export default CreateArea;
