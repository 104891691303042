import SimpleTextInput from 'CoreComponents/SimpleTextInput/SimpleTextInput';
import EmojiPicker, { EmojiClickData, Theme } from 'emoji-picker-react';
import debounce from 'lodash/debounce';
import {
  IconLoader,
  PlasmicEmojiAndIconSelection,
  searchIcon,
} from 'propro-common-components';
import React, { createRef, useLayoutEffect, useRef, useState } from 'react';
import { CabanaIcons, MicrosoftIcons } from 'utils/constants';
import {
  getNextSection,
  getPreviousSection,
} from 'utils/helpers/emojiAndIconSelectorHelpers';
import { generalStore } from 'utils/stores/generalStore';

type IconColorsType =
  | 'linearLightGrey'
  | 'linearMediumGrey'
  | 'linearLavender'
  | 'linearTeal'
  | 'linearMintGreen'
  | 'mapMapGreen'
  | 'linearYellow'
  | 'linearOrange'
  | 'linearLightPink'
  | 'linearRed'
  | 'eggshell'
  | 'hubHubLogoBlue';

// Update the IconResult interface to match what searchIcon returns
interface IconResult {
  name: string;
  component: React.FC<{ color: string; size: number }>;
}

const MAX_ICONS = 40;

interface EmojiAndIconSelectorWrapperProps {
  onIconSelect: (iconData: {
    type: string;
    color: string;
    name: string;
  }) => void;
  onIconColorSelect?: (iconColor: string) => void;
}

const EmojiAndIconSelectorWrapper = ({
  onIconSelect, onIconColorSelect
}: EmojiAndIconSelectorWrapperProps) => {
  const [iconSearchQuery, setIconSearchQuery] = useState('');
  const [iconResults, setIconResults] = useState<IconResult[]>([]);
  const [iconColor, setIconColor] = useState<{
    name: IconColorsType;
    color: string;
  }>({ name: 'linearLightGrey', color: '#BFC2C8' });
  const [selectedIcon, setSelectedIcon] = useState('');
  const [focusedIconIndex, setFocusedIconIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [tabPreview, setTabPreview] = useState('tab1');

  const [isKeyboardNavigation, setIsKeyboardNavigation] = useState(false);

  const iconsPerRow = 11;

  const [currentSection, setCurrentSection] = useState('frequentlyUsed');

  const sectionIcons = {
    frequentlyUsed: generalStore.frequentlUsedIcons,
    cabana: CabanaIcons,
    microsoft: MicrosoftIcons,
  };

  const fetchIcons = async (query: string) => {
    setIsLoading(true);
    try {
      // searchIcon also takes an optional "limit" prop which defaults to 30
      // and it will return an object with:
      // name: cleanName(key),
      // component: lazy(() => icons[key]().then(module => ({ default: module.default })))
      const results = await searchIcon(query, 'glob' /*, MAX_ICONS*/);

      setIconResults(results);
    } catch (error) {
      setIconResults([]);
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedFetchIcons = debounce((query: string) => {
    if (query.length >= 3) {
      fetchIcons(query);
    } else {
      setIconResults([]);
    }
  }, 300);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsKeyboardNavigation(false);
    const query = e.target.value;
    setIconSearchQuery(query);
    debouncedFetchIcons(query);
  };

  const handleEmojiClick = (emojiData: EmojiClickData, event: MouseEvent) => {
    const extendedEmojiData = {
      type: 'emoji',
      name: emojiData.emoji,
      color: '',
    };
    onIconSelect(extendedEmojiData);
  };

  const handleKeyDown = (
    event: React.KeyboardEvent,
    index: number,
    iconsArray,
    section
  ) => {
    let nextSection = section;
    let nextIconsArray = iconsArray;
    let nextIndex = index;

    switch (event.key) {
      case 'ArrowRight':
        event.preventDefault();
        if (index + 1 >= iconsArray.length) {
          // Reached the end of the section, move to the next section
          nextSection = getNextSection(section);
          nextIconsArray = sectionIcons[nextSection];
          nextIndex = 0; // Start at the first icon of the new section
        } else {
          nextIndex = (index + 1) % iconsArray.length;
        }
        break;

      case 'ArrowLeft':
        event.preventDefault();

        if (index - 1 < 0) {
          // Reached the beginning of the section, move to the previous section
          nextSection = getPreviousSection(section);
          nextIconsArray = sectionIcons[nextSection];
          nextIndex = nextIconsArray.length - 1; // Go to the last icon of the previous section
        } else {
          nextIndex = (index - 1 + iconsArray.length) % iconsArray.length;
        }
        break;

      case 'ArrowUp':
        event.preventDefault();
        const upIndex = index - iconsPerRow;
        if (upIndex >= 0) {
          nextIndex = upIndex;
        }
        setIsKeyboardNavigation(true);
        break;

      case 'ArrowDown':
        event.preventDefault();
        const downIndex = index + iconsPerRow;
        if (downIndex < iconsArray.length) {
          nextIndex = downIndex;
        }
        setIsKeyboardNavigation(true);
        break;
      case 'Tab':
        setIsKeyboardNavigation(false);
        break;
      case 'Enter':
        event.stopPropagation();
        if (iconsArray[index]) {
          onIconSelect({
            type: 'icon',
            color: iconColor.color,
            name: iconsArray[index],
          });
        }
        break;
      default:
        break;
    }

    setFocusedIconIndex(nextIndex);
    setSelectedIcon(nextIconsArray[nextIndex]);
    setCurrentSection(nextSection); // Keep track of the active section
    setIsKeyboardNavigation(true);
  };

  const renderIconResult = (Icon: IconResult, index: number) => (
    <div
      key={Icon.name}
      style={{
        borderColor:
          selectedIcon === Icon.name ? iconColor.color : 'transparent',
      }}
      className={`transition-all duration-300 ease-in-out icon cursor-pointer p-1 ${
        selectedIcon === Icon.name
          ? 'rounded-md border border-solid'
          : 'border border-transparent'
      }`}
      onClick={() => {
        onIconSelect({
          type: 'icon',
          color: iconColor.color,
          name: Icon.name,
        });
      }}
      onMouseOver={() => setSelectedIcon(Icon.name)}
      onKeyDown={event =>
        handleKeyDown(
          event,
          index,
          iconResults.map(icon => {
            return icon.name;
          }),
          'result'
        )
      }
      ref={el => {
        if (isKeyboardNavigation && index === focusedIconIndex) {
          el && el.focus();
        }
      }}
      tabIndex={0}
    >
      <Icon.component color={iconColor.color} size={20} />
    </div>
  );

  useLayoutEffect(() => {
    const firstTab: HTMLDivElement = document.querySelector('.emojiTab');

    if (firstTab) {
      firstTab.focus();
    }
  }, []);

  const colorOptions: { [key in IconColorsType]: { color: string } } = {
    linearLightGrey: { color: '#BFC2C8' },
    linearMediumGrey: { color: '#98A2B3' },
    linearLavender: { color: '#6369D2' },
    linearTeal: { color: '#54B2CE' },
    linearMintGreen: { color: '#65B581' },
    mapMapGreen: { color: '#13A734' },
    linearYellow: { color: '#EBC109' },
    linearOrange: { color: '#E79E4D' },
    linearLightPink: { color: '#F1CAC2' },
    linearRed: { color: '#DD635A' },
    eggshell: { color: '#FCFAF6' },
    hubHubLogoBlue: { color: '#5978E8' },
  };

  const colorRefs = Object.keys(colorOptions).reduce((acc, key) => {
    acc[key] = createRef();
    return acc;
  }, {});

  const textInputRef = useRef(null);
  const containerRef = useRef(null);

  return (
    <PlasmicEmojiAndIconSelection
      onKeyDown={e => e.stopPropagation()}
      searchSlot={
        <SimpleTextInput
          // autoFocus={true}
          className="!border !border-solid !border-[#acacac] p-2 w-full"
          value={iconSearchQuery}
          onChange={handleInputChange}
          placeholder="Search Icon"
          ref={textInputRef}
          onClick={() => {
            if (textInputRef.current) {
              textInputRef.current.focus();
            }
          }}
        />
      }
      container={{
        ref: containerRef,
        style: {
          width: '380px',
        },
      }}
      tabsContainer={{
        previewKey: tabPreview,
      }}
      emojiTabButton={{
        className: 'emojiTab',
      }}
      isShowigResults={iconSearchQuery.length >= 3}
      {...Object.fromEntries(
        Object.entries(colorOptions).map(([key, value], index, array) => [
          key,
          {
            ref: colorRefs[key],
            tabIndex: index === 0 ? 0 : -1,
            onKeyDown: e => {
              if (e.key === 'ArrowRight') {
                e.preventDefault();

                const nextIndex = (index + 1) % array.length;
                const nextKey = array[nextIndex][0];
                colorRefs[nextKey].current?.focus();

                //@ts-ignore
                setIconColor({
                  name: nextKey as IconColorsType,
                  color: colorOptions[nextKey].color,
                });
              } else if (e.key === 'ArrowLeft') {
                e.preventDefault();

                const prevIndex = (index - 1 + array.length) % array.length;
                const prevKey = array[prevIndex][0] as IconColorsType;
                colorRefs[prevKey].current?.focus();

                onIconColorSelect(value.color)
                //@ts-ignore
                setIconColor({
                  name: prevKey,
                  color: colorOptions[prevKey].color,
                });
              }
            },
            onClick: () => {
              onIconColorSelect(value.color)
              setIconColor({ name: key as IconColorsType, color: value.color })
            },
            isSelected: iconColor.name === key,
          },
        ])
      )}
      emojiSelectionSlot={
        <EmojiPicker
          theme={Theme.DARK}
          onEmojiClick={handleEmojiClick}
          //onKeyDown={handleEmojiClick}
        />
      }
      frequentlyUsedIconsSlot={generalStore.frequentlUsedIcons.map(
        (icon: string, index) => (
          <div
            key={icon}
            tabIndex={index === 0 ? 0 : -1}
            style={{
              borderColor:
                selectedIcon === icon ? iconColor.color : 'transparent',
            }}
            className={`transition-all duration-300 ease-in-out icon cursor-pointer p-1 ${
              selectedIcon === icon
                ? 'rounded-md border border-solid'
                : 'border border-transparent'
            }`}
            onClick={() => {
              onIconSelect({
                type: 'icon',
                color: iconColor.color,
                name: icon,
              });
            }}
            onMouseOver={() => setSelectedIcon(icon)}
            onKeyDown={event =>
              handleKeyDown(
                event,
                index,
                generalStore.frequentlUsedIcons,
                'frequentlyUsed'
              )
            }
            ref={el => {
              if (
                isKeyboardNavigation &&
                generalStore.frequentlUsedIcons[index] === selectedIcon
              ) {
                el && el.focus();
              }
            }}
          >
            <IconLoader name={icon} color={iconColor.color} size={16} />
          </div>
        )
      )}
      cabanaIconsSlot={CabanaIcons.map((icon, index) => (
        <div
          key={icon}
          tabIndex={index === 0 ? 0 : -1}
          style={{
            borderColor:
              selectedIcon === icon ? iconColor.color : 'transparent',
          }}
          className={`transition-all duration-300 ease-in-out icon cursor-pointer p-1 ${
            selectedIcon === icon
              ? 'rounded-md border border-solid'
              : 'border border-transparent'
          }`}
          onClick={() => {
            onIconSelect({
              type: 'icon',
              color: iconColor.color,
              name: icon,
            });
          }}
          onMouseOver={() => setSelectedIcon(icon)}
          onKeyDown={event =>
            handleKeyDown(event, index, CabanaIcons, 'cabana')
          }
          ref={el => {
            if (
              isKeyboardNavigation &&
              currentSection !== 'frequentlyUsed' &&
              CabanaIcons[index] === selectedIcon
            ) {
              el && el.focus();
            }
          }}
        >
          <IconLoader name={icon} color={iconColor.color} size={16} />
        </div>
      ))}
      microsoftFluentIconsSlot={MicrosoftIcons.map((icon, index) => (
        <div
          key={icon}
          tabIndex={index === 0 ? 0 : -1}
          style={{
            borderColor:
              selectedIcon === icon ? iconColor.color : 'transparent',
          }}
          className={`transition-all duration-300 ease-in-out icon cursor-pointer p-1 ${
            selectedIcon === icon
              ? 'rounded-md border border-solid'
              : 'border border-transparent'
          }`}
          onClick={() => {
            onIconSelect({
              type: 'icon',
              color: iconColor.color,
              name: icon,
            });
          }}
          onMouseOver={() => setSelectedIcon(icon)}
          onKeyDown={event =>
            handleKeyDown(event, index, MicrosoftIcons, 'microsoft')
          }
          ref={el => {
            if (
              isKeyboardNavigation &&
              currentSection !== 'frequentlyUsed' &&
              MicrosoftIcons[index] === selectedIcon
            ) {
              el && el.focus();
            }
          }}
        >
          <IconLoader name={icon} color={iconColor.color} size={16} />
        </div>
      ))}
      selectedIconNameSlot={
        <span className={`text-base ml-2`} style={{ color: iconColor.color }}>
          {selectedIcon}
        </span>
      }
      iconStyleSelectionSlot={
        <IconLoader name={selectedIcon} color={iconColor?.color} size={30} />
      }
      resultsSlot={
        isLoading ? (
          <div>Loading...</div>
        ) : (
          <>{iconResults.map(renderIconResult)}</>
        )
      }
    />
  );
};

export default EmojiAndIconSelectorWrapper;
