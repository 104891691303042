import { OverlayMenuWrapper } from 'CoreComponents/Overlays/OverlayMenuWrapper';
import RenderAreas from 'CoreComponents/Renderers/RenderAreas';
import RenderConnections from 'CoreComponents/Renderers/RenderConnectors';
import RenderPreviews from 'CoreComponents/Renderers/RenderPreviews';
import RenderStacks from 'CoreComponents/Renderers/RenderStacks';
import { overlayMenuWrapperProps } from 'CoreComponents/hotkeyProvider';
import AddStyleShortcut from 'components/themeAndStyleSettings/style/AddStyleShortcut';
import React, { useEffect, useRef } from 'react';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import Selecto from 'react-selecto';
import {
  getScaleFromMatrix,
  getTranslateFromMatrix,
} from 'utils/helpers/Canvas/clamp-zoom';
import {
  EVENTS,
  TRIGGERS,
} from 'utils/hooks/stateMachine/mapSynincincStateMachineTypes';
import useMapSyncingStateMachine from 'utils/hooks/stateMachine/useMapSyncingStateMachine';
import { setIsEditing } from 'utils/stores/gestureStore';
import { hotKeyStateStore } from 'utils/stores/hotKeyStatesStore';
import { anchorMode } from 'utils/stores/mapMode/modesStore';
import { mapModesStore, updateMapMode } from 'utils/stores/mapmodesStore';
import { useSnapshot } from 'valtio';
import Canvas, { Matrix } from '../../CoreComponents/Canvas/canvas';
import {
  checkAndUpdateIsMapEmptyForMap,
  currentMapContentStore,
  currentMapStateStore,
  getCurrentMapData,
} from '../../utils/stores/mapStore';
import EmptyMapOverlay from '../Overlays/EmptyMapOverlay';
import CustomDragLayer from 'CoreComponents/customDragLayer/CustomDragLayer';

const CanvasWrapper: React.FC = () => {
  const mapId = useParams().mapId;

  // console.log('canvasWrapper 🎁', mapId);

  // const canDragItem = useRef<boolean>(true);
  const selectoRef = useRef<Selecto | null>(null);
  const commandInputMousePosition = useRef({ x: 0, y: 0 });
  const containerRef = useRef<HTMLDivElement>(null);
  const mapModes = useSnapshot(mapModesStore.mode);
  const { isEmptyMap } = useSnapshot(currentMapStateStore[mapId]);

  const [machine, send] = useMapSyncingStateMachine(mapId);
  const mapState = useSnapshot(currentMapStateStore[mapId]);
  useSnapshot(currentMapContentStore[mapId]);

  function closeNewMapStyleModal() {
    currentMapStateStore[mapId].openNewStyleShortcut = false;
  }

  useEffect(() => {
    if (mapId) {
      send({ type: EVENTS.PREPARECOMPARISON, trigger: TRIGGERS.LOAD });
    }
  }, [mapId, send]);

  useEffect(() => {
    if (mapId) {
      send({ type: EVENTS.PREPARECOMPARISON, trigger: TRIGGERS.LOAD });
    }
  }, [mapId, send]);

  const currentMapData = getCurrentMapData(mapId);

  useEffect(() => {
    const emptySelectedStore = () => {
      currentMapStateStore[mapId].selected = [];
    };

    window.addEventListener('beforeunload', emptySelectedStore);
    window.addEventListener('popstate', emptySelectedStore);

    // Clear selection when component mounts or updates based on navigation changes
    emptySelectedStore();

    // set isEmptyMap to true when component mounts & map is empty
    checkAndUpdateIsMapEmptyForMap(mapId);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('beforeunload', emptySelectedStore);
      window.removeEventListener('popstate', emptySelectedStore);
    };
  }, [mapId]);

  return (
    <div id="canvas-wrapper">
      {/* Displaying map title */}
      <Helmet>
        <title>
          {`${
            currentMapData?.icon && currentMapData?.icon?.type === 'emoji'
              ? currentMapData?.icon?.name
              : ''
          } 
              ${currentMapData?.name || 'Loading...'}` || 'Map'}
        </title>
      </Helmet>

      {isEmptyMap ? <EmptyMapOverlay /> : null}

      {mapState.openNewStyleShortcut && (
        <OverlayMenuWrapper
          {...overlayMenuWrapperProps}
          closeModal={closeNewMapStyleModal}
          fitContentHeight={true}
          maxWidth={500}
        >
          <AddStyleShortcut />
        </OverlayMenuWrapper>
      )}

      <div
        ref={containerRef}
        id="selecto-wrapper"
        className="absolute inset-0 w-full h-full"
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          if (hotKeyStateStore.current.isModActive) {
            const canvasMatrix = new Matrix(
              currentMapStateStore[mapId].canvas?.CanvasTransform
            );

            const { x, y } = getTranslateFromMatrix(canvasMatrix);
            const zoomLevel = getScaleFromMatrix(canvasMatrix);
            commandInputMousePosition.current.x =
              (e.clientX - x) / zoomLevel - 8;
            commandInputMousePosition.current.y =
              (e.clientY - y) / zoomLevel - 16;

            anchorMode.activate('command');

            return;
          }
          if (mapModes.command) {
            updateMapMode('insert', false);
            updateMapMode('command', false);
          }
        }}
      >
        {/* <RenderSelecto selectoRef={selectoRef} mapId={mapId} send={send}> */}
        <DndProvider
          backend={TouchBackend}
          options={{
            enableMouseEvents: true,
          }}
        >
          {/* <pre>{JSON.stringify(snap.value, null, 2)}</pre> */}
          <CustomDragLayer />

          <Canvas className="map-container " selectoRef={selectoRef}>
            <RenderStacks selectoRef={selectoRef} />
            <RenderPreviews />
            <RenderAreas selectoRef={selectoRef} />

            <RenderConnections />
          </Canvas>
        </DndProvider>
        {/* </RenderSelecto> */}
      </div>
    </div>
  );
};

export { CanvasWrapper };
