import { PreviewData, WebPreviewContent } from "utils/stores/types";

interface VideoPreviewProps {
  preview: PreviewData;
}

export default function VideoPreview({
    preview
}: VideoPreviewProps) { 
    const webContent = preview.content as WebPreviewContent;
    return (
        <div className="flex items-center justify-center" style={{height: `100%`, width: `100%`}}>
            <video autoPlay controls style={{ width: '100%', height: '100%' }}>
                <source src={webContent.link} type="video/mp4" />
            </video>
        </div>
    )
}