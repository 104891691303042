/**
 * areaStore : This tracks  area that was interacted with and allows to run side effect.
 *
 * interactions
 * Drop: when a note is dropped unto a stack within an area
 * Drag: when a note is dragged from a stack within an area
 * Editing:  when a note within a stack is being edited or created with double click
 * Delete: when a stack is removed from a note - this seems to conflict with drag
 * Add : when a note is dropped directly on an area
 * MultipleDrop: when a multiple item  is droped in an area
 *
 *
 */

import {
  handleMultipleAreaDrop,
  resizeAreaOnNewStack,
  resizeAreaOnStackDrop,
  updateAreaOnNoteEdit,
} from 'utils/helpers/Areas/areaUtils';
import { proxy, ref, subscribe } from 'valtio';
import { AreaData } from '../types';

interface MicroActions {
  actions: 'Drop' | 'Drag' | 'Edited' | 'Delete' | 'Add' | 'MultipleDrop';
  targetAreaID: string;
  stackid?: string;
  position?: {
    x: number;
    y: number;
  };
  options?: {
    element?: HTMLElement | HTMLDivElement;
    contentID?: string;
    others?: any;
  }; // any for now
}

/**
 * store states
 *
 * idile: when no action is being excuted
 * pending: set to pending if you want to pending if you want to push changes to it
 * Execute: when there is an action to be executed
 *
 *
 * This approach allows you to multiple tasks  changes to the store
 * and not trigger the microActions to run until state has been set to Execute
 */

//............................. store ........................

interface AreaState {
  state: 'idile' | 'pending' | 'Execute';

  task: MicroActions[];
  area?: AreaData;
}
interface AreaStateStore {
  store: AreaState;
}

type TIsCreatingAreaState = { dragging: boolean; creatingAreaMode: boolean };

interface IAreaCreationState {
  isCreatingArea: TIsCreatingAreaState;
}

export const areaStateStore = proxy<AreaStateStore>({
  store: {
    state: 'idile',
    task: ref([]), // ensure the pushing items to the array doesn't trigger the subscribe function
  },
});

export const areaCreationState = proxy<IAreaCreationState>({
  isCreatingArea: { dragging: false, creatingAreaMode: false },
});

//............................. subscriber ........................

const unsubscriber = subscribe(areaStateStore, runMicroActions);

//........................... Functions ..............................
export function setIsCreatingArea(
  newVal:
    | TIsCreatingAreaState
    | ((_: TIsCreatingAreaState) => TIsCreatingAreaState)
) {
  if (typeof newVal === 'function')
    newVal = newVal(areaCreationState.isCreatingArea);
  areaCreationState.isCreatingArea = newVal;
}

function resetStore() {
  // mutation only
  // array should not be assigned see ref() - valtio
  areaStateStore.store.task.splice(0, areaStateStore.store.task.length);

  //set to default state
  areaStateStore.store.state = 'idile';
  areaStateStore.store.area = null;
}

function runMicroActions() {
  if (areaStateStore.store.state === 'Execute') {
    for (let i = 0; i < areaStateStore.store.task.length; i++) {
      executeMicroTasks(areaStateStore.store.task[i]);

      /**
       * break loop if the first task is a multdrop because we
       * are adding the stacks id to one area
       * and doesn't make sense to make this loop run as we can
       * just pass the whole array to it and have it add it to the area
       */
      if (areaStateStore.store.task[i].actions === 'MultipleDrop') break;
    }
    resetStore();
  }
}

function executeMicroTasks(task: MicroActions) {
  switch (task.actions) {
    case 'Add':
      resizeAreaOnNewStack(
        task.targetAreaID,
        task.options.contentID,
        task.position
      );
      break;
    case 'Drag': {
      // call this after drop
      break;
    }
    case 'Drop': {
      resizeAreaOnStackDrop(
        task.targetAreaID,
        task.stackid,
        task.options?.element
      );
      break;
    }
    case 'Edited': {
      updateAreaOnNoteEdit(
        task.targetAreaID,
        task.stackid,
        task.options.contentID
      );
      break;
    }
    case 'Delete': {
      break;
    }
    case 'MultipleDrop': {
      const stackIDS = areaStateStore.store.task.map(task => task.stackid);
      handleMultipleAreaDrop(areaStateStore?.store?.area, stackIDS);
      return;
    }
    default:
      break;
  }
}
