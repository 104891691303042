import { addItemToSelected, getStackData } from 'utils/stores/mapStore';
import { getArea } from 'utils/mapStoreFN/mapStoreFN_areas';
import selectedContentsStore, {
  SelectedContent,
} from 'utils/stores/Selection/selectedContentStore';
import {
  areAllStackItemsSelectedInArea,
  areArraysEqual,
  checkBothStacksAndAreas,
  extractAreasIdsAndItemsIds,
  getSelectedContentV1,
  getUnselectedItems,
} from './highlightEntireMap/highLightMapUtils';
import { generalStore } from 'utils/stores/generalStore';

export default function useHighlightMap(mapId: string) {
  const highlightEntireMap = () => {
    const mapItems = extractAreasIdsAndItemsIds(mapId);
    const mapItemIds = mapItems.map(item => item.contentID);

    let resolvedSelectedContent: SelectedContent[];
    let resolvedSelectedIDs: string[];

    // using new selected store implementation
    if (generalStore.flags.mapmap_selectionv2) {
      const allSelected = selectedContentsStore.getAllSelected();
      resolvedSelectedContent = allSelected.queriedSelectedContent;
      resolvedSelectedIDs = allSelected.querriedContentIDs;
    } else {
      const { selectedIDs, selectedContent } = getSelectedContentV1(mapId);
      resolvedSelectedContent = selectedContent;
      resolvedSelectedIDs = selectedIDs;
    }

    // when the mapItems are already selected
    if (areArraysEqual(mapItemIds, resolvedSelectedIDs)) return;

    let highlightedMapItems = [];

    // case 1
    if (!resolvedSelectedIDs.length) {
      highlightedMapItems = mapItems; // Highlight all items
    } else {
      // case 2
      resolvedSelectedContent.forEach(content => {
        const { hasUnselectedItems, unselectedItems, stackIds } =
          getUnselectedItems(resolvedSelectedContent, mapId);

        let areaData;
        let areAllStackSelectedInArea = false;
        if (content.type === 'Area') {
          areaData = getArea(content.contentID, mapId);
          areAllStackSelectedInArea = areAllStackItemsSelectedInArea(
            areaData.encompassingStacks,
            resolvedSelectedIDs,
            mapId
          );
        }

        // case 2-A
        if (content.type === 'Note') {
          if (hasUnselectedItems) {
            // case 2-A-1 & case 2-A-2
            highlightedMapItems = [
              ...highlightedMapItems,
              ...resolvedSelectedContent,
              ...unselectedItems, // add unselected items in the stack
            ];
          } else if (
            checkBothStacksAndAreas(resolvedSelectedIDs, stackIds, mapId)
          ) {
            // case 2-C
            highlightedMapItems = mapItems; // Highlight all items
          }
        } else if (content.type === 'Area' && areaData) {
          // case 2-B
          if (!areAllStackSelectedInArea) {
            areaData.encompassingStacks.forEach(stackId => {
              const newStackData = getStackData(stackId, mapId);
              const itemIds = newStackData.items.map(item => {
                return {
                  contentID: item.itemId,
                  type: item.type,
                  parentType: 'stack' as 'stack',
                  parentID: newStackData.stackId,
                };
              });
              highlightedMapItems = [
                ...highlightedMapItems,
                ...resolvedSelectedContent,
                ...itemIds,
              ];
            });
          } else {
            // case 2-C
            highlightedMapItems = mapItems; // Highlight all items
          }
        }
      });
    }

    highlightedMapItems.forEach(item => addItemToSelected(item, mapId));
  };

  return {
    highlightEntireMap,
  };
}
