import { useEffect } from 'react';
import { dashboardStore } from '../stores/dashboardStore';

// we are doing this globally instead of inside the LargeItem.
// Because we need to allow multi selection and there are multiple instances of the large item
// otherwise even clicks on other large items would be registered as outside clicks
export const useClickOutsideLargeItem = () => {
  useEffect(() => {
    const handleGlobalClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      const isLargeItem = target.closest('[id^="large-item-"]');


      if (!isLargeItem && (dashboardStore.selectedMapIds.length > 0 || dashboardStore.selectedFolderIds.length > 0)) {
        dashboardStore.selectedMapIds = [];
        dashboardStore.selectedFolderIds = [];
      }
    };

    document.addEventListener('mousedown', handleGlobalClick);

    return () => {
      document.removeEventListener('mousedown', handleGlobalClick);
    };
  }, []);
};
