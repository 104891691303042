import { isRegistered, register } from '@tauri-apps/api/globalShortcut';
import { uniqueId } from 'lodash';
import { RUNNING_IN_TAURI } from 'propro-common-components';
import openInNewDesktopWindow from 'utils/tauri/openInNewDesktopWindow';

export default function registerGlobalShortcuts() {
  registerOpenOverviewShortcut();
}

async function registerOpenOverviewShortcut() {
  await handleRegister('CommandOrControl+P', () => {
    openInNewDesktopWindow(
      'Overview',
      uniqueId('overview'),
      '/dashboard/overview'
    );
  });
}

async function handleRegister(cmd: string, cb: () => void) {
  if (!RUNNING_IN_TAURI) return;
  const isCmdRegistered = await isRegistered(cmd);
  if (!isCmdRegistered) await register(cmd, cb);
}
