// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type TextCopyIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function TextCopyIcon(props: TextCopyIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 24 24"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M16.995 5.824A3 3 0 0014 3H6l-.176.005A3 3 0 003 6v8l.005.176A3 3 0 006 17h1v1a3 3 0 003 3h8a3 3 0 003-3v-8a3 3 0 00-3-3h-1V6l-.005-.176zM15 7V6a1 1 0 00-.883-.993L14 5H6a1 1 0 00-.993.883L5 6v8a1 1 0 00.883.993L6 15h1v-5a3 3 0 013-3h5zm-6 9v2a1 1 0 001 1h8a1 1 0 001-1v-8a1 1 0 00-1-1h-8a1 1 0 00-1 1v6z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default TextCopyIcon;
/* prettier-ignore-end */
