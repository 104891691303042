import getSelectedIDs from 'utils/helpers/Selection/selectionUtils';
import {
  DraggedArea,
  DraggedItem,
  DraggedPreview,
} from 'utils/hooks/useDropOnCanvas';
import { getStacksInArea } from 'utils/mapStoreFN/mapStoreFN_stacks';
import { generalStore } from 'utils/stores/generalStore';

export function createPreviewClone(node: HTMLElement, nodes: HTMLElement[]) {
  const cloneNode = node.cloneNode(true) as HTMLElement;

  cloneNode.style.zIndex = '100';

  node.parentNode.appendChild(cloneNode);
  // add opacity to selected Node
  if (node.parentElement) node.style.opacity = '0.3';
  nodes.push(node);

  return cloneNode;
}

export function getNode(
  targetID: string,
  useSelectionV2: boolean
): HTMLElement | null {
  const node: HTMLElement = document.querySelector(
    `[data-drag="${targetID}"]`
  ) as HTMLElement;

  if (node) {
    return node;
  } else {
    return null;
  }
}

export function createAreaClone(
  areaId: string,
  mapId: string,
  useSelectionV2: boolean
) {
  const areaNode = getNode(areaId, useSelectionV2);

  if (!areaNode) return [];

  const cloneAreaNode = areaNode.cloneNode(true) as HTMLElement;

  cloneAreaNode.style.position = 'absolute';

  cloneAreaNode.style.top = `${areaNode.offsetTop}px`;
  cloneAreaNode.style.left = `${areaNode.offsetLeft}px`;

  areaNode.parentNode.appendChild(cloneAreaNode);

  const encompassingStacks = getStacksInArea(areaId, mapId);

  // For encompassing stacks in the area
  if (!encompassingStacks?.length) return [cloneAreaNode];

  const stackNodes: HTMLElement[] = [];

  encompassingStacks.forEach(stack => {
    const stackNode = document.querySelector(
      `[data-drag="${stack.stackId}"]`
    ) as HTMLElement;

    const cloneStackNode = stackNode.cloneNode(true) as HTMLElement;

    cloneStackNode.style.position = 'absolute';
    cloneStackNode.style.top = `${stackNode.offsetTop}px`;
    cloneStackNode.style.left = `${stackNode.offsetLeft}px`;

    stackNode.parentNode.appendChild(cloneStackNode);

    stackNodes.push(cloneStackNode);
  });

  return [cloneAreaNode, ...stackNodes];
}

export function createItemClone(node: HTMLElement, nodes: HTMLElement[]) {
  const cloneNode = node.cloneNode(true) as HTMLElement;

  // item_9abb3c40-c562-4186-aed4-9a2de724994b:stack-Default

  let id = (node.getAttribute('data-drag') || '').split(':')[0].split('_')[1];

  cloneNode.setAttribute('id', `clone_${id}`); // = `clone_${node.id}`;
  cloneNode.style.position = 'absolute';
  cloneNode.style.top = `${node.offsetTop}px`;
  cloneNode.style.left = `${node.offsetLeft}px`;
  cloneNode.style.zIndex = '100';

  node.parentNode.appendChild(cloneNode);
  // add opacity to selected Node
  if (node.parentElement) node.style.opacity = '0.3';
  nodes.push(node);

  return cloneNode;
}

export const getSelectedNodes = (
  item: any,
  cloneNodes: React.MutableRefObject<HTMLElement[]>,

  mapId: string,
  nodes: HTMLElement[]
): HTMLElement[] | null => {
  //   debugger;
  if (cloneNodes.current) return null;
  cloneNodes.current = [];

  const selected = getSelectedIDs();

  const useSelectionV2 = generalStore.flags?.mapmap_selectionv2;

  const resolvedId =
    (item as DraggedItem)?.item?.itemId ||
    (item as DraggedArea)?.areaId ||
    (item as DraggedPreview)?.preview?.previewId;

  if (!selected.length || !selected.includes(resolvedId)) {
    if (!resolvedId && resolvedId.includes('item_')) return;

    //   // If what is being dragged is an item
    if (resolvedId.includes('area_')) {
      // If what is being dragged is an area
      const clonedAreaNodes = createAreaClone(
        resolvedId,
        mapId,
        useSelectionV2
      );
      cloneNodes.current = clonedAreaNodes;
    }

    if (resolvedId.includes('preview_')) {
      // If what is being dragged is a preview
      const node = getNode(resolvedId, useSelectionV2);
      const clonedPreviewNode = createPreviewClone(node, nodes);
      cloneNodes.current = [clonedPreviewNode];
    }
  } else {
    // This block is for all selected items

    // here we are updating the function with have the new closure
    //with selectedNodes populated

    for (let i = 0; i < selected?.length; i++) {
      const id = selected[i];

      // If what is being dragged is an item
      if (id.includes('item_') && id !== item.id) {
        const node = getNode(id, useSelectionV2);

        if (node) {
          const cloneNode = createItemClone(node, nodes);

          cloneNodes.current.push(cloneNode);
        }
      } else if (id.includes('area_')) {
        // If what is being dragged is an area
        const clonedAreaNodes = createAreaClone(id, mapId, useSelectionV2);

        cloneNodes.current.push(...clonedAreaNodes);
      } else if (id.includes('preview_')) {
        const node = getNode(id, useSelectionV2);

        if (node) {
          const cloneNode = createPreviewClone(node, nodes);

          cloneNodes.current.push(cloneNode);
        }
      }

      continue;
    }
  }
};
