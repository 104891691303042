// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type SearchIconIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function SearchIconIcon(props: SearchIconIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 20 20"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M8.333 1.667A6.667 6.667 0 1012.421 13.6l4.49 4.49.078.069a.833.833 0 001.1-1.248l-4.49-4.49A6.667 6.667 0 008.333 1.666zm0 1.666a5 5 0 110 10 5 5 0 010-10z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default SearchIconIcon;
/* prettier-ignore-end */
