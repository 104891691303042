import { CSSProperties } from 'react';
import { deepCopy } from 'utils/helpers/deepCopy';
import { proxy } from 'valtio';
import { themeStore } from './themeStore';

interface MapModeProps {
  insert: boolean;
  areaCreation: boolean;
  duplicate: boolean;
  default: boolean;
  command: boolean;
  droppingMultiSelected: boolean;
  draggingMultiSelected: boolean;
  showShortcutModal: boolean;
  canDragItem: boolean;
  mapshotCreation: boolean;
}

interface ModeStoreProps {
  mode: MapModeProps;
  currentModes: (keyof MapModeProps)[];
}

type ModeStyleProps = {
  [key in keyof MapModeProps]: CSSProperties;
};

const defaultState: MapModeProps = {
  default: true,
  insert: false,
  areaCreation: false,
  duplicate: false,
  command: false,
  droppingMultiSelected: false,
  draggingMultiSelected: false,
  showShortcutModal: false,
  canDragItem: true,
  mapshotCreation: false,
};

export const modeStyles: ModeStyleProps = {
  insert: {
    background: themeStore.canvasBackgroundColor,
    cursor: `url(/images/command_icon.svg), auto`,
  },
  default: {
    background: themeStore.canvasBackgroundColor,
  },
  duplicate: {
    cursor: 'copy',
    background: themeStore.canvasBackgroundColor,
  },
  areaCreation: { background: themeStore.canvasBackgroundColor },
  command: { background: themeStore.canvasBackgroundColor },
  droppingMultiSelected: { background: themeStore.canvasBackgroundColor },
  draggingMultiSelected: { background: themeStore.canvasBackgroundColor },
  canDragItem: { background: themeStore.canvasBackgroundColor },
  showShortcutModal: {
    background: themeStore.canvasBackgroundColor,
  },
  mapshotCreation: {
    cursor: 'crosshair',
    background: themeStore.canvasBackgroundColor,
  },
};

export const mapModesStore = proxy<ModeStoreProps>({
  mode: defaultState,
  currentModes: ['default'],
});

export const resetMapModesStore = () => {
  mapModesStore.mode = deepCopy(defaultState);
};

export const updateMapMode = (key: keyof MapModeProps, value: boolean) => {
  mapModesStore.mode[key] = value;

  const trueCount = Object.values(mapModesStore.mode).filter(val => val).length;

  if (trueCount === 0) {
    // If all modes are false, set `default` to true and `currentMode` to 'default'
    mapModesStore.mode.default = true;
    mapModesStore.currentModes = ['default'];
  } else {
    // If there is any true mode, set `default` to false
    mapModesStore.mode.default = false;

    // If setting a mode to true, adjust currentModes accordingly
    if (value) {
      // Filter out 'default' and the current key to potentially remove duplicates
      const newState = mapModesStore.currentModes.filter(
        x => x !== 'default' && x !== key
      );

      // Add the current key
      newState.push(key);

      // Update the currentModes array
      mapModesStore.currentModes = newState;
    } else {
      // Remove the key from currentModes as its value is set to false
      const updatedModes = mapModesStore.currentModes.filter(x => x !== key);

      // If no modes are left true, revert to default
      mapModesStore.currentModes =
        updatedModes.length > 0 ? updatedModes : ['default'];
    }
  }
  // console.log(mapModesStore.mode);
  // console.log(mapModesStore.currentModes);
};
