/**
 * Mapmap supported modes state:
 * This inclueds all modes mapmap can be in.
 * Only one mode can be active at any given time.
 * The default state is set to all modes being inactive.
 *
 * To ensure components subscribe to only what they need and avoid unneccessary rerenders
 * if one part of the store changes, that doesn't neccessary concern the component calling useSnapshot
 * the mode are nested objects: this allows component call useSnapshot to that specific object.
 *
 */

import { CSSProperties } from 'react';
import { proxy, subscribe } from 'valtio';
import { ColorSelectionActions } from '../types';
import { themeStore } from '../themeStore';

//....................... types..............
type ModeStores = DuplicateMode | Anchor | CreatingArea | InsertMode | Default;

type ModeStyle = {
  [Key in Mode]: CSSProperties;
};

/**
 * style object.
 *
 */
const modeStyle: ModeStyle = {
  ANCHOR: {},
  AREACREATION: {},
  DUPLICATE: {
    cursor: 'crosshair',
    background: themeStore.canvasBackgroundColor,
  },
  SHOWMODAL: {},
  DEFAULT: {
    background: themeStore.canvasBackgroundColor,
    cursor: 'auto',
  },
  INSERT: {
    background: themeStore.canvasBackgroundColor,
    cursor: `url(/images/command_icon.svg), auto`,
  },
};

//..........private function.....................

function activateMode(this: ModeStores) {
  this.isActive = true;
  currentMode.activeMode = this;
  currentMode.style = modeStyle[this.name];
}

function deActivate(this: ModeStores) {
  this.isActive = false;
  currentMode.activeMode = defaultMode;
  currentMode.style = modeStyle['DEFAULT'];
}

export type Mode =
  | 'DEFAULT'
  | 'INSERT'
  | 'DUPLICATE'
  | 'ANCHOR'
  | 'SHOWMODAL'
  | 'AREACREATION';

/**
 * INSERT MODE
 * @summary Allows 1 or more notes to be drop into a stack
 * @property  {boolean} isActive - indicates if the mode is active
 * @property  {number} numberOfDraggedItems -  number of currently dragged item
 * @property  {any} others - other values that might be needed
 * @property name - a string representation of the mode name
 *
 *
 * @method activate
 * @param  {number} draggedItems - number of selected item if a multidrag or one
 * toggles the insertmode to true
 *@return void
 *
 * @method deActivate
 * toggles the insertmode false
 *
 * @method reset
 * reset the mode to default - deactivates it
 * @return void
 */

export const insertMode = proxy<InsertMode>({
  isActive: false,
  name: 'INSERT',
  numberOfDraggedItem: 0,
  activate: (draggedItems = 1) => {
    activateMode.call(insertMode);
    insertMode.numberOfDraggedItem = draggedItems;
  },
  reset: () => {
    deActivate.call(insertMode);
    insertMode.numberOfDraggedItem = 0;
  },
});

export interface InsertMode {
  name: Mode;
  isActive: boolean;
  numberOfDraggedItem: number;
  others?: any;
  activate: (draggedItems: number) => void;

  reset: () => void;
}

/**
 * CREATING AREA MODE
 * Track when the user is trying to create an area or an area + stack
 * @property  name - a string representation of the mode name
 * @property {boolean} isActive - indicates if the mode is active
 * @property {boolean} areaAndStack - if the user is creating an area + stack
 * @property {boolean} default - creating just an area with the default area box or custom area
 *
 * @method activateAreaAndStack
 * set isActive true and areaAndStack to true but ensure default is false
 *
 * @method activate
 * set isActive true and default to true
 *
 * @method reset
 * sets all property to false and deactivates mode
 *
 */

export const creatingAreaMode = proxy<CreatingArea>({
  isActive: false,
  areaAndStack: false,
  name: 'AREACREATION',
  default: false,
  hidePreviewBox: false,

  activateAreaAndStack: () => {
    activateMode.call(creatingAreaMode);
    creatingAreaMode.areaAndStack = true;
    creatingAreaMode.default = false;
  },
  activate: () => {
    activateMode.call(creatingAreaMode);
    creatingAreaMode.default = true;
    creatingAreaMode.areaAndStack = false;
  },
  reset: () => {
    deActivate.call(creatingAreaMode);
    creatingAreaMode.areaAndStack = false;
    creatingAreaMode.default = false;
    creatingAreaMode.hidePreviewBox = false;
  },
});

interface CreatingArea {
  isActive: boolean;
  name: Mode;
  hidePreviewBox: boolean;
  areaAndStack: boolean;
  default: boolean;
  activate: () => void;
  activateAreaAndStack: () => void;
  reset: () => void;
}

/**
 * ANCHOR MODE
 * @summary anchors the set compoent to a selected Note.
 *
 * @property { 'colorSelection'| 'command'| 'borderWeightSelector'| 'shortcutModal' | 'CLOSE'} modal - modal child that should be render or close to not
 * render any component
 * @property {Mode} mode - a string representation of the mode name
 * @property {boolean} isActive - indicates if the mode is active
 *
 * @method  activate
 * sets the modal to the component that should be rendered
 * @param {'colorSelection'| 'command'| 'borderWeightSelector'| 'shortcutModal' | 'CLOSE'} target -  The component your want
 * anchored to the last selected item
 *
 * @method reset
 * set all values to default in this case 'CLOSE'
 */

export const anchorMode = proxy<Anchor>({
  name: 'ANCHOR',
  isActive: false,
  anchorComponent: 'CLOSE',
  activate: target => {
    target !== 'CLOSE' && activateMode.call(anchorMode);
    anchorMode.anchorComponent = target;
    anchorMode.isActive = true;
  },
  reset: () => {
    deActivate.call(anchorMode);
    anchorMode.anchorComponent = 'CLOSE';
  },
});

type AnchorType = ColorSelectionActions;
type AnchorElements = 'command' | 'borderWeightSelector';
export type AnchorTargets = AnchorElements | AnchorType;

export interface Anchor {
  name: Mode;
  isActive: boolean;
  anchorComponent: AnchorTargets;
  activate: (targetElement: AnchorTargets) => void;
  reset: () => void;
}

/**
 * DUPLICATE
 * @summary Track if the user is carryout  a dublicate action ( drag + alt windows or options mac)
 * @property {boolean} isActive
 * @property name - a string representation of the mode name
 *
 * @method activate
 * sets isActive to true
 *
 * @method reset
 * resets it to default
 */

export const duplicateMode = proxy<DuplicateMode>({
  name: 'DUPLICATE',
  isActive: false,
  acivate: () => {
    activateMode.call(duplicateMode);
  },

  reset: () => {
    deActivate.call(duplicateMode);
  },
});

interface DuplicateMode {
  isActive: boolean;
  name: Mode;
  acivate: () => void;
  reset: () => void;
}

/**
 * DEFAULT MODE
 * when no mode is active
 *
 * @property {boolean} isActive
 * @property  name - a string representation of the mode name
 * also used to return the css
 * @method  activate
 * sets is active to true
 *
 * @method reset
 * added this to ensure consistency between object method
 * this still sets isActive true
 */

export const defaultMode = proxy<Default>({
  name: 'DEFAULT',
  isActive: true,
  activate: () => {
    defaultMode.isActive = true;
  },
  reset: () => {
    deActivate.call(defaultMode);
  },
});

interface Default {
  name: Mode;
  activate: () => void;
  isActive: boolean;
  reset: () => void;
}
/**
 * MULTI DRAG MODE
 * @summary Track if the user is doing a multiple drag
 */

/**
 * CURRENT MODE STORE
 * This tracks the currently active mode
 *
 * @return An object containing the currenly active mode or 'DEFAULT' if no mode is active and
 * css propery to be inject if any
 * - `currentMode`: the current active mode
 * - `style`: cssProperty style for that mode
 */

export const currentMode = proxy<CurrentMode>({
  activeMode: defaultMode,
  style: {},
});

interface CurrentMode {
  activeMode: ModeStores;
  style: CSSProperties;
}
