import LargeItem from 'PlasmicComponents/LargeItem';
import FolderMapIconRenderer from 'components/folderMapIconRenderer/FolderMapIconRenderer';
import MapAndFolderSkeletonLoader from 'components/loader/skeletons/mapCardSkeleton';
import React, { useCallback, useEffect, useState } from 'react';
import { FolderData, MapData } from 'utils/stores/types';
import { useSnapshot } from 'valtio';
import { dashboardStore } from '../../utils/stores/dashboardStore';
import ResponsiveOpacityGrid from '../ResponsiveOpacityGrid/ResponsiveOpacityGrid';
import { generalStore } from 'utils/stores/generalStore';

export type ItemType = FolderData | MapData;

export interface LargeItemRendererProps {
  items: ItemType[];
  itemType: 'folder' | 'map';
  loading: boolean;
  onItemSelect?: (itemId: string, event: React.MouseEvent) => void;
  onItemOpen?: (itemId: string) => void;
  itemProps?: {
    className?: string;
    getDataAttributes?: (item: ItemType) => Record<string, string>;
  };
}

export const LargeItemRenderer: React.FC<LargeItemRendererProps> = ({
  items,
  itemType,
  loading,
  onItemSelect,
  onItemOpen,
  itemProps,
}) => {
  const loaderCount = itemType === 'folder' ? 3 : 8;

  const { selectedFolderIds, selectedMapIds } = useSnapshot(dashboardStore);
  const { openContextMenuForItem } = useSnapshot(generalStore);

  const [resolvedItems, setResolvedItems] = useState(items)

  const selectedFolderAndMapIdSet = new Set([
    ...selectedFolderIds,
    ...selectedMapIds,
  ]);

  const getItemId = (item: ItemType): string => {
    if (!item) return;
    return 'folderId' in item ? item.folderId : item.mapId;
  };

  const truncateSingleWordInTitle = (title, maxLength) => {
    const words = title.split(' ');

    const truncatedWords = words.map(word => {
      if (word.length > maxLength) {
        return word.substring(0, maxLength) + '...';
      }
      return word;
    });

    return truncatedWords.join(' ');
  }

  const renderItem = (item: ItemType, opacityLevel?: number) => {
    let resolvedItem = {...item};
    const id = getItemId(resolvedItem);

    resolvedItem.name = truncateSingleWordInTitle(resolvedItem.name, 8)

    return (
      <LargeItem
        key={id}
        className={`cursor-pointer ${itemProps?.className || ''}`}
        {...(itemProps?.getDataAttributes
          ? itemProps.getDataAttributes(resolvedItem)
          : {})}
        title={resolvedItem?.name}
        itemType={itemType}
        titleIcon={
          <FolderMapIconRenderer
            icon={{
              name: resolvedItem?.icon?.name,
              type: resolvedItem?.icon?.type,
              color: resolvedItem?.icon?.color,
            }}
          />
        }
        folderId={'folderId' in resolvedItem ? resolvedItem.folderId : undefined}
        mapId={'mapId' in resolvedItem ? resolvedItem.mapId : undefined}
        isSelected={selectedFolderAndMapIdSet?.has(id)}
        onSelect={event => {
          if (onItemSelect) {
            onItemSelect(id, event);
          } else if (onItemOpen) {
            onItemOpen(id);
          }
        }}
        item={resolvedItem}
        opacityLevel={opacityLevel}
      />
    );
  }

  const reArrangeItems = () => {
    if (openContextMenuForItem?.itemPassed) {
      const newArr = items.map(item => {
        if((itemType === 'folder' && item.folderId === openContextMenuForItem.itemPassed.folderId) || (itemType === 'map' && (item as MapData).mapId === openContextMenuForItem.itemPassed.mapId)){
          return openContextMenuForItem.itemPassed
        }

        return item
      });
      setResolvedItems(newArr)
      return;
    }
    setResolvedItems(items)
  }

  useEffect(() => {
    reArrangeItems()
  }, [openContextMenuForItem, items])

  if (loading) {
    return (
      <>
        {Array(loaderCount)
          .fill(null)
          .map((_, index) => (
            <MapAndFolderSkeletonLoader key={index} type={itemType} />
          ))}
      </>
    );
  }

  if (itemType === 'folder') {
    return (
      <ResponsiveOpacityGrid items={resolvedItems}>
        {(item: ItemType, opacityLevel: number) =>
          renderItem(item, opacityLevel)
        }
      </ResponsiveOpacityGrid>
    );
  }

 
  return (
    resolvedItems.map(item => {
      return<>{renderItem(item)}</>
    })
  )
};
