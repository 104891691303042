import { IconLoader } from 'propro-common-components';

const FolderMapIconRenderer = ({ icon }) => {

  return (
    <div>
      {icon?.type === 'emoji' ? (
        <span className="text-[18px]">{icon.name}</span>
      ) : (
        <IconLoader name={icon.name} size={24} color={icon.color} />
      )}
    </div>
  );
};

export default FolderMapIconRenderer;
