type PositionCord = {
  x: number;
  y: number;
};

type Path = {
  from: PositionCord;
  to: PositionCord;
};

type ArgType = HTMLElement;

const parentOFFsetValue = 4;
const borderOffsetValue = 4;

function getTopPosition(nodeID: ArgType, zoomLevel: number) {
  let top: PositionCord | null = null;

  const domNode = nodeID.getBoundingClientRect();
  if (domNode) {
    const { x, width, y, top: TOP } = domNode;
    const midX = x + width / 2;
    top = {
      x: midX,
      y: y - borderOffsetValue * zoomLevel, // accounting for the parent Element px
    };
  }
  return top;
}

function getRightPosition(nodeID: ArgType, zoomLevel: number) {
  let rightCord: PositionCord | null = null;
  const domNode = nodeID.getBoundingClientRect();
  if (domNode) {
    const { x, width, y, right, left, height } = domNode;
    const midY = y + height / 2;

    rightCord = {
      x: right + borderOffsetValue + parentOFFsetValue * zoomLevel,
      y: midY,
    };
  }

  return rightCord;
}

function getLeftTopEdge(nodeID: ArgType, zoomLevel: number) {
  let leftTopEdge: PositionCord | null = null;
  const domNode = nodeID.getBoundingClientRect();
  if (domNode) {
    const { x, width, y, right, left, height } = domNode;

    leftTopEdge = {
      x: x - parentOFFsetValue * zoomLevel,
      y: y - parentOFFsetValue * zoomLevel,
    };
  }

  return leftTopEdge;
}

function getLeftBottomEdge(nodeID: ArgType, zoomLevel: number) {
  let leftBottomEdge: PositionCord | null = null;
  const domNode = nodeID.getBoundingClientRect();
  if (domNode) {
    const { x, width, y, right, left, height } = domNode;

    leftBottomEdge = {
      x: x - parentOFFsetValue * zoomLevel,
      y: height + y + parentOFFsetValue * zoomLevel,
    };
  }

  return leftBottomEdge;
}

function getRightBottomEdge(nodeID: ArgType, zoomLevel: number) {
  let rightBottomEdge: PositionCord | null = null;
  const domNode = nodeID.getBoundingClientRect();
  if (domNode) {
    const { x, width, y, right, left, height } = domNode;
    const edge = y + height;

    rightBottomEdge = {
      x: right + parentOFFsetValue * zoomLevel,
      y: edge + parentOFFsetValue * zoomLevel,
    };
  }
  return rightBottomEdge;
}

function getRightTopEdge(nodeID: ArgType, zoomLevel: number) {
  let rightTopEdge: PositionCord | null = null;
  const domNode = nodeID.getBoundingClientRect();
  if (domNode) {
    const { x, width, y, top, left, right } = domNode;
    const X = x + width + borderOffsetValue;
    const Y = y - parentOFFsetValue * zoomLevel;
    rightTopEdge = {
      x: X,
      y: Y,
    };
  }
  return rightTopEdge;
}

function isRight(from: HTMLElement, to: HTMLElement): boolean {
  // check if the target note by the right
  // this would let us reduce the amount of possible connection edge to test for
  const fromDOmrect = from.getBoundingClientRect();
  const toDomRect = to.getBoundingClientRect();
  // adding an offset here to ensure it returns true if the target is absolutely right
  // this also allows for better connection cords
  if (fromDOmrect.right + 20 < toDomRect.left) {
    return true;
  }
  return false;
}

function isLeft(from: HTMLElement, to: HTMLElement): boolean {
  const fromDOmrect = from.getBoundingClientRect();
  const toDomRect = to.getBoundingClientRect();
  // adding 30 here to ensure absoulte left

  if (fromDOmrect.left > toDomRect.right + 20) {
    return true;
  }

  return false;
}
function isTop(from: HTMLElement, to: HTMLElement) {
  const fromDOmrect = from.getBoundingClientRect();
  const toDomRect = to.getBoundingClientRect();
  if (fromDOmrect.top > toDomRect.bottom) {
    return true;
  }

  return false;
}

function isBottom(from: HTMLElement, to: HTMLElement) {
  const fromDOmrect = from.getBoundingClientRect();
  const toDomRect = to.getBoundingClientRect();
  if (fromDOmrect.bottom < toDomRect.top) {
    return true;
  }

  return false;
}

export function getLeftPosition(nodeID: ArgType, zoomLevel: number) {
  let LeftCord: PositionCord | null = null;
  const domNode = nodeID.getBoundingClientRect();
  if (domNode) {
    const { x, width, y, right, left, height } = domNode;
    const midY = y + height / 2;

    LeftCord = {
      x: left - borderOffsetValue - parentOFFsetValue * zoomLevel,
      y: midY,
    };
  }

  return LeftCord;
}

export function getBottomPosition(nodeID: ArgType, zoomLevel: number) {
  let BottomCord: PositionCord | null = null;
  const domNode = nodeID.getBoundingClientRect();
  if (domNode) {
    const { x, width, y, right, left, height, bottom } = domNode;
    const midX = x + width / 2;

    BottomCord = {
      x: midX,
      y: bottom + parentOFFsetValue * zoomLevel,
    };
  }

  return BottomCord;
}
export function getPathDistance(start: PositionCord, end: PositionCord) {
  const getWidth = Math.sqrt(
    (start.x - end.x) * (start.x - end.x) +
      (start.y - end.y) * (start.y - end.y)
  );
  return getWidth;
}

function left(
  from: HTMLElement,
  to: HTMLElement,
  zoomLevel: number
): null | Path {
  // checks if the target note is by the left of the connection note.
  const fromDOmrect = from.getBoundingClientRect();
  const toDomRect = to.getBoundingClientRect();
  if (fromDOmrect.left - 30 > toDomRect.right) {
    const connectionPoint = getShortesPath(
      [getLeftPosition(from, zoomLevel)!],
      [getRightPosition(to, zoomLevel)]
    );
    return {
      from: getLeftPosition(from, zoomLevel)!,
      to: connectionPoint.shortestPath!,
    };
  } else {
    return null;
  }
}

function right(
  from: HTMLElement,
  to: HTMLElement,
  zoomLevel: number
): null | Path {
  //checks if the target  note is by the right of the connecting note
  const fromDOmrect = from.getBoundingClientRect();
  const toDomRect = to.getBoundingClientRect();
  if (fromDOmrect.right + 30 < toDomRect.left) {
    const connectionPoint = getShortesPath(
      [getRightPosition(from, zoomLevel)!],
      [getLeftPosition(to, zoomLevel)]
    );
    return {
      from: getRightPosition(from, zoomLevel)!,
      to: connectionPoint.shortestPath,
    };
  } else {
    return null;
  }
}

function canConnectEgdes(
  from: HTMLElement,
  to: HTMLElement,
  zoomLevel: number,
  leftEdgePostion: (nodeID: HTMLElement, zoomLevel: number) => PositionCord,
  rightEgdePosition: (nodeID: HTMLElement, zoomLevel: number) => PositionCord,
  node: HTMLElement
): undefined | PositionCord {
  /**
   * improve the connection item notes by checking the
   * position of the connection item relative to the target item.
   * this ensures the connection looks natural
   */

  const targetItem = to.getBoundingClientRect();
  const connectingItem = from.getBoundingClientRect();
  // check if the connecting item is positioned
  // more than half of the target item on either left or the right
  // if it is return egde point connection else return null

  const targeItemMidPoint = 10 + targetItem.x + targetItem.width / 2;
  const isToMidRight = connectingItem.left > targeItemMidPoint;
  const isToMidLeft = connectingItem.right < targeItemMidPoint;

  if (isToMidLeft) {
    return leftEdgePostion(node, zoomLevel);
  }

  if (isToMidRight) {
    return rightEgdePosition(node, zoomLevel);
  }

  return undefined;
}

function top(
  from: HTMLElement,
  to: HTMLElement,
  zoomLevel: number
): null | Path {
  // checks if the target Note is at the top of connection Note
  const fromDOmrect = from.getBoundingClientRect();
  const toDomRect = to.getBoundingClientRect();
  if (fromDOmrect.top > toDomRect.bottom) {
    // check the item location offset right or left to detetmine connection points
    if (isRight(from, to)) {
      const connectionPoint = getShortesPath(
        [
          getTopPosition(from, zoomLevel)!,
          getRightPosition(from, zoomLevel)!,
          getRightTopEdge(from, zoomLevel),
        ],
        [
          getBottomPosition(to, zoomLevel),
          getLeftPosition(to, zoomLevel),
          getLeftBottomEdge(to, zoomLevel),
        ]
      );
      return {
        from: connectionPoint.connectionNodeCord,
        to: connectionPoint.shortestPath,
      };
    }
    if (isLeft(from, to)) {
      const connectionPoint = getShortesPath(
        [
          getTopPosition(from, zoomLevel)!,
          getLeftPosition(from, zoomLevel)!,
          getLeftTopEdge(from, zoomLevel),
        ],
        [
          getBottomPosition(to, zoomLevel),
          getRightBottomEdge(to, zoomLevel),
          getRightPosition(to, zoomLevel),
        ]
      );
      return {
        from: connectionPoint.connectionNodeCord,
        to: connectionPoint.shortestPath,
      };
    } else {
      const connectionPoint = getShortesPath(
        [
          getTopPosition(from, zoomLevel)!,
          // canConnectEgdes(
          //   from,
          //   to,
          //   zoomLevel,
          //   getRightTopEdge,
          //   getLeftTopEdge,
          //   from
          // ),
        ],

        [
          getBottomPosition(to, zoomLevel),
          canConnectEgdes(
            from,
            to,
            zoomLevel,

            getRightBottomEdge,
            getLeftBottomEdge,
            to
          ),
        ]
      );
      return {
        from: connectionPoint.connectionNodeCord,
        to: connectionPoint.shortestPath,
      };
    }
  }
  return null;
}

function bottom(
  from: HTMLElement,
  to: HTMLElement,
  zoomLevel: number
): null | Path {
  // checks if the target note at  the bottom of the connection Note
  const fromDOmrect = from.getBoundingClientRect()!;
  const toDomRect = to.getBoundingClientRect()!;
  if (fromDOmrect.bottom < toDomRect.top) {
    // get the target note position to determine possible connection points
    // this would help reduce the number of cords we need to test.
    if (isLeft(from, to)) {
      const connectionPoint = getShortesPath(
        [
          getBottomPosition(from, zoomLevel)!,
          getLeftPosition(from, zoomLevel)!,
          getLeftBottomEdge(from, zoomLevel),
        ],
        [
          getTopPosition(to, zoomLevel),
          getRightTopEdge(to, zoomLevel),
          getRightPosition(to, zoomLevel),
        ]
      );
      return {
        from: connectionPoint.connectionNodeCord,
        to: connectionPoint.shortestPath,
      };
    }

    if (isRight(from, to)) {
      const connectionPoint = getShortesPath(
        [
          getBottomPosition(from, zoomLevel)!,
          getRightPosition(from, zoomLevel)!,
          getRightBottomEdge(from, zoomLevel)!,
        ],
        [
          getTopPosition(to, zoomLevel),
          getLeftPosition(to, zoomLevel),
          getLeftTopEdge(to, zoomLevel),
        ]
      );
      return {
        from: connectionPoint.connectionNodeCord,
        to: connectionPoint.shortestPath,
      };
    } else {
      const connectionPoint = getShortesPath(
        [
          getBottomPosition(from, zoomLevel)!,
          // canConnectEgdes(
          //   from,
          //   to,
          //   zoomLevel,
          //   getRightBottomEdge,
          //   getLeftBottomEdge,
          //   from
          // ),
        ],
        [
          getTopPosition(to, zoomLevel),
          canConnectEgdes(
            from,
            to,
            zoomLevel,
            getLeftTopEdge,
            getRightTopEdge,
            to
          ),
        ]
      );
      return {
        from: connectionPoint.connectionNodeCord,
        to: connectionPoint.shortestPath,
      };
    }
  }
  return null;
}

const getShortesPath = (
  referencePoint: PositionCord[],
  paths: PositionCord[],
  useOffSet: number = 0
) => {
  let shortestPath = paths[0];
  let pathDistance = 2000;
  let connectionNodeCord = referencePoint[0];

  referencePoint.forEach(connectionNode => {
    if (connectionNode) {
      paths.forEach((path: PositionCord | undefined) => {
        if (path !== undefined) {
          const distance = getPathDistance(connectionNode, path);

          if (pathDistance + useOffSet > distance) {
            pathDistance = distance;
            connectionNodeCord = connectionNode;

            shortestPath = path;
          }
        }
      });
    }
  });

  return {
    shortestPath,
    pathDistance,
    connectionNodeCord,
  };
};

function calculateShortestPath(
  from: HTMLElement,
  to: HTMLElement,
  zoomLevel: number
): Path {
  let pathDistance: number | undefined;
  const linePath: Path = {
    from: { x: 0, y: 0 },
    to: {
      x: 0,
      y: 0,
    },
  };
  const positionCOrds = [
    getLeftPosition,
    getRightPosition,
    getBottomPosition,
    getTopPosition,
    getLeftTopEdge,
    getLeftBottomEdge,
    getRightBottomEdge,
    getRightTopEdge,
  ];
  const possibleCordinates = positionCOrds.map(fn => {
    return fn(to, zoomLevel);
  });

  positionCOrds.forEach(fn => {
    const cordinate = fn(from, zoomLevel)!;

    const connectingCords = getShortesPath([cordinate], possibleCordinates);

    if (!pathDistance) {
      pathDistance = connectingCords.pathDistance;
      linePath.from = cordinate;
      linePath.to = connectingCords.shortestPath;
    } else {
      if (pathDistance > connectingCords.pathDistance) {
        pathDistance = connectingCords.pathDistance;
        linePath.from = cordinate;
        linePath.to = connectingCords.shortestPath;
      }
    }
  });

  return linePath;
}
export function getConnectionPoints(
  fromID: string,
  toID: string,
  zoomLevel: number
): Path | null {
  // TODO find a better way to access the full div Node
  const targetNode =
    document.getElementById(toID)?.parentElement?.parentElement?.parentElement
      ?.parentElement;
  const connectingNode =
    document.getElementById(fromID)?.parentElement?.parentElement?.parentElement
      ?.parentElement;

  if (!targetNode || !connectingNode) return null;

  let connectPoint: Path | null = null;

  const posibleConnectionPoints = [top, bottom, left, right];

  for (let connectionCord of posibleConnectionPoints) {
    const connectionPoint = connectionCord(
      connectingNode,
      targetNode,
      zoomLevel
    );
    if (connectionPoint !== null) {
      connectPoint = connectionPoint;
      return connectPoint;
    }
  }
  if (connectPoint === null) {
    return calculateShortestPath(connectingNode, targetNode, zoomLevel);
  }

  return connectPoint;
}

export function getConnectionLinePosition(
  startCord: PositionCord,
  endCord: PositionCord
) {
  const midPointX = (startCord.x + endCord.x) / 2;
  const midPointY = (startCord.y + endCord.y) / 2;

  return {
    top: midPointY || 0,
    left: midPointX || 0,
  };
}

export function GetSlope(startCord: PositionCord, endCord: PositionCord) {
  const slope = Math.atan2(startCord.y - endCord.y, startCord.x - endCord.x);
  const slopeDeg = (slope * 180) / Math.PI;
  return slopeDeg;
}
