import { useFlag } from '@unleash/proxy-client-react';
import { BorderThicknessSelector } from 'CoreComponents/BorderThicknessSelector';
import { ColorSelection } from 'CoreComponents/color-selector/ColorSelection';
import RenderCommandInput from 'CoreComponents/Renderers/RenderCommandInput';
import { memo, ReactNode } from 'react';
import { useParams } from 'react-router-dom';
import useAnchor from 'utils/hooks/useAnchor';
import { generalStore } from 'utils/stores/generalStore';
import {
  Anchor as TAnchor,
  anchorMode,
  AnchorTargets,
} from 'utils/stores/mapMode/modesStore';
import { useSnapshot } from 'valtio';

type Anchors = {
  [Key in AnchorTargets]: (props: any) => ReactNode | null;
};

const anchors: Anchors = {
  CLOSE: null,
  borderWeightSelector: props => <BorderThicknessSelector {...props} />,
  BORDER: props => <ColorSelection {...props} />,
  FILL: props => <ColorSelection {...props} />,
  TEXT: props => <ColorSelection {...props} />,

  command: props => <RenderCommandInput {...props} />,
};

function AnchorElement({ anchorElement }: { anchorElement: TAnchor }) {
  const commandInput = useFlag('CommandInput');
  const selectionV2 = useFlag('mapmap_selectionv2');

  const mapId = useParams().mapId!;
  const anchorProps = useAnchor(mapId, selectionV2);

  if (anchorElement.anchorComponent !== 'command') {
    if (!anchorProps.canrenderAnchor) {
      anchorMode.anchorComponent !== 'CLOSE' && anchorMode.reset();
      return null;
    }
  }

  const Component = anchors[anchorElement.anchorComponent]({
    ...anchorProps,
    mapId,
    mousePosition: generalStore.mousePosition.mouse,
  });

  return anchorElement?.anchorComponent === 'command'
    ? commandInput && Component
    : Component;
}

function RenderAnchorElement() {
  const anchorElement = useSnapshot(anchorMode);

  if (!anchorElement.isActive) return null;

  return <AnchorElement anchorElement={anchorElement} />;
}

const Anchor = memo(RenderAnchorElement);

export default Anchor;
