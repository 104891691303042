import {
  PersistedClient,
  Persister,
} from '@tanstack/react-query-persist-client';
import { del, get, keys, set, UseStore } from 'idb-keyval';
import { ProxyPersistStorageEngine } from 'valtio-persist';

export async function storage(
  db: UseStore
): Promise<ProxyPersistStorageEngine> {
  return {
    getItem: (name: string) => {
      const g = get(name, db);
      return g == undefined ? null : g;
    },
    setItem: (name, value) => {
      return set(name, value, db);
    },
    removeItem: name => {
      del(name, db);
    },
    getAllKeys: () => {
      return keys(db);
    },
  };
}

export function createIDBPersister(idbValidKey: IDBValidKey = 'reactQuery') {
  return {
    persistClient: async (client: PersistedClient) => {
      await set(idbValidKey, client);
    },
    restoreClient: async () => {
      return await get<PersistedClient>(idbValidKey);
    },
    removeClient: async () => {
      await del(idbValidKey);
    },
  } as Persister;
}
