// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import { HTMLElementRefOf } from '@plasmicapp/react-web';
import { useFlag } from '@unleash/proxy-client-react';
import ActionBar from 'PlasmicComponents/ActionBar';
import * as React from 'react';
import {
  DefaultActionBarContainerProps,
  PlasmicActionBarContainer,
} from './plasmic/map_map/PlasmicActionBarContainer';

export interface ActionBarContainerProps
  extends DefaultActionBarContainerProps {}

function ActionBarContainer_(
  props: ActionBarContainerProps,
  ref: HTMLElementRefOf<'div'>
) {
  const showSequenceBar = useFlag('showSequenceBar');
  return (
    <div
      className="fixed left-1/2 -translate-x-1/2 bottom-5 scale-75"
      data-html2canvas-ignore="true"
    >
      <PlasmicActionBarContainer
        style={{
          overflow: 'visible',
        }}
        showSequenceBar={showSequenceBar}
        actionBar={<ActionBar />}
        root={{ ref }}
        {...props}
      />
    </div>
  );
}

const ActionBarContainer = React.forwardRef(ActionBarContainer_);
export default ActionBarContainer;
