import { HTMLElementRefOf } from '@plasmicapp/react-web';
import * as React from 'react';
import {
  DefaultFloatingBarProps,
  PlasmicFloatingBar,
} from './plasmic/map_map/PlasmicFloatingBar';

export interface FloatingBarProps extends DefaultFloatingBarProps {
  overflowShow?: boolean;
}

function FloatingBar_(
  { overflowShow, ...props }: FloatingBarProps,
  ref: HTMLElementRefOf<'div'>
) {
  return (
    <PlasmicFloatingBar
      bar={{
        ref,
        style: {
          overflow: overflowShow ? 'visible' : undefined,
        },
      }}
      {...props}
    />
  );
}

const FloatingBar = React.forwardRef(FloatingBar_);
export default FloatingBar;
