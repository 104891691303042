import React, { Suspense, useRef, useState } from 'react';

import {
  LargeItemRenderer,
  LargeItemRendererProps,
} from 'CoreComponents/LargeItemRenderer/LargeItemRenderer';
import DashboardContent from 'PlasmicComponents/DashboardContent';
import { useGetFolder, useGetUserFolderStructure } from 'api/queries';
import { ExtendedEmojiDataType } from 'components/emojiPicker/CustomEmojiPicker';
import { useNavigate, useParams } from 'react-router-dom';
import Selecto from 'react-selecto';
import { dashboardStore } from 'utils/stores/dashboardStore';
import { FolderData, FolderTree, MapData } from 'utils/stores/types';
import { useSnapshot } from 'valtio';
import { useClickOutsideLargeItem } from '../../utils/hooks/useClickOutsideLargeItem';
const EmojiAndIconSelectorWrapper = React.lazy(
  () => import('components/EmojiAndIconSelector/EmojiAndIconSelectorWrapper')
);

export type FolderStructure = {
  data: FolderTree;
  isLoading: boolean;
};

type FolderResponse = {
  data: FolderData;
  isLoading: boolean;
};

export function DashboardView() {
  const { folderId } = useParams();
  const navigate = useNavigate();
  const dashboardRef = useRef<HTMLDivElement>(null);
  const selectoRef = useRef<Selecto>(null);

  const { data: folderStructure, isLoading: structureLoading } =
    useGetUserFolderStructure() as FolderStructure;

  const { data: folder, isLoading: folderLoading } = useGetFolder(
    folderId
  ) as FolderResponse;

  const dataSource: FolderTree = React.useMemo(
    () =>
      folderId
        ? {
            root: {
              folders: folder?.subfolders || [],
              maps: folder?.maps || [],
            },
          }
        : folderStructure,
    [folderId, folder, folderStructure]
  );

  const isLoading = folderId ? folderLoading : structureLoading;

  const [lastSelectedItem, setLastSelectedItem] = useState<string | null>(null);
  const [selectedIcon, setSelectedIcon] =
    useState<ExtendedEmojiDataType | null>(null);

  const [showIconAndEmojiSelector, setShowIconAndEmojiSelector] =
    useState(false);

  // Use useSnapshot to make the component reactive to dashboardStore changes
  const { selectedMapIds, selectedFolderIds } = useSnapshot(dashboardStore);

  const getItemId = (item: FolderData | MapData): string => {
    // FolderData has .folderId and MapData has .mapId
    return 'folderId' in item ? item.folderId : item.mapId;
  };

  const isFolder = (itemId: string): boolean => {
    // check whether it's a folder by checking if the property of folderId exists
    return dataSource.root.folders.some(folder => folder.folderId === itemId);
  };

  const handleSetShowIconAndEmojiSelector = () => {
    setShowIconAndEmojiSelector(prev => {
      return !prev;
    });
  };

  const handleItemSelection = (itemId: string, event: React.MouseEvent) => {
    const allItems = [...dataSource.root.folders, ...dataSource.root.maps].map(
      getItemId
    );

    if (event.shiftKey && lastSelectedItem) {
      const startIndex = allItems.indexOf(lastSelectedItem);
      const endIndex = allItems.indexOf(itemId);
      const [start, end] =
        startIndex < endIndex ? [startIndex, endIndex] : [endIndex, startIndex];
      const itemsToSelect = allItems.slice(start, end + 1);

      const newSelectedMapIds = itemsToSelect.filter(id => !isFolder(id));
      const newSelectedFolderIds = itemsToSelect.filter(id => isFolder(id));

      dashboardStore.selectedMapIds = Array.from(
        new Set([...dashboardStore.selectedMapIds, ...newSelectedMapIds])
      );
      dashboardStore.selectedFolderIds = Array.from(
        new Set([...dashboardStore.selectedFolderIds, ...newSelectedFolderIds])
      );
    } else if (event.ctrlKey || event.metaKey) {
      const isAlreadySelected =
        selectedMapIds.includes(itemId) ||
        dashboardStore.selectedFolderIds.includes(itemId);
      if (isAlreadySelected) {
        dashboardStore.selectedMapIds = selectedMapIds.filter(
          id => id !== itemId
        );
        dashboardStore.selectedFolderIds = selectedFolderIds.filter(
          id => id !== itemId
        );
      } else {
        if (isFolder(itemId)) {
          dashboardStore.selectedFolderIds = [
            ...dashboardStore.selectedFolderIds,
            itemId,
          ];
        } else {
          dashboardStore.selectedMapIds = [
            ...dashboardStore.selectedMapIds,
            itemId,
          ];
        }
      }
    } else {
      if (isFolder(itemId)) {
        dashboardStore.selectedMapIds = [];
        dashboardStore.selectedFolderIds = [itemId];
      } else {
        dashboardStore.selectedMapIds = [itemId];
        dashboardStore.selectedFolderIds = [];
      }
    }
    setLastSelectedItem(itemId);
  };

  const handleSelectIcon = (icon: {
    type: string;
    name: string;
    color: string;
  }) => {
    setSelectedIcon({
      iconType: icon.type,
      icon: icon.name,
      emoji: '',
      unified: '',
      isCustom: true,
      names: [],
      activeSkinTone: null,
      getImageUrl: null,
      imageUrl: '',
      unifiedWithoutSkinTone: '',
      color: icon.color,
    });
    setShowIconAndEmojiSelector(!showIconAndEmojiSelector);
  };

  useClickOutsideLargeItem(); // to clear the selection

  /* const handleSelectoSelect = useCallback((e: OnSelect) => {
    // Example logic for updating selected items based on Selecto selection
    const selectedItems = e.selected.map(el => el.id);
    dashboardStore.selectedMapIds = selectedItems.filter(id => id.startsWith('map'));
    dashboardStore.selectedFolderIds = selectedItems.filter(id => id.startsWith('folder'));
  }, []);*/

  const renderLargeItemRenderer = (
    items: (FolderData | MapData)[],
    itemType: 'folder' | 'map'
  ) => {
    const props: LargeItemRendererProps = {
      items,
      itemType,
      loading: isLoading,
      onItemSelect: handleItemSelection,
      /*itemProps: {
          className: 'selecto-item',
          getDataAttributes: item => ({
            'data-id': getItemId(item),
          }),
        },*/
    };

    return <LargeItemRenderer {...props} />;
  };

  return (
    <div
      ref={dashboardRef}
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      {/*<Selecto
        // we're planning to allow box selection of LargeItems in the future - for now it's disabled
        ref={selectoRef}
        selectableTargets={['.selecto-item']}
        selectByClick={false}
        selectFromInside={false}
        toggleContinueSelect={['shift']}
        preventDefault={true}
        dragContainer={dashboardRef.current}
        //onSelect={handleSelectoSelect}
      />*/}
      {folderStructure && (
        // <CSSTransition>
        <DashboardContent
          className="animate__fadeIn animate__animated animate__faster"
          folders={renderLargeItemRenderer(
            dataSource.root?.folders || [],
            'folder'
          )}
          maps={renderLargeItemRenderer(dataSource.root?.maps || [], 'map')}
          selectedIcon={selectedIcon}
          noMapsAndFolders={
            !isLoading &&
            dataSource.root.folders.length === 0 &&
            dataSource.root.maps.length === 0
          }
          setShowIconAndEmojiSelector={handleSetShowIconAndEmojiSelector}
          showIconAndEmojiSelector={showIconAndEmojiSelector}
        />
        // </CSSTransition>
      )}

      <Suspense fallback={<div>Loading...</div>}>
        {showIconAndEmojiSelector === true && (
          <div className="absolute" style={{ top: '13rem', right: '10rem' }}>
            <EmojiAndIconSelectorWrapper onIconSelect={handleSelectIcon} />
          </div>
        )}
      </Suspense>
    </div>
  );
}
