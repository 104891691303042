import { storage } from 'utils/storage';
import {
  unstable_buildProxyFunction as buildProxyFunction,
  proxy,
  ref,
  snapshot,
  subscribe,
} from 'valtio';
import type { INTERNAL_Snapshot as Snapshot } from 'valtio';
import proxyWithPersist, {
  PersistStrategy,
  ProxyPersistStorageEngine,
} from 'valtio-persist';
import { createStore, del, get, set, keys } from 'idb-keyval';
import throttle from '../helpers/throttel';
import { CurrentMapContentStoreWithHistory } from './types';
import { devtools } from 'valtio/utils';
import { getMap } from './mapStore';

type SnapshotOrUndefined<T> = Snapshot<T> | undefined;
type Snapshots<T> = Snapshot<T>[];

const isObject = (x: unknown): x is object =>
  typeof x === 'object' && x !== null;

let refSet: WeakSet<object> | undefined;

const deepClone = <T>(obj: T): T => {
  if (!refSet) {
    refSet = buildProxyFunction()[2];
  }
  if (!isObject(obj) || refSet.has(obj)) {
    return obj;
  }
  if (obj instanceof Date) {
    return new Date(obj.getTime()) as unknown as T;
  }
  const baseObject: T = Array.isArray(obj)
    ? []
    : Object.create(Object.getPrototypeOf(obj));
  Reflect.ownKeys(obj).forEach(key => {
    baseObject[key as keyof T] = deepClone(obj[key as keyof T]);
  });
  return baseObject;
};

/**
 * proxyWithHistory
 *
 * This creates a new proxy with history support.
 * It includes following properties:
 * - value: any value (does not have to be an object)
 * - history: an array holding the history of snapshots
 * - historyIndex: the history index to the current snapshot
 * - canUndo: a function to return true if undo is available
 * - undo: a function to go back history
 * - canRedo: a function to return true if redo is available
 * - redo: a function to go forward history
 * - saveHistory: a function to save history
 *
 * [Notes]
 * Suspense/promise is not supported.
 *
 * @example
 * import { proxyWithHistory } from 'valtio/utils'
 * const state = proxyWithHistory({
 *   count: 1,
 * })
 */

const cmcsIdbStore = createStore(
  'currentMapContentDB',
  'currentMapContentStore'
);

// export function proxyWithHistory<V>(initialValue: V, verbose = false) {
//   const history = localStorage.getItem('stackHistory');
//   const proxyObject = proxy({
//     value: initialValue,

//     history: ref(
//       JSON.parse(history as string) || {
//         wip: undefined as SnapshotOrUndefined<V>, // to avoid infinite loop
//         snapshots: [] as Snapshots<V>,
//         index: -1,
//       }
//     ),
//     saveToLocalStorage: () => {
//       if (verbose) console.count('saveToLocalStorage called');
//       localStorage.setItem(
//         'currentStackAndAreaStateStore',
//         JSON.stringify(proxyObject.value)
//       );
//       localStorage.setItem('stackHistory', JSON.stringify(proxyObject.history));
//       if (verbose) console.count('saveToLocalStorage ended');
//     },
//     clone: deepClone,
//     canUndo: () => proxyObject.history.index > 0,
//     undo: () => {
//       if (verbose) console.count('undo called');
//       if (proxyObject.canUndo()) {
//         proxyObject.value = (proxyObject.history.wip = proxyObject.clone(
//           proxyObject.history.snapshots[--proxyObject.history.index]
//         ) as Snapshot<V>) as V;
//       }
//       if (verbose) console.log('proxyObject after undo', proxyObject);
//       if (verbose) console.count('undo ended');
//     },
//     canRedo: () =>
//       proxyObject.history.index < proxyObject.history.snapshots.length - 1,
//     redo: () => {
//       if (verbose) console.count('redo called');
//       if (proxyObject.canRedo()) {
//         proxyObject.value = (proxyObject.history.wip = proxyObject.clone(
//           proxyObject.history.snapshots[++proxyObject.history.index]
//         ) as Snapshot<V>) as V;
//       }
//       if (verbose) console.log('proxyObject after redo', proxyObject);
//       if (verbose) console.count('redo ended');
//     },
//     saveHistory: () => {
//       if (verbose) console.count('saveHistory called');
//       proxyObject.history.snapshots.splice(proxyObject.history.index + 1);
//       proxyObject.history.snapshots.push(snapshot(proxyObject).value);
//       ++proxyObject.history.index;
//       if (verbose) console.log('proxyObject after saveHistory', proxyObject);
//       if (verbose) console.count('saveHistory ended');
//     },
//     subscribe: () =>
//       subscribe(proxyObject, ops => {
//         if (verbose) console.log('subscribe called', ops);
//         if (
//           ops.every(
//             op =>
//               op[1][0] === 'value' &&
//               (op[0] !== 'set' || op[2] !== proxyObject.history.wip)
//           )
//         ) {
//           proxyObject.saveHistory();
//         }
//         proxyObject.saveToLocalStorage();
//         if (verbose) console.count('subscribe ended');
//       }),
//   });

//   if (verbose) console.count('proxyObject Created');
//   if (!history) proxyObject.saveHistory();
//   proxyObject.subscribe();
//   return proxyObject;
// }

export function proxyWithHistory<T extends object>(
  name: string,
  version: number,
  migrations: Record<number, (() => void | Promise<void>) | undefined>
) {
  const store = proxyWithPersist<T>({
    name: name,
    initialState: {} as T,
    persistStrategies: PersistStrategy.MultiFile,
    version: version,
    migrations: migrations,
    onBeforeBulkWrite: throttle(bulkWrite => bulkWrite(), 1000),

    getStorage: () => storage(cmcsIdbStore),
  });

  subscribe(store, ops => {
    const firstKey = Object.values(ops[0][1])[0].toString();
    if (shouldSaveHistory(ops, getMap(firstKey))) {
      saveHistory(getMap(firstKey));
    }
  });

  devtools(store, {
    name: 'Cmcs',
    enabled: true,
    trace: true,
  });
  return store;
}

const saveHistory = (proxyObject: CurrentMapContentStoreWithHistory) => {
  proxyObject.history.nodes.splice(proxyObject.history.index + 1);
  proxyObject.history.nodes.push({
    createdAt: new Date(),
    snapshot: snapshot(proxyObject).value,
  });
  ++proxyObject.history.index;
};

const shouldSaveHistory = (
  ops: Parameters<Parameters<typeof subscribe>[1]>[0],
  proxyObject: CurrentMapContentStoreWithHistory
) => {
  return ops.every(
    op =>
      (op[1].includes('stacks') ||
        op[1].includes('areas') ||
        op[1].includes('connections') ||
        op[1].includes('previews')) &&
      (op[0] !== 'set' || op[2] !== proxyObject.history.wip)
  );
};
const canUndo = (proxyObject: CurrentMapContentStoreWithHistory) =>
  proxyObject.history.index > 0;

const canRedo = (proxyObject: CurrentMapContentStoreWithHistory) =>
  proxyObject.history.index < proxyObject.history.nodes.length - 1;

export const undo = (proxyObject: CurrentMapContentStoreWithHistory) => {
  if (canUndo(proxyObject)) {
    proxyObject.history.wip = deepClone(
      proxyObject.history.nodes[--proxyObject.history.index]?.snapshot
    );
    //@ts-ignore
    proxyObject.value = proxyObject.history.wip;
  }
};

export const redo = (proxyObject: CurrentMapContentStoreWithHistory) => {
  if (canRedo(proxyObject)) {
    proxyObject.history.wip = deepClone(
      proxyObject.history.nodes[++proxyObject.history.index]?.snapshot
    );
    //@ts-ignore
    proxyObject.value = proxyObject.history.wip;
  }
};
