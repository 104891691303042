import { EmojiClickData } from 'emoji-picker-react';
import { createRef, useEffect, useRef, useState } from 'react';

import { IconLoader } from 'propro-common-components';
import { dashboardStore } from 'utils/stores/dashboardStore';
import { useSnapshot } from 'valtio';
import { folderSuggestedIcons, mapSuggestedIcons } from 'utils/constants';

interface CustomEmojiPickerProps {
  setEmoji: (arg) => void;
  onReactionSelect: (arg) => void;
  isCreatingMap: boolean;
}

export interface ExtendedEmojiDataType extends EmojiClickData {
  iconType: string;
  icon: string;
  color: string;
}

export function CustomEmojiPicker({
  onReactionSelect,
  setEmoji,
  isCreatingMap
}: CustomEmojiPickerProps) {
  const [selectedEmoji, setSelectedEmoji] = useState<any>(isCreatingMap ? {
    id: 1,
    unified: '1F5FA',
    size: 32,
    isSelected: false,
    icon: '🗺️',
    iconType: 'emoji',
    color: '',
  } : {
    id: 1,
    unified: '1f4c2',
    size: 32,
    isSelected: false,
    icon: '📂',
    iconType: 'emoji',
    color: '',
  },);

  const [showExpanded, setShowExpanded] = useState(false);

  const { showIconAndEmojiSelector } = useSnapshot(dashboardStore);

  const emojis_icons = isCreatingMap ? mapSuggestedIcons : folderSuggestedIcons

  const iconRefs = emojis_icons.reduce((acc, icon) => {
    acc[icon.icon] = createRef();
    return acc;
  }, {});

  return (
    <>
      {
        <div>
          {!showExpanded && (
            <div className="defaultEmojis flex mr-3">
              {emojis_icons.map((emoji, index) =>
                emoji.iconType === 'emoji' ? (
                  <div
                    ref={iconRefs[emoji.icon]}
                    tabIndex={index === 0 ? 0 : -1}
                    className='icon'
                    onKeyDown={(e) => {
                      if (e.key === 'ArrowRight') {
                        e.preventDefault();

                        const nextIndex = (index + 1) % emojis_icons.length;
                        const nextKey = emojis_icons[nextIndex];
                        console.log(iconRefs[nextKey.icon])
                        iconRefs[nextKey.icon].current?.focus();

                        //@ts-ignore
                        setSelectedEmoji(nextKey);
                      } else if (e.key === 'ArrowLeft') {
                        e.preventDefault();

                        const prevIndex = (index - 1 + emojis_icons.length) % emojis_icons.length;
                        const prevKey = emojis_icons[prevIndex];
                        iconRefs[prevKey.icon].current?.focus();
                        
                        //@ts-ignore
                        setSelectedEmoji(prevKey);
                      } else if (e.key === 'Enter') {
                        e.preventDefault();
                        e.stopPropagation();
                        console.log(selectedEmoji)
                        onReactionSelect(selectedEmoji);
                        setShowExpanded(false);
                      }
                    }}
                  >
                    <CustomEmoji
                      iconRef={iconRefs}
                      key={emoji.id}
                      setSelectedEmoji={setSelectedEmoji}
                      setEmoji={setEmoji}
                      onReactionSelect={onReactionSelect}
                      selectedEmoji={selectedEmoji}
                      emojiData={emoji}
                      />
                    </div>
                ) : (
                    <div ref={iconRefs[emoji.icon]}
                      tabIndex={index === 0 ? 0 : -1}
                      className='icon'
                      onKeyDown={(e) => {
                        if (e.key === 'ArrowRight') {
                          e.preventDefault();

                          const nextIndex = (index + 1) % emojis_icons.length;
                          const nextKey = emojis_icons[nextIndex];
                          console.log(iconRefs[nextKey.icon])
                          iconRefs[nextKey.icon].current?.focus();

                          //@ts-ignore
                          setSelectedEmoji(nextIndex);
                        } else if (e.key === 'ArrowLeft') {
                          e.preventDefault();

                          const prevIndex = (index - 1 + emojis_icons.length) % emojis_icons.length;
                          const prevKey = emojis_icons[prevIndex];
                          iconRefs[prevKey.icon].current?.focus();
                          
                          //@ts-ignore
                          setSelectedEmoji(prevIndex);
                        } else if (e.key === 'Enter') {
                          e.preventDefault();
                          e.stopPropagation();
                          let emoji = emojis_icons.find(emoji => emoji.id === selectedEmoji);
                          onReactionSelect(emoji);
                          setShowExpanded(false);
                        }
                      }}
                    >
                    <CustomIcon
                      iconRef={iconRefs}
                      key={emoji.id}
                      setSelectedIcon={setSelectedEmoji}
                      setIcon={setEmoji}
                      onReactionSelect={onReactionSelect}
                      selectedIcon={selectedEmoji}
                      iconData={emoji}
                      />
                    </div>
                )
              )}
            </div>
          )}
        </div>
      }
    </>
  );
}

function CustomEmoji(props) {
  const {
    emojiData,
    selectedEmoji,
    onReactionSelect,
    iconRef
  } = props;
  return (
    <div
      className={`${selectedEmoji.id === emojiData.id ? 'rounded-[10px]' : ''}`}
      ref={iconRef}
      onClick={() => onReactionSelect(emojiData)}
      style={{
        background: selectedEmoji.id === emojiData.id ? '#393838' : 'transparent',
        padding: '4px',
      }}
    >
      <span>{emojiData.icon}</span>
    </div>
  );
}
function CustomIcon(props) {
  const { iconData, selectedIcon, onReactionSelect, iconRef } =
    props;
  
  return (
    <div
      className={`${selectedIcon.id === iconData.id ? 'rounded-md' : ''}`}
      ref={iconRef}
      onClick={() => onReactionSelect(iconData)}
      style={{
        background: selectedIcon.id === iconData.id ? '#393838' : 'transparent',
        padding: '4px',
      }}
    >
      <IconLoader name={iconData.icon} size={24} color="#BFC2C8" />
    </div>
  );
}

