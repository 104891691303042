import {
  PlasmicCanvasHost,
  registerComponent,
} from "@plasmicapp/react-web/lib/host";
import SlateEditor from "../CoreComponents/SlateEditor/SlateEditor";

registerComponent(SlateEditor, {
  name: "SlateEditor",
  props: {
    initialValue: {
      type: "any",
      defaultValue: [
        {
          type: "paragraph",
          children: [
            {
              text: "",
            },
          ],
        },
      ],
    },
  },
  importPath: "components/SlateEditor/SlateEditor.js",
});

const PlasmicHost = () => {
  return <PlasmicCanvasHost />;
};

export default PlasmicHost;
