export const unleashConfig = {
  url: 'https://flags.mapmap.app/api/frontend',
  clientKey:
    process.env.NODE_ENV === 'development'
      ? 'default:development.unleash-insecure-frontend-api-token'
      : process.env.NODE_ENV === 'production'
        ? '*:production.e1127ed3ef63c711bd03e6c2ac3c38ef8f2f460079caecabdc5b8472'
        : '',
  refreshInterval: 15,
  appName: 'MapMap',
};
