import { Matrix } from 'CoreComponents/Canvas/InfiniteCanvas';
import {
  getScaleFromMatrix,
  getTranslateFromMatrix,
} from 'utils/helpers/Canvas/clamp-zoom';
import { createNewArea, cursorOnArea } from 'utils/mapStoreFN/mapStoreFN_areas';
import { createItemId, createNewItem } from 'utils/mapStoreFN/mapStoreFN_items';
import {
  createNewStackOrDuplicate,
  getStackAndItem,
} from 'utils/mapStoreFN/mapStoreFN_stacks';
import { ClipboardStore } from 'utils/stores/mapmapClipboardStore';
import {
  createNewImageAndNewStack,
  createNewNoteAndNewStack,
  currentMapStateStore,
} from 'utils/stores/mapStore';
import {
  AreaData,
  ImageUpload,
  ItemData,
  NoteItemData,
} from 'utils/stores/types';
import { uploadImageStore } from 'utils/stores/uploadImagesStore';
import { generalStore } from 'utils/stores/generalStore';
import { deepClone } from 'utils/helpers/deepClone';
import { v4 as uuidv4 } from 'uuid';
import {
  ClipboardAreaDataProps,
  ClipboardItemProps,
  ClipboardStackDataProps,
} from './copyCutHelpers';
import getSelectedIDs from 'utils/helpers/Selection/selectionUtils';

export default function pasteFn(mapID: string) {
  const currentMapState = currentMapStateStore[mapID];
  const matrixTransform = new Matrix(
    currentMapState?.canvas?.CanvasTransform || ''
  );
  const matrixTranslate = getTranslateFromMatrix(matrixTransform);
  const zoomLevel = getScaleFromMatrix(matrixTransform);
  const mouseStore = generalStore.mousePosition.mouse;

  const mousePosition = {
    x: (mouseStore?.x - matrixTranslate.x) / zoomLevel - 8,
    y: (mouseStore?.y - matrixTranslate.y) / zoomLevel - 16,
  };

  const getLastSelectedNote = () =>
    getSelectedIDs()
      .filter(id => !id.includes('area_'))
      .slice(-1)[0];

  const handleClipboardStore = async () => {
    const lastSelectedNote = getLastSelectedNote();

    if (lastSelectedNote) {
      await clipboardStorePasteHelper();
    } else {
      pasteObjectsAtMousePosition(mapID, mousePosition);
    }
  };

  async function clipboardStorePasteHelper() {
    const items: (ClipboardStackDataProps | ClipboardAreaDataProps)[] =
      JSON.parse(ClipboardStore.clipboard);
    const lastSelectedNote = getLastSelectedNote();

    if (!lastSelectedNote || !items.length) return;

    const stackAndItem = getStackAndItem(lastSelectedNote, mapID);
    if (!stackAndItem?.stackData) return;
    const { stackId } = stackAndItem.stackData;

    const createNewNoteFromItem = (subItem: ItemData) => {
      const newNote: ItemData = {
        ...subItem,
        itemId: createItemId(),
        lastInteraction: new Date(),
        position: { x: 0, y: 0 },
      };
      createNewItem(
        stackId,
        newNote,
        mapID,
        lastSelectedNote,
        false,
        false,
        false,
        false
      );
    };

    const processClipboardStack = (stack: ClipboardStackDataProps) => {
      stack.items.forEach(createNewNoteFromItem);
    };

    const processClipboardArea = (area: ClipboardAreaDataProps) => {
      area.stacks.forEach(stack => stack.items.forEach(createNewNoteFromItem));
    };

    items.forEach(item => {
      if ('stackId' in item && 'items' in item) {
        processClipboardStack(item);
      } else if ('areaId' in item && 'stacks' in item) {
        processClipboardArea(item);
      }
    });
  }

  const handleContentPaste = async (
    content: string,
    type: 'Note' | 'Image'
  ) => {
    const lastSelectedNote = getLastSelectedNote();
    if (lastSelectedNote) {
      addToSelectedNote(lastSelectedNote, mapID, content, type);
    } else {
      if (type === 'Image') {
        createPastedImage(content);
      } else {
        const areaID = cursorOnArea(mousePosition, mapID);
        createNewNoteAndNewStack(
          mousePosition,
          [{ type: 'Paragraph', children: [{ text: content }] }],
          mapID,
          areaID
        );
      }
    }
  };

  const createPastedImage = (imageData: string) => {
    const img = new Image();
    img.src = imageData;
    img.onload = () => {
      const width = Math.floor(img.naturalWidth / zoomLevel / 1.67);
      const height = Math.floor(img.naturalHeight / zoomLevel / 1.67);
      createNewImageAndNewStack(
        mousePosition,
        imageData,
        mapID,
        undefined,
        'image/png',
        { width, height }
      );
    };
  };

  const handlePaste = async () => {
    if (ClipboardStore.clipboard) {
      await handleClipboardStore();
      return;
    }

    const clipBoardData = await navigator.clipboard.read();
    for (const item of clipBoardData) {
      if (item.types.includes('image/png')) {
        const blob = await item.getType('image/png');
        const base64String = await blobToBase64(blob);
        if (base64String) {
          await handleContentPaste(base64String as string, 'Image');
        }
      }
      if (item.types.includes('text/plain')) {
        const text = await navigator.clipboard.readText();
        await handleContentPaste(text, 'Note');
      }
    }
  };

  // Calling the pasting function
  handlePaste();
}

function addToSelectedNote(
  noteID: string,
  mapID: string,
  data: string,
  type: 'Note' | 'Image'
) {
  const stackAndItem = getStackAndItem(noteID, mapID);
  if (!stackAndItem?.stackData) return;

  const itemData = stackAndItem.stackData.items.find(
    item => item.itemId === noteID
  );
  if (!itemData) return;

  const handlers = {
    Image: () =>
      handleImageAddition(
        stackAndItem.stackData,
        itemData,
        data,
        mapID,
        noteID
      ),
    Note: () =>
      handleNoteAddition(stackAndItem.stackData, itemData, data, mapID, noteID),
  };

  handlers[type]?.();
}

function handleImageAddition(
  stackData: any,
  itemData: any,
  data: string,
  mapID: string,
  noteID: string
) {
  const image: NoteItemData = createImageItem(itemData, data);
  createNewItem(stackData.stackId, image, mapID, noteID);

  const uploadImage: ImageUpload = {
    base64Data: data.split(',')[1],
    itemId: image.itemId,
    width: itemData.style?.customWidth?.toString() || '200',
    height: '',
    Type: 'image/png',
  };
  uploadImageStore.uploadData = {
    imageUploadData: [uploadImage],
    mapId: mapID,
  };
}

function handleNoteAddition(
  stackData: any,
  itemData: any,
  data: string,
  mapID: string,
  noteID: string
) {
  const newNote: ItemData = createNoteItem(itemData, data);
  createNewItem(stackData.stackId, newNote, mapID, noteID);
}

function createImageItem(itemData: any, data: string): NoteItemData {
  return {
    type: 'Note',
    itemId: createItemId(),
    style: {
      ...itemData.style,
      customWidth: itemData.style?.customWidth || 200,
    },
    content: {
      textContent: '_IMAGE',
      slateContent: [
        {
          type: 'Image',
          url: data,
          alt: 'image data',
          children: [{ text: 'images' }],
        },
      ],
    },
    position: { x: 0, y: 0 },
    delete: false,
    connections: [],
    lastInteraction: new Date(),
  };
}

function createNoteItem(itemData: any, data: string): ItemData {
  return {
    itemId: createItemId(),
    type: 'Note',
    lastInteraction: new Date(),
    content: {
      slateContent: [
        {
          type: 'Paragraph',
          children: [{ text: data.trim() }],
        },
      ],
    },
    style: itemData.style,
    connections: [],
    delete: false,
    position: { x: 0, y: 0 },
  };
}

async function blobToBase64(blob: Blob): Promise<string | ArrayBuffer | null> {
  return new Promise(resolve => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(blob);
    fileReader.onloadend = () => resolve(fileReader.result);
  });
}

export const pasteObjectsAtMousePosition = async (
  mapId: string,
  mousePosition: {
    x: number;
    y: number;
  }
) => {
  const clipBoardData = ClipboardStore.clipboard;
  const clipboardItems: ClipboardItemProps[] = JSON.parse(clipBoardData);

  const minX = Math.min(...clipboardItems.map(item => item.position?.x));
  const minY = Math.min(...clipboardItems.map(item => item.position?.y));

  if (clipboardItems.length) {
    clipboardItems.forEach(item => {
      const offSetX = item?.position?.x - minX;
      const offSetY = item?.position?.y - minY;

      const position: { x: number; y: number } = {
        x: mousePosition.x + offSetX,
        y: mousePosition.y + offSetY,
      };

      if (item.hasOwnProperty('stackId') && item.hasOwnProperty('items')) {
        const stack = item as ClipboardStackDataProps;
        createNewStackOrDuplicate(
          position,
          [
            ...stack.items.map(item => {
              return { ...item, itemId: createItemId() };
            }),
          ],
          mapId
        );
        return;
      }

      if (item.hasOwnProperty('areaId') && item.hasOwnProperty('stacks')) {
        const area = item as ClipboardAreaDataProps;

        pasteAreaAtMousePosition(area.areaData, mapId, position, area?.stacks);
        return;
      }
    });
  }

  // ClipboardStore.action = null;
  return;
};

export function pasteAreaAtMousePosition(
  areaData: AreaData,
  mapId: string,
  position: { x: number; y: number },
  stacks: ClipboardStackDataProps[]
) {
  if (!areaData) return;

  const initialAreaPosition = areaData.position;

  const offSetX = position.x - initialAreaPosition.x;
  const offSetY = position.y - initialAreaPosition.y;

  const newStacksIds: string[] = [];

  const newArea: AreaData = deepClone({
    ...areaData,
    position: { x: position.x, y: position.y },
    encompassingStacks: [],
    areaId: 'area_' + uuidv4(),
  });

  if (stacks.length > 0) {
    stacks?.forEach(stack => {
      const newStack = createNewStackOrDuplicate(
        {
          x: stack.position.x + offSetX,
          y: stack.position.y + offSetY,
        },
        [
          ...stack.items.map(item => {
            return { ...item, itemId: createItemId() };
          }),
        ],
        mapId,
        newArea.areaId,
        false,
        true
      );
      newStack && newStacksIds.push(newStack.stackId);
    });
  }

  newArea.encompassingStacks = newStacksIds;

  createNewArea(mapId, newArea);
}
