import DropdownItem from 'PlasmicComponents/DropdownItem';
import { IconLoader } from 'propro-common-components';

export default function QuickAccessToSection({
  isInModal,
}: {
  isInModal?: boolean;
}) {
  const quickAccessTopSection = [
    {
      name: 'Current Daily Notes',
      iconName: 'CabanaMapPennant',
      iconColor: '#F4BDA5',
    },
    {
      name: 'Current Tasks',
      iconName: 'CabanaVariousSubtask',
      iconColor: '#FB923C',
    },
  ];

  return (
    <div>
      {quickAccessTopSection.map((item, idx) => {
        return (
          <DropdownItem
            key={idx}
            name={item.name}
            leftIcon2={
              <IconLoader
                name={item.iconName}
                color={item.iconColor}
                size={20}
              />
            }
            expansionIcon="hidden"
            size={isInModal ? 'medium' : 'small'}
          />
        );
      })}
    </div>
  );
}
