import { useDebouncedValue, useHotkeys } from '@mantine/hooks';
import { HTMLElementRefOf } from '@plasmicapp/react-web';
import { useGetMapTextSearch } from 'api/queries';
import * as React from 'react';
import {
  mapTextSearchHistory,
  toggleMapTextSearchModal,
} from 'utils/stores/dashboardStore';
import {
  DefaultMapTextSearchProps,
  PlasmicMapTextSearch,
} from './plasmic/map_map/PlasmicMapTextSearch';

import MapPreview from 'components/textSearchModal/MapPreview';
import MapTextSearchSidebar from 'components/textSearchModal/ResultSidebar';
import SimpleTextInput from 'CoreComponents/SimpleTextInput/SimpleTextInput';
import { StackData } from 'utils/stores/types';
import { useSnapshot } from 'valtio';
import { useNavigate } from 'react-router-dom';

export interface MapTextSearchProps extends DefaultMapTextSearchProps {}

function MapTextSearch_(
  props: MapTextSearchProps,
  ref: HTMLElementRefOf<'div'>
) {
  const navigate = useNavigate();
  const inputRef = React.useRef<HTMLInputElement>(null);
  const searchHistory = useSnapshot(mapTextSearchHistory);

  const [searchValue, setSearchValue] = React.useState(
    searchHistory.query || ''
  );
  const [selectedIndex, setSelectedIndex] = React.useState<number | null>(null);
  const [debouncedText] = useDebouncedValue(searchValue, 500);

  const [selectedResult, setSelectedResult] = React.useState<StackData | null>(
    mapTextSearchHistory.result
  );

  const handleResultSelection = (item: StackData, index: number) => {
    if (item.stackId === selectedResult?.stackId) {
      navigateToMap(item);
    } else {
      setSelectedResult(item);
      setSelectedIndex(index);
    }
  };

  const navigateToMap = (item: StackData) => {
    console.log('Action performed on selected item:', item);
    const mapId = item?.map.mapId;
    navigate(
      `/map/${mapId}?stack=${encodeURIComponent(JSON.stringify(item.position))}`
    );

    toggleMapTextSearchModal();
  };

  const closModalWithHistory = () => {
    mapTextSearchHistory.query = debouncedText;
    mapTextSearchHistory.result = selectedResult;
    toggleMapTextSearchModal();
  };

  const closeModalCompletely = () => {
    mapTextSearchHistory.query = '';
    mapTextSearchHistory.result = null;
    toggleMapTextSearchModal();
  };

  const moveSelection = (direction: 'up' | 'down') => {
    if (!stacksResult || Object.keys(stacksResult).length === 0) return;

    const flattenedResults = getFlattenedResults();
    let newIndex = selectedIndex === null ? 0 : selectedIndex;

    if (direction === 'up') {
      newIndex =
        (newIndex - 1 + flattenedResults.length) % flattenedResults.length;
    } else if (direction === 'down') {
      newIndex = (newIndex + 1) % flattenedResults.length;
    }

    setSelectedResult(flattenedResults[newIndex]);
    setSelectedIndex(newIndex);
  };

  const getFlattenedResults = () => {
    return Object.values(stacksResult).flatMap(category =>
      Object.values(category)
    );
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'ArrowUp':
        e.preventDefault();
        moveSelection('up');
        break;
      case 'ArrowDown':
        e.preventDefault();
        moveSelection('down');
        break;
      case 'Enter':
        e.preventDefault();
        if (selectedResult) {
          navigateToMap(selectedResult);
        }
        break;
      case 'Escape':
        e.preventDefault();
        closeModalCompletely();
        break;
      default:
        // Do nothing for other keys
        return;
    }
  };

  useHotkeys([
    ['Escape', closeModalCompletely],
    ['ArrowUp', () => moveSelection('up')],
    ['ArrowDown', () => moveSelection('down')],
    ['Enter', () => selectedResult && navigateToMap(selectedResult)],
  ]);

  const { data, isLoading: searchResultLoading } =
    useGetMapTextSearch(debouncedText);
  const stacksResult = data as { [key: string]: { [key: string]: StackData } };

  React.useEffect(() => {
    inputRef.current?.focus();
  }, []);

  React.useEffect(() => {
    if (
      stacksResult &&
      !selectedResult &&
      Object.keys(stacksResult).length > 0
    ) {
      const flattenedResults = getFlattenedResults();
      if (flattenedResults.length > 0) {
        setSelectedResult(flattenedResults[0]);
        setSelectedIndex(0);
      }
    }
  }, [stacksResult, selectedResult]);

  return (
    <PlasmicMapTextSearch
      root={{ ref }}
      onClickContainer={closModalWithHistory}
      mapTextSearchWindow={{
        onClick: e => {
          e.preventDefault();
          e.stopPropagation();
        },
      }}
      searchTextInputSlot={
        <SimpleTextInput
          ref={inputRef}
          value={searchValue}
          onChange={e => {
            setSelectedResult(null);
            setSelectedIndex(null);
            setSearchValue(e.currentTarget.value);
          }}
          onKeyDown={handleKeyDown}
        />
      }
      sidebar={
        <MapTextSearchSidebar
          stacksResult={stacksResult}
          searchResultLoading={searchResultLoading}
          selectedResult={selectedResult}
          handleResultSelection={handleResultSelection}
          searchText={debouncedText}
        />
      }
      previewContainer={
        <MapPreview selectedStack={selectedResult} searchText={debouncedText} />
      }
      minimizeBtnContainer={{
        onClick: closModalWithHistory,
      }}
      closeBtnContainer={{
        onClick: closeModalCompletely,
      }}
      style={{
        zIndex: 50,
        position: 'fixed',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        left: '50%',
        width: '100vw',
      }}
      {...props}
    />
  );
}

const MapTextSearch = React.forwardRef(MapTextSearch_);
export default MapTextSearch;
