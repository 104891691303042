import { Matrix } from 'CoreComponents/Canvas/canvas';
import { ItemPreviewWithNode } from 'CoreComponents/Item/ItemPreview';
import { CSSProperties, useMemo, useRef } from 'react';
import { XYCoord, useDragLayer } from 'react-dnd';
import { useParams } from 'react-router-dom';
import { getScaleFromMatrix } from 'utils/helpers/Canvas/clamp-zoom';
import { DraggedItem } from 'utils/hooks/useDropOnCanvas';
import { currentMapStateStore } from 'utils/stores/mapStore';
import { getSelectedNodes } from './customDragLayerUtils';

const layerStyles: CSSProperties = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,

  width: '100%',
  height: '100%',
};

export const defaultPreviewStyle: CSSProperties = {
  display: 'inline-block',
  transform: 'translateY(-2px)',
  WebkitTransform: 'translateY(-2px)',
};

function applyPosition(
  initialOffset: XYCoord | null,
  currentOffset: XYCoord | null
) {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    };
  }

  let { x, y } = currentOffset;

  const transform = `translate(${x}px, ${y}px)`;

  return {
    transform,
    WebkitTransform: transform,
  };
}

// moving this outside the function so it's
// created once and not on every rerender
const nodes: HTMLElement[] = [];

const CustomDragLayer = () => {
  const mapId = useParams().mapId!;

  const canvaState = currentMapStateStore[mapId].canvas;

  const cloneNodes = useRef<HTMLElement[]>(null);

  const resetNodesPosition = useRef<() => void>(() => {});
  const { itemType, isDragging, item, initialOffset, currentOffset, posDiff } =
    useDragLayer(monitor => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),

      posDiff: monitor.getDifferenceFromInitialOffset(),
    }));

  const zoomLevel = getScaleFromMatrix(new Matrix(canvaState.CanvasTransform));

  // let isMultiDrag =
  //   item && selected && selected.length > 1 && selected.includes(item.id);

  useMemo(() => {
    if (!isDragging) return null;

    getSelectedNodes(item, cloneNodes, mapId, nodes);
    resetNodesPosition.current = resetStyles;
  }, [isDragging]);

  function renderItem(element: any) {
    cloneNodes.current &&
      updateDOMNodes(cloneNodes.current, posDiff, item.id, zoomLevel);

    switch (itemType) {
      case 'Item':
        const resolvedItem = element as DraggedItem;
        return (
          <ItemPreviewWithNode
            node={resolvedItem.itemRef}
            mapID={mapId}
            dimention={{
              width: resolvedItem.width,
              height: resolvedItem.height,
            }}
          />
        );
      default:
        return null;
    }
  }
  function resetStyles() {
    if (cloneNodes?.current) {
      for (let i = 0; i < cloneNodes.current.length; i++) {
        // using a single loop to go through the
        // two arrays for both cloneNodes and nodes
        // first loop remove the DOM node
        // second removes the opacity

        cloneNodes.current[i].remove();

        if (nodes[i]?.style) {
          nodes[i].style.opacity = '';
        }
      }

      cloneNodes.current = null;
      const nodesLenght = nodes.length;
      // mutate and still keeping the the array address
      nodes.splice(0, nodesLenght);
    }
  }

  if (!isDragging) {
    resetNodesPosition.current();
    return null;
  }
  return (
    <div style={layerStyles} className={`map-container `}>
      <div
        style={{
          ...{
            ...applyPosition(initialOffset, currentOffset),
          },
        }}
      >
        {renderItem(item)}
      </div>
    </div>
  );
};

function updateDOMNodes(
  domNodes: HTMLElement[],
  possDiff: { x: number; y: number },
  draggedItemID: string,
  zoomLevel: number
) {
  if (!possDiff) return;
  const { x, y } = possDiff;
  domNodes.forEach(node => {
    const currentPositionX = node?.clientLeft;
    const currentPositionY = node?.clientTop;

    if (node && node instanceof HTMLElement) {
      const transform = `translate(${(currentPositionX + x) / zoomLevel}px, ${
        (currentPositionY + y) / zoomLevel
      }px)`;
      node.style.transform = transform;
    }
  });
}

export default CustomDragLayer;
