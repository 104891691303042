import useStateMachine, { t } from '@cassiozen/usestatemachine';
import { useNetwork } from '@mantine/hooks';
import { useSaveMapToBackend } from 'api/mutations';
import {
  LastSyncAndLastInteraction,
  useGetLastSyncAndInteractionBE,
  useGetLoadedMap,
} from 'api/queries';
import { AxiosError } from 'axios';
import { pick } from 'lodash';
import {
  pendingChanges,
  resetPendingChanges,
} from 'utils/mapStoreFN/trackingChanges';
import {
  getCmss,
  getCurrentMapData,
  getOldestLastSync,
  updateCmcsAndCmssWithLoadedMapData,
  updateLastSync,
} from 'utils/stores/mapStore';
import {
  generalStore,
  removeMapBeingCreated,
  removeMapBeingLoaded,
} from '../../stores/generalStore';
import { compareLastSyncAndLastInteractionOfBothMaps } from './mapService';
import { saveMapScreenshot } from './mapSyncHelpers';
import {
  EVENTS,
  MapSyncingEvent,
  RETRY_EVENTS,
  SyncTrigger,
  TRIGGERS,
} from './mapSynincincStateMachineTypes';
import { retryWithBackoff } from 'utils/helpers/retry';

function useMapSyncingStateMachine(mapId: string) {
  const { online } = useNetwork();
  const { mapsBeingCreated } = generalStore;

  const {
    data: lastSyncAndInteractionBE,
    refetch: refetchLastSyncAndInteractionBE,
  } = useGetLastSyncAndInteractionBE(mapId);

  const { data: loadedMap, refetch: refetchLoadedMap } = useGetLoadedMap(mapId);

  // let { data: relevantStackObjectsBE, refetch: refetchRelevantStackObjectsBE } =
  //   useGetRelevantStackObjectsBE(mapId);

  const { mutateAsync: syncMapAndPendingChangesToTheBackend } =
    useSaveMapToBackend();

  const sm = useStateMachine({
    verbose: true,
    initial: 'idle',
    schema: {
      context: t<{
        retryCount: number;
        syncing: boolean;
        trigger?: SyncTrigger;
        mapId: string;
        error?: string;
        isReadyToRender?: boolean;
      }>(),
      events: {
        [EVENTS.ERROR]: t<{ prev?: MapSyncingEvent; retry?: boolean }>(),
        [EVENTS.IDLE]: t<{ prev?: MapSyncingEvent }>(),
        [EVENTS.COMPAREMAPS]: t<{ trigger?: SyncTrigger }>(),
        [EVENTS.LOADMAP]: t<{ trigger?: SyncTrigger }>(),
        [EVENTS.PREPARECOMPARISON]: t<{ trigger?: SyncTrigger }>(),
        [RETRY_EVENTS.RETRYCOMPAREMAPS]: t<{ trigger?: SyncTrigger }>(),
      },
    },
    context: { retryCount: 0, mapId, syncing: true, trigger: TRIGGERS.LOAD },
    states: {
      idle: {
        on: {
          //[EVENTS.COMPAREMAPS]: 'compareMaps',
          [EVENTS.SAVEMAP]: 'saveMap',
          [EVENTS.LOADMAP]: 'loadMap',
          [EVENTS.PREPARECOMPARISON]: 'prepareComparison',
        },
        effect({ setContext, context, event }: any) {
          if (context.syncing) {
            setContext((context: any) => ({ ...context, syncing: false }));
          }

          // set isReadyToRender to true when the state machine is idle and render ready
          if (!context.isReadyToRender)
            if (event.prev === EVENTS.LOADMAP) {
              setContext((context: any) => ({
                ...context,
                isReadyToRender: true,
              }));
            }
          if (event.prev === EVENTS.SAVEMAP) {
            // not sure if I need that
            removeMapBeingLoaded(mapId);
            setContext((context: any) => ({
              ...context,
              isReadyToRender: true,
            }));
          }
        },
      },
      prepareComparison: {
        on: {
          [EVENTS.COMPAREMAPS]: 'compareMaps',
          [EVENTS.LOADMAP]: 'loadMap',
        },
        effect({ setContext, context, event, send }: any) {
          if (!context.syncing) {
            setContext((context: any) => ({
              ...context,
              syncing: true,
              trigger: event.trigger,
            }));
          }

          if (mapsBeingCreated.includes(mapId)) {
            removeMapBeingCreated(mapId);
            removeMapBeingLoaded(mapId);
            setContext((context: any) => ({
              ...context,
              isReadyToRender: true,
              syncing: false,
            }));
            send({ type: EVENTS.IDLE, prev: EVENTS.PREPARECOMPARISON });
            return;
          }

          if (!online) {
            removeMapBeingLoaded(mapId);
            setContext((context: any) => ({
              ...context,
              isReadyToRender: true,
            }));
            return send({ type: EVENTS.IDLE, prev: EVENTS.PREPARECOMPARISON });
          }

          // Is there sth on valtio?
          const currentMap = getCmss(mapId);
          if (!currentMap) {
            return send(EVENTS.LOADMAP);
          }

          // since none of the earlier conditions apply
          // we can go ahead and trigger a fetch for the li ls value from BE
          const fetchLiLsFromBE = async () => {
            try {
              // await refetchLastSyncAndInteractionBE();

              if (!online) throw new Error('Offline'); // Ensure offline check

              const result = await refetchLastSyncAndInteractionBE(); // ! FETCHING LAST SYNC AND LAST INTERACTION.

              if (!result.data) {
                throw new Error(
                  'Failed to fetch lastSyncAndInteractionBE data'
                );
              }
            } catch (error) {
              const err = error as AxiosError;
              const ctx = setContext((context: any) => ({
                error: err.message,
                ...context,
              }));
              return ctx.send({
                type: EVENTS.ERROR,
                prev: EVENTS.COMPAREMAPS,
                retry: true,
              });
            }
          };
          if (!online) return; // stops the request when the user is offline
          fetchLiLsFromBE().then(() => {
            // so that the lastSyncAndInteractionBE is up to date when we
            send(EVENTS.COMPAREMAPS, { trigger: TRIGGERS.LOAD });
          });
        },
      },
      compareMaps: {
        on: {
          [EVENTS.LOADMAP]: 'loadMap',
          [EVENTS.SAVEMAP]: 'saveMap',
          [EVENTS.MERGEMAPS]: 'mergeMaps',
          [EVENTS.IDLE]: 'idle',
          [EVENTS.ERROR]: 'error',
        },
        effect({ send, setContext, event }: any) {
          setContext((context: any) => ({
            ...context,
            syncing: true,
            trigger: event.trigger,
          }));

          const currentMap = getCmss(mapId);

          // check if we has lastSyncAndInteractionBE
          if (!lastSyncAndInteractionBE) {
            return send({ type: EVENTS.IDLE, prev: EVENTS.COMPAREMAPS });
          }

          // check if there is neither last sync nor last interaction on BE or FE
          if (!lastSyncAndInteractionBE && !currentMap) {
            return send({ type: EVENTS.IDLE, prev: EVENTS.COMPAREMAPS });
          }

          saveMapScreenshot(mapId);

          const { lastSync: lastSyncFE, lastInteraction: lastInteractionFE } =
            currentMap;

          if (!lastSyncAndInteractionBE && !lastSyncFE && !lastInteractionFE) {
            return send({ type: EVENTS.IDLE, prev: EVENTS.COMPAREMAPS });
          }

          if (!currentMap) {
            return send(EVENTS.LOADMAP);
          }

          const { lastSync: lastSyncBE, lastInteraction: lastInteractionBE } =
            lastSyncAndInteractionBE as LastSyncAndLastInteraction;

          const lastSyncDateBE = new Date(lastSyncBE);
          const lastInteractionDateBE = new Date(lastInteractionBE);

          const lastSyncDateFE = lastSyncFE ? new Date(lastSyncFE) : undefined;
          const lastInteractionDateFE = new Date(lastInteractionFE);

          const nextEvent = compareLastSyncAndLastInteractionOfBothMaps(
            lastInteractionDateFE,
            lastInteractionDateBE,
            lastSyncDateBE,
            lastSyncDateFE
          );

          if (nextEvent === EVENTS.IDLE) {
            removeMapBeingLoaded(mapId);
            setContext((context: any) => ({
              ...context,
              isReadyToRender: true,
            }));
            send(nextEvent);
          }

          return send(nextEvent);
        },
      },
      loadMap: {
        on: { [EVENTS.IDLE]: 'idle', [EVENTS.ERROR]: 'error' },
        effect: async ({ send }) => {
          try {
            if (!online) return; // stops the request when the user is offline
            const result = await refetchLoadedMap(); // ! FETCHING MAP
            if (!result.data) {
              throw new Error('Loaded map data is undefined');
            }
            updateCmcsAndCmssWithLoadedMapData(mapId, result.data);

            try {
              removeMapBeingLoaded(mapId);
            } catch (error) {
              console.error(
                'Failed to remove map from mapsBeingLoaded. ' +
                  'likely it has already been removed',
                error
              );
            }

            send({ type: EVENTS.IDLE, prev: EVENTS.LOADMAP });
          } catch (error) {
            // console.error('Failed to load map:', error);
            send({
              type: EVENTS.ERROR,
              prev: EVENTS.LOADMAP,
              retry: true,
              error: error.message,
            });
          }
        },
      },
      saveMap: {
        on: { [EVENTS.IDLE]: 'idle', [EVENTS.ERROR]: 'error' },
        effect({ send }) {
          const saveMapToBackend = async () => {
            updateLastSync(mapId);
            const mapData = getCurrentMapData(mapId);
            if (!online) return; // stops the request when the user is offline
            try {
              mapData.lastInteraction = new Date();
              await retryWithBackoff(() =>
                syncMapAndPendingChangesToTheBackend(mapData)
              );
              send({ type: EVENTS.IDLE, prev: EVENTS.SAVEMAP });
            } catch (error) {
              console.error('Failed to save map after retries:', error);
              send({ type: EVENTS.ERROR, prev: EVENTS.SAVEMAP, retry: false });
            }
          };
          saveMapToBackend();
        },
      },
      mergeMaps: {
        on: { [EVENTS.IDLE]: 'idle', [EVENTS.SAVEMAP]: 'saveMap' },
        effect({ send }) {
          const mergeMapWithBackend = async () => {
            const oldestLastSyncDate = getOldestLastSync(mapId);
            if (!oldestLastSyncDate) {
              await refetchLoadedMap(); // ! FETCHING MAP
            }

            const relevantStackObjectsFE = getCurrentMapData(mapId);
            if (!online) return;

            try {
              relevantStackObjectsFE.lastInteraction = new Date();
              await retryWithBackoff(() =>
                syncMapAndPendingChangesToTheBackend(relevantStackObjectsFE)
              );
              send({ type: EVENTS.IDLE, prev: EVENTS.SAVEMAP });
            } catch (error) {
              console.error('Failed to save map after retries:', error);
              send({ type: EVENTS.ERROR, prev: EVENTS.SAVEMAP, retry: false });
            }
          };
          if (!online) return;
          mergeMapWithBackend();
        },
      },
      error: {
        on: {
          RETRYCOMPAREMAPS: {
            target: 'compareMaps',
            guard: ({ context }) => context.retryCount < 3,
          },
          RETRYLOADMAPS: {
            target: 'loadMap',
            guard: ({ context }: any) => context.retryCount < 3,
          },
          RETRYSAVEMAPS: {
            target: 'saveMap',
            guard: ({ context }: any) => context.retryCount < 3,
          },
          RETRYMERGEMAPS: {
            target: 'mergeMaps',
            guard: ({ context }) => context.retryCount < 3,
          },
          IDLE: {
            target: 'idle',
          },
        },
        effect({ setContext, event, context }: any) {
          const ctx = setContext((context: any) => ({
            ...context,
            retryCount: context.retryCount + 1,
          }));

          if (context.retryCount === 2 || !event.retry) {
            setContext((context: any) => ({ ...context, retryCount: 0 }));
            return ctx.send('IDLE');
          } else {
            const backoffTime = Math.pow(2, context.retryCount) * 2000; // Exponential backoff
            setTimeout(() => {
              switch (event.prev) {
                case EVENTS.COMPAREMAPS:
                  ctx.send('RETRYCOMPAREMAPS');
                  break;
                case EVENTS.LOADMAP:
                  ctx.send('RETRYLOADMAPS');
                  break;
                case EVENTS.SAVEMAP:
                  ctx.send('RETRYSAVEMAPS');
                  break;
                case EVENTS.MERGEMAPS:
                  ctx.send('RETRYMERGEMAPS');
                  break;
                default:
                  ctx.send('IDLE');
                  break;
              }
            }, backoffTime);
          }
        },
      },
    },
  });

  return sm;
}

export default useMapSyncingStateMachine;
