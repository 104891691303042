export const pick = <T extends object, K extends keyof T>(
  obj: T,
  keys: K[],
): Pick<T, K> => {
  const newObj = {} as Pick<T, K>;
  keys.forEach((key) => {
    newObj[key] = obj[key];
  });
  return newObj;
};

export const splitByFirstSpace = (str) => {
  const [firstPart, ...rest] = str.split(' ');
  const secondPart = rest.join(' ');
  return [firstPart, secondPart];
};
