export const calculateDropZone = (
  dropTargetRect: DOMRect,
  dropPosition: { y: number }
) => {
  const dropTargetTop = dropTargetRect.top;
  const dropTargetHeight = dropTargetRect.height;
  const midPoint = dropTargetTop + dropTargetHeight / 2;

  return dropPosition.y < midPoint ? 'above' : 'below';
};
