import { deepCopy } from 'utils/helpers/deepCopy';
import { proxy, subscribe } from 'valtio';
import { watch } from 'valtio/utils';
import { gestureStore } from './gestureStore';
import { duplicateMode, insertMode } from './mapMode/modesStore';
import { updateMapMode } from './mapmodesStore';

type ActiveHotHeyStore = {
  current: HotKeysState;
};

type HotKeysState = {
  resetOnMouseMove: boolean;

  isModActive: boolean;
  isShiftActive: boolean;
  isAltActive: boolean;
  isMetaActive: boolean;
  isCtrlActive: boolean;
};
type HotKeys = keyof HotKeysState;

const initialHotKeyState: HotKeysState = {
  resetOnMouseMove: true,

  isModActive: false,
  isShiftActive: false,
  isAltActive: false,
  isMetaActive: false,
  isCtrlActive: false,
};

const defaultState: ActiveHotHeyStore = {
  current: initialHotKeyState,
};

export const hotKeyStateStore = proxy<ActiveHotHeyStore>(defaultState);
export function setHotKeyState(targeHotKey: HotKeys, value: boolean) {
  hotKeyStateStore.current[targeHotKey] = value;
}
export function resetHotKeys() {
  hotKeyStateStore.current = deepCopy(initialHotKeyState);
}

subscribe(hotKeyStateStore.current, ops => {
  const state = ops[0][1][0] as keyof HotKeysState;
  // const prevValue = ops[0][3]
  const newValue = ops[0][2];

  switch (state) {
    case 'isModActive':
      if (newValue === false) {
        updateMapMode('insert', false);
      }
      break;

    case 'isAltActive':
      if (newValue === false) {
        updateMapMode('duplicate', false);
      }
      break;

    default:
      break;
  }
});

//............... watch and compose modes...................

watch(get => {
  get(hotKeyStateStore.current);
  get(gestureStore.Dragging);

  if (hotKeyStateStore.current.isModActive === false) {
    insertMode.isActive && insertMode.reset();
  }

  if (
    gestureStore.Dragging.isDragging &&
    hotKeyStateStore.current.isModActive
  ) {
    insertMode.activate(1);
  }

  if (hotKeyStateStore.current.isAltActive === false) {
    duplicateMode.isActive && duplicateMode.reset();
  }

  if (
    gestureStore.Dragging.isDragging &&
    hotKeyStateStore.current.isAltActive === true
  ) {
    duplicateMode.acivate();
  }
});
