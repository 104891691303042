import { useHotkeys } from '@mantine/hooks';
import { message } from 'antd';
import getSelectedIDs from 'utils/helpers/Selection/selectionUtils';
import { getParentIdFromItemID } from 'utils/mapStoreFN/mapStoreFN_stacks';
import {
  addMultipleItemsToStack,
  currentMapContentStore,
  getSelectedNoteIDAndStackAtPosition,
  removeItemFromStack,
} from 'utils/stores/mapStore';
import selectedContentsStore from 'utils/stores/Selection/selectedContentStore';
import { ItemData } from 'utils/stores/types';

export default function useStackItems(
  mapID: string,
  config: {
    icon: React.ReactNode;
    backgroundColor: string;
    textColor: string;
  }
) {
  const [messageApi, contextHolder] = message.useMessage();

  const Info = (MessageType: 'singleItems' | 'singleStack') => {
    switch (MessageType) {
      case 'singleItems':
        messageApi.open({
          type: 'info',
          content: 'Select two or more notes to stack',
          duration: 2,
          icon: config.icon,

          style: {
            filter: `drop-shadow(0 0 0.75rem)`,
          },
        });
        break;
      case 'singleStack':
        messageApi.open({
          type: 'info',
          content: 'Select item from another stack',
          duration: 2,
          icon: config.icon,

          style: {
            filter: `drop-shadow(0 0 0.75rem)`,
          },
        });
        break;
      default:
        messageApi.open({
          type: 'error',
          content: 'unable to stack',
          duration: 2,
          icon: config.icon,

          style: {
            filter: `drop-shadow(0 0 0.75rem)`,
          },
        });
    }
  };

  const stackItems = () => {
    const getSelected = getSelectedIDs();
    let getItemsID: string[] =
      getSelected && getSelected.filter(id => id?.includes('item_'));

    // lastSelectedStackId
    // use this to retrieve the position of the last itemId stack and create a new stack
    const { itemID, stackID } = getSelectedNoteIDAndStackAtPosition(
      mapID,
      null,
      'FIRST'
    );
    let itemsData: ItemData[] = [];
    let stackingItemsStackIDS: Set<string> = new Set();

    if (getItemsID.length >= 2) {
      getItemsID.forEach((itemID, index) => {
        const itemStackID = getParentIdFromItemID(itemID);
        stackingItemsStackIDS.add(itemStackID);
        if (stackID === itemStackID) return;
        const stackData = currentMapContentStore[mapID].value.stacks.find(
          ({ stackId }) => itemStackID === stackId
        );
        if (stackData) {
          const itemIndex = stackData.items.findIndex(
            ({ itemId }) => itemID === itemId
          );
          itemIndex !== undefined && itemsData.push(stackData.items[itemIndex]);
        }
      });

      // console.log('itemsData', itemsData);

      if (stackingItemsStackIDS.size <= 1) return Info('singleStack');

      addMultipleItemsToStack(itemsData, itemID!, stackID!, mapID);

      getItemsID.forEach(id => {
        removeItemFromStack(id, mapID, stackID || undefined);
      });
      selectedContentsStore.unselect();
    } else {
      Info('singleItems');
    }
  };
  useHotkeys([
    [
      'alt+mod+s',
      () => {
        stackItems();
      },
    ],
  ]);
  return { contextHolder };
}
