import { getArea } from 'utils/mapStoreFN/mapStoreFN_areas';
import {
  getStackAndItem,
  getParentIdFromItemID,
} from 'utils/mapStoreFN/mapStoreFN_stacks';
import { currentMapStateStore } from 'utils/stores/mapStore';
import { ItemData } from 'utils/stores/types';
import getSelectedIDs from './Selection/selectionUtils';

interface StyleUpdate {
  propertyName: string;
  propertyValue: any;
}

// Item style shortcuts

// export const isFontSizeShortcut = (e: KeyboardEvent) =>
//   e.metaKey &&
//   e.altKey &&
//   (e.key === '–' ||
//     e.key === '±' ||
//     e.key === '+' ||
//     e.key === '≠' ||
//     e.key === '=' ||
//     e.key === '≤' ||
//     e.key === ',' ||
//     e.key === '≥' ||
//     e.key === '.');

export const isIndentationShortcut = (e: React.KeyboardEvent) =>
  e.metaKey && (e.key === 'ArrowLeft' || e.key === 'ArrowRight');
// ----- //

// Function to help apply style for each Item style function
export const applyStyleToSelectedItems = (
  mapId: string,
  getUpdatedStyle: (itemData: any) => StyleUpdate,
  includeAreaSelection?: boolean,
  passedItemId?: string
) => {
  let itemsToUpdate: string[] = [];

  if (passedItemId) {
    // If a specific item ID is passed, use only that
    itemsToUpdate = [passedItemId];
  } else {
    // Otherwise, use the selected items
    const selectedItemsOnly = getSelectedIDs().filter(id =>
      id.includes('item_')
    );

    const selectedItemsAndAreas = getSelectedIDs();
    itemsToUpdate = includeAreaSelection
      ? selectedItemsAndAreas
      : selectedItemsOnly;
  }

  itemsToUpdate.forEach(itemId => {
    let itemData;

    if (itemId.includes('area_')) {
      itemData = getArea(itemId, mapId);
    } else {
      const stackId = getParentIdFromItemID(itemId);
      if (stackId) {
        const { itemData: item } = getStackAndItem(itemId, mapId);
        itemData = item;
      }
    }

    if (!itemData) return;

    const { propertyName, propertyValue } = getUpdatedStyle(itemData);
    updateStyleProperty(itemData, propertyName, propertyValue);
  });
};

export const updateStyleProperty = (
  itemData: any,
  propertyName: string,
  propertyValue: string | number
) => {
  itemData.style = {
    ...itemData.style,
    [propertyName]: propertyValue,
  };
};
// ----- //

// For textAlignment
export const textAlignments = {
  left: 1,
  center: 2,
  right: 3,
  justify: 4,
} as const;

export const getNextTextAlignment = (
  presentAlignment: number,
  direction: 'ArrowLeft' | 'ArrowRight'
) => {
  const nextTextAlignments = {
    1: 'left',
    2: 'center',
    3: 'right',
    4: 'justify',
  } as const;

  const nextStyle =
    direction === 'ArrowLeft' ? presentAlignment - 1 : presentAlignment + 1;

  return nextTextAlignments[nextStyle as keyof typeof nextTextAlignments];
};
// ----- //
