// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Glance24FilledIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Glance24FilledIcon(props: Glance24FilledIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 16 17"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M12.833 7.793c.645 0 1.167.555 1.167 1.24v4.604c0 .684-.522 1.24-1.167 1.24h-3c-.644 0-1.166-.556-1.166-1.24V9.032c0-.684.522-1.24 1.166-1.24h3zM6.18 10.635c.644 0 1.166.555 1.166 1.24v1.762c0 .684-.522 1.24-1.166 1.24H3.17c-.644 0-1.166-.556-1.166-1.24v-1.762c0-.685.522-1.24 1.166-1.24h3.01zm-.01-8.51c.645 0 1.167.555 1.167 1.24v4.604c0 .65-.472 1.184-1.071 1.235l-.096.004h-3c-.644 0-1.166-.555-1.166-1.24V3.366c0-.685.522-1.24 1.166-1.24h3zm6.664 0c.645 0 1.167.555 1.167 1.24v1.77c0 .685-.522 1.24-1.167 1.24h-3c-.644 0-1.166-.555-1.166-1.24v-1.77c0-.685.522-1.24 1.166-1.24h3z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Glance24FilledIcon;
/* prettier-ignore-end */
