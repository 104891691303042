import {
  getCurrentMapData,
  updateCmcsAndCmssWithLoadedMapData,
  updateLastSync,
} from 'utils/stores/mapStore';
import { MapData } from 'utils/stores/types';
import { EVENTS } from './mapSynincincStateMachineTypes';

/**
 * Merges two arrays of items based on a specified key and returns a new array
 * containing unique items with the latest `lastInteraction` value.
 *
 * @template T - The type of items in the arrays.
 * @param {T[]} itemsFE - The items from the front-end.
 * @param {T[]} itemsBE - The items from the back-end.
 * @param {keyof T} key - The key to use for merging and comparison.
 * @returns {T[]} - The merged array of items.
 */
function mergeItems<T extends { lastInteraction: Date }>(
  itemsFE: T[],
  itemsBE: T[],
  key: keyof T
): T[] {
  const itemMap = new Map<string | number, T>();

  [...itemsFE, ...itemsBE].forEach(item => {
    const existingItem = itemMap.get(item[key] as unknown as string | number);
    if (!existingItem || item.lastInteraction > existingItem.lastInteraction) {
      itemMap.set(item[key] as unknown as string | number, item);
    }
  });

  return Array.from(itemMap.values());
}

export function mergeMapData(
  mapId: string,
  fullStackObjectsFE: MapData,
  fullStackObjectsBE: MapData
): MapData {
  const lastInteractionFE = new Date(fullStackObjectsFE?.lastInteraction);
  const lastInteractionBE = new Date(fullStackObjectsBE?.lastInteraction);

  const mergedStacks = mergeItems(
    fullStackObjectsFE?.stacks || [],
    fullStackObjectsBE?.stacks || [],
    'stackId'
  );

  const mergedAreas = mergeItems(
    fullStackObjectsFE?.areas || [],
    fullStackObjectsBE?.areas || [],
    'areaId'
  );

  const mergedPreviews = mergeItems(
    fullStackObjectsFE?.previews || [],
    fullStackObjectsBE?.previews || [],
    'previewId'
  );

  const lastInteraction = new Date(
    Math.max(lastInteractionFE.getTime(), lastInteractionBE.getTime())
  );

  const mergedMapData: MapData = {
    ...fullStackObjectsFE,
    stacks: mergedStacks,
    areas: mergedAreas,
    // connections: mergedConnections,
    previews: mergedPreviews,
    lastInteraction,
  };

  updateCmcsAndCmssWithLoadedMapData(mapId, mergedMapData);
  updateLastSync(mapId);

  return getCurrentMapData(mapId);
}

export function compareLastSyncAndLastInteractionOfBothMaps(
  lastInteractionFE: Date,
  lastInteractionBE: Date,
  lastSyncBE: Date,
  lastSyncFE?: Date
) {
  const nothingNewOnTheFE = lastInteractionFE.getTime() <= lastSyncBE.getTime();
  const nothingNewOnTheBE =
    lastInteractionBE.getTime() <= (lastSyncFE?.getTime() ?? 0);

  // If both FE and BE have not had any new interactions since their last syncs
  if (nothingNewOnTheFE && nothingNewOnTheBE) {
    return EVENTS.IDLE;
  } else if (!nothingNewOnTheFE && nothingNewOnTheBE) {
    return EVENTS.SAVEMAP;
  } else if (nothingNewOnTheFE && !nothingNewOnTheBE) {
    return EVENTS.LOADMAP;
  }
  // If both FE and BE have new interactions since their last syncs with each other,
  // a comparison or merge operation is necessary.
  else if (!nothingNewOnTheFE && !nothingNewOnTheBE) {
    return EVENTS.MERGEMAPS;
  }
  // Any other case would be unexpected and may indicate an issue with the timestamps
  else {
    throw new Error('An unexpected state has occurred in map syncing.');
  }
}
