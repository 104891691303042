// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react';
import {
  PlasmicResultItem,
  DefaultResultItemProps,
} from './plasmic/map_map/PlasmicResultItem';
import { HTMLElementRefOf } from '@plasmicapp/react-web';

export interface ResultItemProps extends DefaultResultItemProps {
  style?: React.CSSProperties;
  onClick?: () => void;
}

function ResultItem_(props: ResultItemProps, ref: HTMLElementRefOf<'div'>) {
  return (
    <PlasmicResultItem
      root={{ ref }}
      style={{
        cursor: 'pointer',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
      }}
      {...props}
    />
  );
}

const ResultItem = React.forwardRef(ResultItem_);
export default ResultItem;
