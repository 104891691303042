import {
  BaseDraggable,
  CurrentMapContentStore,
  CurrentMapStateStore,
  ImageUpload,
  ItemData,
  ItemsToDelete,
  MapData,
  NoteItemData,
  StackData,
  Style,
} from './types';
// utils/mapStore.ts
/// searching for where lastInteraction is updated in the codebase: type "updateLastInteraction" in the search bar
import { defaultsDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { proxy, subscribe } from 'valtio';
import { devtools } from 'valtio/utils';

import { Descendant } from 'slate';
import { EXAMPLE_MAP } from './exampleMaps';
import { proxyWithHistory } from './proxyWithHistory';
// import { generateUniqueId } from "../helpers/generateUniqueId";
import { Matrix } from 'CoreComponents/Canvas/InfiniteCanvas';
import { createStore } from 'idb-keyval';
import { DropTargetMonitor } from 'react-dnd';
import getSelectedIDs from 'utils/helpers/Selection/selectionUtils';
import {
  DraggedArea,
  DraggedItem,
  DraggedPreview,
} from 'utils/hooks/useDropOnCanvas';
import {
  deleteAreaTitleNote,
  duplicateArea,
  getArea,
  updateAreaPosition,
  updateEncompassingStacks,
} from 'utils/mapStoreFN/mapStoreFN_areas';
import {
  delectConnectionLabel,
  removeDeletedItemConnection,
} from 'utils/mapStoreFN/mapStoreFN_connection';
import {
  createItemId,
  extractTypeAndParentFromItemID,
  getItemData,
} from 'utils/mapStoreFN/mapStoreFN_items';
import {
  getPreview,
  removePreviewFromMap,
  updatePreviewPosition,
} from 'utils/mapStoreFN/mapStoreFN_previews';
import {
  createNewStackOrDuplicate,
  getParentIdFromItemID,
  getStack,
  reRenderStack,
} from 'utils/mapStoreFN/mapStoreFN_stacks';
import { storage } from 'utils/storage';
import proxyWithPersist, { PersistStrategy } from 'valtio-persist';
import {
  getNormalizedCoordinates,
  getScaleFromMatrix,
} from '../helpers/Canvas/clamp-zoom';
import { pick } from '../helpers/pick';
import { cNoteConfig } from './constants';
import { generalStore } from './generalStore';
import { setPrevSelectPos } from './gestureStore';
import { anchorMode } from './mapMode/modesStore';
import selectedContentsStore, {
  SelectedContent,
} from './Selection/selectedContentStore';
import { themeStore } from './themeStore';
import { uploadImageStore } from './uploadImagesStore';

// ..............................map...........................

//TODO break this file  into sections

const initialMapState: CurrentMapStateStore = {
  [EXAMPLE_MAP.mapId]: {
    mapId: EXAMPLE_MAP.mapId,
    name: EXAMPLE_MAP.name,
    lastInteraction: EXAMPLE_MAP.lastInteraction,
    lastSync: EXAMPLE_MAP.lastSync,

    canvas: EXAMPLE_MAP.canvas,
    selected: [],

    outerBoundsOfContent: {
      topLeftPosition: { x: 0, y: 0 },
      centerPosition: { x: 0, y: 0 },
      bottomRightPosition: { x: 0, y: 0 },
    },
    isEmptyMap: true,

    openNewStyleShortcut: false,
    version: 1,
  },
};

const cmssIdbStore = createStore('currentMapStateDB', 'currentMapStateStore');

export const currentMapStateStore = proxyWithPersist({
  name: 'currentMapStateStore',

  initialState: {} as CurrentMapStateStore,
  persistStrategies: PersistStrategy.MultiFile,
  version: 0,
  migrations: {},

  getStorage: () => storage(cmssIdbStore),
});

export const currentMapContentStore = proxyWithHistory<CurrentMapContentStore>(
  'currentMapContentStore',
  0,
  {}
);

export const storeStatus = proxy({
  mapStateReady: false,
  mapContentReady: false,
});

subscribe(currentMapStateStore, () => {
  if (currentMapStateStore._persist.status === 'loaded') {
    storeStatus.mapStateReady = true;
  }
});

subscribe(currentMapContentStore, () => {
  if (currentMapContentStore._persist.status === 'loaded') {
    storeStatus.mapContentReady = true;
  }
});

export const checkAndUpdateIsMapEmptyForMap = (mapId: string) => {
  if (currentMapContentStore[mapId]?.value) {
    const isMapEmpty =
      currentMapContentStore[mapId].value.stacks.length === 0 &&
      currentMapContentStore[mapId].value.areas.length === 0 &&
      currentMapContentStore[mapId].value.connections.length === 0 &&
      currentMapContentStore[mapId].value.previews.length === 0;

    if (currentMapStateStore[mapId]) {
      currentMapStateStore[mapId].isEmptyMap = isMapEmpty;
    }
  }
};

// ----- Pending Changes implementation -----
subscribe(currentMapContentStore, ops => {
  const opsCleaned = ops.filter(
    op =>
      !op[1].includes('_persist') &&
      !op[1].includes('history') &&
      !op[1].includes('length')
  );

  const mapId = ops[0][1][0] as string;
  const mapContent = currentMapStateStore[mapId];
  if (mapContent) {
    mapContent.lastInteraction = new Date();
  }

  // opsCleaned.forEach(op => {
  //   const operationType = op[0];
  //   const pathArray = op[1] as string[];
  //   const entity = pathArray[2] as keyof MapContentData;
  //   if (!entity) {
  //     return;
  //   }
  //   const entityId =
  //     operationType === 'delete'
  //       ? op[2][entity.slice(0, -1) + 'Id']
  //       : pathArray[3];

  //   if (operationType === 'delete') {
  //     mapContent.lastInteraction = new Date();
  //     // updatePendingChanges(entity, entityId, {} as any, 'delete');
  //     return;
  //   }

  //   const index = Number(pathArray[3]);
  //   const entityList = currentMapContentStore[mapId]?.value[entity];
  //   if (entityList && entityList.length > index) {
  //     const entityData = entityList[index];
  //     const entityId = getEntityId(entityData);
  //     if (isValidType(entityData, entity)) {
  //       mapContent.lastInteraction = new Date();
  //       // updatePendingChanges(entity, entityId, entityData, operationType);
  //     } else {
  //       console.error('Invalid entity data type for operation:', entity);
  //     }
  //   } else {
  //     console.error('Unexpected entityId format:', entityId);
  //   }
  // });
});

// TODO rename this to getCmcs
export const getMap = (mapId: string) => {
  return currentMapContentStore[mapId];
};

export function setCurrentMapStateStoreWithMapData(
  mapId: string,
  mapData: MapData
) {
  currentMapStateStore[mapId] = {
    ...pick(mapData, [
      'name',
      'canvas',
      'lastInteraction',
      'lastSync',
      'mapId',
      'version',
      'icon',
    ]),
    selected: [],

    outerBoundsOfContent: {
      topLeftPosition: { x: 0, y: 0 },
      centerPosition: { x: 0, y: 0 },
      bottomRightPosition: { x: 0, y: 0 },
    },

    isEmptyMap: true,
    openNewStyleShortcut: false,
    // isFolderSearchMode: false,
    version: mapData.version || 1,
  };
}

export const updateCmcsAndCmssWithLoadedMapData = (
  mapId: string,
  mapData: MapData | undefined
) => {
  if (!mapData) {
    console.error(`No map data available for mapId ${mapId}`);
    return;
  }

  const { stacks, areas, previews, connections } = mapData;

  currentMapContentStore[mapId] = {
    value: {
      stacks: stacks || [],
      areas: areas || [],
      previews: previews || [],
      connections: connections || [],
    },
    history: {
      nodes: [
        {
          snapshot: { stacks, areas, previews, connections },
          createdAt: new Date(),
        },
      ],
      index: 0,
    },
  };

  setCurrentMapStateStoreWithMapData(mapId, mapData);
};

// enable redux devtools extension, disable type checking errors on next line, to enable build step in vercel
const unsub = devtools(currentMapStateStore, {
  name: 'Cmss',
  enabled: true,
  trace: true,
});

// .......................items...................................
export const resetStacks = (mapId: string) => {
  // this does not work
  // Object.assign(mapStore, initialMapState);

  // this works
  // add preview data to remove this ts ignore
  //@ts-ignore
  currentMapContentStore[mapId].value = {
    stacks: [],
    areas: [],
    previews: [],
    connections: [],
  };
};

// overlap with `addItemToStack`?

export function removeItemFromStack(
  itemID: string,
  mapID: string,
  ignoreStackID?: string,
  lastSelectedID?: string
) {
  // retrieve stackID from rendered item node .

  const stackID = getParentIdFromItemID(itemID);
  // use the index here to retrieve stackObject as well as delete it.
  let stackIndex = getIndex(stackID, mapID);

  if (stackIndex === -1) return;
  // javascript apparently converts 0 to false
  // this stops the block code from running.
  const stackData = currentMapContentStore[mapID].value.stacks[stackIndex];

  if (ignoreStackID && stackData.stackId === ignoreStackID) return;

  const numberOfitemsInStack = stackData.items.length;

  if (numberOfitemsInStack === 1) {
    // remove stack directly

    // remove connection if item connection array  isnt empty

    stackData.items[0].connections.length !== 0 &&
      removeDeletedItemConnection(
        itemID,
        stackData.items[0].connections,
        mapID
      );
    currentMapContentStore[mapID].value.stacks.splice(stackIndex, 1);
    stackData.isContainedInArea !== 'none' &&
      updateEncompassingStacks(
        'REMOVE',
        mapID,

        [stackData.stackId],
        stackData.isContainedInArea,
        stackData.items.length,
        undefined
      );
  } else {
    // remove item from stack

    const itemIndex = stackData.items.findIndex(
      ({ itemId }) => itemId === itemID
    );
    const itemData = stackData.items[itemIndex];

    if (itemData) {
      itemData?.connections.length > 0 &&
        removeDeletedItemConnection(itemID, itemData?.connections, mapID);
    }
    if (lastSelectedID && itemData?.itemId === lastSelectedID) {
      const getPrevItemID = stackData.items[itemIndex - 1]?.itemId;
      const getNextItemID = stackData.items[itemIndex + 1]?.itemId;

      getNextItemID &&
        addItemToSelected(
          {
            contentID: getNextItemID,
            type: 'Note',
            parentType: 'stack',
            parentID: stackData.stackId,
          },
          mapID
        );

      !getNextItemID &&
        getPrevItemID &&
        addItemToSelected(
          {
            contentID: getPrevItemID,
            type: 'Note',
            parentType: 'stack',
            parentID: stackData.stackId,
          },
          mapID
        );
    }
    stackData.items.splice(itemIndex, 1);
  }
  return;
}

function removeAreaFromMap(
  areaID: string,
  mapID: string,
  deleteEncompassing?: {
    deleteAllEncompassingStacks?: boolean;
    encompassingItemsToBeRemoved?: string[];
  }
) {
  const { deleteAllEncompassingStacks, encompassingItemsToBeRemoved } =
    deleteEncompassing;

  let areaIndex = currentMapContentStore[mapID].value.areas.findIndex(
    area => area.areaId === areaID
  );

  const areaData = getArea(areaID, mapID);

  if (areaIndex !== -1) {
    // set containedInArea value to none before
    // deleting area.
    currentMapContentStore[mapID].value.areas[
      areaIndex
    ].encompassingStacks.forEach(stackID => {
      updateContainedInAreas(stackID, 'none', mapID);
    });
    currentMapContentStore[mapID].value.areas.splice(areaIndex, 1);

    if (deleteAllEncompassingStacks) {
      areaData?.encompassingStacks.forEach(stackId => {
        const stackData = getStack(stackId, mapID);
        deleteContent(mapID, {
          idToRemove: stackData.items.map(item => item.itemId),
        });
      });
      return;
    }

    if (encompassingItemsToBeRemoved && encompassingItemsToBeRemoved?.length) {
      deleteContent(mapID, {
        idToRemove: encompassingItemsToBeRemoved,
      });
      return;
    }
    // updateLastInteraction(areaID, mapID);
  }
}

export const deleteContent = (
  mapID: string,
  options?: {
    idToRemove?: string[];
    encompassingItemsToBeRemoved?: string[];
    deleteAllEncompassingStacksForArea?: boolean;
  }
) => {
  const {
    idToRemove,
    deleteAllEncompassingStacksForArea,
    encompassingItemsToBeRemoved,
  } = options || {};

  const idsToDelete = idToRemove !== undefined ? idToRemove : getSelectedIDs();

  if (!idsToDelete || idsToDelete.length === 0) return;

  // reset selected

  const lastSelectedNoteID = getSelectedNoteIDAndStackAtPosition(
    mapID,
    idsToDelete
  )?.itemID;

  idsToDelete.forEach(id => {
    if (id.includes('area_')) {
      removeAreaFromMap(id, mapID, {
        deleteAllEncompassingStacks: deleteAllEncompassingStacksForArea,
        encompassingItemsToBeRemoved: encompassingItemsToBeRemoved,
      });
    }

    if (id.includes('preview_')) {
      removePreviewFromMap(id, mapID);
    }
    if (id.includes('item_')) {
      const getItemDetail = extractTypeAndParentFromItemID(id);
      if (getItemDetail.itemType === 'Default') {
        removeItemFromStack(id, mapID, null, lastSelectedNoteID);
      }
      if (getItemDetail.itemType === 'titleNote') {
        deleteAreaTitleNote(id, mapID);
      }
      if (getItemDetail.itemType === 'label') {
        delectConnectionLabel(id, mapID);
      }
    }
  });
  resetSelected(mapID);
};

// .........................styles..............................
// this should work with setting nested style property
// regardless of the property depth.
// uses a recursive approach until it gets to the final key.
// in cases where the property does not exist the object is created
export function setProperty(
  styleObject: any,
  path: string,
  objectValue: string
): Style {
  const [key, ...rest] = path.split('.');

  return {
    ...styleObject,
    [key]: rest.length
      ? setProperty(styleObject[key], rest.join('.'), objectValue)
      : objectValue,
  } as Style;
}

export const updateItemOrAreaStyle = (
  Id: string,
  style: Partial<Style>,
  mapId: string,
  stackId?: string,
  propertyPath?: {
    path: string;
    value: string;
  },
  shouldToggle = false, // support applying styles that can be toggled,
  defaultProperty?: Partial<Style>
) => {
  if (Id.includes('area_')) {
    const areaData = getArea(Id, mapId);
    if (areaData) {
      const newStyleObject = propertyPath
        ? setProperty(areaData.style, propertyPath.path, propertyPath.value)
        : {
            ...areaData.style,
            ...style,
          };
      areaData.style = defaultsDeep(newStyleObject, defaultProperty);
    }
    // updateLastInteraction(Id, mapId);
    return;
  }

  if (Id.includes('preview_')) {
    const previewData = getPreview(Id);
    if (previewData) {
      const newStyleObject = propertyPath
        ? setProperty(previewData.style, propertyPath.path, propertyPath.value)
        : {
            ...previewData.style,
            ...style,
          };
      previewData.style = defaultsDeep(newStyleObject, defaultProperty);
    }
    // updateLastInteraction(Id, mapId);
    return;
  }

  const itemData = getItemData(Id, mapId);
  if (itemData) {
    if (shouldToggle) {
      // get incoming style value
      const styleKey = Object.keys(style)[0];

      // get note current style property keys
      const itemDataStyleValue = Object.keys(itemData?.style);

      // check if incoming style value has already been set
      const hasProperty = itemDataStyleValue.includes(styleKey);

      if (hasProperty) {
        // get the previous value
        const prevValue = itemData?.style[styleKey];

        // update the new style argument with the toggled value
        // this should cover edge case where the user want to toggle one
        // and pass the remaining values
        style = {
          ...style,
          [styleKey as keyof typeof style]: !prevValue,
        };
      }
    }
    const newStyleObject = propertyPath
      ? setProperty(itemData.style, propertyPath.path, propertyPath.value)
      : {
          ...itemData.style,
          ...style,
        };
    itemData.style = defaultsDeep(newStyleObject, defaultProperty);
  }

  return;
};

export const applyStyleObject = (style: Style, mapId: string) => {
  const { assignedHotkey, ...styleWithoutHotkey } = style;

  getSelectedIDs().forEach(id => {
    if (id.includes('area_')) {
      const area = getArea(id, mapId);
      area.style = styleWithoutHotkey;

      return;
    }
    if (id.includes('item_')) {
      const itemData = getItemData(id, mapId);
      if (itemData) {
        itemData.style = styleWithoutHotkey;
      }
    }
  });
  return;
};

// ................................................stacks...................................

/* this takes an id of a stack, note or area
 should be used for only modification operation (i.e operations 
that do not change the array position) if used in a loop 


*/

function getIndexFromValtio(ID: string, mapID: string) {
  const mapStoreContent = currentMapContentStore[mapID].value;
  if (ID.includes('area_')) {
    return mapStoreContent.areas.findIndex(area => area.areaId === ID);
  }
  if (ID.includes('stack_')) {
    const stackIndex = mapStoreContent.stacks.findIndex(
      stack => stack.stackId === ID
    );
    reRenderStack(currentMapContentStore[mapID].value.stacks[stackIndex]);
    return stackIndex;
  }
  if (ID.includes('preview_')) {
    return mapStoreContent.previews.findIndex(
      preview => preview.previewId === ID
    );
  }
  if (ID.includes('connection_')) {
    return mapStoreContent.connections.findIndex(
      connection => connection.connectionId === ID
    );
  }
}
export function getIndex(ID: string, mapID: string): null | number {
  return getIndexFromValtio(ID, mapID);
}
export const createNewNoteAndNewStack = (
  position: { x: number; y: number },
  slateContent: Descendant[] | null,
  mapId: string,
  areaId?: string,
  isTitleNote?: boolean,
  useStackID?: string
) => {
  const noteItem: ItemData = {
    itemId: createItemId(),
    type: 'Note',
    content: {
      slateContent: slateContent,
      textContent: null,
    },
    style: isTitleNote
      ? {
          border: {
            thickness: '_1',
            color: '#FC9A13',
          },
          fillColor: '#FC9A13',
        }
      : {},
    connections: [],
    lastInteraction: new Date(),
    delete: false,
    position,
  };

  const newStack: StackData = {
    stackId: useStackID || 'stack_' + uuidv4(),
    isContainedInArea: areaId || 'none',
    position,
    items: [noteItem],
    connections: [],
    isTitleNote: isTitleNote || false,
    lastInteraction: new Date(),
    delete: false,
  };

  currentMapContentStore[mapId].value.stacks.push(newStack);
  addItemToSelected(
    {
      contentID: noteItem.itemId,
      type: 'Note',
      parentType: 'stack',
      parentID: newStack.stackId,
    },
    mapId
  );
};

export const createNewImageAndNewStack = (
  position: { x: number; y: number },
  src: string,
  mapId: string,
  areaId?: string,
  imageType?: string,
  size?: {
    width: number;
    height: number;
  }
) => {
  const imageItem: NoteItemData = {
    type: 'Note',
    itemId: createItemId(),
    style: {
      customWidth: size.width,
    },
    content: {
      textContent: '_IMAGE',
      slateContent: [
        {
          type: 'Image',
          url: src,
          alt: 'image data',
          children: [{ text: 'images' }],
        },
      ],
    },
    connections: [],
    position: { x: 0, y: 0 },
    lastInteraction: new Date(),
    delete: false,
  };

  // upload to the backend and replace url

  if (imageItem) {
    const uploadImage: ImageUpload = {
      base64Data: src.split(',')[1],
      itemId: imageItem.itemId,
      width: '',
      height: '',
      Type: imageType || 'image/png',
    };
    uploadImageStore.uploadData = {
      imageUploadData: [uploadImage],
      mapId,
    };
  }

  createNewStackOrDuplicate(position, [imageItem], mapId, areaId);
};

// opportunity for reusability between this function, and `addItemToStack`.

export function handleShiftSelection(
  selectedItem: SelectedContent,
  mousePos: { x: number; y: number },
  mapId: string
) {
  const canvasTransform = currentMapStateStore[mapId].canvas.CanvasTransform;
  const zoomLevel = getScaleFromMatrix(new Matrix(canvasTransform));
  const normalizedMousePos = getNormalizedCoordinates(
    canvasTransform,
    mousePos.x,
    mousePos.y
  );
  setPrevSelectPos({
    x: normalizedMousePos.x / zoomLevel,
    y: normalizedMousePos.y / zoomLevel,
  });
  addItemToSelected(selectedItem, mapId);
}

export const addItemToSelected = (
  selectedItem: SelectedContent,
  mapId: string
) => {
  const useSelectionV2 = generalStore.flags.mapmap_selectionv2;
  if (useSelectionV2) {
    selectedContentsStore.select(selectedItem, false);
    return;
  }
  const selectedItems = getSelectedIDs();

  // Check if the item is already in the array before adding it
  if (!selectedItems.includes(selectedItem.contentID)) {
    selectedItems.push(selectedItem.contentID);
  }
};

export const removeItemFromSelected = (
  selectedItem: SelectedContent,
  mapId: string
) => {
  if (generalStore.flags?.mapmap_selectionv2) {
    selectedContentsStore.unselect(selectedItem.contentID);
  } else {
    currentMapStateStore[mapId].selected = currentMapStateStore[
      mapId
    ].selected.filter(item => item !== selectedItem.contentID);
  }
};

export const resetSelected = (mapId: string) => {
  if (generalStore.flags?.mapmap_selectionv2) {
    selectedContentsStore.unselect();
  } else {
    currentMapStateStore[mapId].selected = [];
  }

  anchorMode.reset();
  // creatingAreaMode.reset();
};

export const getSelected = (mapId: string) => {
  return currentMapStateStore[mapId].selected;
};

export const setSelected = (selectedItem: SelectedContent, mapId: string) => {
  const useSelectionV2 = generalStore.flags.mapmap_selectionv2;
  if (useSelectionV2) {
    selectedContentsStore.select(selectedItem);
  } else {
    currentMapStateStore[mapId].selected = [selectedItem.contentID];
  }
};

export const hoveredStackStore = proxy<{ hoveredStackId: string | null }>({
  hoveredStackId: null,
});

export const areaDataData = (areaId: string, mapId: string) => {
  const area = currentMapContentStore[mapId].value.areas.find(
    item => item.areaId === areaId
  );
  if (!area) return;
  return area;
};

export const getItems = (mapId: string, itemIDs?: string[]) => {
  const stacks = currentMapContentStore[mapId].value.stacks;
  let items: string[] = [];
  for (const stack of stacks) {
    if (itemIDs) {
      items = items.concat(
        stack.items
          .filter(i => itemIDs.includes(i.itemId))
          .map(item => item.itemId)
      );
    } else {
      items = items.concat(stack.items.map(items => items.itemId));
    }
  }
  return items;
};

//...................................Actions.............................

export function closeColorPicker(mapID: string) {
  anchorMode.reset();
}
export const getItemsNormalisedPosition = (
  // return an array of nominalised postions object {x, y}
  itemsID: string[],
  canvasTransform: string
) => {
  if (itemsID.length === -1) return;
  const itemPositions: {
    x: number;
    y: number;
  }[] = [];
  itemsID.forEach(ID => {
    const itemNode = document.getElementById(ID)?.getBoundingClientRect();
    if (itemNode) {
      const normalizedPosition = getNormalizedCoordinates(
        canvasTransform,
        itemNode.x,
        itemNode.y
      );
      itemPositions.push(normalizedPosition);
    }
  });

  return itemPositions;
};
export function getLastSeletedID(mapID: string) {
  let lastID = null;
  const selectedIDs = getSelectedIDs();
  if (selectedIDs.length !== -1) {
    lastID = selectedIDs[selectedIDs.length - 1];
  }

  return lastID;
}

export type AnchorPosition = {
  x: number;
  y: number;
  midY?: number;
  midX?: number;
};
export function getItemNormalisedPositon(
  ID: string,
  mapID: string,
  DomRef?: HTMLElement | HTMLDivElement
) {
  let position: AnchorPosition | null = null;
  const getDOMRef =
    DomRef?.getBoundingClientRect() ||
    document.getElementById(ID)?.getBoundingClientRect();

  let RightPosition: AnchorPosition;
  if (getDOMRef) {
    const NormalisedPosition = getNormalizedCoordinates(
      currentMapStateStore[mapID].canvas.CanvasTransform,
      getDOMRef.x,
      getDOMRef.y + getDOMRef.height / 2
    );
    const midPos = getNormalizedCoordinates(
      currentMapStateStore[mapID].canvas.CanvasTransform,
      getDOMRef.x / getDOMRef.width / 2,
      getDOMRef.y + getDOMRef.height / 2
    );
    const normalisedRightPosition = getNormalizedCoordinates(
      currentMapStateStore[mapID].canvas.CanvasTransform,
      getDOMRef.x + getDOMRef.width + 2,
      getDOMRef.y + getDOMRef.height / 2
    );
    const midPosRight = getNormalizedCoordinates(
      currentMapStateStore[mapID].canvas.CanvasTransform,
      getDOMRef.x + getDOMRef.width,
      getDOMRef.y + getDOMRef.height / 2
    );
    RightPosition = {
      x: normalisedRightPosition.x,
      y: normalisedRightPosition.y,
      midX: midPosRight.x,
      midY: midPosRight.y,
    };
    position = {
      x: NormalisedPosition.x,
      y: NormalisedPosition.y,
      midX: midPos.x,
      midY: midPos.y,
    };
  }

  return { position, getDOMRef, RightPosition };
}

export const addMultipleItemsToStack = (
  items: ItemData[],
  afterItemId: string,
  targetStackID: string,
  mapID: string
) => {
  const stack = getStack(targetStackID, mapID);
  if (!stack) return;
  const index = stack.items.findIndex(i => i.itemId === afterItemId);

  // add a new last interaction value here
  // because we need it to trigger a useEffect for items
  // that are just created or dropped in a new stack
  // this lets use  retrieve the mounted dom node and extract its actual position on the dom

  if (index === -1) {
    stack.items = items; // if stack is empty, push item to stack
  } else {
    stack.items = [...stack.items, ...items]; // if stack is not empty, insert item after afterItemId
  }

  // updateLastInteraction(targetStackID, mapID);
  return;
};

export const deleteIfStackEmpty = (stackId: string, mapId: string) => {
  const stack = currentMapContentStore[mapId].value.stacks.find(
    s => s.stackId === stackId
  );
  if (stack && stack.items.length === 0) {
    // updateLastInteraction(stackId, mapId);

    currentMapContentStore[mapId].value.stacks = currentMapContentStore[
      mapId
    ].value.stacks.filter(s => s.stackId !== stackId);
  }
};

// Function to extract the text content from the slateContent
export function extractTextFromSlateContent(
  slateContent: Descendant[]
): string {
  let textContent = '';

  // Note: Descendants have children array which house different segments,
  // and segments contain text contents.
  // We can have many descendants and many segments

  slateContent.forEach((descendant: any) => {
    if (descendant.children) {
      let isFirstTextSegment = true; // Track whether it's the first text segment
      descendant.children.forEach((child: { text: string }) => {
        if (child.text) {
          if (!isFirstTextSegment) {
            // Add space if it's not the first text segment
            textContent += ' ';
          } else {
            isFirstTextSegment = false; // Update flag after the first text segment
          }
          textContent += child.text;
        }
      });
      // Add newline after each Descendant
      textContent += '\n';
    }
  });

  // Remove trailing newline if present
  if (textContent.endsWith('\n')) {
    textContent = textContent.slice(0, -1);
  }

  return textContent;
}

export const handleChangeTextAndNoteWidth = (
  parentID: string,
  itemId: string,
  slateContent: Descendant[],
  mapId: string,
  setDefaultWidth?: boolean,
  noteHeight = 0
) => {
  const textContent = extractTextFromSlateContent(slateContent);
  const item = getItemData(itemId, mapId);

  if (item) {
    if (setDefaultWidth) {
      item.style = {
        ...item.style,
        customWidth: cNoteConfig.MINWIDTH,
      };
    }

    item!.content = {
      slateContent,
      textContent,
    };
  }
};

// ......................update functions .....................

/*
goes through all the conection in the item.connection array,
using the index it retrieve the current connection id and remove it from the 
items the deleted item shares a connection

when at the base case it call a function that then removes the connection object from the 
cmcs.connection

*/

export const updateItemPositonValue = (
  itemID: string,
  mapID: string,
  position: { x: number; y: number }
) => {
  const stackID = getParentIdFromItemID(itemID);

  if (stackID) {
    currentMapContentStore[mapID].value.stacks.find(({ stackId, items }) => {
      if (stackID === stackId) {
        const itemData = items.find(item => item.itemId === itemID);
        if (itemData) {
          itemData.position = { x: position.x, y: position.y };
        }

        return true;
      }
    });
  }
};
const updateStackInteraction = (ID: string, mapID: string) => {
  const stack = currentMapContentStore[mapID].value.stacks.find(
    s => s.stackId === ID
  );

  if (!stack) return;

  stack.lastInteraction = new Date();
};

function addItemID(
  itemIDsToDelete: any,
  path: string,
  objectValue: string[]
): ItemsToDelete {
  const [key, ...rest] = path.split('.');

  return {
    ...itemIDsToDelete,
    [key]: rest.length
      ? addItemID(itemIDsToDelete[key], rest.join('.'), objectValue)
      : objectValue,
  };
}

// export const updateLastInteraction = (
//   ID: string,
//   mapId: string,
//   itemId?: string
// ) => {
//   ID.includes('area_')
//     ? updateAreaInteraction(ID, mapId)
//     : updateStackInteraction(ID, mapId);

//   // const item = stack.items.find(item => item.itemId === itemId);

//   // if (item) {
//   //   item.lastInteraction = new Date();
//   // }

//   const map = currentMapStateStore[mapId];

//   map.lastInteraction = new Date();
//   if (!map.idsOfStacksToBeSaved.includes(ID)) {
//     map.idsOfStacksToBeSaved.push(ID);
//   }
// };

export const getCmss = (mapId: string) => {
  if (currentMapStateStore[mapId]) {
    return currentMapStateStore[mapId];
  } else {
    return null;
  }
};

export function getStackData(stackId: string, mapId: string) {
  return currentMapContentStore[mapId].value.stacks.find(
    s => s.stackId === stackId
  );
}

export const getCurrentMapData = (mapId: string) => {
  const map = currentMapStateStore[mapId];
  const mapContent = currentMapContentStore[mapId]?.value || {
    stacks: [],
    areas: [],
    previews: [],
    connections: [],
  };

  if (!map) {
    throw new Error(`Map with ID ${mapId} does not exist.`);
  }

  return {
    ...pick(map, [
      'name',
      'canvas',
      'lastInteraction',
      'lastSync',
      'mapId',
      'icon',
      'version',
      'preview',
    ]),
    stacks: mapContent.stacks,
    areas: mapContent.areas,
    previews: mapContent.previews,
    connections: mapContent.connections,
  };
};

// update lastSync of map and stacks
export const updateLastSyncMap = (mapId: string, syncTime: Date): MapData => {
  const map = currentMapStateStore[mapId];
  map.lastSync = syncTime;

  const stacks = currentMapContentStore[mapId].value.stacks;
  const areas = currentMapContentStore[mapId].value.areas;
  const previews = currentMapContentStore[mapId].value.previews;
  const connections = currentMapContentStore[mapId].value.connections;

  return {
    ...map,
    stacks,
    areas,
    previews,
    connections,
  };
};

// update lastSync of the map and its stacks and areas
// (items don't have a lastSync value)
export const updateLastSync = (mapId: string): void => {
  if (!currentMapContentStore || !currentMapContentStore[mapId]) {
    console.error('Map not found in currentMapContentStore');
    return;
  }
  const currentMap = currentMapStateStore[mapId];
  if (!currentMap) {
    console.error('Map not found in currentMapStateStore');
    return;
  }
  currentMap.lastSync = new Date();
};

export const updateLastInteractionMapStacksAreas = (
  mapId: string,
  stackIds: string[],
  areaIds: string[],
  previewIds: string[],
  interactionTime: Date
) => {
  const map = currentMapStateStore[mapId];
  map.lastInteraction = interactionTime;
  const stacks = currentMapContentStore[mapId].value.stacks;
  const areas = currentMapContentStore[mapId].value.areas;
  const previews = currentMapContentStore[mapId].value.previews;

  stacks.forEach(stack => {
    if (stackIds.includes(stack.stackId)) {
      stack.lastInteraction = interactionTime;
    }
  });
  areas.forEach(area => {
    if (areaIds.includes(area.areaId)) {
      area.lastInteraction = interactionTime;
    }
  });
  previews.forEach(preview => {
    if (previewIds.includes(preview.previewId)) {
      preview.lastInteraction = interactionTime;
    }
  });
};

export const isCurrentMapContentStoreEmpty = (mapId: string) => {
  return (
    currentMapContentStore[mapId].value?.stacks.length === 0 &&
    currentMapContentStore[mapId].value?.areas.length === 0 &&
    currentMapContentStore[mapId].value?.previews.length === 0
  );
};

export const getAllMapsIDs = () => {
  return Object.keys(currentMapStateStore);
};

export const getOldestLastSync = (mapId: string) => {
  const map = currentMapStateStore[mapId];
  if (map && map.lastSync) {
    return new Date(map.lastSync);
  }
  return;
};

export const onEnter = (mapId: string) => {
  const getFirstNoteItem = getSelectedIDs()?.find(id => id.includes('item_'));
  if (getFirstNoteItem) {
    const getNoteNode = document.getElementById(getFirstNoteItem);
    currentMapStateStore[mapId].selected = [getFirstNoteItem];
    if (getNoteNode) {
      var Click = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      getNoteNode.dispatchEvent(Click);
    }
  }
};

/**
 * This returns the first or last item in the selected array. It ignores every other type i.e areaIDs, previeIDs would not be considered
 * @param mapID the current map ID
 * @param selectedIds Array of Selected Items
 * @param position The first selected Note or Last selected Note default is last
 * @returns object or null if not found
 * - itemID
 * - stackID
 */

export function getSelectedNoteIDAndStackAtPosition(
  mapID: string,
  selectedIds?: string[],
  position: 'FIRST' | 'LAST' = 'LAST'
): {
  itemID: string | undefined;
  stackID: string | undefined | null;
} {
  let selectedIDs = selectedIds || getSelectedIDs();
  let stackID: string = null;
  let itemID: string = null;

  switch (position) {
    case 'FIRST':
      itemID = [...selectedIDs].find(id => id.includes('item_'));
      if (itemID) {
        stackID = document
          .getElementById(itemID)
          ?.getAttribute('data-parentid');
      }
      return {
        itemID,
        stackID,
      };
      break;
    case 'LAST':
      itemID = [...selectedIDs].reverse().find(id => id.includes('item_'));
      if (itemID) {
        stackID = document
          .getElementById(itemID)
          ?.getAttribute('data-parentid');
      }
      return {
        itemID,
        stackID,
      };
  }
}

// .............................Areas............................................

// updated the array "containedInAreas" in a stack
export const updateContainedInAreas = (
  stackId: string,
  areaId: string,
  mapId: string
) => {
  // TODO   refactor this to work with stack.containedInAreas after removing isContainedInArea

  const stack = currentMapContentStore[mapId].value.stacks.find(
    s => s.stackId === stackId
  );

  if (!stack) return;

  stack.isContainedInArea = areaId;
  // updateLastInteraction(stackId, mapId);
};

export function updateAreaTitleAndStamp( // TODO  remove
  mapId: string,
  AreaId: string,
  value: { stamps: string[]; title: string }
) {
  const area = currentMapContentStore[mapId].value.areas.find(
    a => a.areaId === AreaId
  );
  if (area) {
    area.stamps = value.stamps;
  }
}

// TODO create updateAreaTitleNote

export function getItemWidth(itemId: string, stackId: string, mapId: string) {
  const stack = currentMapContentStore[mapId].value.stacks.find(
    s => s.stackId === stackId
  );
  if (!stack) throw new Error('Invalid stack ID');
  const item = stack.items.find(i => i.itemId === itemId);
  if (!item) throw new Error('Invalid item ID');
  return item.style?.customWidth || themeStore.defaultItemWidth;
}

// updated the array "encompassingStacks" in an area

//.............................. multi drag ...........................
type PositionOffset = {
  x: number;
  y: number;
};

export function handleMultiDragItems(
  positionOffset: PositionOffset,
  zoomLevel: number,
  mapID: string,
  createDuplicate?: boolean,
  draggedItem?: BaseDraggable,
  ignoreID?: string,
  itemBeingDraggedPosition?: PositionOffset,
  monitor?: DropTargetMonitor<
    DraggedArea & DraggedItem & DraggedPreview,
    unknown
  >
) {
  if (createDuplicate) {
    return duplicateMultipleItemsAndAreas(positionOffset, mapID, zoomLevel);
  }

  const selectedIDs = getSelectedIDs(ignoreID);

  const stackIDs: Set<string> = new Set();
  const areaIDs: Set<string> = new Set();
  const previewIDs: Set<string> = new Set();

  selectedIDs.forEach(selectedId => {
    if (selectedId.includes('item_')) {
      const itemType = extractTypeAndParentFromItemID(selectedId);
      if (itemType.itemType === 'Default') {
        const stackID = getParentIdFromItemID(selectedId);
        stackID && stackIDs.add(stackID);
      }
    } else if (selectedId.includes('preview_')) {
      previewIDs.add(selectedId);
    } else {
      areaIDs.add(selectedId);
    }
  });

  // Create a set of all encompassing stacks for the areas
  const encompassingStacksSet: Set<string> = new Set();
  areaIDs.forEach(areaID => {
    const area = getArea(areaID, mapID);
    area.encompassingStacks.forEach(stackID =>
      encompassingStacksSet.add(stackID)
    );
  });

  if (previewIDs.size) {
    console.log(previewIDs);
  }

  // For items/stacks
  if (stackIDs.size) {
    stackIDs.forEach(stackID => {
      const stackData = getStack(stackID, mapID);

      const isStackInArea = encompassingStacksSet.has(stackID);

      if (stackData && !isStackInArea) {
        const selectedNotes = stackData.items.filter(id =>
          selectedIDs.includes(id.itemId)
        );

        // console.log('stackData', stackData);
        // console.log('selectedNotes', selectedNotes);
        // console.log('positionOffset', positionOffset);
        // console.log('positionOffset', positionOffset);
        const isAllItemsBeingDragged =
          stackData?.items?.length === selectedNotes?.length;

        let positionOffsetForDrop = {
          x: stackData.position.x + positionOffset.x / zoomLevel,
          y: stackData.position.y + positionOffset.y / zoomLevel,
        };

        if (!isAllItemsBeingDragged && itemBeingDraggedPosition) {
          if (draggedItem?.id === selectedNotes[0]?.itemId) {
            positionOffsetForDrop = {
              x: itemBeingDraggedPosition?.x + positionOffset.x / zoomLevel,
              y: itemBeingDraggedPosition?.y + positionOffset.y / zoomLevel,
            };
          } else {
            let id = (selectedIDs[0] || '').split(':')[0].split('_')[1];

            const firstItemClone = document.getElementById(`clone_${id}`);

            const firstItemCloneClientRect =
              firstItemClone?.getBoundingClientRect();

            const firstItemClonePositions = getNormalizedCoordinates(
              currentMapStateStore[mapID].canvas.CanvasTransform,
              firstItemCloneClientRect?.x,
              firstItemCloneClientRect?.y
            );

            positionOffsetForDrop = {
              x: firstItemClonePositions?.x || positionOffsetForDrop.x,
              y: firstItemClonePositions?.y || positionOffsetForDrop.y,
            };
            // console.log('positionOffsetForDrop', positionOffsetForDrop);
            // console.log('firstItemClonePositions', firstItemClonePositions);
          }
        }

        createNewStackOrDuplicate(positionOffsetForDrop, selectedNotes, mapID);
      }
    });
  }

  // To move areas in a multi-drag
  if (areaIDs.size) {
    areaIDs.forEach(areaID => {
      updateAreaPosition(
        areaID,
        {
          // not being used presently
          x: 0,
          y: 0,
        },
        mapID,
        positionOffset.x,
        positionOffset.y,
        zoomLevel
      );
    });
  }

  // To move previews in a multi-drag
  if (previewIDs.size) {
    previewIDs.forEach(previewID => {
      const previewData = getPreview(previewID, mapID);

      updatePreviewPosition(
        previewID,
        {
          x: previewData.position.x + positionOffset.x / zoomLevel,
          y: previewData.position.y + positionOffset.y / zoomLevel,
        },
        mapID
      );
    });
  }

  return null;
}

export const duplicateMultipleItemsAndAreas = (
  position: {
    x: number;
    y: number;
  },
  mapId: string,
  zoomLevel: number
) => {
  const selectedIds = getSelectedIDs();
  // unselect selected contents
  resetSelected(mapId);

  const addToSelected: SelectedContent[] = [];

  let stackIds: Set<string> = new Set();
  let areaIds: Set<string> = new Set();

  selectedIds.forEach(selectedId => {
    if (selectedId.includes('item_')) {
      const itemType = extractTypeAndParentFromItemID(selectedId);
      if (itemType.itemType === 'Default') {
        const stackID = getParentIdFromItemID(selectedId);
        stackID && stackIds.add(stackID);
      }
    } else {
      const areaId = getArea(selectedId, mapId).areaId;

      areaId && areaIds.add(areaId);
    }
  });

  // For items/stacks
  if (Array.from(stackIds).length) {
    Array.from(stackIds).forEach(stackId => {
      if (!stackId) return;

      const stack = getStackData(stackId, mapId);
      const selectedItems = stack.items.filter(item =>
        selectedIds.includes(item.itemId)
      );

      const newPosition = {
        x: stack.position.x + position.x / zoomLevel,
        y: stack.position.y + position.y / zoomLevel,
      };

      let duplicatedItems: ItemData[] = selectedItems.map(item => ({
        ...item,
        style: item.style,
        itemId: createItemId(),
      }));
      duplicatedItems.forEach(Item =>
        addToSelected.push({
          contentID: Item.itemId,
          parentType: 'stack',
          type: 'Note',
          parentID: stack.stackId,
        })
      );

      createNewStackOrDuplicate(newPosition, duplicatedItems, mapId);
    });
  }

  // For areas
  if (Array.from(areaIds).length) {
    Array.from(areaIds).forEach(areaId => {
      const areaData = getArea(areaId, mapId);

      const newPosition = {
        x: areaData.position.x + position.x / zoomLevel,
        y: areaData.position.y + position.y / zoomLevel,
      };

      if (areaData) {
        // create a single function to hanlde creating area ID;
        const newAreaID = 'area_' + uuidv4();
        duplicateArea(
          areaData,
          mapId,
          newPosition,
          {
            x: position.x / zoomLevel,
            y: position.y / zoomLevel,
          },
          newAreaID
        );
        addToSelected.push({
          contentID: newAreaID,
          type: 'Area',
          parentType: 'area',
          parentID: newAreaID,
        });
      }
    });
  }

  addToSelected.forEach(ID => {
    addItemToSelected(ID, mapId);
  });
};

//.............................. Zoom to content ...........................

export const updateOuterBoundsPositions = (
  position: { x: number; y: number },
  mapId: string
) => {
  const { topLeftPosition, centerPosition, bottomRightPosition } =
    currentMapStateStore[mapId].outerBoundsOfContent;
  const isWithinBounds =
    position.x >= topLeftPosition.x &&
    position.y >= topLeftPosition.y &&
    position.x <= bottomRightPosition.x &&
    position.y <= bottomRightPosition.y;

  // Check if this is our first stack since the default outerbounds is just zero
  // We then update the outer bounds to be based on our first stack
  if (
    topLeftPosition.x === 0 &&
    topLeftPosition.y === 0 &&
    centerPosition.x === 0 &&
    centerPosition.y === 0 &&
    bottomRightPosition.x === 0 &&
    bottomRightPosition.y === 0
  ) {
    let newOuterBoundsOfContent = {
      topLeftPosition: { x: position.x, y: position.y },
      centerPosition: {
        x: (position.x + position.x + 200) / 2,
        y: (position.y + position.y + 100) / 2,
      },
      bottomRightPosition: { x: position.x + 200, y: position.y + 100 },
    };
    currentMapStateStore[mapId].outerBoundsOfContent = newOuterBoundsOfContent;
  }

  // We don't want to always recalculate the Outerbounds everytime a stack is created
  // We only update the outer bounds when the new item is outside the existing outerbound.
  if (isWithinBounds) return;

  if (!isWithinBounds) {
    let newOuterBoundsOfContent: any = {
      topLeftPosition: {
        x: Math.min(
          ...currentMapContentStore[mapId].value.stacks.map(
            stack => stack.position.x
          )
        ),
        y: Math.min(
          ...currentMapContentStore[mapId].value.stacks.map(
            stack => stack.position.x
          )
        ),
      },
      bottomRightPosition: {
        x: Math.max(
          ...currentMapContentStore[mapId].value.stacks.map(
            stack => stack.position.x + 200
          )
        ),
        y: Math.max(
          ...currentMapContentStore[mapId].value.stacks.map(
            stack => stack.position.y + 100
          )
        ),
      },
    };
    (newOuterBoundsOfContent.centerPosition = {
      x:
        (newOuterBoundsOfContent.topLeftPosition.x +
          newOuterBoundsOfContent.bottomRightPosition.x) /
        2,
      y:
        (newOuterBoundsOfContent.topLeftPosition.y +
          newOuterBoundsOfContent.bottomRightPosition.y) /
        2,
    }),
      (currentMapStateStore[mapId].outerBoundsOfContent =
        newOuterBoundsOfContent);
  }
};

export const detectOverScroll = (
  initialCanvasPosition: { x: number; y: number },
  positionAfterScroll: { x: number; y: number },
  scaleValue: number
) => {
  const mainInitialCanvasXPosition =
    scaleValue === 0
      ? initialCanvasPosition.x
      : initialCanvasPosition.x / scaleValue;
  const mainInitialCanvasYPosition =
    scaleValue === 0
      ? initialCanvasPosition.y
      : initialCanvasPosition.y / scaleValue;

  const mainXCanvasPositionAfterScroll =
    scaleValue === 0
      ? positionAfterScroll.x
      : positionAfterScroll.x / scaleValue;
  const mainYCanvasPositionAfterScroll =
    scaleValue === 0
      ? positionAfterScroll.y
      : positionAfterScroll.y / scaleValue;

  const distance = Math.sqrt(
    Math.pow(mainXCanvasPositionAfterScroll - mainInitialCanvasXPosition, 2) +
      Math.pow(mainYCanvasPositionAfterScroll - mainInitialCanvasYPosition, 2)
  );

  return distance;
};

export const zoomToContent = (
  mapId: string,
  initialCanvasState: {
    initialCanvasPosition: IPosition;
    initialCanvasTransform: string;
    scale: number;
  }
) => {
  const stackElement = document.querySelector('#stacks') as HTMLDivElement;
  if (!stackElement) return;

  let newTransform;

  newTransform = `translate(${initialCanvasState.initialCanvasPosition.x}px, ${initialCanvasState.initialCanvasPosition.y}px) scale(${initialCanvasState.scale})`;
  stackElement.style.transform = newTransform;

  currentMapStateStore[mapId].canvas.CanvasTransform =
    initialCanvasState.initialCanvasTransform;
};

export interface IPosition {
  x: number;
  y: number;
}

export const detectItemsOverBounds = (
  viewportTopLeft: IPosition,
  viewportBottomRight: IPosition,
  boundsTopLeft: IPosition,
  boundsBottomRight: IPosition
) => {
  return (
    viewportTopLeft.x >= boundsTopLeft.x &&
    viewportTopLeft.y >= boundsTopLeft.y &&
    viewportBottomRight.x <= boundsBottomRight.x &&
    viewportBottomRight.y <= boundsBottomRight.y
  );
};
