export const getNextSection = (currentSection) => {
  switch (currentSection) {
    case 'frequentlyUsed':
      return 'cabana';
    case 'cabana':
      return 'microsoft';
    case 'microsoft':
      return 'frequentlyUsed';
    default:
      return 'frequentlyUsed';
  }
};

export const getPreviousSection = (currentSection) => {
  switch (currentSection) {
    case 'frequentlyUsed':
      return 'microsoft';
    case 'cabana':
      return 'frequentlyUsed';
    case 'microsoft':
      return 'cabana';
    default:
      return 'frequentlyUsed';
  }
};