import { proxy, subscribe, ref } from 'valtio';
import { ImageUpload } from './types';
import { currentMapContentStore } from './mapStore';
type UploadImagesStore = {
  uploadData: {
    imageUploadData: ImageUpload[];
    mapId: string;
  };
};
export type WorkerAction =
  | 'update valtio'
  | 'replace url'
  | 'terminate'
  | 'update LastInteraction';

const initialState: ImageUpload[] = [];
export const uploadImageStore = proxy<UploadImagesStore>({
  uploadData: {
    imageUploadData: initialState,
    mapId: '',
  },
});

//************actions************

const subscriber = subscribe(uploadImageStore, () => {
  initiateUploadProcess(
    uploadImageStore.uploadData.mapId,
    uploadImageStore.uploadData.imageUploadData
  );
});

async function initiateUploadProcess(
  mapID: string,
  imageUploadData: ImageUpload[]
) {
  if (mapID === '' || imageUploadData.length === 0) return;
  const imagesArray = JSON.stringify(imageUploadData);

  const uploadImagesWorker = new Worker(
    new URL('utils/workers/uploadImagesWorker.ts', import.meta.url)
  );
  // upload a cloned version of the csaass
  uploadImagesWorker.postMessage({
    imagesToUpload: imagesArray,
    mapID,
  });
  // reducer function that handles messages from the worker object.
  uploadImagesWorker.onmessage = e => {
    const { action, data } = e.data;
    processWorkerAction(action, mapID, uploadImagesWorker, data);
  };

  // terminate worker if any error occurs within
  uploadImagesWorker.onerror = () => {
    uploadImagesWorker.terminate();
  };
}

const getStacks = async (
  retryCount: number,
  workerObject: Worker,
  mapID: string
) => {
  // this ensures the mapData has been added to the valtio store.
  // avoid getting a reference error when trying to replace  item image src
  const getStackData = JSON.stringify(
    currentMapContentStore[mapID].value.stacks
  );
  // recursively calls itself
  // but terminates at after three attempts.
  if (retryCount > 3) return;
  if (!getStackData) {
    setTimeout(() => {
      getStacks(retryCount++, workerObject, mapID);
    }, 1000);
  }
  workerObject.postMessage({
    stacks: getStackData,
  });
};

const handleStackIDLastInteraction = (
  mapID: string,
  stackIDs: string[],
  workerObject: Worker
) => {
  // schedule the function in the eventLoop
  // this would let ui blocking functions or critical process to run if any
  // between each lastSync update process

  stackIDs.forEach((id, index) => {
    setTimeout(() => {
      // updateLastInteraction(id, mapID);
    }, 100 * index);
  });
  workerObject.postMessage({
    terminate: true,
  });
};

const processWorkerAction = (
  action: WorkerAction,
  mapID: string,
  workerObject: Worker,
  data: any
) => {
  switch (action) {
    case 'replace url':
      getStacks(0, workerObject, mapID);
      // get stack value to pass to the web worker
      break;
    case 'update valtio':
      // get updated stack value
      // this is neccessary in situation where the user is
      // current edditing the mapData while the image url is uplading blobs
      currentMapContentStore[mapID].value.stacks = data;
      workerObject.postMessage({
        updateLastSync: true,
      });

      break;

    case 'update LastInteraction':
      handleStackIDLastInteraction(mapID, data, workerObject);

      break;
    case 'terminate':
      workerObject.terminate();
      break;
    default:
      return;
  }
};
