import React, { useRef, useEffect, useState } from 'react';
import { Style } from 'utils/stores/types';

export function getTextWidth(text, divStyle: Style) {
  // Create a temporary span element
  const span = document.createElement('span');

  // Copy the text content to the span
  span.textContent = text;

  // Apply the div's font styles to the span
  // span.style.font = divStyle.font;
  span.style.fontSize = `${divStyle.fontSize}px`;
  span.style.fontWeight =
    divStyle.formattingOfTheEntireNote?.isBold === true ? 'bold' : 'normal';
  span.style.fontStyle = divStyle.formattingOfTheEntireNote?.isItalic
    ? 'italic'
    : 'normal';

  // Append the span to the body (it needs to be in the DOM to measure)
  document.body.appendChild(span);

  // Get the width of the span
  const width = span.offsetWidth;

  // Remove the span from the DOM
  document.body.removeChild(span);

  return width;
}

interface MapTextSearchLabelProps {
  text: string;
  colors?: {
    offset: string;
    color: string;
  }[]; // ! visit type here
}

export const resultColors = [
  {
    background: [
      {
        offset: '3%',
        color: 'hsla(227, 74%, 30%, 0.5)',
      },
      {
        offset: '23%',
        color: 'hsla(229, 61%, 31%, 0.5)',
      },
      {
        offset: '100%',
        color: 'hsla(226, 73%, 19%, 1)',
      },
    ],
    textColor: '#4D6EDF',
  },
  {
    background: [
      {
        offset: '3%',
        color: 'hsla(270, 70%, 32%, 0.35)',
      },
      {
        offset: '25%',
        color: 'hsla(270, 71%, 23%, 0.7)',
      },
      {
        offset: '54%',
        color: 'hsla(270, 71%, 19%, 0.7)',
      },
      {
        offset: '100%',
        color: 'hsla(268, 75%, 13%, 1)',
      },
    ],
    textColor: '#7037A9',
  },
  {
    background: [
      {
        offset: '3%',
        color: 'hsla(351, 61%, 29%, 0.5)',
      },
      {
        offset: '23%',
        color: 'hsla(351, 61%, 29%, 0.5)',
      },
      {
        offset: '100%',
        color: 'hsla(358, 100%, 12%, 1)',
      },
    ],
    textColor: '#BC5867',
  },

  {
    background: [
      {
        offset: '3%',
        color: 'hsla(29, 76%, 36%, 0.45)',
      },
      {
        offset: '100%',
        color: 'hsla(29, 82%, 24%, 0.6)',
      },
    ],
    textColor: '#EC8525',
  },

  {
    background: [
      {
        offset: '3%',
        color: 'hsla(43, 94%, 61%, 0.2)',
      },
      {
        offset: '23%',
        color: 'hsla(43, 62%, 36%, 0.5)',
      },
      {
        offset: '100%',
        color: 'hsla(43, 65%, 17%, 1)',
      },
    ],
    textColor: '#FFB800',
  },

  {
    background: [
      {
        offset: '0%',
        color: 'hsla(133, 80%, 28%, 0.3)',
      },
      {
        offset: '0%',
        color: 'hsla(133, 80%, 25%, 0.3)',
      },
      {
        offset: '16%',
        color: 'hsla(133, 81%, 21%, 0.4)',
      },
      {
        offset: '100%',
        color: 'hsla(133, 86%, 11%, 1)',
      },
    ],
    textColor: '#13A734',
  },
];

const MapTextSearchLabel: React.FC<MapTextSearchLabelProps> = ({
  text,
  colors,
}) => {
  const [svgHeight, setSvgHeight] = useState(0);
  const textRef = useRef<HTMLDivElement>(null);

  const uniqueId = `grad-${Math.random().toString(36).substr(2, 9)}`;

  useEffect(() => {
    if (textRef.current) {
      setSvgHeight(textRef.current.offsetHeight + 3);
    }
  }, [text]);

  return (
    <div
      className="relative"
      style={{ height: svgHeight, marginBottom: '-2px' }}
    >
      <svg
        style={{
          width: getTextWidth(text, {}) + 12,
          maxWidth: '240px',
        }}
        height={svgHeight}
        viewBox={`0 0 61 12`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
      >
        <defs>
          <linearGradient id={uniqueId} x1="0%" y1="100%" x2="0%" y2="0%">
            {colors.map((item, idx) => {
              return (
                <stop
                  key={idx}
                  offset={item.offset}
                  style={{ stopColor: `${item.color}`, stopOpacity: 1 }}
                />
              );
            })}
          </linearGradient>
        </defs>
        <path
          d="M58.8777 5.28523C58.8777 2.6674 56.7556 0.545227 54.1377 0.545227H6.01733C3.3995 0.545227 1.27733 2.66739 1.27733 5.28522V9.11304C1.26277 10.5168 0.997342 11.1974 0 11.1974H6.01733H54.1377H60.4472C59.2795 11.1974 58.9462 10.3538 58.8777 9.11304V5.28523Z"
          fill={`url(#${uniqueId})`}
        />
      </svg>
      <div
        ref={textRef}
        style={{
          position: 'absolute',
          textWrap: 'nowrap',
          width: getTextWidth(text, {}),
          maxWidth: '220px',
          textAlign: 'center',
          padding: '3px 0px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
        className="left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2"
      >
        {text}
      </div>
    </div>
  );
};

export default MapTextSearchLabel;
