// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import { HTMLElementRefOf } from '@plasmicapp/react-web';
import DropdownItem from 'PlasmicComponents/DropdownItem';
import * as React from 'react';
import { FolderData } from 'utils/stores/types';
import PlasmicDropdownItemGroup, {
  DefaultDropdownItemGroupProps,
} from './plasmic/map_map/PlasmicDropdownItemGroup';

export interface DropdownItemGroupProps extends DefaultDropdownItemGroupProps {
  folder: FolderData;
  forceShowChildren?: boolean;
  parentLevel?: number;
  isFromQuickAccess?: boolean;
  parentColumn?: number;
  expandedFolderGroups?: string[];
  setExpandedFolderGroups?: React.Dispatch<React.SetStateAction<string[]>>;
}

function DropdownItemGroup_(
  {
    folder,
    forceShowChildren,
    parentLevel,
    body,
    isFromQuickAccess,
    parentColumn,
    expandedFolderGroups,
    setExpandedFolderGroups,
    ...props
  }: DropdownItemGroupProps,
  ref: HTMLElementRefOf<'div'>
) {
  const [expand, setExpand] = React.useState(false);
  const level = props?.level ? parseInt(props?.level.split('_')[1]) : 0;

  const openExpandedPortal = () => {
    setExpand(!expand);

    if (expand) {
      // start removing this item from expandedFolderGroups
      const folderIdx = expandedFolderGroups.findIndex(
        item => item === folder.folderId
      );
      if (folderIdx) {
        const filteredGroups = expandedFolderGroups.filter(
          (_, i) => i < folderIdx
        );
        setExpandedFolderGroups(filteredGroups);
      }
    } else {
      // add folder into expand list
      const newGroup = [...expandedFolderGroups, folder.folderId];
      setExpandedFolderGroups(newGroup);
    }
  };

  function hiddenItems(passedFolder: FolderData) {
    const result = {
      hideParent: false,
      hideThis: false,
      hideChild: false,
      hiddenItemsAvailable: false,
      shouldRemoveLeftPad: false,
      shouldShowEllipsis: false,
    };

    if (!passedFolder || !expandedFolderGroups) return result;
    // deciding whether or not to hide this folder
    const folderIdx = (expandedFolderGroups || []).findIndex(
      item => item === passedFolder.folderId
    );

    console.log(expandedFolderGroups);

    result.hideParent =
      (expandedFolderGroups || []).includes(passedFolder?.folderId) &&
      folderIdx > 0 &&
      folderIdx <= expandedFolderGroups.length - 4;

    result.hideThis =
      (expandedFolderGroups || []).includes(passedFolder?.folderId) &&
      folderIdx > 0 &&
      folderIdx <= expandedFolderGroups.length - 3;

    result.hideChild =
      (expandedFolderGroups || []).includes(passedFolder?.folderId) &&
      folderIdx > 0 &&
      folderIdx <= expandedFolderGroups.length - 2;

    // checking if any items are hidden
    result.hiddenItemsAvailable = expandedFolderGroups?.length > 3;

    result.shouldRemoveLeftPad =
      expandedFolderGroups.length > 3 &&
      folderIdx > expandedFolderGroups.length - 3;

    result.shouldShowEllipsis =
      expandedFolderGroups.includes(passedFolder.folderId) &&
      expandedFolderGroups.length > 3 &&
      level === 1;

    return result;
  }

  const leftPosition = ({ removeLeftPad = false }) => {
    if (isFromQuickAccess && expand) {
      const columnsContainer = document.getElementById('quick-access-modal');

      const singleColumnWidth = Array.from(
        columnsContainer.children
      )[0].getBoundingClientRect().width;

      const gap = (level + parentColumn) * 24;

      const position = (level + parentColumn) * (singleColumnWidth / 2) + gap;

      return position;
    }

    const leftPad = level < 2 ? level * 120 : 110;

    return leftPad;
  };

  const topPosition = () => {
    const columnsContainer =
      document.getElementById('quick-access-modal') ||
      document.getElementById('folder-search-modal');

    const modal = columnsContainer.getBoundingClientRect();

    const itemElement = document.getElementById(`folder-${folder.user_id}`);

    const itemPosition = itemElement.getBoundingClientRect();

    const itemParent =
      itemElement.parentElement.parentElement.parentElement.parentElement;

    const parent = itemParent.getBoundingClientRect();

    let value;

    if (isFromQuickAccess) {
      value = itemPosition.y - modal.y - itemPosition.height; //  might need to add/minus 8px for modal padding

      return value;
    }

    value = 0;

    return value;
  };

  React.useEffect(() => {
    setExpand(forceShowChildren);
  }, [forceShowChildren]);

  function returnDropdownItemGroup({ isInSidebar }) {
    return (
      <PlasmicDropdownItemGroup
        isInSidebar={isInSidebar}
        id={`dropdownItemGroup-${folder?.folderId}`}
        style={
          {
            // background: hiddenItems(folder).hideThis
            //   ? 'transparent'
            //   : level >= 1 && expand
            //   ? 'grey'
            //   : 'inherit',
          }
        }
        head={
          <div
            style={{
              position: 'relative',
              opacity: !isInSidebar && hiddenItems(folder).hideThis ? 0 : 1,
              width: !isInSidebar && hiddenItems(folder).hideThis ? 0 : 'auto',
              height: !isInSidebar && hiddenItems(folder).hideThis ? 0 : 'auto',
              overflow: 'hidden',
              // background: hiddenItems(folder).hideThis ? 'red' : undefined,
              // background: 'red',
            }}
            onClick={() => {
              if (folder?.subfolders?.length) openExpandedPortal();
            }}
          >
            <DropdownItem
              style={{
                opacity:
                  !isInSidebar && hiddenItems(folder).hideThis ? 0 : undefined,
                background: hiddenItems(folder).hideThis
                  ? 'transparent'
                  : undefined,
              }}
              name={`${folder?.name} ${'' || expand}`}
              isExpanding={false}
              expansionIcon={!folder?.subfolders?.length ? `hidden` : 'right'}
            />
            {hiddenItems(folder).shouldShowEllipsis && isInSidebar && (
              <div
                style={{
                  position: 'absolute',
                  left: `105%`,
                  top: -3,
                  zIndex: 99,
                  backgroundColor: 'grey',
                  color: 'white',
                  padding: 10,
                  width: 30,
                  borderRadius: 10,
                }}
              >
                ...
              </div>
            )}
          </div>
        }
        isNotExpanded={isInSidebar ? (level <= 0 ? !expand : true) : false}
        root={{ ref }}
        body={
          <div
            style={
              {
                // background: hiddenItems(folder).hideThis ? 'red' : undefined,
                // opacity: hiddenItems(folder).hideThis ? 0 : undefined,
              }
            }
          >
            {level > 0 && isInSidebar // || hiddenItems(folder).hideThis
              ? null
              : body}
          </div>
        }
        {...props}
      />
    );
  }

  return (
    <div className="relative">
      {(!(level <= 1) && !expand && !hiddenItems(folder).hideThis) ||
        (level <= 1 && (
          <div id={`folder-${folder?.user_id}`}>
            {returnDropdownItemGroup({
              isInSidebar: true,
            })}
          </div>
        ))}
      {
        expand && level > 0 && (
          // createPortal(
          <div
            id={`folder-${folder.user_id}`}
            style={{
              position: 'absolute',
              left: `${leftPosition({
                removeLeftPad: hiddenItems(folder).shouldRemoveLeftPad,
              })}%`,
              top: topPosition(),
              zIndex: 99,
              // opacity: hiddenItems(folder).hideThis ? 0 : 1,
              // width: hiddenItems(folder).hideThis ? 0 : 'auto',
              // overflow: hiddenItems(folder).hideThis ? 'hidden' : 'visible',
            }}
          >
            {returnDropdownItemGroup({
              isInSidebar: false,
            })}
          </div>
        )
        // ,document.getElementById(`dropdownItemGroup-${folder?.folderId}`))
      }
    </div>
  );
}

const DropdownItemGroup = React.forwardRef(DropdownItemGroup_);
export default DropdownItemGroup;
