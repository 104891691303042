import {
  CheckCircleFilled,
  CaretLeftOutlined,
  CaretRightOutlined,
} from '@ant-design/icons';
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import useESC from 'utils/hooks/hotKeys/useESC';
import {
  currentMapStateStore,
  updateItemOrAreaStyle,
} from 'utils/stores/mapStore';
import { BorderWeight, Style } from 'utils/stores/types';

import { getArea } from 'utils/mapStoreFN/mapStoreFN_areas';
import {
  getStackAndItem,
  getParentIdFromItemID,
} from 'utils/mapStoreFN/mapStoreFN_stacks';
import '../css/plasmicStyles.css';
import { getPreview } from 'utils/mapStoreFN/mapStoreFN_previews';
import { styled } from '@stitches/react';
import { cBorderWeight } from 'utils/stores/constants';
import { anchorMode } from 'utils/stores/mapMode/modesStore';
import getSelectedIDs from 'utils/helpers/Selection/selectionUtils';

const borderWeights: BorderWeight[] = ['_0', '_1', '_2', '_3', '_4', '_5'];

const getCurrentlyAppliedBorder = (
  ID: string,
  mapId: string
): Style | undefined => {
  if (!ID || typeof ID !== 'string') return;

  if (ID.includes('area_')) {
    const selectedArea = getArea(ID, mapId);
    return selectedArea?.style;
  }
  if (ID.includes('item_')) {
    const stackId = getParentIdFromItemID(ID);

    if (stackId) {
      // get itemdata
      const { itemData, stackData } = getStackAndItem(ID, mapId);
      if (!stackData) return {};

      return itemData?.style;
    }
  }
  if (ID.includes('preview_')) {
    const selectedPreview = getPreview(ID);
    return selectedPreview?.style;
  }
};

type BorderLogicProps = {
  renderChild: (props: RenderBorderWeightProps) => ReactNode;
  ID: string;
  mapID: string;
};

// border ui

const BorderWeights = styled('div', {
  width: '100%',
  borderColor: '#fff',
  height: '1px',

  variants: {
    BorderWeight: {
      _0: {
        display: 'none',
      },
      _1: {
        borderTopWidth: '0.5px',
      },
      _2: {
        borderTopWidth: '1px',
      },
      _3: {
        borderTopWidth: '2px',
      },
      _4: {
        borderTopWidth: '3px',
      },
      _5: {
        borderTopWidth: '4px',
      },
    },
  },
});

// Handle Hover and current selected border Level

const SelectBorder = styled('div', {
  width: '100%',
  padding: '8px 20px',
  margin: '2px 0px',
  borderRadius: '4px',
  display: 'flex',
  position: 'relative',

  alignItems: 'center',
  transition: 'background-color 0.3s ease-in',
  color: '#FAF9F6',
  '&:hover': {
    backgroundColor: '#A52A2A',
  },
  variants: {
    selected: {
      true: {
        backgroundColor: '#A52A2A',
      },
    },
    noBorder: {
      true: {
        '& p': {
          fontSize: '12px',
          width: '100%',
          padding: '0px',
          margin: '-7px 0px',
          // textAlign: 'start',
          textTransform: 'capitalize',
        },
        '& svg': {
          top: '0.8em',
        },
      },
      false: {
        '& p': {
          color: 'blue',
          display: 'none',
        },
      },
    },
    isCurrentWeight: {
      true: {
        '& svg': {
          fontSize: '8px',

          marginRight: '4px',
          position: 'absolute',
          left: '8%',
          top: '20%',
        },
      },
      false: {
        '& svg': {
          display: 'none',
        },
      },
    },
  },
});

const BorderLogic = ({ renderChild, ID, mapID }: BorderLogicProps) => {
  const targeContent = useMemo(
    () => getCurrentlyAppliedBorder(ID, mapID),
    [ID]
  );

  /**
   *Call the Child Prop and pass it the required Args
   */

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      {renderChild({
        currentContentBorderWeight: targeContent?.border?.thickness || '_0',
        ID,
        mapID,
      })}
    </div>
  );
};

type RenderBorderWeightProps = {
  currentContentBorderWeight: BorderWeight;
  ID: string;
  mapID: string;
};

export function RenderBorderWeights(props: RenderBorderWeightProps) {
  /**
   ** REASON
   * this Abtracts the portion that renders all the time ( Re-render with every keyup or key down)
   * Idea is to reduce the the number of times getCurrentlyAppliedBorder function runs
   * As the user moves through the available borderWeights
   */
  const [currentSelected, setCurrentSelected] = useState<BorderWeight>('_0');
  const { dispatch } = useESC();
  const focusRef = useRef(null);
  const [, setfocus] = useState({});

  // set focus on the colorSelector
  useEffect(() => {
    focusRef.current && focusRef.current.focus();
  }, [props.ID]);

  const handleSelection = (Action: 'Next' | 'Prev') => {
    switch (Action) {
      case 'Next': {
        const NextBorderWeight =
          cBorderWeight[1 + Number(currentSelected.replace('_', ''))];
        if (NextBorderWeight) {
          setCurrentSelected(NextBorderWeight);
        } else {
          setCurrentSelected('_0');
        }

        break;
      }
      case 'Prev': {
        const getCurrentPosition = Number(currentSelected.replace('_', '')) - 1;
        const prevBorderWeight = cBorderWeight[getCurrentPosition];

        if (prevBorderWeight) {
          setCurrentSelected(prevBorderWeight);
        } else {
          setCurrentSelected('_5');
        }
        break;
      }
    }
  };

  const applyBorderWeight = (ID?: string, borderWeight?: BorderWeight) => {
    const selected = getSelectedIDs();
    for (let i = 0; i < selected.length; i++) {
      updateItemOrAreaStyle(
        selected[i],
        {
          border: {
            thickness: borderWeight || currentSelected,
          },
        },
        props.mapID,
        undefined,
        {
          path: 'border.thickness',
          value: borderWeight || currentSelected,
        }
      );
    }

    anchorMode.reset();
  };
  const handleEsc = () => {
    const BorderThicknessFN = dispatch('thickness selector');
    BorderThicknessFN(props.mapID);
  };
  return (
    <div
      style={{
        width: '120px',
        padding: '2px 4px',
        borderRadius: '6px',
        position: 'relative',
        border: '1px solid',
        borderColor: '#707070',
        backgroundColor: 'rgba(255, 255, 255, 0.3)', // Lighter semi-transparent white
        backdropFilter: 'blur(5px)',
      }}
      onClick={e => {
        e.stopPropagation();
        setfocus({}); // force focus back to component
      }}
    >
      <button
        ref={focusRef}
        onKeyDown={e => {
          if (e.code === 'Escape') {
            e.stopPropagation();
            handleEsc();
          }
          if (e.code === 'ArrowDown') {
            e.stopPropagation();
            handleSelection('Next');
          }
          if (e.code === 'ArrowUp') {
            e.stopPropagation();

            handleSelection('Prev');
          }
          if (e.code === 'Enter') {
            e.stopPropagation();
            if (currentSelected !== props.currentContentBorderWeight) {
              applyBorderWeight();
            }
          }
        }} // handle keyboard event and setting focus to the BorderThicknessSelector
        style={{
          position: 'fixed',
          background: 'none',
          outline: 'none',
        }}
      />
      {borderWeights.map(borderweight => (
        <SelectBorder
          selected={currentSelected === borderweight}
          isCurrentWeight={borderweight === props.currentContentBorderWeight}
          noBorder={borderweight === '_0'}
          key={borderweight}
          onClick={e => {
            e.stopPropagation();
            applyBorderWeight(props.ID, borderweight);
          }}
        >
          <CheckCircleFilled />
          <p> no border</p>
          <BorderWeights BorderWeight={borderweight} key={borderweight} />
        </SelectBorder>
      ))}
    </div>
  );
}

export function BorderThicknessSelector({
  lastSelectedID,
  anchorPosition,
  renderPosition,
  mapId,
}: any) {
  return (
    <div
      id={'Border-selector'}
      style={{
        position: 'fixed',
        left: `${anchorPosition.x}px`,
        top: `${anchorPosition.midY || anchorPosition.y}px`,

        zIndex: '300',
        transform: `${
          renderPosition == 'Left'
            ? 'translate(-105%, -50%)'
            : 'translate(5%, -50%)'
        }`,
        transition: 'left 0.2s linear, top 0.2s linear',
      }}
      className="flex items-center con"
    >
      {renderPosition === 'right' && (
        <CaretLeftOutlined
          style={{
            color: '#332F30',
            margin: '-4px',
          }}
        />
      )}
      <BorderLogic
        renderChild={props => <RenderBorderWeights {...props} />}
        mapID={mapId}
        ID={lastSelectedID}
      />
      {renderPosition === 'Left' && (
        <CaretRightOutlined
          style={{
            color: '#332F30',
            margin: '-4px',
          }}
        />
      )}
    </div>
  );
}
