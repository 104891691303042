import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ImageItemData, NoteItemData } from '../../utils/stores/types';

import Border from 'components/Border/Border';

import { CSSProperties } from '@stitches/react';
import { Matrix } from 'CoreComponents/Canvas/InfiniteCanvas';
import { Resize } from 'CoreComponents/resize/resizable';
import { createEditor } from 'slate';
import { withHistory } from 'slate-history';
import {
  Editable,
  RenderElementProps,
  RenderLeafProps,
  Slate,
  withReact,
} from 'slate-react';
import { getScaleFromMatrix } from 'utils/helpers/Canvas/clamp-zoom';
import { currentMapStateStore } from 'utils/stores/mapStore';
import { themeStore } from 'utils/stores/themeStore';

import { Elements, Leaf } from 'CoreComponents/SlateEditor/slateComponent';
import { StyleShortcut } from 'components/themeAndStyleSettings/style/StyleSection';
import { cNoteConfig, imageTag } from 'utils/stores/constants';

// keeping this for now because it's used in areaPreview
// would remove as soon as the areaPreview is reimplemented.

export type ItemProps = {
  item: NoteItemData | ImageItemData;
  dimension?: {
    width: number;
    height: number;
  };
  selected?: boolean;
};

const ItemPreview = ({ item, dimension, selected }: ItemProps) => {
  const mapId = useParams().mapId!;

  const editor = useMemo(() => withHistory(withReact(createEditor())), []);

  const canvaState = currentMapStateStore[mapId].canvas;

  const zoomLevel = getScaleFromMatrix(new Matrix(canvaState.CanvasTransform));

  const renderElement = useCallback(
    (props: RenderElementProps) => <Elements {...props} />,
    []
  );

  const renderLeaf = useCallback(
    (props: RenderLeafProps) => <Leaf {...props} />,
    []
  );
  const applyStyle: CSSProperties = {
    width: ` ${dimension.width * zoomLevel}px`,
    height: `${dimension.height * zoomLevel}px`,
    display: 'grid',
    placeContent: 'center',
    scale: `${zoomLevel}`,
    textAlign: item.style?.textAlignment,
  };

  // improve rerendering cache return value
  const renderItem = useMemo(
    () => (
      <div style={applyStyle}>
        <Resize
          TargetComponent={item}
          parentID="preview"
          editingState="no"
          mapID="ID"
          type="Note"
        >
          <Border
            isSelected={selected || false}
            style={{
              ...item.style,
            }}
            isImage={(item as NoteItemData).content.textContent === imageTag}
            type="Item"
          >
            <Slate
              initialValue={(item as NoteItemData)?.content?.slateContent}
              // editor={(editor?.children > 0 && Editor.string(editor, [])) ?? ""}
              editor={editor}
            >
              <Editable
                readOnly={true}
                style={{
                  color: item?.style?.textColor || themeStore.defaultColor,
                  fontWeight: item?.style?.formattingOfTheEntireNote?.isBold
                    ? 'bold'
                    : 'initial',
                  fontStyle: item?.style?.formattingOfTheEntireNote?.isItalic
                    ? 'italic'
                    : 'normal',
                  textDecoration: item?.style?.formattingOfTheEntireNote
                    ?.isUnderlined
                    ? 'underline'
                    : '',
                  userSelect: 'none',
                  borderRadius: (item as NoteItemData)?.style?.border?.isSquared
                    ? '0px'
                    : '1em',

                  width: '100%',
                  fontSize: `${item?.style?.fontSize}px`,
                }}
                renderLeaf={props => renderLeaf(props)}
                renderElement={props => renderElement(props)}
              />
              {/* </div> */}
            </Slate>
          </Border>
        </Resize>
      </div>
    ),
    []
  );

  return renderItem;
};
export default ItemPreview;

export function PseudoItemPreview({ item }: { item: StyleShortcut }) {
  const stylesObject = JSON.parse(item.styleObject);
  return (
    <div className="grid">
      <Border
        isSelected={false}
        style={{
          ...stylesObject,
        }}
        type="Item"
      >
        <div
          style={{
            padding: '6px',
            width: 'fit-content',
            maxWidth: `${cNoteConfig.MINWIDTH}px`,
            maxHeight: '110px',
            color: stylesObject?.textColor || themeStore.defaultColor,
            fontWeight: stylesObject?.formattingOfTheEntireNote?.isBold
              ? 'bold'
              : 'initial',
            fontStyle: stylesObject?.formattingOfTheEntireNote?.isItalic
              ? 'italic'
              : 'normal',
            textDecoration: stylesObject?.formattingOfTheEntireNote
              ?.isUnderlined
              ? 'underline'
              : '',
            userSelect: 'none',
            borderRadius: stylesObject?.border?.isSquared ? '0px' : '0.7em',

            fontSize: `${stylesObject?.fontSize}px`,
            background:
              stylesObject?.fillColor || themeStore.canvasBackgroundColor,
          }}
          className=" overflow-clip overflow-ellipsis"
        >
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga, enim
          earum sapiente similique
        </div>
      </Border>
    </div>
  );
}

type ItemPreviewCloneProps = {
  node: HTMLElement;
  mapID: string;
  dimention: {
    width: number;
    height: number;
  };
};

/**
 * Renders a HTMLElement directly using dangerouslySetInnerHTML.
 *
 *
 *
 * @param node - the item Ref  that is being dragged expecting a HTMLElement
 * @param mapID - current map ID
 * @param dimention  - note rendered width and height on the DOM.
 */

export function ItemPreviewWithNode(props: ItemPreviewCloneProps) {
  const canvaState = currentMapStateStore[props.mapID].canvas;

  // console;

  const zoomLevel = getScaleFromMatrix(new Matrix(canvaState.CanvasTransform));
  const cloneNode = useMemo(() => {
    const clone = props.node.cloneNode(true) as HTMLElement;
    clone.style.opacity = '1';

    const resizableChild = clone.querySelector('.resizable');

    (resizableChild as HTMLElement).style.width =
      `${props.dimention.width + 1}px`; // this accounts for dimention offset

    return clone;
  }, []);
  const applyStyle: CSSProperties = {
    width: ` ${props.dimention.width * zoomLevel}px`,
    height: `${props.dimention.height * zoomLevel}px`,
    scale: `${zoomLevel}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <div
      style={applyStyle}
      dangerouslySetInnerHTML={{ __html: cloneNode.outerHTML }}
    />
  );
}
