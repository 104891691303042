import { hotKeyStateStore } from '../hotKeyStatesStore';
import {
  AreaData,
  ConnectionData,
  ItemData,
  ParentType,
  PreviewData,
  StackData,
} from '../types';
import { proxy } from 'valtio';
import { gestureStore } from '../gestureStore';
import { proxyMap } from 'valtio/utils';

type Parent = {
  ID: string;
  proxy: StackData | AreaData | ConnectionData;
  ref?: HTMLDivElement | HTMLElement;
  type: ParentType;
};

export type SelectedContentType = 'Note' | 'Area' | 'Preview' | 'connection';

type SelectableTypes = AreaData | ItemData | PreviewData;

export type SelectedContent = {
  contentID: string;
  type: SelectedContentType;
  parentType: ParentType;
  parentID: string;
};
export type SelectedContentV2 = {
  contentID: string;
  type: SelectedContentType;
  selectedContentProxy: SelectableTypes;
  selectedContentReadOnly: Readonly<SelectableTypes>;
  contentRef: HTMLDivElement | HTMLElement;
  parent: Parent | null;
  setterFunction: React.Dispatch<React.SetStateAction<boolean>>;
};

/**
 * Holds selected contents and makes them available for mutations
 *
 *
 *
 *
 */

class SelectedContentManager {
  selected: Map<String, SelectedContent>;
  multiSelectMode: boolean;

  constructor() {
    this.selected = proxyMap();
    this.multiSelectMode = false;
  }
  reset() {
    this.selected.clear();
  }
  /**
   * Adds the selected content accounting for single selection or multiple selection with mod key pressed.
   * @param selectedContent - The selected content.
   * when performing multiple selection with mod key
   *
   * @returns void
   */

  select(selectedContent: SelectedContent, singleSelected = true) {
    const multipleInsert =
      gestureStore.Dragging.isMultiDrag ||
      hotKeyStateStore.current.isModActive ||
      this.multiSelectMode;

    if (!gestureStore.isEditing && (!singleSelected || multipleInsert)) {
      this.selected.set(selectedContent.contentID, selectedContent);

      return;
    }
    this.unselect();
    this.selected.set(selectedContent.contentID, selectedContent);
  }

  /**
   * This unselects and removes selected content from the selected array
   * unslectes the selectedContent if called with an id otherwiser unslects all.
   *
   * @param id id of content to be removed
   */

  unselect(id?: string) {
    if (id) {
      this.selected.delete(id);
    }

    if (!id && this.getLength() === 0) return;

    if (!id) {
      this.reset();
    }
  }
  /**
   * this returns an  containing selected the specified content type
   * the default is All is no specified type given
   *
   *
   * @param contentType
   *
   *
   * - Arra: returns an array containg only areas if area or an empty array
   * - Notes: returns an array containing selected notes or an empty array
   * - Preview: returns an array containing previews or an empty array
   *
   *
   * @param targeKey : if passed it returns the property value for the key or
   * the entire object if nothing is passed
   *
   *
   * @param filterSelectedID: id of content you dont want to include to the returned array
   *
   *
   * @returns Object selectedContents - An array of selected content objects.
   * - {string[]} querriedContentIDs[] - The IDs of the content .
   * - {SelectedContentType[]} querriedContent[] - object of the selected contents.
   *
   */

  getAllSelected(
    contentType?: SelectedContentType,
    targetKey?: keyof SelectedContent,
    filterSelectedID?: string
  ) {
    const querriedContentIDs: string[] = [];
    const queriedSelectedContent: SelectedContent[] = [];
    this.selected.forEach(selectedContent => {
      if (selectedContent.contentID !== filterSelectedID) {
        querriedContentIDs.push(selectedContent.contentID);
        queriedSelectedContent.push(selectedContent);
      }
    });

    return {
      querriedContentIDs,
      queriedSelectedContent,
    };
  }

  /**
   * Allows for granular interaction and retrieval of  with selected content using the id
   *
   * @param ID id of selected content you want to retrieve for
   *
   * @return returns the content with the ID or null if no selected content has the ID
   */

  getSelectedContentWithID(ID: string) {
    const getSelectedContent = this.selected.get(ID);

    return getSelectedContent || null;
  }
  /**
   * Returns the number of selected content
   * useful when you want to allow note to go into editing mode
   */
  getLength() {
    const length = this.selected.size;
    return length || 0;
  }
  /**
   *
   * @param ID id of the item to be added
   * @returns boolean
   */

  isSelected(ID: string) {
    let isSelected;
    if (this.selected.size === 0) return false;
    isSelected = this.selected.get(ID);
    return !!isSelected;
  }
  /**
   * this is usefull when an operation that removes the content
   * - Delecte function
   * - drag and drop
   * - cut
   *
   *
   * calling without an array of content id removes everything
   * this doesn't unselect if you want to perform an unslection use
   * @method unselect
   *
   * @param {array}ID : id of the content that should be removed
   */

  removeStaleValues(IDs?: string[]) {
    if (!IDs) {
      this.selected.clear();
    }
    IDs.forEach(ID => this.selected.delete(ID));
  }

  /**
   * this selects all contents on the map
   * it targets all content with className selectable
   */

  selectAll() {
    const getAllselectableContent = document.querySelectorAll('.selectable');

    for (let i = 0; i < getAllselectableContent.length; i++) {
      const node = getAllselectableContent[i];
      node && (node as HTMLElement).click();
    }
  }
  /**
   * This allows the selection store to switch to multiple selection
   * mode , toggle is on if you want to keep adding contents to the selected
   * without removing older ones. Note rem
   * @param value a boolean to either set is active or inactive
   * @return void
   */
  activateMultipleSelect() {
    this.multiSelectMode = true;
  }

  /**
   * Deactivates the multiple select mode
   * @return void
   */
  deactivateMultipleSelect() {
    this.multiSelectMode = false;
  }
}

const selectedContentsStore = proxy(new SelectedContentManager());

export default selectedContentsStore;
