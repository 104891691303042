import * as React from "react";
import {
  PlasmicDashboardOverview,
  DefaultDashboardOverviewProps
} from "./plasmic/map_map/PlasmicDashboardOverview";
import { HTMLElementRefOf } from "@plasmicapp/react-web";

export interface DashboardOverviewProps extends DefaultDashboardOverviewProps {}

function DashboardOverview_(
  props: DashboardOverviewProps,
  ref: HTMLElementRefOf<"div">
) {

  return <PlasmicDashboardOverview root={{ ref }} {...props} />;
}

const DashboardOverview = React.forwardRef(DashboardOverview_);
export default DashboardOverview;
