import { TextInputProps } from 'PlasmicComponents/TextInput';
import { forwardRef, LegacyRef } from 'react';

const SimpleTextInput = forwardRef(function SimpleTextInput(
  { isDisabled, placeholder, ...props }: TextInputProps,
  ref: LegacyRef<HTMLInputElement>
) {
  return (
    <input
      disabled={isDisabled}
      placeholder={placeholder ? placeholder : 'Input'}
      style={{
        color: 'white',
        borderWidth: 5,
        borderColor: 'red',
      }}
      ref={ref}
      {...props}
    />
  );
});

export default SimpleTextInput;
