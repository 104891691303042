// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type ArrowsCaretDownIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function ArrowsCaretDownIcon(props: ArrowsCaretDownIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 10 10"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M4.994 6.174a.393.393 0 01-.519.032l-.037-.032L2.08 3.815l-.033-.037-.021-.03-.022-.038-.006-.014-.01-.026-.013-.042-.004-.021-.004-.024-.002-.022v-.047l.002-.022.004-.024.004-.02.012-.043.01-.026.029-.052.025-.035.029-.032.037-.033.03-.021.038-.022.014-.006.026-.01.042-.013.021-.004.024-.004.022-.002h4.74c.335 0 .51.387.308.637l-.03.033-2.358 2.359zm-.278-.834l1.41-1.41h-2.82l1.41 1.41z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default ArrowsCaretDownIcon;
/* prettier-ignore-end */
