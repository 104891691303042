import { useHotkeys } from '@mantine/hooks';
import { ComponentProps, KeyboardEvent, ReactNode, useEffect } from 'react';
import {
  currentMapContentStore,
  currentMapStateStore,
  deleteContent,
} from 'utils/stores/mapStore';

import { useLocation, useParams } from 'react-router-dom';
import { handlePressArrowToUpdateSelected } from '../../utils/helpers/handlePressArrowToUpdateSelected';

import { UngroupOutlined } from '@ant-design/icons';
import useESC from 'utils/hooks/hotKeys/useESC';
import useStackItems from 'utils/hooks/useStackItems';
import { themeStore } from 'utils/stores/themeStore';
import InfiniteCanvas from './InfiniteCanvas';

import {
  useArrowKeysForSelectedItems,
  useBackspaceForDelete,
} from 'constants/hotKeys/mapHotkeys';
import RenderSelecto from 'CoreComponents/Renderers/RenderSelector';
import ActionBarContainer from 'PlasmicComponents/ActionBarContainer';
import { RUNNING_IN_TAURI } from 'propro-common-components';
import detectVisibilityChange from 'utils/helpers/detectVisibilityChange';
import useHotkeysArrangement from 'utils/hooks/hotKeys/useHotkeysArrangement';
import Anchor from 'components/anchor/anchor';
import { BackButton } from 'components/BackButton/BackButton';
import getSelectedIDs from 'utils/helpers/Selection/selectionUtils';
import { useFlag } from '@unleash/proxy-client-react';
import { generalStore } from 'utils/stores/generalStore';

export const Matrix = DOMMatrix ?? WebKitCSSMatrix;

interface CanvasProps {
  selectoRef: React.MutableRefObject<any>;
  children: ReactNode;
  // canDragItem: MutableRefObject<boolean>;
}

const Canvas = ({
  className,
  selectoRef,
  children,
  ...rest
}: ComponentProps<'div'> & CanvasProps) => {
  // use useParams to get the mapId
  const mapId = useParams().mapId!;

  useHotkeysArrangement();
  detectVisibilityChange();

  const isEnabled_mapmap_selectionv2 = useFlag('mapmap_selectionv2');

  const { dispatch } = useESC();

  const { search } = useLocation();
  const actionBar = new URLSearchParams(search).get('actionbar');

  // the DOMMatrix type as a string
  const canvasTransform = currentMapStateStore[mapId].canvas?.CanvasTransform;
  // const canvasTransform = currentMapState.canvas.CanvasTransform;

  // this is for our selected stacking feature
  // mod + alt + s triggers an operation that adds all the items to the stack of the first selected item
  const { contextHolder } = useStackItems(mapId, {
    icon: <UngroupOutlined />,
    backgroundColor: themeStore.canvasBackgroundColor,
    textColor: themeStore.defaultColor,
  });

  useBackspaceForDelete(handlePressBackspace);

  function handlePressBackspace(e: KeyboardEvent) {
    if (getSelectedIDs().length > 0) {
      RUNNING_IN_TAURI && e.preventDefault();
      deleteContent(mapId, {
        deleteAllEncompassingStacksForArea: true,
      });
    }
  }

  // Border Thickness hot keys
  useHotkeys([
    [
      'Escape',
      e => {
        e.stopPropagation();
        e.preventDefault();
        const clearSelectedFN = dispatch('canvas')!;
        clearSelectedFN(mapId);
      },
    ],
    [
      'ArrowUp',
      e => {
        e.stopPropagation();
        e.preventDefault();
        handlePressArrowToUpdateSelected(
          e,
          getSelectedIDs(),
          currentMapContentStore[mapId].value,
          mapId
        );
      },
    ],
    [
      'ArrowDown',
      e => {
        e.stopPropagation();
        e.preventDefault();
        handlePressArrowToUpdateSelected(
          e,
          getSelectedIDs(),
          currentMapContentStore[mapId].value,
          mapId
        );
      },
    ],
  ]);

  useEffect(() => {
    /**
     * update the general store with the mapID
     * and we can slowly move away from the getMapID() function
     */
    generalStore.mapID = mapId;
    generalStore.flags = {
      mapmap_selectionv2: isEnabled_mapmap_selectionv2,
    };
  }, [mapId]);

  return (
    <RenderSelecto selectoRef={selectoRef} mapId={mapId}>
      {!actionBar && <BackButton />}
      <div className="fixed z-[500] bg-red-600  flex h-fit">
        {contextHolder}
      </div>

      <InfiniteCanvas
        selectoRef={selectoRef}
        className={className}
        canvasTransform={canvasTransform}
        {...rest}
      >
        {/* <CustomDragLayer /> */}
        {children}

        <Anchor />
      </InfiniteCanvas>
      {!actionBar && <ActionBarContainer />}
    </RenderSelecto>
  );
};

export default Canvas;
