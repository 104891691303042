// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type DesignImageSearchIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function DesignImageSearchIcon(props: DesignImageSearchIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 24 24"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M18 17a1 1 0 100 2 1 1 0 000-2zm-3 1a3 3 0 115.708 1.293l1 1a1 1 0 01-1.415 1.414l-1-1A3 3 0 0115 18z"
        }
        fill={"currentColor"}
      ></path>

      <path
        d={
          "M9 11a2 2 0 100-4 2 2 0 000 4zm5.91 2.013a.5.5 0 01.237-.005c.01.002.068.015.322.174a1 1 0 101.062-1.695c-.277-.174-.581-.35-.95-.432a2.5 2.5 0 00-1.187.025c-.445.119-.83.396-1.215.671l-.093.067-2.505 1.789c-.48.342-.539.37-.58.382a.5.5 0 01-.212.018c-.042-.006-.105-.023-.635-.28l-.528-.258-.083-.04c-.349-.17-.7-.342-1.08-.4a2.5 2.5 0 00-1 .05c-.373.096-.705.302-1.034.507l-.079.049-1.876 1.16a1 1 0 001.052 1.7l1.876-1.16c.465-.287.521-.309.56-.319a.5.5 0 01.2-.01c.04.006.097.022.589.262l.528.257.09.044c.374.183.75.367 1.159.42a2.5 2.5 0 001.06-.088c.394-.121.735-.365 1.074-.608l.08-.058 2.506-1.79c.547-.39.616-.42.662-.432z"
        }
        fill={"currentColor"}
      ></path>

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M10.357 3h3.286c1.084 0 1.958 0 2.666.058.729.06 1.369.185 1.961.487a5 5 0 012.185 2.185c.302.592.428 1.233.487 1.961C21 8.4 21 9.273 21 10.357V12a1 1 0 11-2 0v-1.6c0-1.137 0-1.929-.051-2.546-.05-.605-.142-.953-.276-1.216a3 3 0 00-1.311-1.311c-.263-.134-.611-.226-1.216-.276C15.529 5.001 14.736 5 13.6 5h-3.2c-1.137 0-1.929 0-2.546.051-.605.05-.953.142-1.216.276a3 3 0 00-1.311 1.311c-.134.263-.226.611-.276 1.216C5.001 8.471 5 9.264 5 10.4v3.2c0 1.137 0 1.929.051 2.546.05.605.142.953.276 1.216a3 3 0 001.311 1.311c.263.134.611.226 1.216.276.617.05 1.41.051 2.546.051H12a1 1 0 110 2h-1.643c-1.084 0-1.958 0-2.666-.058-.728-.06-1.369-.185-1.96-.487a5 5 0 01-2.186-2.185c-.302-.592-.428-1.232-.487-1.961C3 15.6 3 14.727 3 13.643v-3.286c0-1.084 0-1.958.058-2.666.06-.728.185-1.369.487-1.96A5 5 0 015.73 3.544c.592-.302 1.233-.428 1.961-.487C8.4 3 9.273 3 10.357 3z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default DesignImageSearchIcon;
/* prettier-ignore-end */
