import { HotkeysProps } from 'utils/stores/hotKeysStore';
import { applyStyleObject } from 'utils/stores/mapStore';
import { themeStore } from 'utils/stores/themeStore';
import { getMapID } from 'views/core/mapView';

export default function useThemeHotkeys() {
  const mapId = getMapID();

  function handleStyleHotkeys(keyCombination: string) {
    const getStyleObject = themeStore.styleObjects.find(style =>
      style.assignedHotkey?.includes(keyCombination)
    );
    if (getStyleObject) {
      applyStyleObject(getStyleObject, mapId);
    }
  }

  const defaultThemeHotkeys: HotkeysProps = {
    'Theme 1': {
      shortcut: 'mod+alt+Digit1',
      alternativeShortcut: null,
      action: () => handleStyleHotkeys('mod+alt+Digit1'),
      isCustomizable: true,
    },
    'Theme 2': {
      shortcut: 'mod+alt+Digit2',
      alternativeShortcut: null,
      action: () => handleStyleHotkeys('mod+alt+Digit2'),
      isCustomizable: true,
    },
    'Theme 3': {
      shortcut: 'mod+alt+Digit3',
      alternativeShortcut: null,
      action: () => handleStyleHotkeys('mod+alt+Digit3'),
      isCustomizable: true,
    },
    'Theme 4': {
      shortcut: 'mod+alt+Digit4',
      alternativeShortcut: null,
      action: () => handleStyleHotkeys('mod+alt+Digit4'),
      isCustomizable: true,
    },
    'Theme 5': {
      shortcut: 'mod+alt+Digit5',
      alternativeShortcut: null,
      action: () => handleStyleHotkeys('mod+alt+Digit5'),
      isCustomizable: true,
    },
    'Theme 6': {
      shortcut: 'mod+alt+Digit6',
      alternativeShortcut: null,
      action: () => handleStyleHotkeys('mod+alt+Digit6'),
      isCustomizable: true,
    },
    'Theme 7': {
      shortcut: 'mod+alt+Digit7',
      alternativeShortcut: null,
      action: () => handleStyleHotkeys('mod+alt+Digit7'),
      isCustomizable: true,
    },
    'Theme 8': {
      shortcut: 'mod+alt+Digit8',
      alternativeShortcut: null,
      action: () => handleStyleHotkeys('mod+alt+Digit8'),
      isCustomizable: true,
    },
    'Theme 9': {
      shortcut: 'mod+alt+Digit9',
      alternativeShortcut: null,
      action: () => handleStyleHotkeys('mod+alt+Digit9'),
      isCustomizable: true,
    },
    'Theme 0': {
      shortcut: 'mod+alt+Digit0',
      alternativeShortcut: null,
      action: () => handleStyleHotkeys('mod+alt+Digit0'),
      isCustomizable: true,
    },
  };

  // Map hotkeys to style objects with labels
  // ! Come back here when integrating with the backend
  const styleObjectsWithLabels = themeStore.styleObjects.map(styleObj => {
    const hotkey = Object.entries(defaultThemeHotkeys).find(
      ([key, hotkey]) => hotkey.shortcut === styleObj.assignedHotkey[0]
    );

    if (hotkey) {
      const [label] = hotkey;
      return {
        ...styleObj,
        label,
      };
    }

    return styleObj;
  });

  return {
    defaultThemeHotkeys,
    styleObjectsWithLabels,
  };
}
