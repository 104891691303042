import { Badge } from 'ShadcnComponents/Badge';
import { useEffect, useState } from 'react';
import { useDebouncedValue } from '@mantine/hooks';
import { Input } from 'ShadcnComponents/Input';
import { CloseCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';

import '../themeAndStyleSettings/themeStyle.scss';

const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;

const ShortcutBlock = ({ text }: { text: string }) => {
  const isModKey = text === 'mod';
  return (
    <span className="inline-flex items-center gap-1">
      {isModKey && (
        <span className="px-2 py-1 mr-2 flex items-center gap-1 rounded-md text-xs font-medium shortcut-keys">
          {isMac ? 'Cmd' : 'Ctrl'}
          <Badge variant="custom">{text}</Badge>
        </span>
      )}
      {!isModKey && (
        <span className="px-2 py-1 mr-2 rounded-md text-xs font-medium shortcut-keys">
          {text}
        </span>
      )}
    </span>
  );
};

const ShortcutDisplay = ({ shortcut }: { shortcut: string | null }) => {
  if (!shortcut) return <ShortcutBlock text={'Blank'} />;
  const blocks = shortcut.split('+').map(block => block.trim());
  return (
    <div className="flex items-center gap-[2px]">
      {blocks.map((block, index) => (
        <ShortcutBlock key={index} text={block} />
      ))}
    </div>
  );
};

function ShortcutInput({ handleSaveShortcut }) {
  const [shortcuts, setShortcuts] = useState([]);
  const [debouncedShortcuts] = useDebouncedValue(shortcuts, 500);

  const possibleModifierKeys = ['Meta', 'Control'];

  const handleSetShortcuts = shortcut => {
    if (shortcut) {
      // Convert Cmd and Ctrl keys to mod
      if (possibleModifierKeys.includes(shortcut)) shortcut = 'mod';

      setShortcuts(prev =>
        prev.includes(shortcut)
          ? prev.filter(key => key !== shortcut)
          : [...prev, shortcut]
      );
    }
  };

  useEffect(() => {
    handleSaveShortcut(debouncedShortcuts.join('+'));
  }, [debouncedShortcuts]);

  return (
    <div className="flex items-center space-x-2 mr-2">
      <Input
        value={shortcuts.join('+')}
        placeholder="press shortcut..."
        onKeyDown={e => {
          e.preventDefault();
          e.stopPropagation();
          handleSetShortcuts(e.key);
        }}
        type="text"
        style={{ width: '170px' }}
        className="shortcut-input max-w-md focus:outline-none"
      />
    </div>
  );
}

interface SingleShortcutItemProps {
  key: string;
  shortcut: string;
  handleSaveShortcut: (x: string) => void;
  hideEditButton?: boolean;
}

export default function SingleShortcutItem({
  shortcut,
  handleSaveShortcut,
  hideEditButton,
}: SingleShortcutItemProps) {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(prevState => !prevState);
  };

  return (
    <div className="w-fit flex space-x-3 items-center">
      {isOpen ? (
        <ShortcutInput
          handleSaveShortcut={(shortcut: string) => {
            handleSaveShortcut(shortcut);
            handleToggle();
          }}
        />
      ) : (
        <ShortcutDisplay shortcut={shortcut} />
      )}

      {!hideEditButton && (
        <div className="cursor-pointer" onClick={handleToggle}>
          {isOpen ? (
            <CloseCircleOutlined className="text-red-500 cursor-pointer" />
          ) : (
            <PlusCircleOutlined
              style={{
                color: '#BBF7D0',
              }}
              className="text-primary"
            />
          )}
        </div>
      )}
    </div>
  );
}
