import React, { useEffect, useRef, useState } from "react";
import { PreviewData, WebPreviewContent } from "../utils/stores/types";
import { styled } from "@stitches/react";

interface IFrameProps {
    preview: PreviewData;
}

const IFRAME = styled('div', {
    width: 'inherit',
    height: 'inherit',
    backgroundImage: 'url("/images/grain-bg-9x.png")',
    backgroundSize: 'cover',
    backgroundRepeat: 'repeat',
    boxShadow: '2px 2px 8px 3px rgba(255, 255, 255, 0.1)',
})

export default function IFrame({
    preview,
}: IFrameProps) {

    const [foreRerender, setForceRerender] = useState(false)

    useEffect(() => {
        setForceRerender(true)
    }, [preview])
    
    return (
        <>
            <IFRAME style={{ width: 'inherit', height: 'inherit' }}>
                <object
                    className="flex items-center justify-center text-white"
                    style={{ width: 'inherit', height: 'inherit' }}
                    data={preview.content.link}
                    title={preview.content.title}
                >
                    <div>
                        This site does not allow access on iFrames (within other websites)
                        <div className="flex flex-col items-center justify-center">
                            <a href={preview.content.link} target="_blank">{ preview.content.link }</a>
                        </div>
                    </div>
                </object>
            </IFRAME>
        </>
    )
}
