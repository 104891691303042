import { proxy } from 'valtio';

interface ItemHotKeyStore {
  itemHotkeys: HotkeysProps;
  themeHotkeys: HotkeysProps;
  mapHotkeys: HotkeysProps;
  globalHotkeys: HotkeysProps;
}

export interface HotkeyProps {
  shortcut: string | null;
  alternativeShortcut: string | null;
  label?: string;
  description?: string;
  action: (e?: Event) => void;
  isCustomizable?: boolean;
  shouldPreventDefault?: boolean;
  triggerOnEditing?: boolean;
  ignore?: string[];
}

export interface HotkeysProps {
  [key: string]: HotkeyProps;
}

export const hotKeysStore = proxy<ItemHotKeyStore>({
  itemHotkeys: {},
  themeHotkeys: {},
  mapHotkeys: {},
  globalHotkeys: {},
});
