import { LottieOptions, useLottie } from "lottie-react";
import syncingAnimation from "./MapSyncing.json";
import syncingAnimationDark from "./MapSyncingDark.json";
import { themeStore } from "../../utils/stores/themeStore";
import chroma from "chroma-js";

const MapSyncingAnimation = () => {
  const isDarkMode = chroma(themeStore.canvasBackgroundColor).luminance() < 0.5;
  const options: LottieOptions = {
    animationData: isDarkMode ? syncingAnimationDark : syncingAnimation,
    loop: true,
    autoplay: true,
  };

  const { View } = useLottie(options);

  return <div className="w-24 h-24">{View}</div>;
};

export default MapSyncingAnimation;
