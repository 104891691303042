import { FolderData, FolderTree, MapData } from "utils/stores/types";

export const getMostFrequentIcons = (folderTreeStructure: FolderTree): string[] => {
  const iconFrequency: Record<string, number> = {};

  function countIconsInMaps(maps: MapData[]) {
    maps.forEach((map) => {
      if (map.icon && map?.icon?.type === 'icon') {
        const iconName = map.icon.name;
        iconFrequency[iconName] = (iconFrequency[iconName] || 0) + 1;
      }
    });
  }

  function countIconsInFolders(folders: FolderData[]) {
    folders.forEach((folder) => {
      if (folder?.icon?.type === 'icon') {
        const iconName = folder?.icon?.name;
        iconFrequency[iconName] = (iconFrequency[iconName] || 0) + 1;
      }
      // Recurse into subfolders
      countIconsInMaps(folder?.maps);
      countIconsInFolders(folder.subfolders);
    });
  }

  countIconsInMaps(folderTreeStructure.root.maps);
  countIconsInFolders(folderTreeStructure.root.folders);

  // Convert the frequency object to an array and sort by frequency
  return Object.entries(iconFrequency)
    .sort((a, b) => b[1] - a[1])
    .map(([name]) => name);
}
