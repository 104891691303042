import { Button } from 'ShadcnComponents/Button';
import { Input } from 'ShadcnComponents/Input';
import { Checkbox } from 'ShadcnComponents/Checkbox';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'ShadcnComponents/Select';
import SingleShortcutItem from 'components/shortCutOverview/SingleShortcutItem';
import { setIsEditing } from 'utils/stores/gestureStore';

import useAddStyleShortcut from 'utils/hooks/themeAndStyles/useAddStyleShortcut';
import { Controller } from 'react-hook-form';

export default function AddStyleShortcut() {
  const {
    onSubmit,
    control,
    errors,
    overwriteOptions,
    remainingOptions,
    requestLoading,
  } = useAddStyleShortcut();

  return (
    <form
      onSubmit={onSubmit}
      style={{ color: 'white', maxWidth: '500px', margin: 'auto' }}
    >
      <div className="text-center text-xl" style={{ marginBottom: '26px' }}>
        Add Style Shortcut
      </div>
      <div className="grid gap-3 px-2 flex-1 whitespace-normal">
        <div className="flex space-x-4 items-center">
          <div style={{ maxWidth: '40%', fontSize: '14px' }} className="w-full">
            Name:
          </div>
          <Controller
            name="title"
            control={control}
            render={({ field }) => (
              <Input
                placeholder="Enter a name for this style"
                {...field}
                onChange={e => {
                  field.onChange(e);
                  setIsEditing(true);
                }}
                onKeyDown={e => {
                  e.stopPropagation();
                }}
                className="w-full !h-8 border border-solid !border-[#151518] max-w-md focus:outline-none bg-[#09090b] modal-input"
              />
            )}
          />
        </div>

        {errors.title && (
          <div className="text-red-500">{errors.title.message}</div>
        )}

        <div className="flex space-x-4 items-center">
          <div style={{ maxWidth: '40%', fontSize: '14px' }} className="w-full">
            Shortcut:
          </div>
          <Controller
            name="shortcut"
            control={control}
            render={({ field }) => (
              <SingleShortcutItem
                key=""
                shortcut={field.value}
                handleSaveShortcut={field.onChange}
              />
            )}
          />
        </div>
        {errors.shortcut && (
          <div className="text-red-500">{errors.shortcut.message}</div>
        )}

        <div className="flex space-x-4 items-center">
          <div style={{ maxWidth: '40%', fontSize: '14px' }} className="w-full">
            Alternative Shortcut:
          </div>
          <Controller
            name="alternativeShortcut"
            control={control}
            render={({ field }) => (
              <SingleShortcutItem
                key=""
                shortcut={field.value}
                handleSaveShortcut={field.onChange}
              />
            )}
          />
        </div>
        {errors.alternativeShortcut && (
          <div className="text-red-500">
            {errors.alternativeShortcut.message}
          </div>
        )}

        <div className="flex space-x-4 items-center">
          <div style={{ maxWidth: '40%', fontSize: '14px' }} className="w-full">
            Style Location:
          </div>
          <Controller
            name="styleLocation"
            control={control}
            render={({ field }) => (
              <Select
                value={field.value}
                onValueChange={value => field.onChange(value)}
              >
                <SelectTrigger style={{ width: '300px', height: '35px' }}>
                  <SelectValue
                    defaultValue={'global'}
                    placeholder="Select a style location"
                  />
                </SelectTrigger>
                <SelectContent
                  style={{ background: '#1F1F1F' }}
                  className="border-none text-white w-full"
                >
                  <SelectGroup>
                    <SelectItem value="global">Global Style</SelectItem>
                    <SelectItem value="map">Map Style</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            )}
          />
        </div>
        {errors.styleLocation && (
          <div className="text-red-500">{errors.styleLocation.message}</div>
        )}
      </div>

      <div style={{ marginTop: '20px' }}>
        <div className="flex ">
          <div className="grid gap-2">
            {overwriteOptions.map(option => (
              <div key={option.key} className="items-center flex space-x-2">
                <Controller
                  name={`overwriteOptions`}
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      checked={field.value[option.key]}
                      onCheckedChange={checked => field.onChange(checked)}
                    />
                  )}
                />
                <label className="text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                  Include {option.label}
                </label>
              </div>
            ))}

            {remainingOptions.map(option => (
              <div key={option.key} className="items-center flex space-x-2">
                <Checkbox
                  checked={false}
                  disabled={true}
                  className="cursor-none"
                />
                <label className="text-sm leading-none peer-disabled:cursor-none peer-disabled:opacity-70">
                  Include {option.label}
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="flex justify-center" style={{ marginTop: '32px' }}>
        <Button
          type="submit"
          style={{
            width: 'fit-content',
            background: '#78E08F',
            color: 'white',
          }}
          loading={requestLoading}
          disabled={Object.keys(errors).length > 0}
        >
          Add style shortcut
        </Button>
      </div>
    </form>
  );
}
