// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type ArrowsArrowsJoin2IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function ArrowsArrowsJoin2Icon(props: ArrowsArrowsJoin2IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 17 17"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M12.698 8.89H9.855a3.268 3.268 0 00-2.64 1.415 4.695 4.695 0 01-3.803 2.004l.008.001H2.052a.682.682 0 01-.684-.684c0-.383.3-.683.684-.683H3.42a3.35 3.35 0 002.735-1.416 4.616 4.616 0 011.368-1.31A4.697 4.697 0 016.1 6.888a3.29 3.29 0 00-2.72-1.417H2.052a.682.682 0 01-.684-.684c0-.383.3-.683.684-.683h1.327a4.67 4.67 0 013.843 2.003 3.33 3.33 0 002.68 1.416h2.808l-.892-.889v-.007a.677.677 0 010-.97.677.677 0 01.971 0l2.052 2.05c.092.09.152.2.18.317a.685.685 0 01-.31.77l-1.928 1.93a.72.72 0 01-.486.197l.014.022a.694.694 0 01-.486-.199.683.683 0 01-.007-.97v-.008l.88-.875z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default ArrowsArrowsJoin2Icon;
/* prettier-ignore-end */
