import { HTMLElementRefOf } from '@plasmicapp/react-web';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  toggleMapFolderSearchView,
  toggleQuickAccessModal,
} from 'utils/stores/dashboardStore';
import {
  DefaultSidebarProps,
  PlasmicSidebar,
} from './plasmic/map_map/PlasmicSidebar';

import * as VisuallyHidden from '@radix-ui/react-visually-hidden';

import { useUserLogout } from 'api/mutations';
import {
  AccountManagementComponent,
  logout,
  RUNNING_IN_TAURI,
} from 'propro-common-components';
import QuickAccessToSection from 'components/quickAccess/sidebar/TopSection';
import { QuickAccessItems } from 'components/quickAccess/sidebar/QuickAccessItems';
import { mapMapSettings } from 'components/settings/MapMapSettings';
import { useFlag } from '@unleash/proxy-client-react';
import { Dialog, DialogContent, DialogTitle } from 'ShadcnComponents/Dialog';

export interface SidebarProps extends DefaultSidebarProps {
  width: number;
  onMouseMove: React.MouseEventHandler;
  onMouseLeave: React.MouseEventHandler;
  onMouseDown: React.MouseEventHandler;
}

function Sidebar_(
  { width, onMouseMove, onMouseLeave, onMouseDown, ...props }: SidebarProps,
  ref: HTMLElementRefOf<'div'>
) {
  const navigate = useNavigate();

  const [settingsModal, setSettingsModal] = React.useState(false);

  const logoutFn = useUserLogout();

  const toggleUISettings = useFlag('UISettings');

  function handleLogout() {
    if (RUNNING_IN_TAURI) return logout();
    logoutFn.mutate(undefined);
  }

  return (
    <>
      <PlasmicSidebar
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
        onMouseDown={onMouseDown}
        root={{ ref }}
        {...props}
        style={{
          width: `${width}px`,
          height: '100%',
        }}
        overviewButton={{
          onClick: e => {
            e.preventDefault();
            navigate('/dashboard/overview');
          },
          style: {
            cursor: 'pointer',
          },
        }}
        foldersButton={{
          onClick: e => {
            e.preventDefault();
            navigate('/dashboard');
          },
        }}
        settingsButton={{
          onClick: () => setSettingsModal(true),
        }}
        quickAccess={{
          topSectionSlot: <QuickAccessToSection />,
          quickAccessItems: <QuickAccessItems />,
          quickAccessModalShortcut: {
            onClick: toggleQuickAccessModal,
          },
        }}
        mapFolderSearchFancyButton={{
          onClick: () => toggleMapFolderSearchView(),
          style: {
            backgroundImage: '/images/apple-dark.png',
            // opacity: 0,
          },
        }}
        logOutButton={{
          onClick: () => handleLogout(),
        }}
      />

      {/* // settings dialog  */}
      <Dialog
        open={settingsModal}
        onOpenChange={() => setSettingsModal(!settingsModal)}
      >
        <DialogContent className="bg-[#1F1F1F] max-w-[750px] max-h-[80vh] p-5 text-white outline-none border-none">
          <VisuallyHidden.Root>
            <DialogTitle>Account management settings</DialogTitle>
          </VisuallyHidden.Root>
          <AccountManagementComponent
            viewItems={toggleUISettings ? mapMapSettings : []}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

const Sidebar = React.forwardRef(Sidebar_);
export default Sidebar;
