import {
  getScaleFromMatrix,
  getTranslateFromMatrix,
} from 'utils/helpers/Canvas/clamp-zoom';
import { currentMapStateStore } from 'utils/stores/mapStore';
import { Matrix } from './canvas';

interface Position {
  x: number;
  y: number;
}

export function transformedMouseCordinates(
  mouseCordinates: Position,
  mapID: string
): Position {
  const canvasTransform = currentMapStateStore[mapID].canvas?.CanvasTransform;
  const domMatrix = new Matrix(canvasTransform);

  const { x, y } = getTranslateFromMatrix(domMatrix);
  const zoomLevel = getScaleFromMatrix(domMatrix);

  const position = {
    x: (mouseCordinates.x - x) / zoomLevel,
    y: (mouseCordinates.y - y) / zoomLevel,
  };
  return position;
}
