import {
  toggleMapFolderSearchView,
  toggleMapTextSearchModal,
  toggleQuickAccessModal,
  toggleShortCutModal,
  toggleThemeModal,
} from 'utils/stores/dashboardStore';

export const searchOptions = [
  {
    id: 1,
    text: 'Map Text Search',
    shortcut: 'mod+shift+f',
    trigger: () => toggleMapTextSearchModal(),
    active: true,
  },
  {
    id: 2,
    text: 'Map Folder Search',
    shortcut: 'mod+shift+o',
    trigger: () => toggleMapFolderSearchView(),
    active: true,
  },
];

export const moreOptions = [
  {
    id: 1,
    text: 'Shortcut overview',
    shortcut: 'mod+shift+g',
    trigger: () => toggleShortCutModal(),
    active: true,
  },
  {
    id: 2,
    text: 'Theme style shortcut overview',
    shortcut: 'mod+shift+s',
    trigger: () => toggleThemeModal(),
    active: true,
  },
  {
    id: 3,
    text: 'Inspector',
    shortcut: '',
    trigger: () => null,
    active: false,
  },
  {
    id: 4,
    text: 'Quick Access',
    shortcut: 'mod+shift+b',
    trigger: () => toggleQuickAccessModal(),
    active: true,
  },
];
