import { styled } from '@stitches/react';
import { Header } from 'components/DashboardHeader/DashboardHeader';

import { MenuOutlined } from '@ant-design/icons';
import { SideBarRenderer } from 'components/sidebar/sidebar';
import { ReactNode, useLayoutEffect, useState } from 'react';

const DashboardLayout = styled('div', {
  width: '100dvw',
  height: '100dvh',
  background: '#131313',
  display: 'flex',
  justifyContent: 'center',
  backgroundImage: 'url("/images/grain-bg-9x.png")',
  backgroundSize: '200px',
  backgroundPosition: 'center',
  backgroundRepeat: 'repeat',
});

const DashboardContentContainer = styled('div', {
  width: '100%',
  height: '100%',
  display: 'flex',
  maxWidth: '1920px',
  padding: '20px 32px 0px',
  flexDirection: 'column',
});

const HeaderContainer = styled('header', {
  width: '100%',
  height: 'fit-content',
});

const DashboardMain = styled('main', {
  width: '100%',
  display: 'flex',
  minHeight: 0,
  overflow: 'auto',
  flexGrow: 1,
  paddingBottom: '1vh',
});

const MainContentMaps = styled('main', {
  width: '100%',
  height: '100%',
  maxHeight: '100%',
});

type RenderElementAtHeightprops = {
  children: ReactNode;
  offSetHeight: number;
  currentHeight: number;
};
function RenderElementAtHeight(props: RenderElementAtHeightprops) {
  const render = props.currentHeight > props.offSetHeight;

  return (
    <>
      {render && props.children}
      {!render && null}
    </>
  );
}

export function DashboardLayoutV2(props) {
  const [tabHeight, setTabViewHeight] = useState(0);

  useLayoutEffect(() => {
    const updateRenderState = () => {
      if (typeof window !== 'undefined') {
        const viewportHeight = window.innerHeight;
        setTabViewHeight(viewportHeight);
      }
    };

    // Run once to set initial state
    updateRenderState();

    window.addEventListener('resize', updateRenderState);

    return () => window.removeEventListener('resize', updateRenderState);
  }, [props.offSetHeight]);

  return (
    <DashboardLayout>
      <DashboardContentContainer>
        <HeaderContainer>
          <Header />
        </HeaderContainer>
        <DashboardMain>
          <RenderElementAtHeight offSetHeight={480} currentHeight={tabHeight}>
            <SideBarRenderer name="left" />
          </RenderElementAtHeight>

          <MainContentMaps>{<props.children />}</MainContentMaps>

          {/* <SideBarRenderer name="right" /> */}
        </DashboardMain>
      </DashboardContentContainer>
    </DashboardLayout>
  );
}
