import { useGetUserFolderStructure } from 'api/queries';
import { LargeItemRenderer } from 'CoreComponents/LargeItemRenderer/LargeItemRenderer';
import DashboardOverview from 'PlasmicComponents/DashboardOverview';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import handleGetRecentFolders, {
  handleGetRecentMaps,
} from 'utils/helpers/getRecent';
import { FolderData, FolderTreeRootProps, MapData } from 'utils/stores/types';
import { FolderStructure } from './DashboardView';

export function DashboardOverviewPage() {
  const [recentFolders, setRecentFolders] = useState<FolderData[]>([]);
  const [recentMaps, setRecentMaps] = useState<MapData[]>([]);
  const { data: folderStructure, isLoading: folderStructureLoading } =
    useGetUserFolderStructure() as FolderStructure;
  const navigate = useNavigate();

  function getRecentFolders(root: FolderTreeRootProps, count = 6) {
    const returnedRecentFolders = handleGetRecentFolders(root, count);
    setRecentFolders(returnedRecentFolders);
  }

  function getRecentMaps(root: FolderTreeRootProps, count = 12) {
    const returnedRecentMaps = handleGetRecentMaps(root, count);
    setRecentMaps(returnedRecentMaps);
  }

  useEffect(() => {
    if (folderStructure?.root) {
      getRecentFolders(folderStructure.root);
      getRecentMaps(folderStructure.root);
    }
  }, [folderStructure]);

  const handleItemOpen = (itemId: string, itemType: 'folder' | 'map') => {
    if (itemType === 'folder') {
      console.log(itemId);
      itemId === 'Root-folder'
        ? navigate(`/dashboard`)
        : navigate(`/dashboard/folder/${itemId}`);
    } else {
      navigate(`/map/${itemId}`);
    }
  };

  return (
    <div
      className="relative"
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <DashboardOverview
        recentFolders={
          <LargeItemRenderer
            items={recentFolders}
            itemType="folder"
            loading={folderStructureLoading}
            onItemOpen={id => handleItemOpen(id, 'folder')}
          />
        }
        recentMaps={
          <LargeItemRenderer
            items={recentMaps}
            itemType="map"
            loading={folderStructureLoading}
            onItemOpen={id => handleItemOpen(id, 'map')}
          />
        }
      />
    </div>
  );
}
