import {
  FolderData,
  FolderTree,
  FolderTreeRootProps,
  MapData,
} from 'utils/stores/types';

export const rootFolder = {
  folderId: 'Root-folder',
  user_id: '',
  name: 'Root Folder',
  maps: [],
  subfolders: [],
  stamps: [],
  settings: undefined,
  icon: { name: '📂', color: '', type: 'emoji' },
  color: 'white',
};

export function collectMaps(
  root: FolderTree | FolderTreeRootProps | FolderData
): Map<string, MapData> {
  const mapsMap = new Map<string, MapData>();
  if (!root) return mapsMap;

  // If root is a FolderTree, start with the root folders
  const folders = 'root' in root ? root?.root?.folders : [root];

  folders.forEach(folder => {
    const maps = (folder.maps as MapData[]) || [];
    maps.forEach(map => {
      // Ensure the map has a lastInteraction date
      if (map.lastInteraction && map.mapId) {
        mapsMap.set(map.mapId, map);
      }
    });

    if ((folder as FolderData).subfolders) {
      (folder as FolderData).subfolders.forEach(subFolder => {
        const subMaps = collectMaps(subFolder);
        subMaps.forEach((value, key) => mapsMap.set(key, value));
      });
    }
  });

  return mapsMap;
}

export function collectQuickAccessMaps(
  root: FolderTree | FolderTreeRootProps | FolderData
): Map<string, MapData> {
  const mapsMap = new Map<string, MapData>();
  if (!root) return mapsMap;

  const folders = 'root' in root ? (root?.root?.folders ?? []) : [root];
  const rootMaps = 'root' in root ? (root?.root?.maps ?? []) : [];

  const processFolder = (folder: FolderData) => {
    // Collect maps in the folder, ignoring maps if both folder and map are in Quick Access
    (folder.maps || []).forEach((map: MapData) => {
      if (
        !(folder.isInQuickAccess && map.isInQuickAccess) &&
        map.lastInteraction &&
        map.mapId
      ) {
        mapsMap.set(map.mapId, map);
      }
    });

    // Recursively process subfolders
    (folder.subfolders || []).forEach(processFolder);
  };

  // Process each folder and its subfolders
  folders.forEach(processFolder);

  // Collect root-level maps
  rootMaps.forEach((map: MapData) => {
    if (map.lastInteraction && map.mapId) {
      mapsMap.set(map.mapId, map);
    }
  });

  return mapsMap;
}

export default function handleGetRecentFolders(
  root: FolderTreeRootProps,
  count = 6
) {
  const recentFolders: any = [];

  function traverse(folder: FolderData) {
    // if (!folder) return;

    const uniqueMapsSet = collectMaps(folder);

    const allMaps = Array.from(uniqueMapsSet.values());

    if (allMaps.length > 0) {
      const recentMap = allMaps.reduce((latest, current) => {
        return new Date(latest.lastInteraction) >
          new Date(current.lastInteraction)
          ? latest
          : current;
      });

      if (recentMap) {
        recentFolders.push({
          folder,
          mostRecentInteraction: new Date(recentMap.lastInteraction),
        });
      }
    }
  }

  (root.folders || []).forEach(folder => {
    traverse(folder);
  });

  const sortedFolders = recentFolders
    .sort((a, b) => b.mostRecentInteraction - a.mostRecentInteraction)
    .slice(0, count);

  return [rootFolder, ...sortedFolders.map(x => x.folder)];
}

export function handleGetRecentMaps(root: FolderTreeRootProps, count = 12) {
  const uniqueMapsSet = collectMaps(root);

  const allMaps = Array.from(uniqueMapsSet.values());

  const recentMaps = allMaps
    .sort(
      (a, b) =>
        new Date(b.lastInteraction).getTime() -
        new Date(a.lastInteraction).getTime()
    )
    .slice(0, count);

  return [...recentMaps];
}

export function getRecentMapsMapFolderSearchItem(root: FolderTreeRootProps) {
  const recentMapsItem: FolderData = {
    name: 'Recent Maps',
    folderId: 'folderUniqueID',
    icon: {
      name: '🕒',
      type: 'emoji',
      color: '',
    },
    maps: handleGetRecentMaps(root, 10),
    user_id: 'userUniqueID',
    stamps: [],
    settings: {
      isShared: false,
      accessLevel: 'private',
      color: undefined,
    },
    subfolders: handleGetRecentFolders(root, 5),
  };
  return recentMapsItem;
}
