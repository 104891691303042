import TopLeftButton from "PlasmicComponents/TopLeftButton"
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const BackButton = () => {

    const location = useLocation();
    const navigate = useNavigate();

    const [topLeftMode, setTopLeftMode] = useState<"map" | "dashboard">(location.pathname === '/dashboard' ? 'dashboard' : 'map')

    const changeTopLeftMode = () => {
        if (location.pathname === '/dashboard') {
            if(topLeftMode === 'dashboard') setTopLeftMode("map")
            if(topLeftMode === 'map') setTopLeftMode("dashboard")            
        }
    }
    
    return (
        <div onMouseEnter={changeTopLeftMode} onMouseLeave={changeTopLeftMode} onClick={() => navigate(-1)} className="fixed top-5 left-8 text-white z-50">
            <TopLeftButton context={topLeftMode} />
        </div>
    )
}