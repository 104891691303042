import MapFolderIcon from 'PlasmicComponents/MapFolderIcon';
import { Skeleton } from 'antd';
import React, { CSSProperties } from 'react';

type MapAndFolderSkeletonLoaderProps = {
  type: 'map' | 'folder';
};

type StyleLoader = {
  [key: string]: CSSProperties;
};

const styleLoader: StyleLoader = {
  map: {
    width: '154px',
    height: '105px',
  },
  folder: {
    width: '115px',
    height: '131px',
    backgroundColor: '#138c34',
    opacity: '0.3',
  },
};

const MapAndFolderSkeletonLoader = (props: MapAndFolderSkeletonLoaderProps) => {
  return (
    <>
      <Skeleton.Node
        active
        style={{
          borderRadius: '0.3em',
          margin: '8px ',

          ...styleLoader[props.type],
        }}
      >
        <div />
      </Skeleton.Node>
    </>
  );
};

export default MapAndFolderSkeletonLoader;
