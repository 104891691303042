import { Resizable } from 're-resizable';
import { getPreview } from 'utils/mapStoreFN/mapStoreFN_previews';
import { setIsResizing } from 'utils/stores/gestureStore';
import { PreviewData } from 'utils/stores/types';

const MIN_WIDTH = 50;
const MAX_WIDTH = 800;

type PreviewResizableComponentProps = {
    children: React.ReactNode;
    previewId: string;
    mapId: string;
    size: {width: number, height: number}
}

export default function PreviewResizableComponent({ children, previewId, mapId, size }: PreviewResizableComponentProps) {
    return (
        <Resizable
            size={{ width: size.width, height: size.height}}
            handleStyles={{
                right: { cursor: 'ew-resize' },
                left: { cursor: 'ew-resize' },
            }}
            onResizeStart={(e, direction, ref) => {
                // prevents react-selecto from intercepting drag events
                e.stopPropagation();
                e.preventDefault();

                setIsResizing(true); // prevents react-dnd from intercepting drag events
                // setResizing(true);
            }}
            onResizeStop={(e, direction, ref, d) => { 
                setIsResizing(false);
                
                const preview: PreviewData = getPreview(previewId, mapId);
                const newSize = {
                    width: preview.size.width + d.width,
                    height: preview.size.height + d.height,
                }

                preview.size = newSize
            }}
            enable={{
                top: false,
                left: false,
                right: true,
                bottom: true,
                bottomRight: true,
            }}
            minWidth={MIN_WIDTH}
            maxWidth={MAX_WIDTH}
        >
            {children}
        </Resizable>
   ) 
}