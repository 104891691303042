import { useLayoutEffect } from 'react';

export const useKeyUpLayout = (
  callback: Function,
  keys: string[],
  preventDefault?: string
) => {
  const onKeyUp = (e: KeyboardEvent) => {
    const wasAnyKeyPressed = keys.some((key: string) => e.key === key);

    if (wasAnyKeyPressed) {
      if (preventDefault === 'preventDefault') {
        e.preventDefault();
      }
      callback(e);
    }
  };

  useLayoutEffect(() => {
    document.addEventListener('keyup', onKeyUp);
    return () => {
      document.removeEventListener('keyup', onKeyUp);
    };
  }, [onKeyUp]);
};
