import { useGesture } from "@use-gesture/react";
import { runOnDrag, runOnDragEnd, runOnPinch, runOnWheel, runOnWheelEnd } from "./infiniteCanvasHelper";
import { Matrix } from "CoreComponents/Canvas/canvas";
import { MAX_ZOOM, ZOOM_SPEED_FACTOR, MIN_ZOOM_FACTOR } from "utils/constants";
import { currentMapStateStore } from "utils/stores/mapStore";
import { getScaleFromMatrix, getTranslateFromMatrix } from "../Canvas/clamp-zoom";

export const useCustomGesture = (
    mapId,
    setGestureStarted,
    setOverScrollDistance,
    setScrollAxis,
    canDragCanvas,
    canvasRef,
    elementsContainer,
    isPanning,
    scrollAxis
) => {
    useGesture(
    {
      onPinchStart: e => {
        setGestureStarted(true);
      },
      onPinch: ({
        event,
        memo,
        origin: [pinchOriginX, pinchOriginY],
        offset: [d],
      }) => {
        event.preventDefault();

        runOnPinch({memo, pinchOriginX, pinchOriginY, d}, elementsContainer)
      },

      onPinchEnd: e => {
        setGestureStarted(false);
      },
      onWheelStart: e => {
        setGestureStarted(true);
      },
      onWheel: e => {
        if (e.pinching || e.ctrlKey || e.metaKey) {
          return;
        }

        if (e.shiftKey) {
          setScrollAxis('x');
        }
        runOnWheel(mapId, e, elementsContainer)
      },
      onWheelEnd: () => {
        runOnWheelEnd(mapId, elementsContainer, setOverScrollDistance, setGestureStarted, setScrollAxis)
      },
      onDragStart: () => {
        setGestureStarted(true);
        if (!canDragCanvas.current) return;
        canvasRef.current!.style.cursor = 'grabbing';
      },
      onDrag: e => {
        runOnDrag(e, mapId, elementsContainer, canDragCanvas)
      },
      onDragEnd: () => {
        setGestureStarted(false);
        runOnDragEnd(isPanning, canvasRef, canDragCanvas, elementsContainer, setOverScrollDistance)
      },
    },
    {
      // eventOptions: { passive: false },
      target: canvasRef,
      pinch: {
        offsetBounds: { min: 1 / MAX_ZOOM, max: ZOOM_SPEED_FACTOR * MAX_ZOOM },
        scaleBounds: { min: 1 / MAX_ZOOM, max: ZOOM_SPEED_FACTOR * MAX_ZOOM },
        modifierKey: ['metaKey', 'ctrlKey'],
        from: () => {
          const initScale = getScaleFromMatrix(
            new Matrix(elementsContainer.current.style.transform)
          );

          const scale =
            (initScale - MIN_ZOOM_FACTOR / MAX_ZOOM) * ZOOM_SPEED_FACTOR;
          return [scale, 0];
        },
      },
      wheel: {
        axis: scrollAxis,
        from: () => {
          const initTranslate = getTranslateFromMatrix(
            new Matrix(currentMapStateStore[mapId].canvas?.CanvasTransform)
          );
          return [initTranslate.x, initTranslate.y];
        },
      },
      drag: {
        axis: scrollAxis,
        from: () => {
          const initTranslate = getTranslateFromMatrix(
            new Matrix(currentMapStateStore[mapId].canvas?.CanvasTransform)
          );
          return [initTranslate.x, initTranslate.y];
        },
      },
    }
  );
}