const EmptyMapOverlay = ({ fontFamily = 'Nunito' }) => {
  const styles = {
    overlay: {
      '--bg-color': 'rgba(4, 103, 13, 0.12)',
      '--text-normal': 'white',
      '--text-bold': 'white',
      '--text-italic': '#13A734',
    },
    animation: {
      animation: 'emptyMapSlideIn 1s ease-out forwards',
    },
  };

  return (
    <div
      className="fixed top-1/2 -translate-y-1/2 flex items-center justify-center z-50"
      style={{ pointerEvents: 'none', left: '-18px' }}
    >
      <div
        style={{
          ...styles.overlay,
          ...styles.animation,
          backgroundColor: 'var(--bg-color)',
          color: 'var(--text-normal)',
          maxWidth: '28rem',
          fontFamily: fontFamily,
          padding: '2rem',
          borderRadius: '1rem',
          pointerEvents: 'auto',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          <p>
            ✌️
            <i style={{ color: 'var(--text-italic)' }}>
              double click anywhere{' '}
            </i>{' '}
            to{' '}
            <strong style={{ color: 'var(--text-bold)' }}>
              create a note item
            </strong>{' '}
            and start writing
          </p>
          <p>
            ↔ <i style={{ color: 'var(--text-italic)' }}>Space + Drag </i> to{' '}
            <strong style={{ color: 'var(--text-bold)' }}>pan</strong> (move
            around on the map)
          </p>
          <p>
            🔎{' '}
            <i style={{ color: 'var(--text-italic)' }}>cmd or ctrl + Scroll </i>{' '}
            to{' '}
            <strong style={{ color: 'var(--text-bold)' }}>zoom in/out</strong>
          </p>
          <div style={{ height: '1rem' }}></div>
          {/* Empty line */}
          <p>also:</p>
          <p>
            <i style={{ color: 'var(--text-italic)' }}>
              drag items into other items{' '}
            </i>{' '}
            to{' '}
            <strong style={{ color: 'var(--text-bold)' }}>
              connect ⛓️‍ them with lines
            </strong>
          </p>
          <p>
            <i style={{ color: 'var(--text-italic)' }}>hold option or alt </i>{' '}
            to{' '}
            <strong style={{ color: 'var(--text-bold)' }}>
              duplicate items
            </strong>{' '}
            by <i style={{ color: 'var(--text-italic)' }}>dragging</i> them
          </p>

          <p>
            <i style={{ color: 'var(--text-italic)' }}>hold cmd or ctrl </i> to
            activate{' '}
            <strong style={{ color: 'var(--text-bold)' }}>
              insert mode 📥
            </strong>
          </p>
          <p style={{ paddingLeft: '1rem' }}>
            <i style={{ color: 'var(--text-italic)' }}>then click anywhere </i>{' '}
            to insert something special
          </p>
          <p style={{ paddingLeft: '1rem' }}>
            insert mode is{' '}
            <strong style={{ color: 'var(--text-bold)' }}>especially </strong>
            useful for{' '}
            <strong style={{ color: 'var(--text-bold)' }}>
              dragging items into a stack of items
            </strong>{' '}
            (instead of creating connection lines)
          </p>
        </div>
      </div>
    </div>
  );
};

export default EmptyMapOverlay;
