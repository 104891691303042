'use client';
import axios from 'axios';
import { useState } from 'react';

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('')
  return (
    <div className="min-h-[100vh] grid place-items-center text-white">
      <div className="w-[400px] bg-black p-4 rounded-md">
        <div className=" text-center text-xl">Reset You Password</div>
        <div className="text-sm mt-4">
          Enter your email address and we&apos;ll send you instructions to reset
          your password.
        </div>
        <div className="mt-4">
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            placeholder="Email"
            className="w-full p-2 rounded-md bg-gray-700"
          />
        </div>
        {error && <div className="text-red-500 my-3">
          {error}
        </div>}
        <div className="mt-4">
          <button
            className="w-full p-2 rounded-md bg-blue-600"
            onClick={async () => {
              try {
                const res = await axios.post('https://auth.innate.io/api/v1/auth/forgot-password', { email });
                if(res.status === 404){
                  setError(res?.data?.message)
                }
              } catch (error) {
                setError(error?.response?.data?.message || error?.response?.message || error?.message || `${error}`)
              }
            }}
          >
            Reset Password
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;