// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import { HTMLElementRefOf } from '@plasmicapp/react-web';
import { LuLoader } from 'react-icons/lu';
// import { useLogin } from 'api/queries';
import { useFlag } from '@unleash/proxy-client-react';
import { useLogin } from 'api/mutations';
import { Formik } from 'formik';
import {
  DefaultSignInSignUpFormProps,
  PlasmicSignInSignUpForm,
  setStorage,
} from 'propro-common-components';
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export interface SignInSignUpFormProps extends DefaultSignInSignUpFormProps {}

function SignInSignUpForm_(
  props: SignInSignUpFormProps,
  ref: HTMLElementRefOf<'div'>
) {
  const socialAuthFeatureReleased = useFlag('socialAuth');
  const enableMagicLinks = useFlag('enableMagicLinks');
  const [searchParams] = useSearchParams();
  const signInMode = React.useRef(true);
  const { mutateAsync } = useLogin(!!signInMode?.current);

  async function handleSubmit({
    email,
    password,
    name,
  }: {
    email: string;
    password: string;
    name: string;
  }) {
    if (!email || !password)
      return toast.error('Please enter email and password.');

    if (!signInMode.current && !name)
      return toast.error('Please enter your name.');

    try {
      let reqBody: { name?: string; email: string; password: string } = {
        email,
        password,
      };

      if (!signInMode?.current) reqBody.name = name;

      const res = await mutateAsync(reqBody as any);

      const account = (res as any)?.account;
      const user = (res as any)?.user;
      const accessToken = (res as any)?.tokens?.access?.token;
      const accessTokenExpiry = (res as any)?.tokens?.access?.expires;
      const refreshToken = (res as any)?.tokens?.refresh?.token;
      const refreshTokenExpiry = (res as any)?.tokens?.refresh?.expires;

      setStorage('account', account);
      setStorage('user', user);
      setStorage('accessToken', accessToken);
      setStorage('refreshToken', refreshToken);
      setStorage('accessTokenExpiry', accessTokenExpiry);
      setStorage('refreshTokenExpiry', refreshTokenExpiry);

      // console.log('responses', res);
      // console.log(localStorage.getItem('account'));
      // console.log(localStorage.getItem('user'));
      // console.log(localStorage.getItem('accessToken'));
      // console.log(localStorage.getItem('refreshToken'));
      // console.log(localStorage.getItem('accessTokenExpiry'));
      // console.log(localStorage.getItem('refreshTokenExpiry'));

      window.location.href = '/dashboard/overview';
    } catch (e) {
      toast.error(`${e}`);
    }
  }

  React.useEffect(() => {
    if (!searchParams.get('mode')) {
      window.location.href = '/desktop-entry?mode=signIn';
    } else {
      signInMode.current = !(searchParams.get('mode') === 'signUp');
    }
  }, [searchParams.get('mode')]);

  return (
    <Formik
      initialValues={{ name: '', email: '', password: '' }}
      initialErrors={{ name: '', email: '', password: '' }}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        isSubmitting,
      }) => (
        <PlasmicSignInSignUpForm
          root={{ ref }}
          hidingSignupElements={
            socialAuthFeatureReleased
              ? enableMagicLinks
                ? undefined
                : 'magicLink'
              : enableMagicLinks
                ? 'socialLogins'
                : 'both'
          }
          form={{
            onSubmit:
              handleSubmit as unknown as React.FormEventHandler<HTMLDivElement>,
          }}
          mode={signInMode.current ? 'signIn' : 'signUp'}
          app="mapMapDesktopAlt"
          nameInput={{
            value: values?.name,
            onChange: handleChange,
            placeholder: 'Name',
            name: 'name',
            className: '!text-base !text-white',
            style: {
              color: 'white',
              // fontSize: '16px',
            },
          }}
          nameError={
            <span className="text-red-500 text-xs pl-[1px]">
              {errors.name && touched.name && errors.name}
            </span>
          }
          passwordInput={{
            value: values?.password,
            onChange: handleChange,
            placeholder: 'Password',
            name: 'password',
            style: {
              color: 'white',
            },
            type: 'password',
          }}
          passwordError={
            <span className="text-red-500 text-xs pl-[1px]">
              {errors.password && touched.password && errors.password}
            </span>
          }
          emailInput={{
            value: values?.email,
            onChange: handleChange,
            placeholder: 'Email address',
            name: 'email',
            style: {
              color: 'white',
            },
          }}
          emailError={
            <span className="text-red-500 text-xs pl-[1px]">
              {errors.email && touched.email && errors.email}
            </span>
          }
          signInButton={
            isSubmitting
              ? {
                  children: (
                    <div className="animate-loading h-5 w-5 flex items-center justify-center">
                      <LuLoader />
                    </div>
                  ),
                  isDisabled: true,
                }
              : undefined
          }
          resetPasswordButton={{
            onClick: () => (location.href = '/desktop-entry/reset-password'),
          }}
          authUrl={`/desktop-entry?mode=${
            signInMode.current ? 'signUp' : 'signIn'
          }`}
          magicLinkSignInButton={{
            onClick: () => (location.href = '/desktop-entry/magic-link'),
          }}
          xComAuthButton={{
            onClick: () =>
              window.open('localhost:5522/api/v1/auth/twitter', '_self'),
            // onClick: () => {
            //   alert(getStorage('random_item'));
            // },
          }}
          googleAuthButton={{
            onClick: () =>
              window.open('localhost:5522/api/v1/auth/google', '_self'),
            // onClick: () => {
            //   if (!signInMode.current) {
            //     return localStorage.setItem('random_item', 'RANDOM VALUE');
            //   }
            //   setStorage('random_item', 'RANDOM VALUE');
            // },
          }}
          facebookAuthButton={{
            onClick: () =>
              window.open('localhost:5522/api/v1/auth/facebook', '_self'),
          }}
          {...props}
        />
      )}
    </Formik>
  );
}

const SignInSignUpForm = React.forwardRef(SignInSignUpForm_);
export default SignInSignUpForm;
