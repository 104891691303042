import { useFlag } from '@unleash/proxy-client-react';
import { AreaProps } from 'CoreComponents/Areas/Area';
import { selectAreaVersion } from 'CoreComponents/Areas/areaHelper';
import { useParams } from 'react-router-dom';
import { currentMapContentStore } from 'utils/stores/mapStore';
import { useSnapshot } from 'valtio';

function RenderAreas({ selectoRef }) {
  const mapId = useParams().mapId!;
  const currentAreaState = useSnapshot(currentMapContentStore[mapId]).value
    .areas;

  const areaProxy = currentMapContentStore[mapId].value.areas;

  const useSelectionV2 = useFlag('mapmap_selectionv2');

  return (currentAreaState || []).map((area, index) =>
    selectAreaVersion(
      {
        ...area,
        mapID: mapId,
        areaIndex: index,
        useSelectionV2,
        selectoRef,
        areaProxy: areaProxy[index],
      } as AreaProps,
      useSelectionV2,
      mapId
    )
  );
}

export default RenderAreas;
