import { useHotkeys } from '@mantine/hooks';
import MouseMoveListener from 'components/HotKeys/MouseMouseEventListerner';
import QuickAccessModal from 'components/quickAccess/QuickAccessModal';
import ShortcutOverView from 'components/shortCutOverview/ShortcutOverView';
import MapFolderSearch from 'PlasmicComponents/MapFolderSearch';
import MapTextSearch from 'PlasmicComponents/MapTextSearch';
import { memo, ReactNode, useEffect } from 'react';
import useAltkey from 'utils/hooks/hotKeys/useAltkey';
import useHotkeysArrangement from 'utils/hooks/hotKeys/useHotkeysArrangement';
import useIsModKeyPressed from 'utils/hooks/hotKeys/useIsModKeyPressed';
import useIsShiftPressed from 'utils/hooks/hotKeys/useIsShiftPressed';
import { useKeyHandlers } from 'utils/hooks/hotKeys/useKeyHandler';
import {
  dashboardStore,
  toggleShortCutModal,
} from 'utils/stores/dashboardStore';
import { hotKeysStore } from 'utils/stores/hotKeysStore';
import { hotKeyStateStore } from 'utils/stores/hotKeyStatesStore';
import { useSnapshot } from 'valtio';
import {
  OverlayMenuWrapper,
  OverlayMenuWrapperProps,
} from './Overlays/OverlayMenuWrapper';
import NewMapAndFolder from 'components/NewMaAndFolder/NewMapAndFolder';
import ContextMenu from 'PlasmicComponents/ContextMenu';

export const overlayMenuWrapperProps: OverlayMenuWrapperProps = {
  position: 'center',
  backgroundColor: '#131313',
  backdropDarkening: 0.8,
  borderRadius: 12,
};

export const HotkeyProvider = memo(({ children }: { children: ReactNode }) => {
  const { handleModKeyDown, handleModKeyUp, handleKeyCombination } =
    useIsModKeyPressed();
  const { handleShiftKeyDown, handleShiftKeyUp } = useIsShiftPressed();
  const { handleAltKeyUp, handleAtkeyDown } = useAltkey();
  const dashboardStateStore = useSnapshot(dashboardStore);

  // only calling global hotkeys here.
  useHotkeysArrangement({
    global: true,
  });

  useSnapshot(hotKeyStateStore.current);
  useSnapshot(hotKeysStore);

  const isModifierKeyActive =
    hotKeyStateStore.current.isAltActive ||
    hotKeyStateStore.current.isShiftActive ||
    hotKeyStateStore.current.isModActive;

  useKeyHandlers(
    {
      Alt: {
        down: handleAtkeyDown,
        up: handleAltKeyUp,
      },
      Shift: {
        down: handleShiftKeyDown,
        up: handleShiftKeyUp,
      },
      Meta: {
        down: handleModKeyDown,
        up: handleModKeyUp,
      },
      // Capture ctrl key for windows
      Control: {
        down: handleModKeyDown,
        up: handleModKeyUp,
      },
    },
    handleKeyCombination
  );

  const combinedHotkeys = {
    ...hotKeysStore.itemHotkeys,
    ...hotKeysStore.themeHotkeys,
    ...hotKeysStore.mapHotkeys,
    ...hotKeysStore.globalHotkeys,
  };

  const flattenedHotkeys = Object.values(combinedHotkeys).flatMap(hotkey => {
    const hotkeyMappings = [];
    if (hotkey.shortcut) {
      hotkeyMappings.push([
        hotkey.shortcut,
        e => {
          hotkey.action(e);
        },
        {
          preventDefault: hotkey.shouldPreventDefault,
        },
      ]);
    }
    if (hotkey.alternativeShortcut) {
      hotkeyMappings.push([
        hotkey.alternativeShortcut,
        e => {
          hotkey.action(e);
        },
        {
          preventDefault: hotkey.shouldPreventDefault,
        },
      ]);
    }
    return hotkeyMappings;
  });

  useHotkeys(flattenedHotkeys, ['INPUT', 'TEXTAREA'], true);

  useEffect(() => {}, [flattenedHotkeys]);

  return (
    <div>
      {/* // Context menu  */}
      <ContextMenu />

      {isModifierKeyActive && <MouseMoveListener />}
      {dashboardStateStore.searchModal && <MapFolderSearch />}
      {dashboardStateStore.quickAccessModal && <QuickAccessModal />}
      {dashboardStateStore.textSearchModal && <MapTextSearch />}
      {dashboardStateStore.showShortCutModal && (
        <OverlayMenuWrapper
          {...overlayMenuWrapperProps}
          width={0.65}
          closeModal={toggleShortCutModal}
        >
          <ShortcutOverView borderColor={'#616167'} />
        </OverlayMenuWrapper>
      )}
      {dashboardStateStore.newMapModal && (
        <OverlayMenuWrapper
          fitContentHeight={true}
          width={0.5}
          backgroundColor="#131313"
          backdropDarkening={0.8}
        >
          <NewMapAndFolder />
        </OverlayMenuWrapper>
      )}

      {children}
    </div>
  );
});
