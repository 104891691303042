// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Icon6IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Icon6Icon(props: Icon6IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 16 16"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M4 4c.368 0 .667.298.667.667v5.466c0 .571 0 .96.025 1.26.024.292.067.441.12.546.128.25.332.454.583.582.104.053.253.097.546.12.3.025.688.026 1.259.026h1.6c.571 0 .96 0 1.26-.025.292-.024.441-.067.545-.12a1.34 1.34 0 00.583-.583c.053-.105.096-.254.12-.546.025-.3.025-.689.025-1.26V4.667a.667.667 0 011.334 0v5.494c0 .537 0 .98-.03 1.34-.03.375-.096.72-.261 1.043-.256.502-.664.91-1.165 1.165-.324.165-.668.23-1.043.261-.36.03-.804.03-1.34.03H7.172c-.536 0-.98 0-1.34-.03-.375-.03-.72-.096-1.043-.26a2.667 2.667 0 01-1.165-1.166c-.165-.324-.23-.668-.261-1.043a17.8 17.8 0 01-.03-1.34V4.667C3.333 4.298 3.632 4 4 4z"
        }
        fill={"currentColor"}
      ></path>

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M8 10a.667.667 0 00-.667.667v2H6v-2a2 2 0 114 0v2H8.667v-2A.667.667 0 008 10zM2.07 5.631c.165.33.565.463.895.299L8 3.412l5.035 2.518a.667.667 0 10.597-1.193L8.298 2.07a.667.667 0 00-.596 0L2.369 4.737a.667.667 0 00-.299.894z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Icon6Icon;
/* prettier-ignore-end */
