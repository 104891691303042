import { SideBarLeft } from 'components/sidebar-left/sidebar-left';
import { SideBarRight } from 'components/sidebar-right/sidebar-right';
import { useState } from 'react';

export function SideBarRenderer({ name }) {
  const [sidebarLeftWidth, setSidebarLeftWidth] = useState(250);
  const [sidebarRightWidth, setSidebarRightWidth] = useState(250);
  const [isResizing, setIsResizing] = useState(false);

  // In progress for side bar expansion and reduction
  const handleMouseMove = e => {
    if (name === 'left') {
      const rightEdge = e.currentTarget.getBoundingClientRect().right;
      if (rightEdge - e.clientX <= 20) {
        document.body.style.cursor = 'ew-resize';
      } else {
        document.body.style.cursor = 'default';
      }
      return;
    }
    if (name === 'right') {
      const leftEdge = e.currentTarget.getBoundingClientRect().left;
      if (leftEdge - e.clientX >= -20) {
        document.body.style.cursor = 'ew-resize';
      } else {
        document.body.style.cursor = 'default';
      }
      return;
    }
  };

  const handleMouseLeave = () => {
    if (!isResizing) {
      document.body.style.cursor = 'default';
    }
  };

  const startResizing = e => {
    e.preventDefault();
    if (name === 'left') {
      // Only start resizing if mouse is within the resize zone
      const rightEdge = e.currentTarget.getBoundingClientRect().right;
      if (rightEdge - e.clientX <= 20) {
        setIsResizing(true);
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', stopResizing);
      }
    }
    if (name === 'right') {
      // Only start resizing if mouse is within the resize zone
      const leftEdge = e.currentTarget.getBoundingClientRect().left;
      if (leftEdge - e.clientX <= 20) {
        setIsResizing(true);
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', stopResizing);
      }
    }
  };

  const onMouseMove = e => {
    e.preventDefault();
    e.stopPropagation();

    if (name === 'right') {
      const newWidth = window.innerWidth - e.clientX;
      if (newWidth <= 200) {
        setSidebarRightWidth(200);
      } else {
        setSidebarRightWidth(newWidth);
      }
    }

    if (e.clientX <= 200) {
      setSidebarLeftWidth(200);
    } else {
      setSidebarLeftWidth(e.clientX);
    }
  };

  const stopResizing = () => {
    setIsResizing(false);
    document.body.style.cursor = 'default';
    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseup', stopResizing);
  };

  return (
    <>
      {name === 'left' && (
        <SideBarLeft
          onMouseLeave={handleMouseLeave}
          onMouseMove={handleMouseMove}
          onMouseDown={startResizing}
          width={sidebarLeftWidth}
        />
      )}
      {name === 'right' && (
        <SideBarRight
          onMouseLeave={handleMouseLeave}
          onMouseMove={handleMouseMove}
          onMouseDown={startResizing}
          width={sidebarRightWidth}
        />
      )}
    </>
  );
}
