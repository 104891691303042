// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type LinesIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function LinesIcon(props: LinesIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 455 19"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        stroke={"currentColor"}
        strokeOpacity={".04"}
        strokeWidth={"1.2"}
        strokeLinecap={"round"}
        d={
          "M.6 1.4h452.954M.6 3.8h452.954M.6 6.201h452.954M.6 8.601h452.954M.6 11.002h452.954M.6 13.402h452.954M.6 15.802h452.954M.6 18.203h452.954"
        }
      ></path>
    </svg>
  );
}

export default LinesIcon;
/* prettier-ignore-end */
