import React, { useState, useEffect, useRef } from 'react';

const ResponsiveOpacityGrid = ({ items, children }) => {
  const [grid, setGrid] = useState([]);
  const [hoveredCell, setHoveredCell] = useState(null);
  const gridRef = useRef(null);
  const colGap = 8; // 2rem in pixels
  const itemWidth = 200; // Adjust this value based on your LargeItem width

  useEffect(() => {
    const updateGrid = () => {
      if (!gridRef.current) return;

      const containerWidth = gridRef.current.offsetWidth;
      const totalItemWidth = itemWidth + colGap;
      const columns = Math.floor((containerWidth + colGap) / totalItemWidth);

      const newGrid = [];
      for (let i = 0; i < items.length; i += columns) {
        newGrid.push(items.slice(i, i + columns).map(item => ({ content: item, opacity: 0 })));
      }

      setGrid(newGrid);
    };

    updateGrid();
    window.addEventListener('resize', updateGrid);
    return () => window.removeEventListener('resize', updateGrid);
  }, [items, colGap, itemWidth]);

  useEffect(() => {
    if (!hoveredCell) return;

    const { row, col } = hoveredCell;
    const newGrid = grid.map((r, i) =>
      r.map((cell, j) => {
        if (!cell) return null;
        const rowDiff = Math.abs(i - row);
        const colDiff = Math.abs(j - col);

        let opacity;
        if (rowDiff === 0 && colDiff === 0) opacity = 0;
        else if (rowDiff + colDiff === 1) opacity = 9;
        else if (rowDiff === 1 && colDiff === 1) opacity = 8;
        else if (rowDiff + colDiff === 2) opacity = 7;
        else if (rowDiff + colDiff === 3) opacity = 6;
        else opacity = 5;

        return { ...cell, opacity };
      })
    );

    setGrid(newGrid);
  }, [hoveredCell]);

  const handleMouseEnter = (row, col) => {
    setHoveredCell({ row, col });
  };

  const handleMouseLeave = () => {
    setHoveredCell(null);
    setGrid(grid.map(row => row.map(cell => ({ ...cell, opacity: 0 }))));
  };

  return (
    <div ref={gridRef} className="w-full" onMouseLeave={handleMouseLeave}>
      <div className="grid gap-2" style={{
        gridTemplateColumns: `repeat(auto-fill, minmax(${itemWidth}px, 1fr))`,
        columnGap: `${colGap}px`
      }}>
        {grid.flat().map((cell, index) => (
          cell && (
            <div key={index} onMouseEnter={() => handleMouseEnter(Math.floor(index / grid[0].length), index % grid[0].length)}>
              {children(cell.content, cell.opacity)}
            </div>
          )
        ))}
      </div>
    </div>
  );
};

export default ResponsiveOpacityGrid;
