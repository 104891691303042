import React, { useState } from 'react';
import { useUpdateMap, useUpdateFolder } from 'api/mutations';
import { useSnapshot } from 'valtio';
import { dashboardStore } from 'utils/stores/dashboardStore';
import { FolderData, MapData } from 'utils/stores/types';
import { useParams } from 'react-router-dom';

interface UseItemEditingProps {
  initialTitle: string;
  mapId?: string;
  folderId?: string;
}

export function useLargeItemEditing({
  initialTitle,
  mapId,
  folderId,
}: UseItemEditingProps) {
  const [localTitle, setLocalTitle] = React.useState<string>(
    initialTitle || ''
  );
  const [isEditing, setIsEditing] = React.useState(false);
  const [displayIconSelector, setDisplayIconSelector] = useState(false);
  const prevPropsTitle = React.useRef(initialTitle);
  const titleInputRef = React.useRef(null);

  const mapUpdate = useUpdateMap();
  const folderUpdate = useUpdateFolder();
  const largeItemState = useSnapshot(dashboardStore.largeItems);

  const editItemName = () => {
    setIsEditing(false);
    if (folderId) {
      const finalData = {
        data: {
          name: localTitle,
        },
        folderId,
      };
      folderUpdate.mutateAsync(finalData).finally(resetRenamingMode);
    }
    if (mapId) {
      const data = {
        name: localTitle,
        mapId,
      };
      mapUpdate.mutateAsync(data).finally(resetRenamingMode);
    }
  };

  const toggleIconSelector = () => {
    setDisplayIconSelector(!displayIconSelector);
  };

  const resetRenamingMode = () => {
    dashboardStore.largeItems.itemToRename = null;
    setIsEditing(false);
  };

  const isItemInRenamingMode = () => {
    const itemToRenamed = largeItemState.itemToRename;
    const resolvedId =
      (itemToRenamed as MapData)?.mapId ||
      (itemToRenamed as FolderData)?.folderId;
    return (
      largeItemState.itemToRename &&
      (resolvedId === folderId || resolvedId === mapId)
    );
  };

  React.useEffect(() => {
    if (initialTitle !== prevPropsTitle.current && !isEditing) {
      setLocalTitle(initialTitle || '');
      prevPropsTitle.current = initialTitle;
    }
  }, [initialTitle, isEditing]);

  React.useEffect(() => {
    if (isItemInRenamingMode()) {
      setIsEditing(true);
      titleInputRef.current?.focus();
    }
  }, [largeItemState.itemToRename]);

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalTitle(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      editItemName();
    }
  };

  return {
    localTitle,
    isEditing,
    titleInputRef,
    displayIconSelector,
    isItemInRenamingMode,
    handleTitleChange,
    handleKeyDown,
    setIsEditing,
    resetRenamingMode,
    editItemName,
    toggleIconSelector,
  };
}

export function useChangeItemIcon() {
  const mapUpdate = useUpdateMap();
  const folderUpdate = useUpdateFolder();

  // To invalidate if the item is in an open folder
  const { folderId: routerFolderId } = useParams();

  const editItemIcon = async (
    type: 'folder' | 'map',
    id: string,
    icon: {
      type: string;
      name: string;
      color: string;
    }
  ) => {
    if (type === 'folder') {
      const finalData = {
        data: {
          icon,
        },
        folderId: id,
        parentFolder: routerFolderId,
      };
      folderUpdate.mutateAsync(finalData);
    }

    if (type === 'map') {
      const finalData = {
        data: {
          icon,
          mapId: id,
        },
        parentFolder: routerFolderId,
      };

      mapUpdate.mutateAsync(finalData);
    }
  };

  return { editItemIcon };
}

export function useUpdateQuickAccess() {
  const mapUpdate = useUpdateMap();
  const folderUpdate = useUpdateFolder();

  // To invalidate if the item is in an open folder
  const { folderId: routerFolderId } = useParams();

  const updateItem = async (
    type: 'folder' | 'map',
    id: string,
    isInQuickAccess: boolean
  ) => {
    if (type === 'folder') {
      const finalData = {
        data: {
          isInQuickAccess: !isInQuickAccess,
        },
        folderId: id,
        parentFolder: routerFolderId,
      };
      folderUpdate.mutateAsync(finalData);
    }

    if (type === 'map') {
      const finalData = {
        data: {
          isInQuickAccess: !isInQuickAccess,
          mapId: id,
        },
        parentFolder: routerFolderId,
      };
      mapUpdate.mutateAsync(finalData);
    }
  };

  return { updateItem };
}
