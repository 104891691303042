import * as React from "react";
import {
  PlasmicButtonFromMapMap,
  DefaultButtonFromMapMapProps
} from "./plasmic/map_map/PlasmicButtonFromMapMap";

import {
  ButtonRef,
  HtmlAnchorOnlyProps,
  HtmlButtonOnlyProps
} from "@plasmicapp/react-web";

export interface ButtonFromMapMapProps extends DefaultButtonFromMapMapProps {
  // Feel free to add any additional props that this component should receive
}
function ButtonFromMapMap_(props: ButtonFromMapMapProps, ref: ButtonRef) {
  const { plasmicProps } =
    PlasmicButtonFromMapMap.useBehavior<ButtonFromMapMapProps>(props, ref);
  return <PlasmicButtonFromMapMap {...plasmicProps} />;
}

export type ButtonComponentType = {
  (
    props: Omit<ButtonFromMapMapProps, HtmlAnchorOnlyProps> & {
      ref?: React.Ref<HTMLButtonElement>;
    }
  ): React.ReactElement;
  (
    props: Omit<ButtonFromMapMapProps, HtmlButtonOnlyProps> & {
      ref?: React.Ref<HTMLAnchorElement>;
    }
  ): React.ReactElement;
};
const ButtonFromMapMap = React.forwardRef(
  ButtonFromMapMap_
) as any as ButtonComponentType;

export default Object.assign(ButtonFromMapMap, { __plumeType: "button" });
