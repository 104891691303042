import axios from 'axios';
export function base64ToBlob(base64: string, contentType: string) {
  const byteCharacters = atob(base64);

  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  console.log(blob);
  // return blob;
}

async function uploadUrl({
  ImageBase64,
  oldUrl = '',
  type,
}: {
  ImageBase64: string;
  oldUrl?: string;
  type?: string;
}) {
  // const getBlob = base64ToBlob(ImageBase64, type);

  try {
    const url: any = await axios(
      'http://localhost:5522/api/uploadBlobs' +
        (oldUrl ? `?old_url=${oldUrl}` : ''),
      {
        method: 'POST',
        data: { base64Data: ImageBase64 },
      }
    );

    return url.data.urls[0];
  } catch (error) {
    return 'error';
  }
}
export default uploadUrl;
