// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type NavigationTravelLocationIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function NavigationTravelLocationIcon(
  props: NavigationTravelLocationIconProps
) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 24 24"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M12 4a7 7 0 00-7 7c0 2.206 1.336 4.268 3.205 5.888l3.441 2.982a.54.54 0 00.708 0l3.441-2.982C17.664 15.268 19 13.206 19 11a7 7 0 00-7-7zm-9 7a9 9 0 0118 0c0 3.026-1.81 5.592-3.895 7.399l-3.441 2.983a2.54 2.54 0 01-3.328 0l-3.441-2.983C4.81 16.592 3 14.026 3 11z"
        }
        fill={"currentColor"}
      ></path>

      <path d={"M14 11a2 2 0 11-4 0 2 2 0 014 0z"} fill={"currentColor"}></path>
    </svg>
  );
}

export default NavigationTravelLocationIcon;
/* prettier-ignore-end */
