import { canvasStateAfterScroll, initialCanvasState, Matrix, setMemo } from "CoreComponents/Canvas/InfiniteCanvas";
import { MAX_ZOOM, MIN_ZOOM_FACTOR, ZOOM_SPEED_FACTOR } from "utils/constants";
import { setZoomLevel, zoomLevel } from "utils/stores/gestureStore";
import { getScaleFromMatrix, getTranslateFromMatrix } from "../Canvas/clamp-zoom";
import { currentMapStateStore, detectOverScroll } from "utils/stores/mapStore";

export const runOnPinch = ({memo, pinchOriginX, pinchOriginY, d}, elementsContainer) => {
    // Initialize memo if it's not already set
    if (!memo && elementsContainer.current) {
        memo = setMemo(elementsContainer);
    }

    let displacementX = (memo.transformOriginX - pinchOriginX) / memo.scale;
    let displacementY = (memo.transformOriginY - pinchOriginY) / memo.scale;

    let movementDistance = d - memo.initialOffsetDistance;

    const matrix = `matrix(${
        MIN_ZOOM_FACTOR / MAX_ZOOM + d / ZOOM_SPEED_FACTOR
    }, 0, 0, ${MIN_ZOOM_FACTOR / MAX_ZOOM + d / ZOOM_SPEED_FACTOR}, ${
        memo.bounds.x + (displacementX * movementDistance) / ZOOM_SPEED_FACTOR
    },  ${
        memo.bounds.y + (displacementY * movementDistance) / ZOOM_SPEED_FACTOR
    })`;

    elementsContainer.current.style.transform = matrix;

    // * update zoom level on pinch
    setZoomLevel(getScaleFromMatrix(new Matrix(matrix)));

    return memo;
}

export const runOnWheel = (mapId, e, elementsContainer) => {
    const zoomLevel = getScaleFromMatrix(
        new Matrix(currentMapStateStore[mapId].canvas?.CanvasTransform)
    );

    const xTranslate = e.lastOffset[0] - e.movement[0];
    const yTranslate = e.lastOffset[1] - e.movement[1];

    elementsContainer.current.style.transform = `matrix(${zoomLevel}, 0, 0, ${zoomLevel}, ${xTranslate},  ${yTranslate})`;
}

export const runOnWheelEnd = (mapId, elementsContainer, setOverScrollDistance, setGestureStarted, setScrollAxis) => {
    currentMapStateStore[mapId].canvas.CanvasTransform =
        elementsContainer.current.style.transform;
    
    canvasStateAfterScroll.canvasPositionAfterScroll =
        getTranslateFromMatrix(new Matrix(elementsContainer.current.style.transform));
    canvasStateAfterScroll.canvasTransformAfterSCroll = elementsContainer.current.style.transform;
    
    setOverScrollDistance(
        detectOverScroll(
            initialCanvasState.initialCanvasPosition,
            canvasStateAfterScroll.canvasPositionAfterScroll,
            zoomLevel.level
        )
    );

    setGestureStarted(false);
    setScrollAxis(undefined);
}

export const runOnDrag = (e, mapId, elementsContainer, canDragCanvas) => {
    if (!canDragCanvas.current) return;
    const zoomLevel = getScaleFromMatrix(
        new Matrix(currentMapStateStore[mapId].canvas?.CanvasTransform)
    );

    const xTranslate = e.lastOffset[0] + e.movement[0] / zoomLevel;
    const yTranslate = e.lastOffset[1] + e.movement[1] / zoomLevel;

    const newMatrixValue = `matrix(${zoomLevel}, 0, 0, ${zoomLevel}, ${xTranslate},  ${yTranslate})`;

    currentMapStateStore[mapId].canvas.CanvasTransform = newMatrixValue;

    elementsContainer.current.style.transform = newMatrixValue;
}

export const runOnDragEnd = (isPanning, canvasRef, canDragCanvas, elementsContainer, setOverScrollDistance) => {
    if (isPanning) {
        canvasRef.current!.style.cursor = 'grab';
    }
    canvasRef.current!.style.cursor = canDragCanvas.current
        ? 'grab'
        : 'auto';
    
    canvasStateAfterScroll.canvasPositionAfterScroll =
        getTranslateFromMatrix(new Matrix(elementsContainer.current.style.transform));
    canvasStateAfterScroll.canvasTransformAfterSCroll = elementsContainer.current.style.transform;
    
    setOverScrollDistance(
        detectOverScroll(
            initialCanvasState.initialCanvasPosition,
            canvasStateAfterScroll.canvasPositionAfterScroll,
            zoomLevel.level
        )
    );
}
