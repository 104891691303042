import { CSSProperties } from 'react';

export function SharpArrow(props: IconProps) {
  return (
    <div style={{ ...props.style }}>
      <svg viewBox="0 0 31 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.2148 0.61084L0.0687866 32.9383H30.3609L15.2148 0.61084Z"
          fill="#D9D9D9"
        />
      </svg>
    </div>
  );
}

type IconProps = {
  style: CSSProperties;
};
export function Arrow(props: IconProps) {
  return (
    <div style={{ ...props.style }}>
      <svg viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.123188 11.1207C0.123188 10.6162 0.296638 10.1151 0.632614 9.77303L8.8224 1.15481C9.3291 0.639932 10.0529 0.646843 10.5528 1.17382L18.7473 9.81277C19.3264 10.4227 19.4043 11.5129 18.9221 12.2472C18.44 12.9798 17.5782 13.0783 16.9992 12.4667L9.66371 4.73479L2.34527 12.4667C1.75799 13.0645 0.897568 12.947 0.425018 12.2023C0.221521 11.8844 0.123188 11.5008 0.123188 11.1207Z"
          fill="white"
        />
      </svg>
    </div>
  );
}
