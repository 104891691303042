import { MapView } from 'views/core/mapView';
import { PersistedView } from 'views/core/persistedView';
import { DashboardOverviewPage } from 'views/dashboard/DashboardOverviewView';
import { DashboardView } from 'views/dashboard/DashboardView';
import { Help } from 'views/dashboard/help';
import { OnboardingView } from 'views/onboarding/onboarding';
import TestAuth from 'views/TestAuth';

const Routes = [
  // Core
  {
    path: '/',
    view: PersistedView,
    layout: 'core',
    title: 'redirecting to the last route',
  },

  {
    path: '/map/:mapId',
    view: MapView,
    layout: 'core',
    permission: 'user',
    // title: 'Map',
  },
  {
    path: '/testAuth',
    view: TestAuth,
    layout: 'core',
  },

  {
    path: '*',
    view: PersistedView,
    layout: 'core',
    permission: 'user',
    title: '...its the wildcard route',
  },

  // Dashboard

  {
    path: '/dashboard',
    view: DashboardView,
    layout: 'dashboard',
    title: 'Dashboard',
  },

  {
    path: 'dashboard/overview',
    view: DashboardOverviewPage,
    layout: 'dashboard',
    title: 'Dashboard',
  },

  {
    path: '/dashboard/folder/:folderId',
    view: DashboardView,
    layout: 'dashboard',
    title: 'Dashboard',
  },

  // Gravity

  // {
  //   path: '/dashboard',
  //   view: Dashboard,
  //   layout: 'app',
  //   permission: 'user',
  //   title: 'Dashboard',
  // },
  {
    path: '/welcome',
    view: OnboardingView,
    layout: 'onboarding',
    permission: 'user',
    title: 'Welcome',
  },
  {
    path: '/help',
    view: Help,
    layout: 'app',
    permission: 'user',
    title: 'Get Help',
  },
];

export default Routes;
