import { useRef, useState } from 'react';
import { useDrag } from 'react-dnd';
import { useParams } from 'react-router-dom';
import { gestureStore } from 'utils/stores/gestureStore';
import { hotKeyStateStore, resetHotKeys } from 'utils/stores/hotKeyStatesStore';
import { PreviewData, WebPreviewContent } from 'utils/stores/types';
import { useSnapshot } from 'valtio';
import PreviewResizableComponent from './PreviewResizableComponent';

import { styled } from '@stitches/react';

import { DownOutlined, ExportOutlined, RightOutlined } from '@ant-design/icons';

// import { ExternalLink } from "components/icon/externalLink";
// import { RightArrow } from "components/icon/rightArrow";

import Border from 'components/Border/Border';
import IFrame from 'CoreComponents/Iframe';
import VideoPreview from 'CoreComponents/VideoPreview';
import handleSelectContent from 'utils/helpers/handleClickItem';
import { DraggedPreview } from 'utils/hooks/useDropOnCanvas';
import { currentMapStateStore, resetSelected } from 'utils/stores/mapStore';
import './preview.css';

import arrowIcon from './images/Arrow Icon.svg';
import externalLinkIcon from './images/Open Link Icon.svg';
import settings from './images/Settings.svg';
import SimpleTextInput from 'CoreComponents/SimpleTextInput/SimpleTextInput';
import { useHotkeys } from '@mantine/hooks';
import { getPreview } from 'utils/mapStoreFN/mapStoreFN_previews';
import {
  constructYoutubeEmbedLink,
  normalizeURL,
  reversetoYoutubeLink,
} from 'utils/helpers/URLsHelper';
import { HoverWrapper } from 'components/HoverWrapper';
import { onSelect } from 'utils/helpers/Selection/selectionUtils';

type PreviewProps = {
  preview: PreviewData;
  previewIndex: number;
};

const PreviewTitleHeight = 45;

const PreviewDisplay = styled('div', {
  position: 'relative',
  overflow: 'hidden',
  height: '100%',
  width: 'inherit',
  borderRadius: '10px',

  '& > .preview-header > .title-area': {
    visibility: 'hidden', // Hidden by default
    opacity: '0',
    position: 'absolute', // Absolute position to overlap preview content
    top: '0', // Stick to the top
    height: `${PreviewTitleHeight}px`,
    // zIndex: '1500',
    background: '#242323',
    color: '#FFFFFF',
    fontSize: '14px',
    width: '100%',
    padding: '12px 12px 12px 30px',
    borderRadius: '10px 10px 0px 0px',
    transition: 'padding 0.3s, height 0.3s, visibility 0.3s, opacity 0.3s ease',
    // transition: 'opacity 0.3s ease', // Smooth transition for opacity
  },
  variants: {
    previewState: {
      collapsed: {
        '& > .preview-header > .title-area': {
          visibility: 'visible',
          opacity: '1',
          borderRadius: '10px',
        },
      },
      expanded: {
        '& > .preview-header > .title-area': {
          position: 'absolute',
          visibility: 'hidden',
          opacity: '0',
          top: `${PreviewTitleHeight}px`,
        },
      },
    },
  },
});

export default function Preview({ preview, previewIndex }: PreviewProps) {
  const mapId = useParams().mapId!;
  const selected = useSnapshot(currentMapStateStore[mapId]).selected;
  const { isResizing, isPanning } = useSnapshot(gestureStore);

  const [titleSwitch, setTitleSwitch] = useState('title');

  const [newUrl, setNewUrl] = useState('');

  const previewOnDrop: DraggedPreview = {
    id: preview.previewId,
    type: 'Preview',
    preview,
    style: {},
  };

  const [showControlsArea, setShowControlArea] = useState(false);

  const [previewVariant, setPreviewVariant] = useState<
    'expanded' | 'collapsed'
  >('expanded');

  const isSelected =
    selected?.length > 0 &&
    selected.some(selected => selected === preview.previewId);

  const previewCollapseRef = useRef(null);
  const arrowRef = useRef(null);

  const handleVariantChange = variant => {
    setPreviewVariant(variant);
  };

  const handlePreviewClick = event => {
    if ((event.ctrlKey || event.metaKey) && event.button === 0) {
      window.open(`${content.link}`, '_blank');
    }
  };

  const handleInputChange = event => {
    setNewUrl(event.target.value);
  };

  const handleKeyDown = (e, previewId: string) => {
    if (e.key === 'Escape') {
      setTitleSwitch('title');
      return;
    }
    if (e.key === 'Enter') {
      let updateURL = normalizeURL(newUrl);

      if (updateURL.includes('www.youtube.com'))
        updateURL = constructYoutubeEmbedLink(updateURL);

      const previewToEdit = getPreview(previewId, mapId);
      previewToEdit.content.title = updateURL;
      previewToEdit.content.link = updateURL;
      setTitleSwitch('title');
    }
  };

  const content = preview.content as WebPreviewContent;

  const [{ isDragging }, drag] = useDrag(
    () => ({
      canDrag: !isResizing && !isPanning,
      type: 'Preview',
      collect: monitor => ({ isDragging: !!monitor.isDragging() }),
      item: { ...previewOnDrop },
      end: (preview, monitor) => {
        resetHotKeys();
      },
    }),
    [preview, isResizing]
  );

  return (
    <div
      className="absolute preview-con"
      style={{
        top: `${preview.position.y}px`,
        left: `${preview.position.x}px`,
      }}
      data-drag={preview.previewId}
      ref={el => {
        drag(el);
      }}
    >
      <HoverWrapper
        width={preview.size.width}
        height={preview.size.height}
        radius={100}
        hoverEffectStyle={{
          height: '45px',
          padding: '12px 12px 12px 30px',
          visibility: 'visible',
          opacity: '1',
          position: 'absolute',
          top: '0',
        }}
        classToEffect="title-area"
      >
        <div>
          <div
            onMouseOver={() => setShowControlArea(true)}
            onMouseLeave={() => setShowControlArea(false)}
            className={`controls-area 
            ${showControlsArea ? 'show-controls-area' : 'hide-controls-area'}`}
          >
            <div className="collapse-expand">
              {previewVariant === 'expanded' ? (
                <img
                  className="w-4 down-arrow"
                  onClick={() => handleVariantChange('collapsed')}
                  src={arrowIcon as unknown as string}
                />
              ) : (
                <img
                  className="w-4"
                  onClick={() => handleVariantChange('expanded')}
                  src={arrowIcon as unknown as string}
                />
              )}
            </div>
            <div
              className="external-tab"
              onClick={() => {
                if (content.link.includes('www.youtube.com')) {
                  const link = reversetoYoutubeLink(content.link);
                  window.open(`${link}`, '_blank');
                  return;
                }
                window.open(`${content.link}`, '_blank');
              }}
            >
              <img
                className="w-4"
                src={externalLinkIcon as unknown as string}
              />
            </div>
            <div className="settings">
              <img className="w-4" src={settings as unknown as string} />
            </div>
          </div>
          <Border
            isSelected={isSelected}
            style={preview.style}
            type={'Preview'}
          >
            <PreviewResizableComponent
              mapId={mapId}
              previewId={preview.previewId}
              size={{
                ...preview.size,
                height:
                  previewVariant === 'expanded'
                    ? preview.size.height
                    : PreviewTitleHeight,
              }}
            >
              <PreviewDisplay
                previewState={previewVariant}
                id={preview.previewId}
                data-preview-index={previewIndex}
                data-preview-id={preview.previewId}
              >
                {/* <div
                onMouseOver={() => setShowControlArea(true)}
                  onMouseLeave={() => setShowControlArea(false)}
                  style={{
                    width: `${preview.size.width + 100}px`,
                    height: `${PreviewTitleHeight}px`,
                    display: 'flex',
                    alignItems: 'flex-start',
                    marginLeft: `-50px`,
                    justifyContent: 'center',
                  }}
              > */}
                <div
                  className="preview-header"
                  // style={{
                  //   // width: `${preview.size.width + 100}px`,
                  //   // height: `${PreviewTitleHeight}px`,
                  //   display: 'flex',
                  //   alignItems: 'flex-start',
                  //   marginLeft: `-50px`,
                  //   justifyContent: 'center',
                  // }}
                  onMouseOver={() => setShowControlArea(true)}
                  onMouseLeave={() => setShowControlArea(false)}
                  onDoubleClick={() => {
                    if (hotKeyStateStore.current.isModActive) {
                      window.open(`${content.link}`, '_blank');
                    }
                  }}
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    onSelect(
                      e,
                      isSelected,
                      {
                        contentID: preview.previewId,
                        type: 'Preview',
                        parentType: null,
                        parentID: preview.previewId,
                      },
                      { current: null }
                    );
                  }}
                >
                  <div
                    className="title-area"
                    style={{ width: `${preview.size.width}px` }}
                  >
                    <span
                      onDoubleClick={() => {
                        resetSelected(mapId);
                        setTitleSwitch('input');
                        setNewUrl(preview.content.title);
                      }}
                    >
                      {titleSwitch === 'title' ? (
                        preview.content.title
                      ) : (
                        <SimpleTextInput
                          value={newUrl}
                          onChange={handleInputChange}
                          onKeyDown={e => handleKeyDown(e, preview.previewId)}
                          onFocus={() => {
                            resetSelected(mapId);
                          }}
                          autoFocus={true}
                          style={{ padding: '4px 8px' }}
                        />
                      )}
                    </span>
                  </div>
                </div>
                {/* </div> */}
                {/* </HoverWrapper> */}
                <div
                  ref={previewCollapseRef}
                  className="preview-area"
                  style={{ width: '100%', height: '100%' }}
                  onClick={handlePreviewClick}
                >
                  {renderPreview(preview)}
                </div>
              </PreviewDisplay>
            </PreviewResizableComponent>
          </Border>
        </div>
      </HoverWrapper>
    </div>
  );
}

const renderPreview = (preview: PreviewData) => {
  switch (preview.type) {
    case 'Web Page':
      return <IFrame preview={preview} key={preview.previewId} />;
    case 'Map':
      return <IFrame preview={preview} key={preview.previewId} />;
    case 'Video':
      return <VideoPreview preview={preview} key={preview.previewId} />;
    // case 'PDF':
    //     return (
    //         <PDFPreview />
    //     )
  }
};
