import {
  updateAreaSize,
  updateEncompassingStacks,
} from 'utils/mapStoreFN/mapStoreFN_areas';
import { AreaData } from 'utils/stores/types';
import { getMapID } from 'views/core/mapView';

// .................... private function ....................
// only used withing the areaUtils

/**
 * Calculates width overlap and returns the offset or null if there is no overlap
 *
 * @param rootRight -  the width of the parent
 * @param targetRight - the width of the Element that overlaps or not
 *
 * @return null if there is no overlap or a number - the overlap offset
 */

function horizontalOverlap(rootRight: number, targetRight: number) {
  let widthOverLapOffset = null;

  if (rootRight < targetRight) {
    widthOverLapOffset = targetRight - rootRight;
    widthOverLapOffset += 10;
  }
  return widthOverLapOffset;
}

/**
 * Calculates height overlap and returns the offset or null if there is no overlap
 *
 * @param rootBottom -  the Height of the parent
 * @param targetBottom - the Height of the Element that overlaps or not
 *
 * @return null if there is no overlap or a number - the overlap offset
 */

function verticalOverlap(rootBottom: number, targetBottom: number) {
  let heightOverLapOffset = null;
  if (rootBottom < targetBottom) {
    heightOverLapOffset = targetBottom - rootBottom;
    heightOverLapOffset += 20;
  }
  return heightOverLapOffset;
}

/**
 * this functions compares two dom nodes and return overlap offset.
 * we would be using this to ensure the item does fit into the are if not we resize it
 * @param rootNode: the bounding box
 * @param targetNode : the node you want to check if it over laps the node
 * @param dropPosition: For cases where it is an Add action - at the point the item hasn't been rendered however with the
 * drop posiition we can calculate if the area would need a resizing or not
 *
 * @returns An object containing the overlap offsets:
 *  - `overlapOffsetWidth`: The width by which the overlapping node exceeds the target node. `null` if there's no horizontal overlap.
 * - `overlapOffsetHeight`: The height by which the overlapping node exceeds the target node. `null` if there's no vertical overlap.
 *
 */

function getBoundsOffset(
  rootNode: HTMLElement | HTMLDivElement,
  targetNode: HTMLElement | HTMLDivElement,
  dropPosition?: {
    x: number;
    y: number;
  }
) {
  // Bounds
  // we gets the bounds in the 2d space (canvas)

  const rootBoundingBox = rootNode.getBoundingClientRect();
  const targetBoundingBox = targetNode.getBoundingClientRect();
  const overlapOffsetWidth = horizontalOverlap(
    Math.abs(rootBoundingBox.right),
    dropPosition
      ? Math.abs(dropPosition.x) + targetBoundingBox.width
      : Math.abs(targetBoundingBox.x) + targetBoundingBox.width
  );

  /**
   * for dropping directly into an area we cannot use the dragged item as it wont give
   * accurate measurement - hence we use dropPosition to extrapulate where the
   * incoming item would drop then use that to get the right boundingbox value
   */
  const targetPosition = dropPosition
    ? Math.abs(dropPosition.y) + targetBoundingBox.height
    : Math.abs(targetBoundingBox.bottom);

  const overlapOffsetHeight = verticalOverlap(
    Math.abs(rootBoundingBox.bottom),
    targetPosition
  );

  return {
    overlapOffsetHeight,
    overlapOffsetWidth,
  };
}

//............................ public function ......................

/**
 * compares the area current size with dropped item -
 * Resizes either the width , height or both as the case may be
 *
 * @param areaID -  the recieving area.
 * @param itemID - usually a note item  - used to retrieve the item rendered DOM
 * @param itemRef(optional)- the item ref if available used instead of the itemID.
 *
 * @returns void
 */
export function resizeAreaOnNewStack(
  areaID: string,
  itemID: string,
  position: { x: number; y: number },
  itemRef?: HTMLElement | HTMLDivElement
) {
  const areaNode = document.getElementById(areaID);
  const itemNode = itemRef || document.getElementById(itemID);

  if (areaNode && itemNode) {
    const { overlapOffsetHeight, overlapOffsetWidth } = getBoundsOffset(
      areaNode,
      itemNode,
      position
    );
    // update area only if there is an overlap
    if (overlapOffsetHeight || overlapOffsetWidth) {
      updateAreaSize(
        {
          width: overlapOffsetWidth,
          height: overlapOffsetHeight,
        },
        areaID,
        getMapID(),
        true,
        true
      );
    }
  }
  return;
}

/**
 * updates the area when a note is dropped in a stack contained in area
 *
 * @param stackID: The stack recieving the note
 * @param areaID: The area containing the stack
 * @param node: the item being dropped into the stack - would be used to estimate the stackwidth and height when dropped
 *
 * @return void
 */

export function resizeAreaOnStackDrop(
  areaID: string,
  stackID: string,
  node: HTMLDivElement | HTMLElement
) {
  const areaNode = document.getElementById(areaID);
  const stackNode = document.getElementById(stackID);

  if (areaNode && stackNode && node) {
    node.style.display = 'none';
    stackNode.appendChild(node);

    const { overlapOffsetHeight, overlapOffsetWidth } = getBoundsOffset(
      areaNode,
      stackNode
    );

    // update area only if there is an overlap
    if (overlapOffsetHeight || overlapOffsetWidth) {
      updateAreaSize(
        {
          width: overlapOffsetWidth,
          height: overlapOffsetHeight,
        },
        areaID,
        getMapID(),
        true,
        true
      );
    }
  }
}

/**
 * Handles case for when a note in a stack has been interracted with ( for all stacks contained in an area)
 *
 * @param stackid: The stack that was interacted with
 * @param areaid: The area that the stack is contained in
 * @param itemid: The note id that was edited
 * @param itemRef(optional): The item was was interacted with - used when in place of itemid if given
 *
 * @return void
 */

export function updateAreaOnNoteEdit(
  areaID: string,
  stackID: string,
  itemID: string,
  itemRef?: HTMLElement | HTMLDivElement
) {
  const areaNode = document.getElementById(areaID);
  const stackNode = document.getElementById(stackID);

  if (areaNode && stackNode) {
    const { overlapOffsetHeight, overlapOffsetWidth } = getBoundsOffset(
      areaNode,
      stackNode
    );

    if (overlapOffsetHeight || overlapOffsetWidth) {
      updateAreaSize(
        {
          width: overlapOffsetWidth,
          height: overlapOffsetHeight,
        },
        areaID,
        getMapID(),
        true,
        true
      );
    }
  }
}

export function containedInArea(
  area: AreaData,
  stackDetails: {
    stackID: string;
    stackPosition: { x: number; y: number };
  }
) {
  const isWithinArea =
    stackDetails.stackPosition.y >= area.position.y &&
    stackDetails.stackPosition.x >= area.position.x &&
    stackDetails.stackPosition.y <= area.position.y + area.size.height &&
    stackDetails.stackPosition.x <= area.position.x + area.size.width;

  return isWithinArea;
}

export function handleMultipleAreaDrop(area: AreaData, stackIDs: string[]) {
  updateEncompassingStacks('ADD', null, stackIDs, area.areaId, 1, area);
}
