import { useRef, useMemo, useEffect } from 'react';
import { useMutateWithKeepAlive } from 'propro-common-components';
import { getCurrentMapData } from '../stores/mapStore';
import {
  isPendingChangesEmpty,
  pendingChanges,
  resetPendingChanges,
} from '../mapStoreFN/trackingChanges';
import { pick } from '../helpers/pick';

export function useMapBeacon(mapId: string) {
  const authToken = useRef(
    JSON.parse(localStorage.getItem('user') ?? '{}').token
  );

  const config = useMemo(
    () => ({
      url: '/api/map',
      method: 'POST' as const,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken.current}`,
      },
      toastMessage: {
        message: 'Syncing map data...',
        successMessage: 'Map data synced successfully!',
        errorMessage: 'Failed to sync map data.',
      },
      // onSuccess: () => {
      //   console.log('Map data synced successfully.');
      // },
      // onError: (error: any) => {
      //   console.error('Failed to sync map data:', error);
      // },
      invalidateAllQueries: false,
    }),
    [authToken]
  );

  const { mutate } = useMutateWithKeepAlive(config);

  useEffect(() => {
    const handleBeforeUnload = () => {
      const mapData = getCurrentMapData(mapId);
      mapData.lastInteraction = new Date();
      if (mapData) {
        mutate(mapData);
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [mutate, mapId]);

  return mutate;
}
