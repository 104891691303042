import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { Input } from 'ShadcnComponents/Input';
import { Label } from 'ShadcnComponents/Label';
import { Switch } from 'ShadcnComponents/Switch';
import { Button } from 'ShadcnComponents/Button';

// Define the schema for form validation
const schema = z.object({
  showBackButton: z.boolean(),
  showIconEmoji: z.boolean(),
  showActionBar: z.boolean(),
  selectionColor: z.string().regex(/^#[0-9A-Fa-f]{6}$/),
  dashboardColor: z.string().regex(/^#[0-9A-Fa-f]{6}$/),
});

export default function UISettings() {
  const { control, handleSubmit, watch, setValue } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      showBackButton: true,
      showIconEmoji: true,
      showActionBar: true,
      selectionColor: '#f54646',
      dashboardColor: '#12A532',
    },
  });

  const dashboardColor = watch('dashboardColor');

  const onSubmit = data => {
    console.log(data);
  };

  const updateSelectionColor = () => {
    setValue('selectionColor', dashboardColor);
  };

  return (
    <div className="overflow-y-scroll h-full max-h-[450px]">
      <div className="text-4xl font-semibold">UI Settings</div>
      <div className="text-gray-500">Manage your MapMap Settings here</div>

      <div className="mt-7 mb-4 text-2xl font-bold !z-50">Inside Map View</div>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-8 mt-2">
        <div className="space-y-6">
          <div className="flex items-center space-x-2">
            <Controller
              name="showBackButton"
              control={control}
              render={({ field }) => (
                <Switch
                  id="show-back-button"
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              )}
            />
            <Label htmlFor="show-back-button">
              Show back button at top left
            </Label>
          </div>

          <div className="flex items-center space-x-2">
            <Controller
              name="showIconEmoji"
              control={control}
              render={({ field }) => (
                <Switch
                  id="show-icon-emoji"
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              )}
            />
            <Label htmlFor="show-icon-emoji">Show icon/emoji at top left</Label>
          </div>

          <div className="flex items-center space-x-2">
            <Controller
              name="showActionBar"
              control={control}
              render={({ field }) => (
                <Switch
                  id="show-action-bar"
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              )}
            />
            <Label htmlFor="show-action-bar">Show action bar</Label>
          </div>

          <div>
            <Label htmlFor="selection-color">Set default selection color</Label>
            <Controller
              name="selectionColor"
              control={control}
              render={({ field }) => (
                <Input
                  type="color"
                  id="selection-color"
                  style={{
                    padding: '0px',
                    border: 'none',
                    borderRadius: '8px',
                  }}
                  className="!w-60"
                  {...field}
                />
              )}
            />
          </div>

          <div>
            <Label htmlFor="dashboard-color">Set dashboard color</Label>
            <Controller
              name="dashboardColor"
              control={control}
              render={({ field }) => (
                <Input
                  type="color"
                  id="dashboard-color"
                  style={{
                    padding: '0px',
                    border: 'none',
                    borderRadius: '8px',
                  }}
                  className="!w-60 !rounded-lg"
                  {...field}
                />
              )}
            />
            <p className="text-md text-gray-500">
              Also change selection color to this color?
            </p>
            <Button
              className="text-xs h-8"
              type="button"
              style={{
                width: 'fit-content',
                background: '#12A532',
                color: 'white',
              }}
              onClick={updateSelectionColor}
            >
              Yes, update selection color
            </Button>
          </div>
        </div>

        <div className="flex justify-center">
          <Button
            style={{
              width: 'fit-content',
              background: '#12A532',
              color: 'white',
            }}
            type="submit"
          >
            Save Settings
          </Button>
        </div>
      </form>
    </div>
  );
}
