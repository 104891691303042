import { Input } from 'ShadcnComponents/Input';
import { ThemeSettingsProps } from './ThemeSection';
import { useState } from 'react';

import { ColorPicker } from 'antd';
import '../themeStyle.scss';

export default function SingleThemeSettingItem({
  theme,
  onChange,
}: {
  theme: ThemeSettingsProps;
  onChange: (newValue: string) => void;
}) {
  switch (theme.type) {
    case 'input':
      return <InputItem item={theme} onChange={onChange} />;

    case 'color-picker':
      return <ThemeColorItem item={theme} onChange={onChange} />;

    default:
      return null;
  }
}

function ThemeColorItem({ item, onChange }: { item: ThemeSettingsProps, onChange: (newValue: string) => void }) {
  const [selectedColor, setSelectedColor] = useState(item.value);

  const handleColorChange = (_, color) => {
    setSelectedColor(color);
    onChange(color); // Correctly pass the new value
  };

  return (
    <div
      style={{ maxWidth: '46%' }}
      className="w-full flex space-x-4 items-center justify-between px-2 py-3 rounded-xl"
    >
      <div
        style={{ maxWidth: '300px', fontSize: '14px', whiteSpace: 'nowrap' }}
      >
        {item.label}:
      </div>
      <div className="w-fit self-end">
        <ColorPicker
          style={{
            width: '170px',
            background: 'none',
            border: 'none',
            boxShadow: 'none',
            padding: 0,
            backgroundColor: 'none',
          }}
          defaultValue={selectedColor}
          onChange={handleColorChange}
        />
      </div>
    </div>
  );
}

function InputItem({ item, onChange }: { item: ThemeSettingsProps, onChange: (newValue: string) => void }) {
  const [inputValue, setInputValue] = useState(item.value);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    onChange(newValue); // Correctly pass the new value
  };

  return (
    <div
      style={{ maxWidth: '46%' }}
      className="w-full flex space-x-4 items-center justify-between px-2 py-3 rounded-xl"
    >
      <div
        style={{ maxWidth: '300px', fontSize: '14px', whiteSpace: 'nowrap' }}
      >
        {item.label}:
      </div>
      <Input
        value={inputValue}
        onChange={handleInputChange}
        type="text"
        style={{
          width: '240px',
          height: '30px',
        }}
        className="border border-solid !border-[#151518] max-w-md focus:outline-none bg-[#09090b] modal-input"
      />
    </div>
  );
}
