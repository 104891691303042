// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type MapGlobeIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function MapGlobeIcon(props: MapGlobeIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 24 24"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M16.498 2.133a1 1 0 10-.996 1.734 7.015 7.015 0 11-8.096 11.378 1 1 0 00-1.312 1.51A9.01 9.01 0 0011 18.908V21H8l-.117.007A1 1 0 008 23h8l.117-.007A1 1 0 0016 21h-3v-2.09a9.015 9.015 0 003.498-16.776zM12 5a5 5 0 100 10 5 5 0 000-10zm0 2a3 3 0 110 6 3 3 0 010-6z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default MapGlobeIcon;
/* prettier-ignore-end */
