import React, { useEffect, useRef } from 'react';

const BackgroundBlurWrapper = ({ children, enabled, borderColor }) => {
  const wrapperRef = useRef(null);
  const childRef = useRef(null);

  useEffect(() => {
    if (enabled && wrapperRef.current && childRef.current) {
      const childStyles = window.getComputedStyle(childRef.current);
      //const borderColor = childStyles.getPropertyValue('border-color');

      // console.log(childStyles.filter);

      wrapperRef.current.style.width = childStyles.width;
      wrapperRef.current.style.height = childStyles.height;
      wrapperRef.current.style.backgroundColor = borderColor;
      wrapperRef.current.style.filter = 'blur(20px)';
    }
  }, [enabled, children]);

  if (enabled)
    return (
      <div style={{ position: 'relative', width: '100%' }}>
        <div
          ref={wrapperRef}
          style={{
            position: 'absolute',
            zIndex: -1,
            opacity: enabled ? 0.6 : 0,
            transition: 'opacity 0.3s ease',
            width: '100%',
          }}
        />

        <div ref={childRef}>{children}</div>
      </div>
    );

  return <>{children}</>;
};

export default BackgroundBlurWrapper;
