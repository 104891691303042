// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type UserInterfaceSearchIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function UserInterfaceSearchIcon(props: UserInterfaceSearchIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 24 24"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        opacity={".25"}
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M12.793 12.793a1 1 0 011.414 0l6.5 6.5a1 1 0 01-1.414 1.414l-6.5-6.5a1 1 0 010-1.414z"
        }
        fill={"currentColor"}
      ></path>

      <path d={"M10 3a7 7 0 100 14 7 7 0 000-14z"} fill={"currentColor"}></path>
    </svg>
  );
}

export default UserInterfaceSearchIcon;
/* prettier-ignore-end */
