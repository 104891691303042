// components
import { View } from 'components/lib';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// 404
import { NotFound } from 'views/error/404';

// tailwind css
import '../css/output.css';
import '../css/tauri-images.css';

import {
  isUserLoggedIn,
  Loading,
  PrivateRoute,
} from 'propro-common-components';
import PlasmicHost from './PlasmicHost';

import { HotkeyProvider } from 'CoreComponents/hotkeyProvider';
import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const routes = [
  ...require('routes/account').default,
  ...require('routes/app').default,
  ...require('routes/auth').default,
];

export default function App() {
  const [shouldCheckAuth, setShouldCheckAuth] = useState(true);
  const [userLoggedIn, setUserLoggedIn] = useState(true);

  async function checkUserLoggedIn() {
    // return
    if (window.location.pathname.includes('desktop-entry'))
      return setShouldCheckAuth(false);

    if (!shouldCheckAuth) return;
    const isLoggedIn = await isUserLoggedIn();
    setUserLoggedIn(isLoggedIn);
  }

  useEffect(() => {
    checkUserLoggedIn();
  }, [window.location.pathname]);

  return (
    <>
      <ToastContainer limit={1} />
      <Loading loading={shouldCheckAuth && !userLoggedIn} transparent={false} />
      {/* <div className="animate__animated animate__fadeIn animate__faster"> */}
      <BrowserRouter>
        <HotkeyProvider>
          <Routes>
            <Route path="/plasmic-host" element={<PlasmicHost />} />
            {routes.map(route => {
              return (
                <Route
                  key={route.path}
                  path={route.path}
                  element={
                    route.permission ? (
                      <PrivateRoute>
                        <View
                          display={route.view}
                          layout={route.layout}
                          title={route?.title || 'MapMap'}
                        />
                      </PrivateRoute>
                    ) : (
                      <View
                        display={route.view}
                        layout={route.layout}
                        title={route?.title || 'MapMap'}
                      />
                    )
                  }
                />
              );
            })}
            {/* 404 */}
            <Route
              path="*"
              element={
                <View display={NotFound} layout="auth" title="404 Not Found" />
              }
            />
          </Routes>
        </HotkeyProvider>
      </BrowserRouter>
      {/* </div> */}
    </>
  );
}
