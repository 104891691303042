import { Matrix } from 'CoreComponents/Canvas/InfiniteCanvas';
import { ItemData, ConnectionID, MapData, AreaData } from './types';
import { v4 as uuidv4 } from 'uuid';
import { createItemId } from 'utils/mapStoreFN/mapStoreFN_items';

export const EXAMPLE_MAP: MapData = {
  mapId: uuidv4(),
  name: `Example Map(${new Date().toLocaleTimeString()})`,
  lastInteraction: new Date(),
  lastSync: new Date(),
  connections: [],

  canvas: {
    CanvasTransform: new Matrix().toString(),
  },

  stacks: [
    {
      stackId: 'stack_' + uuidv4(),

      isTitleNote: false,
      isContainedInArea: 'none',
      position: { x: 50, y: 50 },
      items: [
        {
          itemId: createItemId(),
          position: { x: 0, y: 0 },
          type: 'Note',
          content: {
            slateContent: [
              {
                children: [
                  {
                    text: 'This is a dog',
                  },
                ],
              },
            ],
          },
          connections: [],
          lastInteraction: new Date(),
          delete: false,
        },
        {
          itemId: createItemId(),
          type: 'Image',
          content: {
            src: 'https://picsum.photos/500?grayscale',
          },
          lastInteraction: new Date(),
        },
      ] as ItemData[],
      connections: [],
      lastInteraction: new Date(),
      lastSync: new Date(),
      delete: false,
    },
    {
      stackId: 'stack_' + uuidv4(),
      isTitleNote: false,
      isContainedInArea: 'none',
      position: { x: 200, y: 200 },
      delete: false,
      items: [
        {
          itemId: createItemId(),
          position: { x: 0, y: 0 },
          type: 'Note',
          content: {
            slateContent: [
              {
                type: 'Paragraph',
                children: [
                  {
                    text: 'This is a lonely note',
                  },
                ],
              },
            ],
          },
          connections: [],

          lastInteraction: new Date(),
          delete: false,
        },
      ],
      connections: [],
      lastInteraction: new Date(),
      lastSync: new Date(),
    },
    {
      stackId: 'stack_' + uuidv4(),
      isTitleNote: false,
      isContainedInArea: 'none',
      position: { x: 100, y: 500 },
      items: [
        {
          itemId: createItemId(),
          position: { x: 0, y: 0 },
          type: 'Note',
          content: {
            slateContent: [
              {
                type: 'Paragraph',
                children: [
                  {
                    text: 'A styled note',
                    isBold: true,
                  },
                ],
              },
            ],
          },
          connections: [],
          lastInteraction: new Date(),
          delete: false,
        },
        {
          itemId: createItemId(),
          position: { x: 0, y: 0 },
          type: 'Note',
          content: {
            slateContent: [
              {
                type: 'Paragraph',
                children: [
                  {
                    text: 'And another note. It was created with love and passion. Beneath another note.',
                  },
                ],
              },
            ],
          },
          connections: [],
          lastInteraction: new Date(),
          delete: false,
        },
      ],
      connections: [],
      lastInteraction: new Date(),
      lastSync: new Date(),
      delete: false,
    },
  ],
  areas: [],
  previews: [],
};

const AREAEXAMPLE: AreaData[] = [];

export const TEST_MAP: MapData = {
  mapId: uuidv4(),
  name: `test-map:${new Date().toLocaleTimeString()}`,
  lastInteraction: new Date(),
  lastSync: new Date(),

  canvas: {
    CanvasTransform: new Matrix().toString(),
  },
  stacks: [],
  areas: [],
  previews: [],
  connections: [],
};
