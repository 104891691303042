import * as React from "react";
import {
  PlasmicTextInputFromMapMap,
  DefaultTextInputFromMapMapProps
} from "./plasmic/map_map/PlasmicTextInputFromMapMap";

import { TextInputRef } from "@plasmicapp/react-web";

export interface TextInputFromMapMapProps
  extends DefaultTextInputFromMapMapProps {
  // Feel free to add any additional props that this component should receive
}
function TextInputFromMapMap_(
  props: TextInputFromMapMapProps,
  ref: TextInputRef
) {
  const { plasmicProps } =
    PlasmicTextInputFromMapMap.useBehavior<TextInputFromMapMapProps>(
      props,
      ref
    );
  return <PlasmicTextInputFromMapMap {...plasmicProps} />;
}

const TextInputFromMapMap = React.forwardRef(TextInputFromMapMap_);

export default Object.assign(
  TextInputFromMapMap,

  {
    __plumeType: "text-input"
  }
);
