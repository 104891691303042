// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import { HTMLElementRefOf } from '@plasmicapp/react-web';
import SignInSignUpForm from 'PlasmicComponents/SignInSignUpForm';
import * as React from 'react';
import {
  DefaultDesktopEntryPageProps,
  PlasmicDesktopEntryPage,
} from './plasmic/map_map/PlasmicDesktopEntryPage';

export interface DesktopEntryPageProps extends DefaultDesktopEntryPageProps {}

function DesktopEntryPage_(
  props: DesktopEntryPageProps,
  ref: HTMLElementRefOf<'div'>
) {
  return (
    <PlasmicDesktopEntryPage
      root={{ ref }}
      {...props}
      signInSignUpFormSlot={<SignInSignUpForm />}
      logo={<img src="/images/mapmap-logo-text-large.png" />}
      rightSide={{
        style: {
          background: '#131313',
          backgroundImage: 'url("/images/grain-bg-9x.png")',
          backgroundSize: '200px',
          backgroundPosition: 'center',
          backgroundRepeat: 'repeat',
        },
      }}
    />
  );
}

const DesktopEntryPage = React.forwardRef(DesktopEntryPage_);
export default DesktopEntryPage;
