import { PlayCircleFilled } from '@ant-design/icons';
import { useGetLoadedMap, useGetMyGlobalStyles } from 'api/queries';
import { cn } from 'lib/utils';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'ShadcnComponents/Button';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../CustomAccordion';
import SingleStyleSettingItem from './SingleStyleSettingItem';

export interface StyleShortcut {
  id: string;
  isActive: boolean;
  name: string;
  styleObject: string;
  shortcut: string;
  alternativeShortcut: string;
}

interface StyleCategory {
  _id: string;
  id: string;
  styleShortcuts: StyleShortcut[];
  accountId: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

interface CategoryData {
  personal: boolean;
  title: string;
  styleObjects: StyleShortcut[];
  // username?: string;
}

const StyleSection = () => {
  const mapId = useParams().mapId;

  const [styleCategories, setStyleCategories] = useState<CategoryData[]>([]);
  const [isOpen, setIsOpen] = useState<Record<number, boolean>>({});

  const { data: globalStyles, isLoading: isLoadingGlobal } =
    useGetMyGlobalStyles();
  const { data: loadedMap, isLoading: isLoadingMap } = useGetLoadedMap(mapId);
  // const { data: sharedStyles, isLoading: isLoadingShared } = useGetSharedStyles();

  useEffect(() => {
    if (globalStyles && loadedMap) {
      const newStyleCategories: CategoryData[] = [];

      // Process global styles
      newStyleCategories.push({
        personal: true,
        title: 'User Global',
        styleObjects: [...(globalStyles as StyleCategory)?.styleShortcuts],
      });

      // Process map styles
      if (loadedMap?.theme?.styleShortcuts?.length) {
        newStyleCategories.push({
          personal: true,
          title: 'Map',
          styleObjects: [...loadedMap.theme?.styleShortcuts],
        });
      }

      setStyleCategories(newStyleCategories);

      // Initialize isOpen state
      const newIsOpen: Record<number, boolean> = {};
      newStyleCategories.forEach((_, idx) => {
        newIsOpen[idx] = idx === 0;
      });
      setIsOpen(newIsOpen);
    }
  }, [globalStyles, isLoadingGlobal, loadedMap]);

  const handleToggle = (key: number) => {
    setIsOpen(prevState => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  if (isLoadingGlobal || isLoadingMap) {
    return (
      <div className="grid gap-5">
        {[...Array(3)].map((_, idx) => (
          <div
            className="h-56 rounded-xl bg-gray-700 animate-pulse"
            key={idx}
          ></div>
        ))}
      </div>
    );
  }

  return (
    <div className="grid gap-4">
      {styleCategories.map((category, idx) => (
        <Accordion
          key={idx}
          className="text-white rounded-xl bg-black"
          style={{ padding: '8px' }}
        >
          <AccordionItem>
            <AccordionTrigger
              onClick={() => handleToggle(idx)}
              isOpen={isOpen[idx]}
            >
              <div></div>
            </AccordionTrigger>
            <AccordionContent
              heading={{
                personal: category.personal,
                title: category.title,
                // ownersName: category.username,
              }}
              style={{
                background: '#1E1D1D',
                borderBottomLeftRadius: '8px',
                borderBottomRightRadius: '8px',
              }}
              className="rounded-b-xl py-3 px-2 grid gap-4"
              isOpen={isOpen[idx]}
            >
              <div
                className={cn('pb-4 pt-0 gap-4', {
                  grid: isOpen[idx],
                  hidden: !isOpen[idx],
                })}
              >
                {category.styleObjects.map((styleShortcut, itemIdx) => (
                  <SingleStyleSettingItem
                    canDelete={category.personal}
                    sectionTitle={category.title}
                    key={itemIdx}
                    styleItem={styleShortcut}
                  />
                ))}
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      ))}

      <Button
        style={{
          width: 'fit-content',
          background: '#78E08F',
          color: 'white',
        }}
        className="w-fit mx-auto"
      >
        Explore more StyleShortcuts
        <PlayCircleFilled className="ml-2" />
      </Button>
    </div>
  );
};

export default StyleSection;
