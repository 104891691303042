import DesktopEntryPage from '../../../PlasmicComponents/DesktopEntryPage'
import SignInSignUpForm from '../../../PlasmicComponents/SignInSignUpForm'

export default function DesktopEntry() {
  return (

    <DesktopEntryPage 
     signInSignUpFormSlot={
        <SignInSignUpForm app='mapMap' />
    } />


  )
}
