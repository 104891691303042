// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type ImageAddLineIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function ImageAddLineIcon(props: ImageAddLineIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 24 24"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        opacity={".25"}
        d={
          "M3 9.4c0-2.24 0-3.36.436-4.216a4 4 0 011.748-1.748C6.04 3 7.16 3 9.4 3h5.2c2.24 0 3.36 0 4.216.436a4 4 0 011.748 1.748C21 6.04 21 7.16 21 9.4v5.2c0 2.24 0 3.36-.436 4.216a4 4 0 01-1.748 1.748C17.96 21 16.84 21 14.6 21H9.4c-2.24 0-3.36 0-4.216-.436a4 4 0 01-1.748-1.748C3 17.96 3 16.84 3 14.6V9.4z"
        }
        fill={"#fff"}
      ></path>

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M19 15a1 1 0 011 1v2h2a1 1 0 110 2h-2v2a1 1 0 11-2 0v-2h-2a1 1 0 110-2h2v-2a1 1 0 011-1z"
        }
        fill={"#fff"}
      ></path>

      <path
        d={
          "M9 11a2 2 0 100-4 2 2 0 000 4zm-6 4.088c.001.97.01 1.711.07 2.308l3.332-2.06c.465-.288.52-.31.56-.32a.5.5 0 01.2-.01c.039.006.097.022.589.262l.528.257.09.043c.374.184.75.368 1.159.421a2.5 2.5 0 001.06-.088c.394-.121.735-.365 1.073-.608l.081-.058 2.506-1.79c.547-.39.616-.42.662-.433a.5.5 0 01.237-.005c.01.003.067.015.322.175a1 1 0 101.062-1.695c-.277-.174-.582-.35-.951-.432a2.501 2.501 0 00-1.186.025c-.445.119-.83.396-1.215.671l-.093.067-2.506 1.789c-.479.342-.538.37-.579.382a.501.501 0 01-.212.018c-.042-.006-.106-.023-.635-.28l-.528-.258-.083-.04c-.349-.17-.7-.342-1.081-.4a2.5 2.5 0 00-.999.05c-.373.096-.705.302-1.035.507l-.078.049L3 15.088z"
        }
        fill={"#131313"}
      ></path>
    </svg>
  );
}

export default ImageAddLineIcon;
/* prettier-ignore-end */
