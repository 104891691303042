import { deepCopy } from 'utils/helpers/deepCopy';
import { DraggedItem } from 'utils/hooks/useDropOnCanvas';
import { addItemToSelected, setSelected } from 'utils/stores/mapStore';
import { ParentType, ItemType, ItemData, Style } from 'utils/stores/types';
import { getArea } from './mapStoreFN_areas';
import { retrieveConnectionData } from './mapStoreFN_connection';
import {
  getStackAndItem,
  getParentIdFromItemID,
  getStack,
  removeStackOrItems,
} from './mapStoreFN_stacks';
import { v4 as uuidv4 } from 'uuid';
import { areaStateStore } from 'utils/stores/components/areaStore';
import getSelectedIDs from 'utils/helpers/Selection/selectionUtils';

export function createItemId(
  parent: ParentType = 'stack',
  itemType: ItemType = 'Default'
): string {
  const itemID = `item_${uuidv4()}:${parent}-${itemType}`;
  return itemID;
}

interface ItemDetail {
  parent: ParentType;
  itemType: ItemType;
}

export function extractTypeAndParentFromItemID(itemID: string): ItemDetail {
  const itemDetails: ItemDetail = {
    parent: 'stack',
    itemType: 'Default',
  };
  const itemDetail = itemID.split(':')[1];
  if (!itemDetail) {
    return itemDetails;
  }
  if (itemDetail) {
    const parentAndType = itemDetail.split('-');
    itemDetails.parent = parentAndType[0] as ParentType;
    itemDetails.itemType = parentAndType[1] as ItemType;
  }
  return itemDetails;
}

// ................... setters................

export function getItemData(itemID: string, mapID: string): ItemData {
  const itemDetails = extractTypeAndParentFromItemID(itemID);

  switch (itemDetails.itemType) {
    case 'Default': {
      const stackAndItem = getStackAndItem(itemID, mapID);
      if (stackAndItem) {
        stackAndItem.itemData.lastInteraction = new Date();
        return stackAndItem.itemData;
      }
      break;
    }
    case 'titleNote': {
      const areaID = getParentIdFromItemID(itemID);
      const areaData = getArea(areaID, mapID);
      if (areaData && areaData.titleNote) {
        areaData.titleNote.lastInteraction = new Date();
        return areaData.titleNote;
      }
      break;
    }
    case 'label': {
      const connectionID = getParentIdFromItemID(itemID);
      const connectionData = retrieveConnectionData(connectionID, mapID);
      if (connectionData && connectionData.label) {
        connectionData.label.lastInteraction = new Date();
        return connectionData.label;
      }
      break;
    }
    default: {
      const stackAndItem = getStackAndItem(itemID, mapID);
      if (stackAndItem) {
        stackAndItem.itemData.lastInteraction = new Date();
        return stackAndItem.itemData;
      }
    }
  }
}
export const createNewItem = (
  parentID: string,
  newItem: ItemData,
  mapId: string,
  itemId?: string, // of the last item that is currently selected/isEditing when cmd+Enter is pressed
  isBefore: boolean = false,
  selectItem = true,
  isDroppedItem = false,
  inheritItemStyle = true
) => {
  if (!parentID.includes('stack_')) return;
  const stack = getStack(parentID, mapId);

  if (!stack) return;
  if (stack.isTitleNote) return; // exit adding note to stack if it's a title note

  isDroppedItem && removeStackOrItems([newItem.itemId], mapId);
  let newItemWithId = {
    ...newItem,
    lastInteraction: new Date(),

    itemId: isDroppedItem ? newItem.itemId : createItemId(), // create new item with previous id if it is a drop or create a new one is it's a new note
  } as ItemData;

  // currently editing or selected item index
  const index = stack.items.findIndex(item => item.itemId === itemId);
  if (index !== -1) {
    const getStyle = stack.items[index].style?.indentation;
    if (getStyle && !isBefore) {
      newItemWithId = {
        ...newItemWithId,
        lastInteraction: new Date(),
        style: {
          indentation: getStyle,
        },
      };
    }
  }

  if (stack?.items && itemId) {
    // Copying styles of the last selected item to create new item
    const lastSelectedItem = stack?.items?.find(
      item => item?.itemId === itemId
    );

    let style: Style;

    if (lastSelectedItem) {
      // making a deep copy  style object
      // this ensure they are not linked or share a reference of the same [[prototype]] - since these are proxied object

      style =
        inheritItemStyle &&
        lastSelectedItem?.style &&
        deepCopy(lastSelectedItem.style);

      if (style) {
        const getAppliedStylesKey: Array<keyof Style> = Object.keys(
          style
        ) as Array<keyof Style>;
        if (
          getAppliedStylesKey.includes('customWidth') &&
          lastSelectedItem.style.customWidth < 100
        ) {
          // remove the custom width property
          // allowing it to default back to the usually  text wraping behaviour
          // when no customWidth is applied
          delete style.customWidth;
        }
      }

      newItemWithId = {
        ...newItemWithId,
        style: {
          ...(style || { ...newItem.style }),
          ...(newItemWithId?.style || {}),
        },
      };
    }
  }

  // if itemId index not found
  if (index === -1) {
    return;

    // stack.items.push(newItemWithId);
  } else {
    // if item index found, insert new item in next index

    const newNoteIndexPos = isBefore ? index - 1 : index;
    const getItemsBefore = stack.items.slice(0, newNoteIndexPos + 1); // get all items before the index position
    const getItemsAfter = stack.items.slice(newNoteIndexPos + 1); // get all items after the index position
    stack.items = [
      ...getItemsBefore,
      deepCopy(newItemWithId), // create a brand-new object with no inheritance.
      ...getItemsAfter,
    ];
  }

  if (selectItem) {
    addItemToSelected(
      {
        contentID: newItemWithId.itemId,
        parentType: 'stack',
        type: 'Note',
        parentID: stack.stackId,
      },
      mapId
    );
  } else {
    setSelected(
      {
        contentID: newItemWithId.itemId,
        parentType: 'stack',
        type: 'Note',
        parentID: stack.stackId,
      },
      mapId
    );
  }

  return;
};

export function handleDropOnItem(
  stackID: string,
  recevingItem: {
    note: ItemData;
    noteIndex: number;
    dropPosition: 'below' | 'above';
    isContainedInArea: string;
  },
  mapID: string,
  droppedItem: DraggedItem
) {
  createNewItem(
    stackID,
    { ...(droppedItem.item as ItemData) },
    mapID,
    recevingItem.note.itemId,
    recevingItem.dropPosition === 'above' ? true : false,
    false,
    true,

    false
  );

  /**
   * this handles resizing area when an item it added to a stack
   * contained in an area.
   *
   *
   * case:
   * 1. Area to Area :  dragged item was from a stack in an area and dropped on a stack that is also in an area
   * 2. Canvas to Area : dragged item is coming from a stack that is not from an area
   *
   */

  // if (droppedItem.isContainedInArea !== 'none') {
  //   areaStateStore.store.task.push({
  //     actions: 'Drag',
  //     targetAreaID: droppedItem.isContainedInArea,
  //   });
  // }

  if (recevingItem.isContainedInArea !== 'none') {
    areaStateStore.store.task.push({
      actions: 'Drop',
      stackid: stackID,
      options: {
        element: droppedItem.itemRef,
        contentID: droppedItem.id,
      },
      targetAreaID: recevingItem.isContainedInArea,
    });
  }

  if (
    recevingItem.isContainedInArea !== 'none' ||
    droppedItem.isContainedInArea !== 'none'
  ) {
    areaStateStore.store.state = 'Execute';
  }

  return;
}

export function handleDropItemsWithMultiSelect(
  receivingStackId: string,
  receivingItem: {
    note: ItemData;

    dropPosition: 'above' | 'below';
  },
  mapId: string,
  draggedItem: DraggedItem // single item
) {
  const stackData = getStack(receivingStackId, mapId);
  if (!stackData) return;

  let noteIndex: number = stackData.items.findIndex(
    item => item.itemId === receivingItem.note.itemId
  );

  if (noteIndex === -1) {
    return;
  }

  if (receivingItem.dropPosition === 'below') {
    noteIndex += 1;
  }

  const selectedItems = getSelectedIDs().filter(x => !x.includes('_item'));

  const selectedDroppedItem = selectedItems.map(itemId => {
    const { itemData, stackData } = getStackAndItem(itemId, mapId);

    const draggedItem: DraggedItem = {
      item: itemData,
      parentID: stackData.stackId,
      isContainedInArea: stackData.isContainedInArea,
      createDuplicate: false,
      totalItemsInStack: stackData.items.length,
      type: itemData.type,
      id: itemData.itemId,
      itemRef: null,
      itemIndexPosition: noteIndex,
      height: 0,
      width: 0,
      style: {},
      initialOffset: {
        x: 0,
        y: 0,
      },
    };

    return draggedItem;
  });

  const allSelectedStackIds = new Set(
    selectedDroppedItem.map(item => item.parentID)
  );

  if (Array.from(allSelectedStackIds).includes(receivingStackId)) {
    // Switch positions within a stack
    const selected = stackData.items.filter(item =>
      selectedItems.includes(item.itemId)
    );

    itemsPositionSwapInStack(
      receivingStackId,
      receivingItem.note,
      selected,
      mapId
    );
  } else {
    // The reason for this is to keep copied items from same stack in the same format
    // they were arranged originally on their stack before inserting
    // into the new stack
    const stackMap = new Map<string, DraggedItem[]>();

    selectedDroppedItem.forEach(droppedItem => {
      if (stackMap.has(droppedItem.parentID)) {
        stackMap.get(droppedItem.parentID).push(droppedItem);
      } else {
        stackMap.set(droppedItem.parentID, [droppedItem]);
      }
    });

    const arrangedStackItems = Array.from(stackMap.entries()).flatMap(
      ([stackId, items]) => {
        const stackData = getStack(stackId, mapId);
        if (stackData.items.length <= 1) {
          return [...items];
        }

        // Sort the `items` based on the order they appear in `stackData.items`
        const sortedItems = items.sort((a, b) => {
          const indexA = stackData.items.findIndex(
            x => x.itemId === a.item.itemId
          );
          const indexB = stackData.items.findIndex(
            x => x.itemId === b.item.itemId
          );

          return indexA - indexB;
        });

        return [...sortedItems];
      }
    );

    // Remove all selected items from their previous stacks
    selectedDroppedItem.forEach(droppedItem => {
      removeStackOrItems([droppedItem.item.itemId], mapId);
    });

    stackData.items.splice(
      noteIndex,
      0,
      ...arrangedStackItems.map(item => item.item)
    );
  }
}

function itemsPositionSwapInStack(
  stackId,
  receivingItem,
  selectedItems,
  mapId
) {
  const stack = getStack(stackId, mapId);
  if (!stack) return;

  // Find the index of the receiving item in the original stack
  const originalTargetIndex = stack.items.findIndex(
    item => item.itemId === receivingItem.itemId
  );

  if (originalTargetIndex === -1) return;

  // Filter out selected items from the stack
  const filteredItems = stack.items.filter(
    item => !selectedItems.some(selected => selected.itemId === item.itemId)
  );

  // Recalculate the target index in the new array of items
  const newTargetIndex = filteredItems.findIndex(
    item => item.itemId === receivingItem.itemId
  );

  // Insert selected items into the new position before the recalculated target index
  filteredItems.splice(newTargetIndex, 0, ...selectedItems);

  // Update the stack items
  stack.items = filteredItems;
}
