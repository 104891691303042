import { SettingFilled, PlusOutlined } from '@ant-design/icons';
import { styled } from '@stitches/react';
import { useFlag } from '@unleash/proxy-client-react';
import { renderNoteVersion } from 'CoreComponents/Item/noteHelpers';
import { addAreaTitleNote } from 'utils/mapStoreFN/mapStoreFN_areas';
import { generalStore } from 'utils/stores/generalStore';
import { themeStore } from 'utils/stores/themeStore';
import { AreaData, NoteItemData, Style } from 'utils/stores/types';

const IconAndStampContainer = styled('div', {
  width: '100%',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  color: 'White',
  opacity: '0',

  '&:hover': {
    cursor: 'default',
    opacity: '1',
    transition: 'opacity 0.2s ease-in',
  },
});

const CreateStackHeaderplaceHolder = styled('div', {
  borderRadius: '1em',
  display: 'flex',
  width: '100%',
  color: themeStore.defaultColor,
  alignItems: 'center',
  backgroundColor: themeStore.canvasBackgroundColor,
  padding: '4px 12px',
  transition: 'opacity 0.2s ease-in',
  opacity: '0',

  '&:hover': {
    cursor: 'default',
    opacity: '1',
    transition: 'opacity 0.2s ease-in',
  },

  ['& svg']: {
    marginRight: '4px',
  },
});
export type AreaIconAndStampsProps = {
  // children: ReactNode;
  titleNote?: NoteItemData;
  ID: string;
  lastInteraction;
  style?: Style;
  mapID: string;
  position: {
    x: number;
    y: number;
  };
  areaProxy: AreaData;
  size: {
    width: number;
    height: number;
  };
};

const TitleStampsWrapper = styled('div', {
  width: '100%',

  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  padding: '0px 20px',
  zIndex: '2',
  position: 'absolute',

  variants: {
    border: {
      _0: {
        top: '-15.5px',
      },
      _1: {
        top: '-15.5px',
      },
      _2: {
        top: '-14.5px',
      },
      _3: {
        top: '-13.5px',
      },
      _4: {
        top: '-14.5px',
      },
      _5: {
        top: '-12.5px',
      },
    },
  },
});

export function TitleAndStamps(props: AreaIconAndStampsProps) {
  const useselectionv2 = useFlag('mapmap_selectionv2');
  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
      }}
    >
      <TitleStampsWrapper border={props.style?.border?.thickness || '_0'}>
        <div
          style={{
            width: 'fit-content',
            height: '30px',
          }}
        >
          {props.titleNote ? (
            renderNoteVersion(
              {
                item: props.titleNote,
                parentID: props.ID,

                selectoRef: null,
                isContainedInArea: null,
                totalItemsInStack: 0,
                itemIndexPosition: 0,
                isTitleNote: true,
                insertModeActive: false,
                parentPostion: props.position,

                useSelectionV2: useselectionv2,
                lastInteraction: props.lastInteraction,
                parentLastInteraction: props.lastInteraction,
                isSelected: false,
                parentType: 'area',
              },

              props.mapID
            )
          ) : (
            <CreateStackHeaderplaceHolder
              onClick={() => addAreaTitleNote(props.ID, props.mapID)}
            >
              <PlusOutlined />
              <p> Title Note</p>
            </CreateStackHeaderplaceHolder>
          )}
        </div>
        <IconAndStampContainer>
          {/*
           *ADD STAMPS HERE
           */}
          <SettingFilled
            style={{
              fontSize: '20px',
              color: '#F0EAD6',
              width: 'min-comtent',
              height: 'min-content',
              borderRadius: '100%',
              marginRight: '20px',
              backgroundColor: `${themeStore.canvasBackgroundColor}`,
            }}
          />
        </IconAndStampContainer>
      </TitleStampsWrapper>
    </div>
  );
}
