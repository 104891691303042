// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type MapMapLogoIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function MapMapLogoIcon(props: MapMapLogoIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 12 12"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M0 1.54v9.206a.59.59 0 00.59.589h1.349a.59.59 0 00.589-.59V7.228a.59.59 0 011.006-.416l1.342 1.342c.23.23.603.23.833 0l.747-.746a.59.59 0 000-.834l-5.45-5.45A.59.59 0 000 1.54zm11.389.061v9.262a.59.59 0 01-.59.59H9.206a.59.59 0 01-.59-.59v-3.29a.59.59 0 00-.14-.383l-1.278-1.5a.59.59 0 010-.764L10.35 1.22a.59.59 0 011.038.382z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default MapMapLogoIcon;
/* prettier-ignore-end */
