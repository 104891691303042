import { proxy } from 'valtio';

export const gestureStore = proxy<{
  isResizing: boolean;
  isEditing: boolean;
  isPanning: boolean;
  Dragging: {
    isDragging: boolean;
    isMultiDrag: boolean;
  };
  prevSelectPos?: { x: number; y: number };
}>({
  isResizing: false,
  isEditing: false,
  isPanning: false,
  Dragging: {
    isDragging: false,
    isMultiDrag: false,
  },
});

export const zoomLevel = proxy<{
  level: number;
}>({
  level: 0,
});

// ==================================== Actions =======================================================
export function setIsResizing(isResizing: boolean) {
  gestureStore.isResizing = isResizing;
}

export function setIsEditing(isEditing: boolean) {
  gestureStore.isEditing = isEditing;
}

export function setIsPanning(isPanning: boolean) {
  gestureStore.isPanning = isPanning;
}

export function setPrevSelectPos(prevSelectPos: { x: number; y: number }) {
  gestureStore.prevSelectPos = prevSelectPos;
}

export function getPrevSelectPos() {
  return gestureStore.prevSelectPos;
}

export function setZoomLevel(zl: number) {
  zoomLevel.level = zl;
}
/**
 *
 * @param dragging set the dragging key to true
 * @param isMultiDrag set the multiDrag to true if it's a multidrag
 */

export function setDragging(dragging: boolean, isMultiDrag: boolean) {
  gestureStore.Dragging.isDragging = dragging;
  gestureStore.Dragging.isMultiDrag = isMultiDrag;
}
