import { Action } from 'CoreComponents/Item/Item';
import SlateEditor from 'CoreComponents/SlateEditor/SlateEditor';
import React, { useEffect } from 'react';
import { imageTag } from 'utils/stores/constants';
import { setIsEditing } from 'utils/stores/gestureStore';
import { NoteItemData } from 'utils/stores/types';

export type EditingItemState = 'yes' | 'no' | undefined;
export interface NoteProps {
  item: NoteItemData;
  parentID: string;
  editingItem: EditingItemState;
  updateNoteState: React.Dispatch<Action>;
  itemRef: React.MutableRefObject<HTMLElement | null>;
  isResizing: boolean;
  containedInArea: string;
}
function Note_({
  item,
  parentID,
  editingItem,
  updateNoteState,
  itemRef,
  isResizing,
  containedInArea,
}: NoteProps) {
  // press "modifier" (cmd/ctrl) + "alt" + "0-9" to apply style hotkeys
  function handleFinishedEditing() {
    updateNoteState({
      target: 'editingNote',
      payload: 'no',
    });
    setIsEditing(false);
  }
  function handleStartEditing() {
    if (item.content?.textContent === imageTag) {
      return;
    }
    updateNoteState({
      target: 'editingNote',
      payload: 'yes',
    });
    setIsEditing(true);
  }

  const isReadOnly = () => {
    if (item.content?.textContent === imageTag) {
      return true;
    }
    if (editingItem === 'no') {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (editingItem !== 'yes') {
      updateNoteState({
        target: 'editingNote',
        payload: 'yes',
      });
    }
  }, []);

  return (
    <SlateEditor
      isResizing={isResizing}
      itemRef={itemRef}
      parentID={parentID}
      item={item}
      containedInArea={containedInArea}
      readOnly={isReadOnly()}
      onFinishedEditing={handleFinishedEditing}
      onStartEditing={handleStartEditing}
      editing={editingItem}
      updateNoteState={updateNoteState}
    />
  );
}
const Note = Note_;
export default Note;
