// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type ArrowsCaretRight2IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function ArrowsCaretRight2Icon(props: ArrowsCaretRight2IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 24 24"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M17.502 12.613c.412-.392.374-.96-.112-1.32l-8.089-6-.114-.076C8.33 4.703 7 5.147 7 6v12l.002.06.007.057.012.06.015.052.043.108.035.067.023.036.073.096.073.077.112.094.11.073.12.064.179.071.09.027.145.032.071.01.08.01.079.004.08.002.079-.002.077-.005.081-.008.071-.011.146-.032.09-.027.048-.017.13-.054.103-.054.127-.083 8.09-6 .111-.094z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default ArrowsCaretRight2Icon;
/* prettier-ignore-end */
