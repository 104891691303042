import useGlobalHotkeys from 'constants/hotKeys/globalHotkeys';
import useMapHotkeys from 'constants/hotKeys/mapHotkeys';
import useThemeHotkeys from 'constants/hotKeys/themeHotkeys';
import { useEffect } from 'react';
import { HotkeysProps, hotKeysStore } from 'utils/stores/hotKeysStore';
import useItemKeys from '../../../constants/hotKeys/styleHotkeys';

export default function useHotkeysArrangement({ global = false } = {}) {
  const { defaultKeys: defaultItemHotkeys } = useItemKeys();
  const { defaultThemeHotkeys } = useThemeHotkeys();
  const { defaultMapKeys } = useMapHotkeys();
  const { defaultGlobalHotkeys } = useGlobalHotkeys();

  const arrangedDefaultHotkeys = (defaultKeys: HotkeysProps) => {
    return Object.entries(defaultKeys).map(([key, hotkey]) => {
      return {
        [hotkey.shortcut]: {
          ...hotkey,
          label: key,
          shortcut: hotkey.shortcut,
        },
      };
    });
  };

  const arrangeStyleHotkeys = () => {
    const formattedHotkeys = arrangedDefaultHotkeys(defaultItemHotkeys);

    if (sampleBackendData.length) {
      const updatedKeys = { ...defaultItemHotkeys };

      sampleBackendData.forEach(data => {
        // Check if the actionLabel from backend matches any key in defaultKeys
        if (updatedKeys[data.actionLabel]) {
          // Update the combo array of the matched action label with the new combo from backend
          updatedKeys[data.actionLabel].shortcut = data.shortcut;
          updatedKeys[data.actionLabel].alternativeShortcut =
            data.alternativeShortcut;
        }
      });

      const newFormattedHotkeys = arrangedDefaultHotkeys(updatedKeys);

      // Merge all formatted item hotkeys into the store
      newFormattedHotkeys.forEach(newFormattedHotkey => {
        const key = Object.keys(newFormattedHotkey)[0];
        const hotkey = newFormattedHotkey[key];
        hotKeysStore.itemHotkeys = {
          ...hotKeysStore.itemHotkeys,
          [key]: {
            ...hotkey,
            action: global ? () => {} : hotkey.action, // Override action if global is true
          },
        };
      });
    } else {
      // Merge all formatted item hotkeys into the store
      formattedHotkeys.forEach(formattedHotkey => {
        const key = Object.keys(formattedHotkey)[0];
        const hotkey = formattedHotkey[key];
        hotKeysStore.itemHotkeys = {
          ...hotKeysStore.itemHotkeys,
          [key]: {
            ...hotkey,
            action: global ? () => {} : hotkey.action,
          },
        };
      });
    }
  };

  const arrangeThemeHotkeys = () => {
    const formattedHotkeys = arrangedDefaultHotkeys(defaultThemeHotkeys);

    // Merge all formatted item hotkeys into the store
    formattedHotkeys.forEach(formattedHotkey => {
      const key = Object.keys(formattedHotkey)[0];
      const hotkey = formattedHotkey[key];

      hotKeysStore.themeHotkeys = {
        ...hotKeysStore.themeHotkeys,
        [key]: {
          ...hotkey,
          action: global ? () => {} : hotkey.action,
        },
      };
    });
  };

  const arrangeMapHotkeys = () => {
    const formattedHotkeys = arrangedDefaultHotkeys(defaultMapKeys);
    // Merge all formatted item hotkeys into the store
    formattedHotkeys.forEach(formattedHotkey => {
      const key = Object.keys(formattedHotkey)[0];
      const hotkey = formattedHotkey[key];

      hotKeysStore.mapHotkeys = {
        ...hotKeysStore.mapHotkeys,
        [key]: {
          ...hotkey,
          action: global ? () => {} : hotkey.action,
        },
      };
    });
  };
  const arrangeGlobalHotkeys = () => {
    const formattedHotkeys = arrangedDefaultHotkeys(defaultGlobalHotkeys);
    // Merge all formatted item hotkeys into the store
    formattedHotkeys.forEach(formattedHotkey => {
      hotKeysStore.globalHotkeys = {
        ...hotKeysStore.globalHotkeys,
        ...formattedHotkey,
      };
    });
  };

  useEffect(() => {
    arrangeGlobalHotkeys();
    arrangeStyleHotkeys();
    arrangeMapHotkeys();
    arrangeThemeHotkeys();
  }, [sampleBackendData]);
}

interface SampleBackendProps {
  shortcut: string;
  alternativeShortcut: string | null;
  actionLabel: string;
}

const sampleBackendData: SampleBackendProps[] = [
  {
    shortcut: 'mod+shift+d',
    alternativeShortcut: null,
    actionLabel: 'Apply Fade',
  },
];
