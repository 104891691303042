import { ForgotPassword } from 'views/auth/signin/forgotpassword';
import { ImpersonateSignin } from 'views/auth/signin/impersonate';
import { MagicSignin } from 'views/auth/signin/magic';
import { SigninOTP } from 'views/auth/signin/otp';
// import { ResetPassword } from 'views/auth/signin/reset-password';
import { SocialSignin } from 'views/auth/signin/social';
import { SignupPlan } from 'views/auth/signup/plan';
import { SignupUser } from 'views/auth/signup/user';
import { SignupVerification } from 'views/auth/signup/verify';
import DesktopEntry from '../views/auth/desktop/entry';
import MagicLink from '../views/auth/desktop/magic-link';
import ResetPassword from '../views/auth/desktop/reset-password';

const Routes = [
  {
    path: '/signup/verify',
    view: SignupVerification,
    layout: 'auth',
    permission: 'user',
    title: 'Please verify your email address',
  },
  {
    path: '/signup/plan',
    view: SignupPlan,
    layout: 'auth',
    permission: 'owner',
    title: 'Sign up to MapMap',
  },
  {
    path: '/signup/user',
    view: SignupUser,
    layout: 'auth',
    title: 'Sign up to MapMap',
  },

  {
    path: '/signin/otp',
    view: SigninOTP,
    layout: 'auth',
    title: 'Enter verification code',
  },
  {
    path: '/signin/social',
    view: SocialSignin,
    layout: 'auth',
    title: 'Completing Sign In',
  },
  {
    path: '/magic',
    view: MagicSignin,
    layout: 'auth',
    title: 'Sign In',
  },
  {
    path: '/forgotpassword',
    view: ForgotPassword,
    layout: 'auth',
    title: 'Forgot Your Password?',
  },
  {
    path: '/resetpassword',
    view: ResetPassword,
    layout: 'auth',
    title: 'Reset Your Password',
  },
  {
    path: '/signin/impersonate',
    view: ImpersonateSignin,
    layout: 'auth',
    title: 'Sign in via Mission Control',
  },
  {
    path: '/desktop-entry',
    view: DesktopEntry,
    layout: 'core',
    title: 'Desktop Entry',

  },
  {
    path: '/desktop-entry/reset-password',
    view: ResetPassword,
    layout: 'core',
    title: 'Desktop Reset Password',
  },
  {
    path: '/desktop-entry/magic-link',
    view: MagicLink,
    layout: 'core',
    title: 'Desktop Magic Link',
  },
];

export default Routes;
