// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type IconLIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function IconLIcon(props: IconLIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 24 24"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M8 4a1 1 0 01.117 1.993L8 6H7v2h4a2 2 0 012-2h6a2 2 0 012 2v2a2 2 0 01-2 2h-6a2 2 0 01-2-2H7v6h4a2 2 0 012-2h6a2 2 0 012 2v2a2 2 0 01-2 2h-6a2 2 0 01-2-2H7a2 2 0 01-1.995-1.85L5 16V6H4a1 1 0 01-.117-1.993L4 4h4zm5 13v1h6v-2h-6v1zm0-7V8h6v2h-6z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default IconLIcon;
/* prettier-ignore-end */
