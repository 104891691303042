import {
  cAlignments,
  cBorderWeight,
  cFontSize,
  cIndentation,
} from 'utils/stores/constants';
import {
  currentMapStateStore,
  currentMapContentStore,
  updateItemOrAreaStyle,
} from 'utils/stores/mapStore';
import { Style } from 'utils/stores/types';
import { getStackAndItem, getParentIdFromItemID } from './mapStoreFN_stacks';
import getSelectedIDs from 'utils/helpers/Selection/selectionUtils';

type Direction = 'prev' | 'next';
interface StyleFuntionArgs {
  direction: Direction;
  styleObject: Style | null;
  ID: string;
  mapID: string;
}

type UpdateFuntion = (arg: StyleFuntionArgs) => void;
// interface StyleFunctions {
//   alignment: typeof updateAlignment;
//   indentation: typeof updateIndentation;
//   fontSize: typeof updateFontSize;
// }
interface StyleFunctions {
  alignment: UpdateFuntion;
  indentation: UpdateFuntion;
  fontSize: UpdateFuntion;
}

type TargetStyleValue = keyof StyleFunctions;
//TODO look into the benefit of accessing the style property directly from the dom node using getComputedStyle() api
// behaviour on reload
// how reliable it would be.
// compare the time complexity to retrieve the value we want in each case

const getIDStyleProperties = (ID: string, mapID: string) => {
  let style = null;

  if (ID.includes('item_')) {
    const stackID = getParentIdFromItemID(ID);

    if (stackID) {
      const { itemData } = getStackAndItem(ID, mapID);

      if (itemData && itemData.style) {
        style = itemData.style;
      }
    }
  }

  if (ID.includes('area_')) {
    const areaData = currentMapContentStore[mapID].value.areas.find(
      area => area.areaId === ID
    );
    if (areaData && areaData.style) style === areaData.style;
  }

  return style;
};

const updateBorderWeight = (arg: StyleFuntionArgs) => {
  const getValue = arg.styleObject?.border?.thickness;
  if (!arg.styleObject || !getValue) {
    return updateItemOrAreaStyle(arg.ID, {}, arg.mapID, undefined, {
      path: 'border.thickness',
      value: '_1',
    });
  }

  let valueIndex = cBorderWeight.findIndex(
    borderWeight => borderWeight === getValue
  );

  if (arg.direction === 'next') {
    const nextStyle = cBorderWeight[valueIndex + 1];

    updateItemOrAreaStyle(arg.ID, {}, arg.mapID, undefined, {
      path: 'border.thickness',
      value: nextStyle || '_0',
    });

    return;
  }
  if (arg.direction === 'prev') {
    const PrevStyle = cBorderWeight[valueIndex - 1];

    updateItemOrAreaStyle(arg.ID, {}, arg.mapID, undefined, {
      path: 'border.thickness',
      value: PrevStyle || '_5',
    });
    return;
  }
  // updateLastInteraction(ID, mapID);
  return;
};

const updateAlignment = (arg: StyleFuntionArgs) => {
  if (arg.ID.includes('area_')) return;
  const getCurrenAlignment = arg.styleObject?.textAlignment;

  if (!arg.styleObject || !getCurrenAlignment) {
    updateItemOrAreaStyle(
      arg.ID,
      {
        textAlignment: 'center',
      },
      arg.mapID
    );
    return;
  }

  let alignmentIndex = cAlignments.findIndex(
    alignment => alignment === getCurrenAlignment
  );

  if (arg.direction === 'next') {
    const nextStyle = cAlignments[alignmentIndex + 1];

    updateItemOrAreaStyle(
      arg.ID,
      {
        textAlignment: nextStyle || 'left', // if undefined go back to the first style value
      },
      arg.mapID
    );
    return;
  }
  if (arg.direction === 'prev') {
    const prevStyle = cAlignments[alignmentIndex - 1];

    updateItemOrAreaStyle(
      arg.ID,
      {
        textAlignment: prevStyle || 'justify', // if undefined go back to the last style value
      },
      arg.mapID
    );
    return;
  }
  // updateLastInteraction(ID, mapID);
  return;
};

const updateFontSize = (arg: StyleFuntionArgs) => {
  if (arg.ID.includes('area_')) return;
  let currentFontSize = arg.styleObject?.fontSize;
  if (!arg.styleObject || !currentFontSize) {
    updateItemOrAreaStyle(
      arg.ID,
      {
        fontSize: 16,
      },
      arg.mapID
    );
    return;
  }

  const fontLowerBound = cFontSize[0];
  const fontUpperBound = cFontSize[1];
  if (arg.direction === 'next') {
    fontUpperBound >= currentFontSize + 4;
    updateItemOrAreaStyle(
      arg.ID,
      {
        fontSize: currentFontSize + 4,
      },
      arg.mapID
    );
    return;
  }
  if (arg.direction === 'prev') {
    currentFontSize - 4 > fontLowerBound;
    updateItemOrAreaStyle(
      arg.ID,
      {
        fontSize: currentFontSize - 4,
      },
      arg.mapID
    );
    return;
  }
  // updateLastInteraction(ID, mapID);
  return;
};

const updateIndentation = (arg: StyleFuntionArgs) => {
  const getValue = arg.styleObject?.indentation;
  if (arg.ID.includes('area_')) return;

  if (!arg.styleObject || !getValue) {
    return updateItemOrAreaStyle(
      arg.ID,
      {
        indentation: '_1',
      },
      arg.mapID
    );
  }

  let valueIndex = cIndentation.findIndex(
    indentation => indentation === getValue
  );

  if (arg.direction === 'next') {
    const nextStyle = cIndentation[valueIndex + 1];

    updateItemOrAreaStyle(
      arg.ID,
      {
        indentation: nextStyle || '_0',
      },
      arg.mapID
    );

    return;
  }
  if (arg.direction === 'prev') {
    const prevStyle = cIndentation[valueIndex - 1];

    updateItemOrAreaStyle(
      arg.ID,
      {
        indentation: prevStyle || '_7',
      },
      arg.mapID
    );
    return;
  }
  return;
};

/**
 * @params :targetStyleValue : string - style property we intend to have updated
 * this includes only  property where the previous style value is need to determine the next
 * direction: determines if we want to update it to the next style or the previous style value
 */

// private function should only be called by the setNextStyleValue

const styleFunctions: StyleFunctions = {
  alignment: updateAlignment,
  indentation: updateIndentation,
  fontSize: updateFontSize,
};

function updateNextStyleProperty(
  mapID: string,
  ID: string,
  targetStyleValue: TargetStyleValue,
  direction: Direction,
  stylePropertyFunction: UpdateFuntion
) {
  const styleProperties: Style | null = getIDStyleProperties(ID, mapID);

  stylePropertyFunction({
    direction,
    styleObject: styleProperties,
    ID,
    mapID,
  });
}

export function setNextStyleValue(
  mapID: string,
  targetStyleValue: TargetStyleValue,
  direction: Direction
) {
  const selectedID = getSelectedIDs();

  const getUpdateFunction = styleFunctions[targetStyleValue];

  if (!getUpdateFunction) {
    throw new Error(
      'use only supported style - go to MapStoreFN_styles and add property to use',
      {
        cause: 'Target value passed not supported',
      }
    );
  }

  if (selectedID.length === 0) return;
  selectedID.forEach(ID => {
    updateNextStyleProperty(
      mapID,
      ID,
      targetStyleValue,
      direction,
      getUpdateFunction
    );
  });
}
