import { HTMLElementRefOf } from '@plasmicapp/react-web';
import { useCreateFolder, useCreateMap } from 'api/mutations';
import * as React from 'react';
import { TEST_MAP } from 'utils/stores/exampleMaps';
import {
  DefaultDashboardContentProps,
  PlasmicDashboardContent,
} from './plasmic/map_map/PlasmicDashboardContent';

import { AntdBreadcrumbItem } from '@plasmicpkgs/antd5/skinny/registerBreadcrumb';
import { v4 as uuidv4 } from 'uuid';

import { Link, useParams } from 'react-router-dom';

import { useHotkeys } from '@mantine/hooks';
import { useFlag } from '@unleash/proxy-client-react';
import { useGetUserFolderStructure } from 'api/queries';
import {
  CustomEmojiPicker,
  ExtendedEmojiDataType,
} from 'components/emojiPicker/CustomEmojiPicker';
import SimpleTextInput from 'CoreComponents/SimpleTextInput/SimpleTextInput';
import { IconLoader, ListGridSwitch } from 'propro-common-components';
import { getMostFrequentIcons } from 'utils/helpers/folderstructureFunctions';
import {
  dashboardStore,
  findAncestors,
  updateBreadcrumbs,
} from 'utils/stores/dashboardStore';
import { folderMapCreationFlow } from 'utils/stores/types';
import { useSnapshot } from 'valtio';
import { FolderStructure } from 'views/dashboard/DashboardView';
import { addMapBeingCreated, generalStore } from '../utils/stores/generalStore';

// import Picker  from '@emoji-mart/react';
// import 'emoji-mart/css/emoji-mart.css';

export interface DashboardContentProps extends DefaultDashboardContentProps {
  selectedIcon?: ExtendedEmojiDataType;
  setShowIconAndEmojiSelector?: any;
  showIconAndEmojiSelector?: boolean;
}

// unlike its parents (DashboardView and DashboardOverviewView), the logic here
// does not handle the rendering of the maps and folders
// but their creation
// and the breadcrumbs
function DashboardContent_(
  {
    selectedIcon,
    setShowIconAndEmojiSelector,
    showIconAndEmojiSelector,
    ...props
  }: DashboardContentProps,
  ref: HTMLElementRefOf<'div'>
) {
  const layoutButtonEnabled = useFlag('layoutToggleButton');

  const { folderId } = useParams();

  const inputRef = React.useRef(null);
  const confirmButtonRef = React.useRef(null);

  const { mutate: addMap } = useCreateMap(true, folderId);
  const createFolder = useCreateFolder(folderId);

  const { breadcrumbs } = useSnapshot(dashboardStore);

  const [folderCreationState, setFolderCreationState] =
    React.useState<folderMapCreationFlow>('createFolderButton');

  const [creatingMap, setCreatingMap] = React.useState(false);

  const [mapCreationState, setmapCreationState] =
    React.useState<folderMapCreationFlow>('createMapButton');

  const [title, setTitle] = React.useState('');
  const [emoji, setEmoji] = React.useState(null);

  const { data: folderStructure, isLoading: structureLoading } =
    useGetUserFolderStructure() as FolderStructure;

  const handleInputChange = e => {
    setTitle(e.target.value);
  };

  const frequentIcons = React.useMemo(
    () => getMostFrequentIcons(folderStructure),
    [folderStructure]
  );

  const handleAddMap = emojiData => {
    const newMapId = uuidv4();
    const mapDTO = { ...TEST_MAP, mapId: newMapId, name: title };
    addMapBeingCreated(newMapId);

    if (emojiData === null) {
      mapDTO.icon = { name: '🗺️', type: 'emoji', color: '' };
    } else {
      if (emojiData.iconType === 'emoji')
        mapDTO.icon = { name: emojiData.icon, color: '', type: 'emoji' };
      if (emojiData.iconType === 'icon')
        mapDTO.icon = {
          name: emojiData.icon,
          color: emojiData.color,
          type: 'icon',
        };
    }
    if (folderId) mapDTO.folderId = folderId;

    addMap(mapDTO);

    setmapCreationState('createMapButton');
    setTitle('');
    setEmoji('');
  };

  const handleCreateFolder = (folderTitle: string, emojiData) => {
    const newFolder: {
      name: string;
      description: string;
      parentFolderId?: string;
      icon?: { name: string; type: string; color: string };
    } = {
      name: folderTitle,
      description: 'Brief test on folder creation',
    };

    if (emojiData === null) {
      newFolder.icon = { name: '📂', type: 'emoji', color: '' };
    } else {
      if (emojiData.iconType === 'emoji')
        newFolder.icon = { name: emojiData.icon, color: '', type: 'emoji' };
      if (emojiData.iconType === 'icon')
        newFolder.icon = {
          name: emojiData.icon,
          color: emojiData.color,
          type: 'icon',
        };
    }
    if (folderId) newFolder.parentFolderId = folderId;

    if (!newFolder.name) {
      return;
    }

    createFolder.mutateAsync(newFolder);

    setFolderCreationState('createFolderButton');
    setTitle('');
    setEmoji('');
  };

  const updateCreationState = (item: 'folder' | 'map') => {
    if (item === 'folder') {
      setCreatingMap(false);
      if (folderCreationState === 'createFolderButton') {
        if (
          mapCreationState === 'titleInput' ||
          mapCreationState === 'iconOrEmojiSelection'
        ) {
          setmapCreationState('createMapButton');
          if (showIconAndEmojiSelector) setShowIconAndEmojiSelector();
        }

        setFolderCreationState('titleInput');

        confirmButtonRef?.current.blur();
        inputRef?.current?.focus();
      } else if (folderCreationState === 'titleInput') {
        if (title === '') return;

        setFolderCreationState('iconOrEmojiSelection');
      } else if (folderCreationState === 'iconOrEmojiSelection') {
        createFolderMap(emoji);
        setFolderCreationState('createFolderButton');
      }
    }
    if (item === 'map') {
      setCreatingMap(true);
      if (mapCreationState === 'createMapButton') {
        if (
          folderCreationState === 'titleInput' ||
          folderCreationState === 'iconOrEmojiSelection'
        ) {
          setFolderCreationState('createFolderButton');
          if (showIconAndEmojiSelector) setShowIconAndEmojiSelector();
        }

        setmapCreationState('titleInput');
      } else if (mapCreationState === 'titleInput') {
        if (title === '') return;

        setmapCreationState('iconOrEmojiSelection');
      } else if (mapCreationState === 'iconOrEmojiSelection') {
        createFolderMap(emoji);
        setmapCreationState('createMapButton');
      }
    }
  };

  const createFolderMap = (emojiData?: ExtendedEmojiDataType) => {
    if (folderCreationState === 'iconOrEmojiSelection') {
      handleCreateFolder(title, emojiData);
      return;
    }

    if (mapCreationState === 'iconOrEmojiSelection') {
      handleAddMap(emojiData);
      return;
    }

    setEmoji(emojiData);
  };

  const handleKeyDown = event => {
    event.stopPropagation();
    console.log('button hit');
    if (event.key === 'Enter') {
      if (folderCreationState === 'titleInput') {
        setFolderCreationState('iconOrEmojiSelection');
        return;
      }
      if (mapCreationState === 'titleInput') {
        setmapCreationState('iconOrEmojiSelection');
        return;
      }
    }
  };

  React.useEffect(() => {
    updateBreadcrumbs(
      folderId ? findAncestors(folderId, folderStructure?.root) : []
    );

    setTitle('');
    setmapCreationState('createMapButton');
    setFolderCreationState('createFolderButton');
  }, [folderId, folderStructure]);

  React.useEffect(() => {
    generalStore.frequentlUsedIcons = frequentIcons;
  }, [frequentIcons]);

  const reArrangedBreadcrumbs = [
    { name: 'Root', id: ':root' },
    { name: 'Root Folder' },
    ...breadcrumbs,
  ];

  React.useEffect(() => {
    if (selectedIcon) {
      createFolderMap(selectedIcon);
    }
  }, [selectedIcon]);

  React.useEffect(() => {
    const textInput = document.querySelector('#mapInput') as HTMLInputElement;
    if (!textInput) return;
    setTimeout(() => {
      textInput.focus();
    }, 500);

    setTimeout(() => {
      const focusableIcon = document.querySelector('.icon') as HTMLElement;
      if (focusableIcon) {
        focusableIcon.focus();
      }
    }, 200);
  }, [mapCreationState]);

  React.useEffect(() => {
    const textInput = document.querySelector(
      '#folderInput'
    ) as HTMLInputElement;
    if (!textInput) return;
    setTimeout(() => {
      textInput.focus();
    }, 500);

    setTimeout(() => {
      const focusableIcon = document.querySelector('.icon') as HTMLElement;
      if (focusableIcon) {
        focusableIcon.focus();
      }
    }, 200);
  }, [folderCreationState]);

  useHotkeys([
    [
      'Escape',
      e => {
        e.stopPropagation();
        e.preventDefault();
        if (showIconAndEmojiSelector) {
          setShowIconAndEmojiSelector(!showIconAndEmojiSelector)(
            document.querySelector('#selectMore') as HTMLElement
          ).focus();
          return;
        }
        setTitle('');
        setmapCreationState('createMapButton');
        setFolderCreationState('createFolderButton');
      },
    ],
  ]);

  return (
    <PlasmicDashboardContent
      // className="op"
      // className="bg-[green] p-5 border border-white"
      listGridSwitchPlaceholderSlot={
        layoutButtonEnabled ? <ListGridSwitch color={'green'} /> : null
      }
      style={{
        height: '100%',
      }}
      folderBreadcrumbs={{
        style: {
          color: 'white',
        },
        itemsRaw:
          // <>
          reArrangedBreadcrumbs.map((folder, index) => (
            <div key={folder.folderId}>
              {folder.id === ':root' ? (
                <AntdBreadcrumbItem>
                  <Link
                    to="/dashboard"
                    className="flex items-center justify-center"
                  >
                    {
                      <IconLoader
                        name={'CabanaUserInterfaceHomeLine'}
                        size={19.2}
                        color={'white'}
                      />
                    }
                  </Link>
                </AntdBreadcrumbItem>
              ) : index !== reArrangedBreadcrumbs.length - 1 ? (
                <AntdBreadcrumbItem>
                  {folder.name === 'Root Folder' ? (
                    <Link style={{ color: 'grey' }} to={`/dashboard`}>
                      {folder.name}
                    </Link>
                  ) : (
                    <Link
                      style={{ color: 'grey' }}
                      to={`/dashboard/folder/${folder.folderId}`}
                    >
                      {folder.name}
                    </Link>
                  )}
                </AntdBreadcrumbItem>
              ) : (
                <AntdBreadcrumbItem>
                  <Link style={{ color: 'white' }} to={''}>{folder.name}</Link>
                </AntdBreadcrumbItem>
              )}
            </div>
          )),
        // </>
      }}
      folderCreation={{
        emojiPickerSlot: (
          <div style={{ zIndex: '20' }}>
            <CustomEmojiPicker
              setEmoji={setEmoji}
              onReactionSelect={createFolderMap}
              isCreatingMap={creatingMap}
            />
          </div>
        ),

        confirmButton: {
          ref: confirmButtonRef,
          onClick: () => updateCreationState('folder'),
          onKeyDown: e => {
            e.preventDefault();
            e.stopPropagation();
            if (e.key === 'Enter') updateCreationState('folder');
          },
        },

        showMoreButton: {
          id: 'selectMore',
          onClick: () => setShowIconAndEmojiSelector(),
          onKeyDown: e => {
            if (e.key === 'Enter') setShowIconAndEmojiSelector();
          },
        },
        titleCreationTextInputSlot: (
          <SimpleTextInput
            ref={inputRef}
            id="folderInput"
            placeholder="Enter Folder Title"
            onChange={handleInputChange}
            autoFocus={true}
            autoComplete="off"
            value={title}
            onKeyDown={handleKeyDown}
            // className="text-white focus:ring-white focus:border-white focus:shadow-outline border-none"
          />
        ),
        // textInput: {
        //   // className: 'text-white focus:outline-none',
        //   id: 'folderInput',
        //   ref: inputRef,
        //   onChange: handleInputChange,
        //   autoFocus: true,
        //   autoComplete: 'off',
        //   value: title,
        //   onKeyDown: handleKeyDown,
        // },

        creationFlow: folderCreationState,
      }}
      mapCreation={{
        emojiPickerSlot: (
          <div style={{ zIndex: '20' }}>
            <CustomEmojiPicker
              setEmoji={setEmoji}
              onReactionSelect={createFolderMap}
              isCreatingMap={creatingMap}
            />
          </div>
        ),

        showMoreButton: {
          id: 'selectMore',
          onClick: () => setShowIconAndEmojiSelector(),
          onKeyUp: e => {
            e.preventDefault();
            e.stopPropagation();
            if (e.key === 'Enter') {
              setShowIconAndEmojiSelector();
              // (document.querySelector('#selectMore') as HTMLElement).focus();
            }
          },
        },

        confirmButton: {
          ref: confirmButtonRef,
          onClick: () => {
            updateCreationState('map');
          },
          onKeyDown: e => {
            e.preventDefault();
            e.stopPropagation();
            if (e.key === 'Enter') updateCreationState('map');
          },
        },

        titleCreationTextInputSlot: (
          <SimpleTextInput
            ref={inputRef}
            id="mapInput"
            placeholder="Enter Map Title"
            onChange={handleInputChange}
            autoFocus={true}
            autoComplete="off"
            value={title}
            onKeyDown={handleKeyDown}
            className="text-white focus:ring-white focus:border-white focus:shadow-outline border-none"
          />
        ),
        // textInput: {
        //   className:
        //     'text-white focus:ring-white focus:border-white focus:shadow-outline border-none',
        //   ref: inputRef,
        //   id: 'mapInput',
        //   onChange: handleInputChange,
        //   autoFocus: true,
        //   autoComplete: 'off',
        //   value: title,
        //   onKeyDown: handleKeyDown,
        // },

        creationFlow: mapCreationState,
      }}
      root={{ ref }}
      {...props}
    />
  );
}

const DashboardContent = React.forwardRef(DashboardContent_);
export default DashboardContent;
