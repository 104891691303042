import { RenderSelectedContent } from 'CoreComponents/Renderers/RenderSelectedContent';
import { ItemProps } from './Item';
import { ReactNode } from 'react';
import ItemMemo from './Item';

export const renderNoteVersion = (
  noteProps: ItemProps,
  mapID: string
): ReactNode => {
  return (
    <RenderSelectedContent
      key={noteProps.item.itemId}
      childprops={{ ...noteProps }}
      mapID={mapID}
      ID={noteProps.item.itemId}
      renderChild={props => <ItemMemo {...props} />}
    />
  );
};
