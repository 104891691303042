import { loadStripe } from '@stripe/stripe-js';
import { Query, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { FlagProvider } from '@unleash/proxy-client-react';
import 'animate.css';
import axios from 'axios';
import { AppProvider, AuthProvider } from 'propro-common-components';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { unleashConfig } from 'utils/unleashConfig';

import App from './app/app';

import { createIDBPersister } from './utils/storage';
import registerGlobalShortcuts from './utils/tauri/registerGlobalShortcuts';
// const Settings = require('settings.json');
import Settings from 'settings.json';

const container = document.getElementById('root');
const root = createRoot(container);

// global shortcut registration
registerGlobalShortcuts();

loadStripe(Settings[process.env.NODE_ENV].stripe.publishableAPIKey);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 1000 * 60 * 5, // 5 mins
    },
    dehydrate: {
      shouldDehydrateQuery: (query: Query) =>
        JSON.stringify(query.queryKey) === JSON.stringify(['folder structure']),
    },
  },
});

axios.defaults.baseURL = Settings[process.env.NODE_ENV].server_url;
axios.defaults.withCredentials = true;

root.render(
  <StrictMode>
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{ persister: createIDBPersister() }}
    >
      <ReactQueryDevtools initialIsOpen={false} />
      <AppProvider baseURL={Settings[process.env.NODE_ENV].server_url}>
        <AuthProvider>
          <FlagProvider config={unleashConfig}>
            <App />
          </FlagProvider>
        </AuthProvider>
      </AppProvider>
    </PersistQueryClientProvider>
  </StrictMode>
);

const welcome = () => console.log('Welcome to MapMap 🚀');
welcome();
// welcome('de529c70-eb80-4dfb-9540-5075db7545bf');
