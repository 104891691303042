import Sidebar from 'PlasmicComponents/Sidebar';
import { MouseEventHandler } from 'react';

interface sidebarLeftProps {
  width: number;
  onMouseMove: MouseEventHandler;
  onMouseLeave: MouseEventHandler;
  onMouseDown: MouseEventHandler;
}

export function SideBarLeft({width, onMouseMove, onMouseLeave, onMouseDown}: sidebarLeftProps) {
  return (
    <div>
      <Sidebar onMouseLeave={onMouseLeave} onMouseDown={onMouseDown} width={width} onMouseMove={onMouseMove} />
    </div>
  );
}
