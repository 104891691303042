const Style = {
  base: `relative text-center mb-4 after:absolute after:w-4 after:h-[2px] after:left-1/2 
    after:-bottom-2 after:bg-slate-200 after:-translate-x-1/2 md:mb-6 text-brand-500 dark:text-white`,

  title: "font-semibold mb-2",
  subtitle: "font-semibold mt-0 mb-10 text-xl md:text-2xl md:mb-16",
  desc: "text-center",
};

export default Style;
