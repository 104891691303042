import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import MapSyncingAnimation from './lottie/MapSyncingAnimation'

type ModalProps = {
	isOpen?: boolean;
	handleClose?: () => void;
}

export default function SyncingModal ({ isOpen = false, handleClose = () => { } }: ModalProps) {
	return (
		<Transition appear show={isOpen} as={Fragment}>
			<Dialog as="div" className="relative z-10" onClose={handleClose}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					{/* <div className="fixed inset-0 bg-white/5 backdrop-blur-sm cursor-wait" /> */}
					<div className="fixed inset-0 bg-black opacity-50" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-y-auto">
					<div className="flex min-h-full items-center justify-center p-4 text-center">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<Dialog.Panel className="w-full max-w-md transform overflow-hidden bg-transparent p-6 align-middle text-center transition-all">
								<Dialog.Title
									as="h3"
									className="sr-only"
								>
									Ongoing Map Synchronization
								</Dialog.Title>
								<div className="mx-auto w-fit">
									<MapSyncingAnimation />
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	)
}
