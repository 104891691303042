import { useClickOutside } from '@mantine/hooks';
import ShortcutLabel from 'PlasmicComponents/ShortcutLabel';
import { ReactNode, useEffect, useState } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';

export default function Tooltip({
  tooltipText = '',
  shortCutLabel = '',
  activateMethod = 'hover',
  tooltipContent,
  closeOnClick,
  children,
  tooltipColor,
  showArrow = true,
  tooltipInnerClass = '',
  tooltipOuterClass = '',
}: {
  tooltipText: string;
  shortCutLabel: string;
  activateMethod?: 'hover' | 'click';
  tooltipContent: ReactNode;
  children: (ref) => ReactNode;
  closeOnClick?: boolean;
  showArrow?: boolean;
  tooltipColor: string;
  tooltipInnerClass?: string;
  tooltipOuterClass?: string;
}) {
  const [visibleViaClick, setVisibleViaClick] = useState(false);
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    triggerRef,
    visible: visibleViaHover,
  } = usePopperTooltip(
    {
      interactive: true,
    },
    {
      placement: 'top',
    }
  );

  const innerRef = useClickOutside(() => setVisibleViaClick(false), null, [
    triggerRef,
  ]);

  function shouldShow() {
    if (activateMethod === 'hover') return visibleViaHover;
    if (activateMethod === 'click') return visibleViaClick;
    return false;
  }

  function returnToolTip() {
    if (!tooltipText && !tooltipContent) return null;
    return shouldShow() ? (
      <div
        ref={setTooltipRef}
        {...getTooltipProps({
          className: `tooltip-container text-white text-sm flex flex-row gap-x-3 animate__animated animate__fadeIn animate__faster overflow-hidden ${tooltipOuterClass}`,
          style: {
            background: tooltipColor,
            fontFamily: 'Karla',
          },
        })}
        onClick={() => {
          if (closeOnClick) setVisibleViaClick(!visibleViaClick);
        }}
      >
        <div
          className={`px-3 py-2 flex items-center space-x-2 ${tooltipInnerClass}`}
          ref={innerRef}
        >
          <>
            <div>{tooltipText}</div>
            {shortCutLabel && (
              <div>
                <ShortcutLabel>{shortCutLabel}</ShortcutLabel>
              </div>
            )}
          </>
          {tooltipContent}
          {showArrow && (
            <div
              {...getArrowProps({
                className: 'tooltip-arrow',
              })}
            />
          )}
        </div>
      </div>
    ) : null;
  }

  useEffect(() => {
    if (
      triggerRef &&
      triggerRef?.addEventListener &&
      triggerRef?.removeEventListener
    ) {
      const handleClick = () => setVisibleViaClick(prev => !prev);

      triggerRef.addEventListener('click', handleClick, false);
      return () => {
        triggerRef.removeEventListener('click', handleClick, false);
      };
    }
  }, [triggerRef]);

  return (
    <>
      {/* {children && triggerRef && ( */}
      <span>
        {children(setTriggerRef)}
        {returnToolTip()}
      </span>
      {/* )} */}
    </>
  );
}
