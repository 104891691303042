import { styled } from '@stitches/react';
import { ReactNode } from 'react';
import { themeStore } from 'utils/stores/themeStore';
import { ItemType, Style } from 'utils/stores/types';

type BorderElements = {
  children: ReactNode;
};

type BorderType = {
  children: ReactNode;
  style: Style;
  isSelected: boolean;
  type: 'Area' | 'Item' | 'Preview';
  connecting?: boolean;
  isEditing?: 'yes' | 'no';
  parentID?: string;
  isImage?: boolean;
  noteType?: ItemType;
  readOnly?: boolean;
  insertMode?: boolean;
  height?: number;
};

// UI

const SelectionShadow = styled('div', {
  borderStyle: 'inherit',

  width: 'max-content',
  height: 'fit-content',
  borderRadius: '0.9em',
  display: 'flex',
  alignItems: 'center',

  variants: {
    squared: {
      true: {
        borderRadius: '0px',
      },
    },
    type: {
      Item: {
        width: '100%',
      },
      Area: {
        width: 'fit-content',
      },
      Preview: {
        width: 'fit-content',
      },
    },
    selected: {
      true: {
        boxShadow: '0px 0px 0px 4px #f54646',
      },
    },
    isEditing: {
      yes: {
        boxShadow: '0px 0px 0px 4px #e37f8c',
      },
      no: {},
    },

    indentation: {
      _0: {
        marginLeft: '0em',
      },
      _1: {
        marginLeft: '1.12em',
      },
      _2: {
        marginLeft: '2.12em',
      },
      _3: {
        marginLeft: '3.12em',
      },
      _4: {
        marginLeft: '4.12em',
      },
      _5: {
        marginLeft: '5.12em',
      },
      _6: {
        marginLeft: '6.12em',
      },
      _7: {
        marginLeft: '7.12em',
      },
    },
  },
  compoundVariants: [
    {
      selected: true,
      isEditing: 'yes',
      css: {
        boxShadow: '0px 0px 0px 4px #e37f8c',
      },
    },
  ],
});

const MainBorder = styled('div', {
  borderRadius: 'inherit',
  height: 'auto',

  borderStyle: 'solid',

  backgroundColor: 'var(--bg-color)',

  variants: {
    connecting: {
      true: {
        backgroundColor: 'rgba(255, 255, 255, 0.192)',
        backdropFilter: 'blur(10px)',
      },
    },
    type: {
      Item: {
        width: '100%',
      },
      Area: {
        width: 'fit-content',
      },
      Preview: {
        width: 'fit-content',
      },
    },
    hasDropShadow: {
      true: {
        boxShadow: `0px 2px 2px 2px rgba(0, 0, 0, 0.4)`,
      },
    },

    glow: {
      true: {
        filter: 'drop-shadow(0 0 0.3rem var(--border-color))',
      },
    },

    BorderWeight: {
      _0: {
        borderWidth: '0px',
      },
      _1: {
        borderWidth: '2px',
      },
      _2: {
        borderWidth: '3px',
      },
      _3: {
        borderWidth: '4px',
      },
      _4: {
        borderWidth: '6px',
      },
      _5: {
        borderWidth: '8px',
      },
    },
  },
  compoundVariants: [
    {
      connecting: 'true',
      css: {
        backgroundColor: 'rgba(255, 255, 255, 0.192)',
        backdropFilter: 'blur(10px)',
      },
    },
  ],
});

const EditingBorder = styled('div', {
  outline: `0px solid  `,
  borderRadius: 'inherit',
  // use transparent here would create
  //excess over head - since css engine uses abit of computation
  // resource to apply a transparent bg
  outlineColor: `${themeStore.canvasBackgroundColor}`,
  height: 'auto',
  variants: {
    type: {
      Item: {
        width: '100%',
      },
      Area: {
        width: 'fit-content',
      },
      Preview: {
        width: 'fit-content',
      },
    },

    isEditing: {
      yes: {
        outline: '2px solid',
        outlineColor: '#F0EAD6',
      },
      no: {
        outline: '0px solid',
        outlineColor: `${themeStore.canvasBackgroundColor}`,
      },
    },
  },
});

const HoverBorderWrapper = styled('div', {
  border: '2px dashed',
  borderColor: `${themeStore.canvasBackgroundColor}`,
  borderRadius: '0.9em',
  padding: '0px  7px',
  height: 'auto',
  transition: 'border 0.3s linear',

  variants: {
    squared: {
      true: {
        borderRadius: '0px',
      },
      false: {},
    },
    type: {
      Item: {
        width: '100%',
      },
      Area: {
        width: 'fit-content',
      },
      Preview: {
        width: 'fit-content',
      },
    },
    noPadding: {
      true: {
        padding: '0px',
      },
    },
    BorderWeight: {
      _0: {},
      _1: {
        borderRadius: '0.8em',
        padding: '0px 5px',
      },
      _2: {
        borderRadius: '0.7em',
        padding: '0px 5px',
      },
      _3: {
        borderRadius: '0.6em',
        padding: '0px 4px',
      },
      _4: {
        borderRadius: '0.5em',
        padding: '0px 2px',
      },
      _5: {
        borderRadius: '0.4em',
        padding: '0px 2px 0px 0px',
      },
    },
    readOnly: {
      true: {},
      false: {
        '&:hover': {
          borderColor: '#FCE9D7',
        },
      },
    },
  },
  compoundVariants: [
    {
      squared: 'true',
      css: {
        borderRadius: '0px',
      },
    },
    {
      noPadding: 'true',
      css: {
        padding: '0px',
        overflow: 'clip',
      },
    },
    {
      type: 'Area',
      css: {
        padding: '0px',
      },
    },
    {
      type: 'Preview',
      css: {
        padding: '0px',
      },
    },
  ],
});

const applyBg = (
  isInsertMode: boolean,
  height: number,
  appliedStyleValue: Style
): string => {
  if (!isInsertMode && !height) {
    return appliedStyleValue?.fillColor || themeStore.canvasBackgroundColor;
  }
  if (!isInsertMode) {
    return appliedStyleValue?.fillColor || themeStore.canvasBackgroundColor;
  }
  if (isInsertMode && height < 60 && !appliedStyleValue?.border?.thickness) {
    return 'transparent';
  }

  if (isInsertMode && height > 60) {
    return appliedStyleValue?.fillColor || themeStore.canvasBackgroundColor;
  }

  return appliedStyleValue?.fillColor || themeStore.canvasBackgroundColor;
};
function Border(props: BorderType) {
  return (
    <div
      style={{
        width: props.readOnly ? '' : '100%',
        height: 'max-content',
      }}
      className="py-1"
    >
      <SelectionShadow
        selected={props?.isSelected}
        // BorderWeight={props?.style?.border?.thickness || '_0'}
        isEditing={props?.isEditing}
        squared={props?.style?.border?.isSquared}
        indentation={props?.style?.indentation || '_0'}
        type={props.type}
      >
        <EditingBorder isEditing={props?.isEditing} type={props.type}>
          <div
            style={{
              overflow: 'hidden',
              width: '100%',
              height: '100%',
              borderRadius: 'inherit',
            }}
          >
            <MainBorder
              BorderWeight={props?.style?.border?.thickness}
              connecting={props.connecting}
              // hasDropShadow={props?.style?.border?.hasDropShadow}
              glow={
                props?.style?.border?.thickness &&
                props?.style?.border?.thickness !== '_0' &&
                props?.style?.isGlowing
              }
              hasDropShadow={props?.style?.border?.hasDropShadow}
              css={{
                borderColor: props?.style?.border?.color,
                '--bg-color': applyBg(
                  props.insertMode,
                  props?.height,
                  props.style
                ),
                '--border-color': props?.style?.border?.color,
              }}
              type={props.type}
            >
              <HoverBorderWrapper
                BorderWeight={props?.style?.border?.thickness || '_0'}
                squared={props?.style?.border?.isSquared}
                readOnly={props.readOnly}
                noPadding={props.isImage}
                css={{
                  border: props.insertMode && '2px solid rgba(0, 0, 0, 0)',
                }}
                type={props.type}
              >
                {props?.children}
              </HoverBorderWrapper>
            </MainBorder>
          </div>
        </EditingBorder>
      </SelectionShadow>
    </div>
  );
}
export default Border;
