// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type VectorEditFilledIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function VectorEditFilledIcon(props: VectorEditFilledIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 24 24"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M15.03 5.499C14.998 5.225 14.777 5 14.5 5h-5c-.276 0-.497.225-.53.499a4.002 4.002 0 01-3.471 3.47C5.225 9.003 5 9.224 5 9.5v5c0 .276.225.497.499.53a4.002 4.002 0 013.47 3.471c.034.274.255.499.531.499h5c.276 0 .497-.225.53-.499a4.002 4.002 0 013.471-3.47c.274-.034.499-.255.499-.531v-5c0-.276-.225-.497-.499-.53a4.002 4.002 0 01-3.47-3.471zM3 5a2 2 0 114 0 2 2 0 01-4 0zm16-2a2 2 0 100 4 2 2 0 000-4zm0 14a2 2 0 100 4 2 2 0 000-4zM5 17a2 2 0 100 4 2 2 0 000-4z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default VectorEditFilledIcon;
/* prettier-ignore-end */
