import Area from 'CoreComponents/Areas/Area';
import Border from 'components/Border/Border';
import { FC, useEffect, useRef, useState } from 'react';
import { NoteItemData, StackData } from 'utils/stores/types';

import './MapPreview.css';
import { useNavigate, useParams } from 'react-router-dom';
import { toggleMapTextSearchModal } from 'utils/stores/dashboardStore';
import { cNoteConfig } from 'utils/stores/constants';
import { themeStore } from 'utils/stores/themeStore';

interface MapPreviewProps {
  selectedStack: StackData;
  searchText: string;
}

export default function MapPreview({
  selectedStack,
  searchText,
}: MapPreviewProps) {
  const navigate = useNavigate();
  const mapIdFromRoute = useParams().mapId!;

  const containerRef = useRef<HTMLDivElement>(null);
  const [zoomLevel, setZoomLevel] = useState(1);

  const handleNavigateToMap = () => {
    if (!selectedStack) return;

    const mapId = selectedStack?.map.mapId;

    if (mapIdFromRoute !== mapId) {
      const stack = selectedStack.position;
      navigate(
        `/map/${mapId}?stack=${encodeURIComponent(JSON.stringify(stack))}`
      );
      // navigate(`/map/${mapId}?stack=`);
    }

    toggleMapTextSearchModal();
  };

  useEffect(() => {
    const calculateZoomLevel = () => {
      if (!containerRef.current) return;

      const containerHeight = containerRef.current.clientHeight;
      let totalHeight = 0;

      // Calculate the total height of all items
      selectedStack.items.forEach(() => {
        totalHeight += 43; // Assume each item has a height of 43px, adjust as needed
      });

      // Determine zoom level
      const calculatedZoom = containerHeight / totalHeight;
      setZoomLevel(calculatedZoom < 1 ? calculatedZoom : 1);
    };

    // Calculate the initial zoom level
    if (selectedStack) calculateZoomLevel();

    // Recalculate zoom level on window resize
    window.addEventListener('resize', calculateZoomLevel);
    return () => window.removeEventListener('resize', calculateZoomLevel);
  }, [selectedStack]);

  return (
    <div
      ref={containerRef}
      className={`h-full w-full flex  ${selectedStack && 'map-preview'}  `}
      style={{
        background: '#1E1D1D',
        borderRadius: 'inherit',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '500px',
        maxHeight: '100%',
      }}
      onClick={handleNavigateToMap}
    >
      {selectedStack && (
        <div className="preview-overlay">
          <div className="overlay-text font-light">
            Click to navigate to{' '}
            <span className="font-bold">{selectedStack?.map.name}</span>
          </div>
        </div>
      )}

      <div
        className=" grid"
        style={{
          padding: '40px 5px',
          gap: '8px',
          alignItems: 'center',
          placeItems: 'center',
          justifyContent: 'center',
          transform: `scale(${zoomLevel})`,
          transformOrigin: 'center',
        }}
      >
        {!selectedStack ? (
          <div>Select a result on the sidebar to preview.</div>
        ) : (
          <>
            <StackPreview stack={selectedStack} searchText={searchText} />

            {/* <AreaPreview /> */}
          </>
        )}
      </div>
    </div>
  );
}

function StackPreview({
  stack,
  searchText,
}: {
  stack: StackData;
  searchText: string;
}) {
  const searchTerms = searchText
    .toLowerCase()
    .split(' ')
    .filter(term => term);

  const matchesSearch = (text: string, terms: string[]) => {
    return terms.every(term => text.toLowerCase().includes(term));
  };

  return (
    <div
      className="flex flex-col"
      style={
        {
          // overflowY: 'scroll',
          // height: 'fit-content',
        }
      }
    >
      {stack.items.map((item, idx) => {
        const textContent = (item as NoteItemData).content.textContent;
        const itemHasSearchText = matchesSearch(textContent, searchTerms);

        return (
          <div
            key={idx}
            style={{
              margin: '3px 0',
              // height: 'fit-content',
              // margin: itemHasSearchText ? '3px 0' : '3px 0',
              maxWidth: `${cNoteConfig.MINWIDTH}px`,
            }}
          >
            <Border
              style={{ ...item.style }}
              isSelected={itemHasSearchText}
              type={'Item'}
              readOnly
            >
              <div
                style={{
                  maxWidth: `${cNoteConfig.MINWIDTH}px`,
                  wordBreak: 'break-all',
                  padding: '2px',
                  color: themeStore.defaultColor,
                }}
              >
                {(item as NoteItemData).content.textContent}
              </div>
            </Border>
          </div>
        );
      })}
    </div>
  );
}

function AreaPreview({ children }: { children?: FC }) {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr',
        minWidth: '400px',
        width: 'fit-content',
        minHeight: '250px',
      }}
    >
      <Border
        style={{
          border: {
            thickness: '_1',
            color: 'white',
          },
        }}
        isSelected={false}
        type={'Area'}
      >
        <div className="h-full w-fit mx-auto" style={{ padding: '15px 10px' }}>
          {/* {mockStackData?.map((stack: StackData, index) => (
            <StackPreview key={stack.stackId} stack={stack} />
          ))} */}
        </div>
      </Border>
    </div>
  );
}
