// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type ArrowIconIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function ArrowIconIcon(props: ArrowIconIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 10 10"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M5.762 7.917a.416.416 0 01-.325-.156l-2.012-2.5a.417.417 0 01.005-.528l2.083-2.5a.417.417 0 01.64.534L4.29 5.005l1.798 2.234a.417.417 0 01-.325.678"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default ArrowIconIcon;
/* prettier-ignore-end */
