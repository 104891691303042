import { ItemType } from './types';

export const cAlignments = ['left', 'center', 'right', 'justify'] as const;

export const cBorderWeight = ['_0', '_1', '_2', '_3', '_4', '_5'] as const;

export const cIndentation = [
  '_0',
  '_1',
  '_2',
  '_3',
  '_4',
  '_5',
  '_6',
  '_7',
] as const;
export const cFontSize = [8, 72] as const;

export const imageTag = '_IMAGE';

type SlatePlaceHolder = {
  [key in ItemType]: string;
};

export const cNoteConfig = {
  MINWIDTH: 200,
  MAXWIDTH: 600,
};
export const cSLATE_PLACEHOLDER: SlatePlaceHolder = {
  Default: 'New Note',
  label: 'Label',
  stamp: 'New Stamp ',
  titleNote: 'Title Note',
} as const;
