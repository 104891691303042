// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type VectorEditLineIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function VectorEditLineIcon(props: VectorEditLineIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 24 24"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path opacity={".25"} d={"M5 5h14v14H5V5z"} fill={"currentColor"}></path>

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M3 5a2 2 0 114 0 2 2 0 01-4 0zm16-2a2 2 0 100 4 2 2 0 000-4zm0 14a2 2 0 100 4 2 2 0 000-4zM5 17a2 2 0 100 4 2 2 0 000-4z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default VectorEditLineIcon;
/* prettier-ignore-end */
