import { HTMLElementRefOf } from '@plasmicapp/react-web';
import { useFlag } from '@unleash/proxy-client-react';
import { mapMapSettings } from 'components/settings/MapMapSettings';
import { AvatarMenu } from 'propro-common-components';
import * as React from 'react';
import { toggleMapTextSearchModal } from 'utils/stores/dashboardStore';
import {
  DefaultDashboardHeaderProps,
  PlasmicDashboardHeader,
} from './plasmic/map_map/PlasmicDashboardHeader';
export interface DashboardHeaderProps extends DefaultDashboardHeaderProps {}

function DashboardHeader_(
  props: DashboardHeaderProps,
  ref: HTMLElementRefOf<'div'>
) {
  const toggleUISettings = useFlag('UISettings');
  return (
    <PlasmicDashboardHeader
      mapTextSearchBar={{
        onClick: () => toggleMapTextSearchModal(),
      }}
      userAvatars2={
        <AvatarMenu viewItems={toggleUISettings ? mapMapSettings : []} />
      }
      root={{ ref }}
      {...props}
    />
  );
}

const DashboardHeader = React.forwardRef(DashboardHeader_);
export default DashboardHeader;
