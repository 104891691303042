// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import { HTMLElementRefOf } from '@plasmicapp/react-web';
import SimpleTextInput from 'CoreComponents/SimpleTextInput/SimpleTextInput';
import * as React from 'react';
import {
  DefaultCommandInputProps,
  PlasmicCommandInput,
} from './plasmic/map_map/PlasmicCommandInput';

// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface CommandInputProps extends Omit<DefaultCommandInputProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultCommandInputProps altogether and have
// total control over the props for your component.
export interface CommandInputProps extends DefaultCommandInputProps {
  // mapId: string;
  // mousePosition: { x: number; y: number };
  inputRef: any;
  inputValue: string;
  handleCommandInputChange: React.KeyboardEventHandler;
  handleKeyDown: React.KeyboardEventHandler;
}

function CommandInput_(
  {
    inputValue,
    inputRef,
    handleCommandInputChange,
    handleKeyDown,
    ...props
  }: CommandInputProps,
  ref: HTMLElementRefOf<'div'>
) {
  return (
    <PlasmicCommandInput
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
      root={{ ref }}
      {...props}
      textInputSlot={
        <SimpleTextInput
          className="text-white"
          ref={inputRef}
          autoFocus={true}
          autoComplete="off"
          value={inputValue}
          onChange={handleCommandInputChange}
          onKeyDown={handleKeyDown}
        />
      }
    />
  );
}

const CommandInput = React.forwardRef(CommandInput_);
export default CommandInput;