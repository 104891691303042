import StyleSection from './style/StyleSection';

export default function ThemeAndStyleModal() {

  return (
    <div className="grid gap-4">
      <StyleSection />
    </div>
  );
}
