import { proxy, subscribe } from 'valtio';
import { currentMapStateStore } from './mapStore';
import { deepCopy } from 'utils/helpers/deepCopy';
import { getConnectionPoints } from 'CoreComponents/Connections/connectionUtils';
import {
  getNormalizedCoordinates,
  getScaleFromMatrix,
} from 'utils/helpers/Canvas/clamp-zoom';
import { Matrix } from 'CoreComponents/Canvas/InfiniteCanvas';
import { createConnection } from 'utils/mapStoreFN/mapStoreFN_connection';

export type ConnectingItems = {
  itemId: string;
  position: { x: number; y: number };
};

type ConnectItemsStore = {
  creatingConnection: boolean;
  connectNotes: ConnectingNotesValue;
};

export const connectionOriginLabels = {
  from: 0,
  to: 1,
} as const;

export type ConnectingNotesValue = {
  mapID: string | null;
  isDragging: boolean;

  connectPairs:
    | [ConnectingItems, ConnectingItems]
    | [null, null]
    | [ConnectingItems, null];
  type: 'line' | 'arrow';
  connectionColor?: string;
};

const connectItemsInitialState: ConnectingNotesValue = {
  mapID: null,
  isDragging: false,
  connectPairs: [null, null],
  type: 'line',
};
const connectItemsStore = proxy<ConnectItemsStore>({
  connectNotes: connectItemsInitialState,
  creatingConnection: false,
});

const reset = () => {
  connectItemsStore.connectNotes = deepCopy(connectItemsInitialState);
  connectItemsStore.creatingConnection = false;
};

const subscriber = subscribe(connectItemsStore, () => {
  // console.log({ ...connectItemsStore });
  if (!document.hasFocus()) return;
  if (connectItemsStore.connectNotes.isDragging) return;
  if (
    connectItemsStore.connectNotes.mapID &&
    connectItemsStore.connectNotes.connectPairs.every(
      element => element !== null
    )
  ) {
    if (
      !connectItemsStore.connectNotes.isDragging &&
      connectItemsStore.connectNotes.connectPairs[1] === null
    ) {
      // handle edge case where a connection flow is initiated but not hovered any item
      // After dragend

      reset();
    }
    // TODO find a better way to type cast the array tuple

    const canvasTransform =
      currentMapStateStore[connectItemsStore.connectNotes.mapID].canvas
        .CanvasTransform;
    const zoomLevel = getScaleFromMatrix(new Matrix(canvasTransform));

    const shortestPath = getConnectionPoints(
      connectItemsStore.connectNotes.connectPairs[0]?.itemId!,
      connectItemsStore.connectNotes.connectPairs[1]?.itemId!,
      zoomLevel
    );
    if (!shortestPath) return;

    const normalisedCordsFrom = getNormalizedCoordinates(
      canvasTransform,
      shortestPath.from.x,
      shortestPath.from.y
    );
    const normalisedCordsTo = getNormalizedCoordinates(
      canvasTransform,
      shortestPath.to.x,
      shortestPath.to.y
    );

    createConnection(
      [
        {
          ...connectItemsStore.connectNotes.connectPairs[0],
          position: { x: normalisedCordsFrom.x, y: normalisedCordsFrom.y },
        },
        {
          ...connectItemsStore.connectNotes.connectPairs[1],
          position: { x: normalisedCordsTo.x, y: normalisedCordsTo.y },
        },
      ] as [ConnectingItems, ConnectingItems],
      connectItemsStore.connectNotes.mapID,
      connectItemsStore.connectNotes.type,
      connectItemsStore.connectNotes.connectionColor
    );

    return reset();
  }
});

export default connectItemsStore;
