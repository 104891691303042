export function deepClone(obj) {
    if (obj === null || typeof obj !== 'object') {
        return obj;
    }

    let cloned, i;

    // Handle Array
    if (obj instanceof Array) {
        cloned = [];
        for (i = 0; i < obj.length; i++) {
            cloned[i] = deepClone(obj[i]);
        }
        return cloned;
    }

    // Handle Object
    if (obj instanceof Object) {
        cloned = {};
        for (i in obj) {
            if (obj.hasOwnProperty(i)) {
                cloned[i] = deepClone(obj[i]);
            }
        }
        return cloned;
    }

    throw new Error('Unable to copy obj! Its type is not supported.');
}
