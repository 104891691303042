// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import { HTMLElementRefOf } from '@plasmicapp/react-web';
import FolderMapIconRenderer from 'components/folderMapIconRenderer/FolderMapIconRenderer';
import DropdownItem from 'PlasmicComponents/DropdownItem';
import * as React from 'react';
import { createPortal } from 'react-dom';
import { useNavigate } from 'react-router-dom';
import { dashboardStore } from 'utils/stores/dashboardStore';
import { FolderData } from 'utils/stores/types';
import {
  DefaultDropdownItemGroupProps,
  PlasmicDropdownItemGroup,
} from './plasmic/map_map/PlasmicDropdownItemGroup';

export interface DropdownItemGroupProps extends DefaultDropdownItemGroupProps {
  highlight?: boolean;
  folder: FolderData;
  forceShowChildren?: boolean;
  parentLevel?: number;
  isFromQuickAccess?: boolean;
  parentColumn?: number;
  expandedFolderGroups: string[];
  setExpandedFolderGroups: React.Dispatch<React.SetStateAction<string[]>>;
  additionalExpandingFunction?: () => void;
  onSelect?: (event: React.MouseEvent) => void;
  isQuickAccessModal?: boolean;
  handleClick?: Function;
  hasChildren?: boolean;
}

function DropdownItemGroupDup(
  {
    highlight,
    folder,
    forceShowChildren,
    parentLevel,
    body,
    isFromQuickAccess,
    parentColumn,
    expandedFolderGroups,
    setExpandedFolderGroups,
    onSelect,
    isQuickAccessModal,
    handleClick,
    hasChildren,
    additionalExpandingFunction,
    ...props
  }: DropdownItemGroupProps,
  ref: HTMLElementRefOf<'div'>
) {
  const navigate = useNavigate();
  const [expand, setExpand] = React.useState(forceShowChildren);

  const level = props?.level ? parseInt(props?.level.split('_')[1]) : 0;

  const openExpandedPortal = () => {
    if (!folder?.subfolders?.length && !folder.maps.length) return;

    setExpand(!expand);
    if (expand) {
      // start removing this item from expandedFolderGroups
      // TODO: If you're adding an ID, remove all its siblings so they don't overlay.
      const folderIdx = expandedFolderGroups.findIndex(
        item => item === folder.folderId
      );
      if (folderIdx >= 0) {
        const filteredGroups = expandedFolderGroups.filter(
          (_, i) => i < folderIdx
        );
        setExpandedFolderGroups(filteredGroups);
      }
    } else {
      // add folder into expand list
      const newGroup = [...expandedFolderGroups, folder.folderId];
      setExpandedFolderGroups(newGroup);
    }
  };

  const leftPosition = () => {
    if (isFromQuickAccess && isQuickAccessModal && expand) {
      const columnsContainer = document.getElementById('quick-access-modal');

      const singleColumnWidth = Array.from(columnsContainer?.children)[
        parentColumn
      ].getBoundingClientRect().width;

      const gap = 15;

      const position = singleColumnWidth / gap + singleColumnWidth / 2;

      return position;
    }

    const leftPad = level < 2 ? level * 110 : 110;

    return leftPad;
  };

  React.useEffect(() => {
    setExpand(forceShowChildren);
    // if (forceShowChildren) openExpandedPortal();
  }, [forceShowChildren]);

  function returnDropdownItemGroup({ isInSidebar }) {
    folder?.name === 'x' && console.log(folder?.subfolders?.length);

    return (
      <div>
        <PlasmicDropdownItemGroup
          isInSidebar={isInSidebar}
          id={`dropdown-${folder?.folderId}`}
          style={{
            backgroundColor: level >= 1 && expand ? 'grey' : 'inherit',
          }}
          // headContainer={{
          //   style: {
          //     width: '100%',
          //     overflow: 'hidden',
          //   },
          // }}
          head={
            <DropdownItem
              highlight={highlight}
              style={{
                width: expand ? 170 : 184,
              }}
              isExpansionDisabled={level === 3}
              expandingFunction={() => {
                level !== 3
                  ? (() => {
                      openExpandedPortal();

                      if (additionalExpandingFunction) {
                        additionalExpandingFunction();
                      }
                    })()
                  : undefined;
              }}
              iconNameContainerClick={
                isFromQuickAccess
                  ? () => {
                      navigate(`/dashboard/folder/${folder.folderId}`);
                      dashboardStore.quickAccessModal = false;
                    }
                  : undefined
              }
              handleClick={() => {
                handleClick && handleClick();
                if (isFromQuickAccess) return;
                level !== 3 ? openExpandedPortal() : undefined;
              }}
              name={
                <div
                  style={{
                    width: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    cursor: 'pointer',
                  }}
                  title={folder?.name}
                >{`${folder?.name}`}</div>
              }
              leftIcon2={
                folder?.icon ? (
                  folder?.icon?.name ? (
                    <FolderMapIconRenderer
                      icon={{
                        name: folder?.icon?.name || '',
                        type: folder?.icon?.type || '',
                        color: folder?.icon?.color || '',
                      }}
                    />
                  ) : (
                    (`${folder?.icon}` as string)
                  )
                ) : undefined
              }
              isLeftIconHidden={!folder?.icon}
              isExpanding={false}
              size={
                isFromQuickAccess && isInSidebar && !isQuickAccessModal
                  ? 'small'
                  : 'medium'
              }
              onSelect={onSelect}
              expansionIcon={
                // 'right'
                (!folder?.subfolders?.length && !folder.maps.length) ||
                !hasChildren
                  ? `hidden`
                  : expand
                    ? 'down'
                    : 'right'
              }
              itemPassed={folder}
            />
          }
          isNotExpanded={isInSidebar ? (level <= 0 ? !expand : true) : false}
          root={{ ref }}
          body={body}
          {...props}
        />
      </div>
    );
  }

  return (
    <div
      className="relative"
      style={{ zIndex: level <= 1 && expand ? 999 : 1 }}
    >
      {returnDropdownItemGroup({
        isInSidebar: true,
      })}
      {expand &&
        level > 0 &&
        expandedFolderGroups?.includes(folder?.folderId) &&
        (document.getElementById(`dropdown-${folder?.folderId}`)
          ? createPortal(
              <div
                id={`folder-${folder.user_id}`}
                style={{
                  position: 'absolute',
                  left: `${leftPosition()}%`,
                  top: 0,
                }}
              >
                {returnDropdownItemGroup({
                  isInSidebar: false,
                })}
              </div>,
              document.getElementById(`dropdown-${folder?.folderId}`)
            )
          : null)}
    </div>
  );
}

const DropdownItemGroup = React.forwardRef(DropdownItemGroupDup);
export default DropdownItemGroup;
