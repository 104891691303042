import { proxy } from 'valtio';

type clipboardDataType = {
  clipboard: null | string;
  action: 'cut' | 'copy' | null;
};

const clipboardInitialState = null;

export const ClipboardStore = proxy<clipboardDataType>({
  clipboard: clipboardInitialState,
  action: null,
});
