import {
  useAddToMapStyleShortcuts,
  useAddToMyGlobalStyleShortcuts,
} from 'api/mutations';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getItemData } from 'utils/mapStoreFN/mapStoreFN_items';
import { currentMapStateStore } from 'utils/stores/mapStore';
import { Style } from 'utils/stores/types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { isShortcutUsed } from 'utils/helpers/shortcutValidator';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import getSelectedIDs from 'utils/helpers/Selection/selectionUtils';

const defaultOverwriteOptions = [
  { key: 'fontStyle', label: 'Font Style' },
  { key: 'border', label: 'Border Style' },
  { key: 'textAlignment', label: 'Text Alignment' },
  { key: 'textColor', label: 'Text Color' },
  { key: 'fontSize', label: 'Font Size' },
  { key: 'affectNoteBody', label: 'Note Body' },
  { key: 'faded', label: 'Fade' },
  { key: 'textDecoration', label: 'Text Decoration' },
  { key: 'fillColor', label: 'Background Color' },
];

const validationSchema = z.object({
  title: z.string().nonempty({ message: 'Required' }),
  shortcut: z
    .string()
    .nonempty({ message: 'Required' })
    .refine(value => !isShortcutUsed(value), {
      message: 'Shortcut is already in use',
    }),
  alternativeShortcut: z.string().refine(
    value => {
      if (!value) return true;
      return !isShortcutUsed(value);
    },
    {
      message: 'Shortcut is already in use',
    }
  ),
  styleLocation: z.string().nonempty({ message: 'Required' }),
  overwriteOptions: z.record(z.boolean()).refine(
    value => {
      return Object.values(value).some(Boolean);
    },
    {
      message: 'At least one option must be selected',
    }
  ),
});

export default function useAddStyleShortcut() {
  const { mapId } = useParams<{ mapId: string }>();
  const addGlobalStyleShortcutRequest = useAddToMyGlobalStyleShortcuts();
  const addMapStyleShortcutRequest = useAddToMapStyleShortcuts(mapId);
  const selectedItems = getSelectedIDs() || [];

  const [style, setStyle] = useState<Style | null>(null);

  function closeNewMapStyleModal() {
    currentMapStateStore[mapId].openNewStyleShortcut = false;
  }

  const initialValues = {
    title: '',
    shortcut: '',
    alternativeShortcut: '',
    styleLocation: 'global',
    overwriteOptions: {},
  };

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    resolver: zodResolver(validationSchema),
    mode: 'onChange',
    defaultValues: initialValues,
  });

  const onSubmit = values => {
    const filteredStyles = Object.entries(values.overwriteOptions)
      .filter(([, value]) => value)
      .map(([key]) => key);

    const resolvedStyle = filteredStyles.reduce((acc, key) => {
      if (style && Object.keys(style).includes(key)) {
        acc[key] = style[key];
      }
      return acc;
    }, {});

    const formData = {
      name: values.title,
      shortcut: values.shortcut,
      alternativeShortcut: values.alternativeShortcut,
      styleObject: JSON.stringify(resolvedStyle),
      isActive: true,
    };

    if (values.styleLocation === 'global') {
      const finalData = {
        shortcuts: [formData],
      };
      addGlobalStyleShortcutRequest.mutateAsync(finalData).then(() => {
        reset();
        closeNewMapStyleModal();
      });
    }

    if (values.styleLocation === 'map') {
      const finalData = {
        shortcut: formData,
      };
      addMapStyleShortcutRequest.mutateAsync(finalData).then(() => {
        reset();
        closeNewMapStyleModal();
      });
    }
  };

  const { overwriteOptions, remainingOptions } = useMemo(() => {
    if (selectedItems.length === 0)
      return { overwriteOptions: [], remainingOptions: [] };

    const itemData = getItemData(selectedItems[0], mapId);
    if (!itemData) return { overwriteOptions: [], remainingOptions: [] };

    const styleKeys = Object.keys(itemData.style);
    const overwriteOptions = defaultOverwriteOptions.filter(option =>
      styleKeys.includes(option.key)
    );
    const remainingOptions = defaultOverwriteOptions.filter(
      option => !styleKeys.includes(option.key)
    );

    setStyle(itemData.style);

    return { overwriteOptions, remainingOptions };
  }, [selectedItems, mapId]);

  useEffect(() => {
    setValue(
      'overwriteOptions',
      Object.fromEntries(overwriteOptions.map(opt => [opt.key, true]))
    );
  }, [overwriteOptions, setValue]);

  useEffect(() => {
    if (!selectedItems.length) {
      closeNewMapStyleModal();
    }
  }, [selectedItems]);

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  return {
    onSubmit: handleSubmit(onSubmit),
    control,
    errors,
    overwriteOptions,
    remainingOptions,
    style,
    requestLoading:
      addGlobalStyleShortcutRequest.isPending ||
      addMapStyleShortcutRequest.isPending,
    validationSchema,
  };
}
