// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react';
import {
  PlasmicResultFrame,
  DefaultResultFrameProps,
} from './plasmic/map_map/PlasmicResultFrame';
import { HTMLElementRefOf } from '@plasmicapp/react-web';

// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface ResultFrameProps extends Omit<DefaultResultFrameProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultResultFrameProps altogether and have
// total control over the props for your component.
export interface ResultFrameProps extends DefaultResultFrameProps {
  borderColor?: string;
}

function ResultFrame_(
  { borderColor, ...props }: ResultFrameProps,
  ref: HTMLElementRefOf<'div'>
) {
  return (
    <PlasmicResultFrame
      resultsContainer={{
        style: {
          borderColor: borderColor || 'white',
        },
      }}
      root={{ ref }}
      {...props}
    />
  );
}

const ResultFrame = React.forwardRef(ResultFrame_);
export default ResultFrame;
