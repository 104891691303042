import { useGet } from 'propro-common-components';
import { useEffect } from 'react';
import { MapData } from 'utils/stores/types';

export type LastSyncAndLastInteraction = {
  lastSync: Date;
  lastInteraction: Date;
};

// export const useLogin = () => {
//   return useGet(['login'], '/api/login', {
//     enabled: false,
//   });
// };

export const useGetAllMapsList = ({
  recentlyAccessed = false,
  fullFields = false,
} = {}) => {
  return useGet(
    ['All maps'],
    `/api/map${recentlyAccessed ? `?recentlyAccessed=true` : ''}${fullFields ? '?fields=full' : ''}`,
    {}
  );
};

export const useGetAllFoldersList = () => {
  return useGet(['All folders'], '/api/folder/user/folderList', {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};

export const useGetUserFolderStructure = () => {
  return useGet(['folder structure'], '/api/folder/structure', {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};

export const useGetFolder = (folderId: string) => {
  return useGet(['folder', folderId], `/api/folder/${folderId}`, {
    enabled: !!folderId,
  });
};

export const useGetLastSyncAndInteractionBE = (mapId: string) => {
  const queryInfo = useGet<LastSyncAndLastInteraction>(
    ['lastSyncAndLastInteraction', mapId],
    `/api/map/${mapId}/lastSyncAndLastInteraction`,
    { enabled: false }
  );

  useEffect(() => {
    if (queryInfo.data) {
      console.log(
        'useGetLastSyncAndInteractionBE data on response',
        queryInfo.data
      );
    }
  }, [queryInfo.data]);

  return queryInfo;
};
export const useGetLoadedMap = (mapId: string) => {
  return useGet<MapData>(
    ['MapFromBackend', mapId],
    `/api/map/${mapId}?fields=full`,
    {
      enabled: !!mapId,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};
export const useGetRelevantStackObjectsBE = (mapId: string) => {
  return useGet(
    ['FullStackObjectsBE', mapId],
    `/api/map/${mapId}?fields=full`,
    {
      enabled: false,
    }
  );
};

export const useGetMapTextSearch = (searchQuery: string) => {
  return useGet(
    ['searchResult', searchQuery],
    `/api/search?searchQuery=${searchQuery}`,
    {
      enabled: !!searchQuery,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};

//user global styles

export const useGetMyGlobalStyles = () => {
  return useGet(['My General Style Shortcuts'], `/api/user/globalStyle`, {
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};
