// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type ArrowsArrowsDiagonalIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function ArrowsArrowsDiagonalIcon(props: ArrowsArrowsDiagonalIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 18 18"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M9.97 8.041a.76.76 0 00.533.217l-.007-.008c.194 0 .39-.082.532-.217L14.25 4.81v1.2c0 .412.33.749.75.749a.751.751 0 00.749-.75V3.015a.747.747 0 00-.225-.54.751.751 0 00-.683-.209h-2.84c-.42 0-.75.33-.75.75 0 .411.33.748.75.748h1.17L9.962 6.97v.007a.748.748 0 00.008 1.064zM6.007 15.75H3.008a.756.756 0 01-.532-.217.747.747 0 01-.217-.65v-2.879c0-.42.33-.75.75-.75.412 0 .749.33.749.75v1.168l3.207-3.206c.293-.3.765-.3 1.065 0a.755.755 0 010 1.064l-3.224 3.222h1.2c.413 0 .75.33.75.749a.751.751 0 01-.75.749z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default ArrowsArrowsDiagonalIcon;
/* prettier-ignore-end */
