/***
 *
 *   VIEW
 *   The view houses global components that are common to all views
 *   (notification, modal), handles errors and renders the layout
 *   The view and it's props are rendered by the router
 *
 *   PROPS
 *   data: custom view data (object, optional)
 *   display: custom view (component, required)
 *   layout: layout component to use (string, required)
 *   title: document title (string, required)
 *
 **********/

import { createContext, useState } from 'react';
import { OverlayMenuWrapper } from '../../CoreComponents/Overlays/OverlayMenuWrapper';
import CssTransition from '../animate/css-transition';
import {
  AppLayout,
  AuthLayout,
  CoreLayout,
  Loader,
  Modal,
  Notification,
  OnboardingLayout,
  useNavigate,
} from '../lib';
import { DashboardLayoutV2 } from 'components/layout/dashboard/dashboardLayoutV2';

export const ViewContext = createContext();

export function View(props) {
  const navigate = useNavigate();

  // state
  const [notification, setNotification] = useState({
    visible: 'hide',
    autoclose: true,
  });
  const [menu, setMenu] = useState({
    show: false,
    title: '',
    width: 0.3,
    backdropDarkening: 0.7,
    backgroundColor: 'white',
    position: 'center',
    children: null,
  });
  const [modal, setModal] = useState({});
  const [loading, setLoading] = useState(false);

  // layouts
  const layouts = {
    app: AppLayout,
    core: CoreLayout,
    auth: AuthLayout,
    onboarding: OnboardingLayout,
    dashboard: DashboardLayoutV2,
  };

  // set title & layout
  document.title = props.title;
  let Layout = props.layout ? layouts[props.layout] : AppLayout;

  if (!props.display) return false;

  function showNotification(text, type, autoclose, format, icon) {
    setNotification({
      text: text,
      type: type,
      show: true,
      format: format,
      icon: icon,
      autoclose: autoclose,
    });

    if (autoclose) setTimeout(hideNotification, 2000);
  }

  function hideNotification() {
    setNotification({
      text: '',
      type: '',
      show: false,
      format: null,
      icon: null,
      autoclose: true,
    });
  }

  function showModal(content, callback) {
    let data = { ...modal };

    if (content) {
      for (let key in content) data[key] = content[key];

      data.show = true;
      data.callback = callback;
    }

    setModal(data);
  }

  function hideModal(cancel, res) {
    // callback?
    if (!cancel && modal.callback) modal.callback(modal.form, res);

    // reset the modal
    setModal({
      title: null,
      text: null,
      buttonText: null,
      url: null,
      method: null,
      show: false,
    });
  }

  function showMenu(content) {
    let data = { ...menu };

    if (content) {
      for (let key in content) data[key] = content[key];
      data.show = true;
    }

    setMenu(data);
  }

  function hideMenu() {
    // reset the modal
    setMenu({
      show: false,
      title: '',
      width: 0.3,
      position: 'center',
      backdropDarkening: 0.7,
      content: null,
    });
  }

  function handleError(err) {
    let message = 'There was a glitch in the matrix – please try again';

    if (err) {
      message = err.toString();

      if (err.response) {
        if (err.response.data?.message) message = err.response.data.message;

        if (err.response.status) {
          switch (err.response.status) {
            case 401:
              navigate('/signin');
              break;

            case 404:
              navigate('/notfound');
              break;

            case 429:
              showNotification(err.response.data, 'error', false);
              break;

            case 402: // payment required
              navigate('/account/upgrade?plan=' + err.response.data.plan);
              break;

            default:
              showNotification(message, 'error', false);
              break;
          }
        }
      } else {
        showNotification(message, 'error', false);
      }
    }
  }

  const context = {
    notification: {
      show: showNotification,
      hide: hideNotification,
      data: notification,
    },
    modal: {
      show: showModal,
      hide: hideModal,
      data: modal,
    },
    menu: {
      // TODO:    []    specify menus (Inspector, Theme and Styles Menu, etc.)
      show: showMenu,
      hide: hideMenu,
      data: menu,
    },

    setLoading: state => {
      // console.log("msinsins", state);
      setLoading(state);
    },
    handleError: handleError,
  };

  return (
    <CssTransition>
      <ViewContext.Provider value={context}>
        {notification.show && <Notification {...notification} />}

        {loading && <Loader fullScreen />}

        {modal.show && <Modal {...modal} />}

        {menu.show && <OverlayMenuWrapper {...menu} />}

        <Layout title={props.title} data={props.data}>
          {props.display}
        </Layout>
      </ViewContext.Provider>
    </CssTransition>
  );
}
