import {
  toggleMapFolderSearchView,
  toggleMapTextSearchModal,
  toggleNewMapModal,
  toggleQuickAccessModal,
  toggleShortCutModal,
} from 'utils/stores/dashboardStore';
import { HotkeysProps } from 'utils/stores/hotKeysStore';

export default function useGlobalHotkeys() {
  const defaultGlobalHotkeys: HotkeysProps = {
    'Show Map Folder Search': {
      shortcut: 'mod+shift+o',
      alternativeShortcut: null,
      action: () => {
        toggleMapFolderSearchView();
      },
      shouldPreventDefault: true,
      isCustomizable: true,
    },

    'Show Quick Access Modal': {
      shortcut: 'mod+shift+b',
      alternativeShortcut: null,
      action: e => {
        toggleQuickAccessModal();
      },
      shouldPreventDefault: true,
      isCustomizable: true,
    },

    'Show Map Text Search Modal': {
      shortcut: 'mod+shift+f',
      alternativeShortcut: null,
      action: () => {
        toggleMapTextSearchModal();
      },
      shouldPreventDefault: true,
      isCustomizable: true,
    },
    'Shortcut modal': {
      shortcut: 'mod+shift+g',
      alternativeShortcut: null,
      action: toggleShortCutModal,
      shouldPreventDefault: true,
      isCustomizable: true,
    },

    'New Map Modal': {
      shortcut: 'mod+shift+k',
      alternativeShortcut: null,
      action: (e) => {
        console.log("mod+shift+n")
        toggleNewMapModal();
      },
      shouldPreventDefault: true,
      isCustomizable: true,
    },
  };

  return {
    defaultGlobalHotkeys,
  };
}
