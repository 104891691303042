import { useHotkeys } from '@mantine/hooks';
import { useFlag } from '@unleash/proxy-client-react';
import CreateArea, { createArea } from 'CoreComponents/Areas/CreateArea';
import { ReactNode } from 'react';
import Selecto from 'react-selecto';
import { setIsCreatingArea } from 'utils/stores/components/areaStore';
import { gestureStore } from 'utils/stores/gestureStore';
import { hotKeyStateStore } from 'utils/stores/hotKeyStatesStore';
import { anchorMode, creatingAreaMode } from 'utils/stores/mapMode/modesStore';
import {
  addItemToSelected,
  onEnter,
  removeItemFromSelected,
  resetSelected,
  resetStacks,
} from 'utils/stores/mapStore';
import selectedContentsStore from 'utils/stores/Selection/selectedContentStore';
import {
  setMapshotMode,
  mapshotStore,
  takeMapshotAndSaveToClipboard,
} from 'utils/stores/mapshotStore';
import { useSnapshot } from 'valtio';
type RenderSelectoProps = {
  selectoRef: React.MutableRefObject<Selecto>;
  children: ReactNode;
  mapId: string;
};
function RenderSelecto({ selectoRef, children, mapId }: RenderSelectoProps) {
  //TODO remove this usePanning

  const createAreaMode = useSnapshot(creatingAreaMode);

  const useSelectionV2 = useFlag('mapmap_selectionv2');

  const snapshotStoreSnapshot = useSnapshot(mapshotStore);
  useHotkeys(
    [
      // shift+r is only relevent for development
      [
        'mod+shift+a',
        e => {
          e.preventDefault();
          e.stopPropagation();

          if (createAreaMode.isActive) {
            creatingAreaMode.reset();
          } else {
            creatingAreaMode.activate();
          }
        },
      ],
      [
        'shift+r',
        () => {
          resetStacks(mapId);
        },
      ],
      [
        'Enter',
        e => {
          if (anchorMode.isActive) return;
          e.preventDefault();
          e.stopPropagation();

          if (createAreaMode.isActive && createAreaMode.default) {
            createArea(false, mapId);
            createAreaMode.reset();
            return;
          }

          onEnter(mapId);
        },
      ],
      [
        'Escape',
        () => {
          if (createAreaMode.isActive) {
            creatingAreaMode.reset();
          }
        },
      ],
    ],
    [],
    true
  );
  return (
    <>
      <SelectoBox
        selecto={
          createAreaMode.isActive && createAreaMode.default === true
            ? 'Area'
            : 'Default'
        }
        selectoRef={selectoRef}
        mapID={mapId}
        useSelectionV2={useSelectionV2}
        isSnapshotMode={snapshotStoreSnapshot.mapshotMode}
        setIsCreatingArea={setIsCreatingArea}
      />

      {createAreaMode.isActive && createAreaMode.default && (
        <CreateArea hideBox={createAreaMode.hidePreviewBox} />
      )}
      {children}
    </>
  );
}

type SelectoBoxProps = {
  selecto: 'Area' | 'Default';
  selectoRef: React.MutableRefObject<Selecto>;

  mapID: string;
  useSelectionV2: boolean;
  setIsCreatingArea: React.Dispatch<
    React.SetStateAction<{
      dragging: boolean;
      creatingAreaMode: boolean;
    }>
  >;
  isSnapshotMode: boolean;
};

function SelectoBox(props: SelectoBoxProps) {
  if (props.selecto === 'Area') {
    return (
      <Selecto
        hitRate={100}
        className="selecto-area"
        ref={props.selectoRef}
        selectableTargets={['.selectable-target']}
        onDragStart={() => {
          creatingAreaMode.hidePreviewBox = true;

          resetSelected(props.mapID);
        }}
        onSelectStart={() => {
          selectedContentsStore.activateMultipleSelect();
        }}
        onSelectEnd={() => {
          selectedContentsStore.deactivateMultipleSelect();
        }}
        onSelect={e => {
          if (props.isSnapshotMode) return; // stops the select if the snapshot mode is true. Snapshot mode, as in it's ready to take a snapshot of the view.
          e.added.forEach(el => {
            const itemId = el.getAttribute('id');
            const parentID = el.getAttribute('data-parentid');
            addItemToSelected(
              {
                contentID: itemId,
                type: 'Note',
                parentType: 'stack',
                parentID,
              },
              props.mapID
            );
          });
          e.removed.forEach(el => {
            const itemId = el.getAttribute('id');
            const parentID = el.getAttribute('data-parentid');

            removeItemFromSelected(
              {
                contentID: itemId,
                type: 'Note',
                parentType: 'stack',
                parentID,
              },
              props.mapID
            );
          });
        }}
        onDragEnd={e => {
          createArea(
            e.inputEvent.altKey, // create a title with custom drag
            props.mapID,
            {
              x: e.rect.left,
              y: e.rect.top,
            },
            e.rect.width,
            e.rect.height
          );
          creatingAreaMode.reset();
        }}
      />
    );
  }

  return (
    <Selecto
      ref={props.selectoRef}
      dragContainer={'.map-container'}
      selectableTargets={['.selectable-target']}
      hitRate={0}
      onDragStart={e => {
        if (gestureStore.isPanning) {
          e.stop();
          return;
        }

        if (
          hotKeyStateStore.current.isModActive ||
          hotKeyStateStore.current.isShiftActive
        ) {
          e.stop();
          return;
        }

        if (e.inputEvent.target.id !== 'selecto-selectable') {
          e.stop();
          return;
        }

        resetSelected(props.mapID);

        if (props.selectoRef.current) {
          props.selectoRef.current.setSelectedTargets([]);
        }
      }}
      onSelectStart={() => {
        selectedContentsStore.activateMultipleSelect();
      }}
      onSelectEnd={() => {
        selectedContentsStore.deactivateMultipleSelect();
      }}
      onDragEnd={e => {
        if (props.isSnapshotMode) {
          // take snapshot if snapshot mode is true and the selecto onDragEnd event is triggered.
          takeMapshotAndSaveToClipboard({
            // toaster: context.notification.show
            x: e.rect.left,
            y: e.rect.top,
            width: e.rect.width,
            height: e.rect.height,
          });
          setMapshotMode(false);
        }

        props.setIsCreatingArea(prev => ({
          creatingAreaMode: false,
          dragging: false,
        }));
      }}
      onSelect={e => {
        e.added.forEach(el => {
          const parentID = el.getAttribute('data-parentid');
          const itemId = el.getAttribute('id');

          addItemToSelected(
            {
              contentID: itemId,
              type: 'Note',
              parentType: 'stack',
              parentID,
            },
            props.mapID
          );
        });
        e.removed.forEach(el => {
          const itemId = el.getAttribute('id');
          const parentID = el.getAttribute('data-parentid');

          removeItemFromSelected(
            {
              contentID: itemId,
              type: 'Note',
              parentType: 'stack',
              parentID,
            },
            props.mapID
          );
        });
      }}
    />
  );
}

export default RenderSelecto;
