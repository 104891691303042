import { useHotkeys } from '@mantine/hooks';
import { useGetUserFolderStructure } from 'api/queries';
import FolderMapIconRenderer from 'components/folderMapIconRenderer/FolderMapIconRenderer';
import CommandInput from 'PlasmicComponents/CommandInput';
import SuggestedCommand from 'PlasmicComponents/SuggestedCommand';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  constructYoutubeEmbedLink,
  normalizeURL,
} from 'utils/helpers/URLsHelper';
import { splitByFirstSpace } from 'utils/helpers/pick';
import { createPreview } from 'utils/mapStoreFN/mapStoreFN_previews';
import { setIsEditing } from 'utils/stores/gestureStore';
import { FolderStructure } from 'views/dashboard/DashboardView';
import { Matrix } from 'CoreComponents/Canvas/canvas';
import {
  getScaleFromMatrix,
  getNormalizedCoordinates,
} from 'utils/helpers/Canvas/clamp-zoom';
import { currentMapStateStore } from 'utils/stores/mapStore';
import { anchorMode } from 'utils/stores/mapMode/modesStore';
import { toast } from 'react-toastify';

export default function RenderCommandInput({ mousePosition, mapId }) {
  const { data: folderStructure, isLoading: structureLoading } =
    useGetUserFolderStructure() as FolderStructure;

  const pos = useMemo(() => {
    // run this function once.
    const zoomLevel = getScaleFromMatrix(
      new Matrix(currentMapStateStore[mapId].canvas?.CanvasTransform)
    );
    const noteMouseXOffset = 8 * zoomLevel;
    const noteMouseYoffset = 12 * zoomLevel;
    return getNormalizedCoordinates(
      currentMapStateStore[mapId].canvas?.CanvasTransform,
      mousePosition.x - noteMouseXOffset,
      mousePosition.y - noteMouseYoffset
    );
  }, []);

  const [inputValue, setInputValue] = useState('');
  const [option, setOption] = useState(0);
  const [mapOption, setMapOption] = useState(0);

  const [selectedMap, setSelectedMap] = useState(null);

  const [mapDropDown, setMapDropDown] = useState(false);

  const inputRef = useRef(null);

  const [commandText, title] = splitByFirstSpace(inputValue);

  const [filteredMaps, setFilteredMaps] = useState([]);

  const [showPlaceholder, setShowPlaceholder] = useState(false)
  const [placeholder, setPlaceholder] = useState("")

  const [availabeSuggestions, setAvailableSuggestions] = useState([
    { name: 'Preview Map', command: '/pm' },
    { name: 'Preview Youtube Video', command: '/yt' },
    { name: 'Preview Video', command: '/pv' },
    { name: 'Preview PDF', command: '/ppdf' },
    { name: 'Preview Website', command: '/pw' },
  ]);

  const [visibleSuggestions, setVisibleSuggestions] = useState([
    { name: 'Preview Map', command: '/pm' },
    { name: 'Preview Website', command: '/pw' },
    { name: 'Preview Youtube Video', command: '/yt' },
  ]);

  const origin = window.location.origin;

  const updateSuggestions = value => {
    const suggestions = availabeSuggestions.filter(
      suggestion =>
        suggestion.name.toLowerCase().includes(value) ||
        suggestion.command.toLowerCase().includes(value)
    );
    setVisibleSuggestions(() => {
      return suggestions;
    });
  };

  const handleCommandInputChange = event => {
    if (commandText && title) {
      setFilteredMaps(
        folderStructure?.root?.maps?.filter(map =>
          map.name.toLowerCase().includes(title.toLowerCase())
        )
      );
      setMapDropDown(true);
    }
    setInputValue(event.target.value);
    updateSuggestions(event.target.value);
  };

  const handleKeyDown = event => {
    if (event.getModifierState("CapsLock")) {
      toast.warn("Please turn off caps lock")
      return;
    }
    const commandSet = inputValue.toLowerCase().split(' ');

    const command = commandSet[0];
    let instruction = commandSet[1];

    if (instruction) {
      setShowPlaceholder(false);
      return;
    }

    if (command.includes('/pm') && event.key === "Backspace") {
      setFilteredMaps([])
      setMapDropDown(false)
      return
    }

    if (command.includes('/p') && event.key === "m") {
      setPlaceholder("Enter map name")
      setShowPlaceholder(true)
      return
    }

    if (command.includes('/p') && event.key === "w") {
      setPlaceholder("Enter web address")
      setShowPlaceholder(true)
      return
    }

    if (command.includes('/pm') && event.key === " ") {
      setFilteredMaps(folderStructure?.root?.maps);
      setMapDropDown(true);
      return
    }

    if (event.key === 'ArrowDown') {
      inputRef.current?.blur();
      if (!title) setOption(1);
      else setMapOption(1)
    }

    if (event.key === 'Escape') {
      event.preventDefault();
      event.stopPropagation();
      anchorMode.reset();
      return;
    }

    if (event.key === 'Enter') {

      if (command.includes('/pw') || command.includes('/yt')) {
        instruction = normalizeURL(instruction);
        let link = instruction;

        if (command.includes('/yt')) {
          link = constructYoutubeEmbedLink(instruction);
        }

        createPreview(
          { x: pos.x, y: pos.y },
          {
            type: 'Web Page',
            link: `${link}`,
            title: `${link}`,
          },
          mapId
        );
        anchorMode.reset()
        setIsEditing(false);
        setInputValue('');
        return;
      } 
    }
  };

  const createMapPreview = (mapTitle: string, mapIdToCreate: string) => {
    createPreview(
      { x: pos.x, y: pos.y },
      {
        type: 'Map',
        link: `${origin}/map/${mapIdToCreate}?actionbar=false`,
        title: `${mapTitle}`,
        mapId: mapIdToCreate,
      },
      mapId
    );
    anchorMode.reset();
    setIsEditing(false);
    setInputValue('');
  };

  const handleSuggestionSelect = () => {
    setInputValue(`${visibleSuggestions[option].command} `);
    inputRef.current?.focus();
  };

  useEffect(() => {
    if (filteredMaps.length > 0) {
      setSelectedMap(filteredMaps[mapOption]);
    }
  }, [filteredMaps, mapOption]);

  useHotkeys([
    [
      'ArrowUp',
      e => {
        e.stopPropagation();
        e.preventDefault();
        if (!title) {
          if (option === 0) {
            inputRef.current?.focus();
          } else {
            setOption(option - 1);
          }
          return;
        }

        if (mapOption === 0) {
          inputRef.current?.focus();
        } else {
          setMapOption(mapOption - 1);
        }
      },
    ],
    [
      'ArrowDown',
      e => {
        e.stopPropagation();
        e.preventDefault();
        if (!title) {
          if (option === visibleSuggestions.length - 1) {
            setOption(0);
          } else {
            setOption(option + 1);
          }
        }
        if (mapOption === filteredMaps.length - 1) {
          setMapOption(0);
        } else {
          setMapOption(mapOption + 1);
        }
      },
    ],
    [
      'Enter',
      e => {
        e.preventDefault();
        if (!title) {
          setInputValue(`${visibleSuggestions[option].command} `);
          inputRef.current?.focus();
          return;
        }

        createMapPreview(selectedMap?.name, selectedMap?.mapId);
      },
    ],

    [
      'Escape',
      e => {
        e.preventDefault();
        e.stopPropagation();
        anchorMode.reset();
      },
    ],
  ]);

  return (
    <div className='relative w-full'>
      <div
        style={{
          position: 'absolute',
          top: `${pos.y}px`,
          left: `${pos.x}px`,
        }}
      >
        <CommandInput
          handleCommandInputChange={handleCommandInputChange}
          handleKeyDown={handleKeyDown}
          inputRef={inputRef}
          inputValue={inputValue}
        >
          {visibleSuggestions.map((sug, index) => (
            <SuggestedCommand
              key={index}
              handleOnClick={handleSuggestionSelect}
              className="cursor-pointer"
              commandSlot={sug.command}
              commandNameSlot={sug.name}
              isSelected={option === index}
            />
          ))}
          {mapDropDown && (
            <div>
              {filteredMaps?.map((map, index) => {
                return (
                  <SuggestedCommand
                    key={index}
                    handleOnClick={() => createMapPreview(map.name, map.mapId)}
                    className="cursor-pointer"
                    commandNameSlot={map.name}
                    mapIconSlot={<FolderMapIconRenderer icon={map?.icon} />}
                    isSelected={mapOption === index}
                    isSuggestedMap={true}
                  />
                );
              })}
            </div>
          )}
        </CommandInput>
      </div>
      {showPlaceholder && <span className='font-semibold' style={{
        position: 'absolute',
        top: `${pos.y + 5}px`,
        left: `${pos.x + 95}px`,
        width: '150px',
        color: '#757279'
      }}>
        {placeholder}
      </span>}
    </div>
  );
}
