import { MouseEventHandler } from "react";

interface sidebarRightProps {
  width: number;
  onMouseMove: MouseEventHandler;
  onMouseLeave: MouseEventHandler;
  onMouseDown: MouseEventHandler;
}

export function SideBarRight({ width, onMouseDown, onMouseLeave, onMouseMove }: sidebarRightProps) {
  return (
    <>
      <div className="text-white hidden md:block" style={{ width: `${width}px` }} onMouseDown={onMouseDown} onMouseLeave={onMouseLeave} onMouseMove={onMouseMove}>
        Side bar right
      </div>
    </>
  );
}
