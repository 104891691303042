import { HTMLElementRefOf } from '@plasmicapp/react-web';
import { styled } from '@stitches/react';
import SimpleTextInput from 'CoreComponents/SimpleTextInput/SimpleTextInput';
import { RUNNING_IN_TAURI } from 'propro-common-components';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLargeItemEditing } from 'utils/hooks/dashboard/useLargeItem';
import { getMapPreview } from 'utils/stores/mapshotStore';
import openInNewDesktopWindow from 'utils/tauri/openInNewDesktopWindow';
import { dashboardStore } from '../utils/stores/dashboardStore';
import { setContextMenuForItem } from '../utils/stores/generalStore';
import { FolderData, MapData } from '../utils/stores/types';
import {
  DefaultLargeItemProps,
  PlasmicLargeItem,
} from './plasmic/map_map/PlasmicLargeItem';

export interface LargeItemProps extends DefaultLargeItemProps {
  folderId?: string;
  mapId?: string;
  isSelected?: boolean;
  onSelect?: (event: React.MouseEvent) => void;
  contextMenuFn?: (event: React.MouseEvent) => void;
  item?: FolderData | MapData;
  opacityLevel?: number;
}

function LargeItem_(
  {
    folderId,
    mapId,
    isSelected,
    onSelect,
    opacityLevel,
    ...props
  }: LargeItemProps,
  ref: HTMLElementRefOf<'div'>
) {
  if (!mapId && !folderId) return null;

  // this id is used for useClickOutsideLargeItem
  // an identifying class would have also worked for this
  const itemId = `large-item-${folderId || mapId}`;

  const navigate = useNavigate();

  const openItem = (event: React.MouseEvent) => {
    if (event.metaKey || event.ctrlKey || event.shiftKey) {
      onSelect?.(event);
      return;
    }

    if (!mapId && !folderId) return;

    if (mapId) {
      navigate(`/map/${mapId}`);
      return;
    }
    folderId === 'Root-folder'
      ? navigate(`/dashboard`)
      : navigate(`/dashboard/folder/${folderId}`);
  };

  function dblClickItem(event: React.MouseEvent) {
    if ((event.metaKey || event.ctrlKey) && props?.itemType === 'map') {
      if (RUNNING_IN_TAURI) {
        return openInNewDesktopWindow(`${props?.title}`, mapId, `/map/${mapId}`);
      } else {
        return window?.open(`/map/${mapId}`);
      }
    }
  }

  // Renaming Folder/Map title implementations
  const {
    localTitle,
    titleInputRef,
    isItemInRenamingMode,
    handleTitleChange,
    handleKeyDown,
    setIsEditing,
    resetRenamingMode,
  } = useLargeItemEditing({
    initialTitle: props?.title as string,
    mapId,
    folderId,
  });

  return (
    <PlasmicLargeItem
      id={itemId}
      mapPreviewImageSlot={
        getMapPreview(mapId, props?.item) ? (
          <img
            src={getMapPreview(mapId, props?.item)}
            style={{
              height: 100,
              width: 154,
            }}
          />
        ) : (
          getMapPreview(mapId, props?.item)
        )
      }
      titleContainer={{
        style: {
          all: 'unset',
          width: '100%',
          display: 'flex',
          justifyContent: 'left',
        },
      }}
      title={<FolderTitle>{localTitle}</FolderTitle>}
      titleIcon={props.titleIcon}
      itemType={props.itemType}
      largeItem={{ ref }}
      onClick={!isItemInRenamingMode() ? openItem : () => null}
      onDoubleClick={!isItemInRenamingMode() ? dblClickItem : () => null}
      {...(folderId === 'Root-folder' && { color: 'white' })}
      isSelected={isSelected}
      // Editing the title/name
      isEditing={isItemInRenamingMode()}
      titleTextInputSlot={
        <SimpleTextInput
          className="w-full"
          ref={titleInputRef}
          value={localTitle}
          autoFocus={true}
          placeholder="Enter title"
          onChange={handleTitleChange}
          onFocus={() => setIsEditing(true)}
          onBlur={resetRenamingMode}
          onKeyDown={handleKeyDown}
        />
      }
      onContextMenu={event => {
        event.preventDefault();
        if (
          dashboardStore.selectedMapIds.length === 0 &&
          dashboardStore.selectedFolderIds.length === 0
        ) {
          onSelect?.(event);
        }

        setContextMenuForItem({
          position: { x: event.clientX, y: event.clientY },
          itemPassed: props.item,
          context: 'largeItem',
        });
      }}
      //@ts-ignore
      opacityLevelVariant={`_${opacityLevel}`}
    />
  );
}

const FolderTitle = styled('p', {
  overflow: 'hidden',

  display: '-webkit-box',
  '-webkit-line-clamp': '2',
  '-webkit-box-orient': 'vertical',
  whiteSpace: 'wrap',
  textOverflow: 'ellipsis',
});

const LargeItem = React.forwardRef(LargeItem_);
export default LargeItem;
