import { RenderSelectedContent } from 'CoreComponents/Renderers/RenderSelectedContent';
import Area, { AreaMemo, AreaProps } from './Area';

export const selectAreaVersion = (
  areaProps: AreaProps,
  useSelectionV2: boolean,
  mapID: string
): React.ReactNode => {
  return (
    <RenderSelectedContent
      key={areaProps.areaId}
      childprops={{ ...areaProps }}
      mapID={mapID}
      ID={areaProps.areaId}
      renderChild={props => <AreaMemo {...props} />}
    />
  );
};
