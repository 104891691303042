// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import { HTMLElementRefOf } from '@plasmicapp/react-web';
import * as React from 'react';
import {
  DefaultSidebarButtonProps,
  PlasmicSidebarButton,
} from './plasmic/map_map/PlasmicSidebarButton';

export interface SidebarButtonProps extends DefaultSidebarButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  style: Object;
}

function SidebarButton_(
  props: SidebarButtonProps,
  ref: HTMLElementRefOf<'button'>
) {
  return <PlasmicSidebarButton root={{ ref }} {...props} />;
}

const SidebarButton = React.forwardRef(SidebarButton_);
export default SidebarButton;
