// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type GesturesHandStopIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function GesturesHandStopIcon(props: GesturesHandStopIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 24 24"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M13 12c0 .55.44 1 1 1 .55 0 1-.45 1-1V5.5c0-.28.22-.5.5-.5.27 0 .49.22.5.5v1.588a2.53 2.53 0 00-.03.392c0 .084.01.167.03.245V12c0 .55.44 1 1 1 .55 0 1-.45 1-1V7.306a.494.494 0 01.47-.326c.27 0 .49.22.5.5v8.5c0 2.76-2.24 5-5 5h-1.8c-1.7 0-3.28-.86-4.2-2.28-.06-.08-.12-.16-.19-.27-.31-.45-1.52-2.61-3.19-5.67a.494.494 0 01.19-.68c.32-.18.73-.13 1 .14l1.47 1.48c.122.119.267.2.42.245A1.003 1.003 0 009 13V5.5c0-.28.22-.5.5-.5.27 0 .49.22.5.5V12c0 .55.44 1 1 1 .55 0 1-.45 1-1V3.5c0-.28.22-.5.5-.5.27 0 .49.22.5.5V12zm4.957-6.968a2.55 2.55 0 01.513-.052c1.38 0 2.5 1.11 2.5 2.5v8.5c0 3.86-3.14 7-7 7L14 23h-2.121a7.024 7.024 0 01-5.53-3.14c-.039-.05-.081-.113-.125-.177a4.551 4.551 0 00-.105-.153c-.28-.43-1.15-1.95-3.3-5.76H2.81c-.68-1.19-.28-2.69.89-3.38a2.83 2.83 0 013.3.246V5.5C7 4.11 8.11 3 9.5 3c.185 0 .365.02.538.058A2.487 2.487 0 0112.5 1c1.23 0 2.253.882 2.461 2.057.174-.037.354-.057.539-.057 1.22 0 2.238.869 2.457 2.032z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default GesturesHandStopIcon;
/* prettier-ignore-end */
