import { useClickOutside } from '@mantine/hooks';
import { HTMLElementRefOf } from '@plasmicapp/react-web';
import EmojiAndIconSelectorWrapper from 'components/EmojiAndIconSelector/EmojiAndIconSelectorWrapper';
import * as React from 'react';
import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import {
  useChangeItemIcon,
  useUpdateQuickAccess,
} from 'utils/hooks/dashboard/useLargeItem';
import { useSnapshot } from 'valtio';
import {
  useDeleteFolder,
  useDeleteMapFromBackend,
  useDeleteMultipleMapsAndFoldersFromBackend,
} from '../api/mutations';
import { dashboardStore } from '../utils/stores/dashboardStore';
import {
  generalStore,
  setContextMenuForItem,
} from '../utils/stores/generalStore';
import { ContextMenuContext, FolderData, MapData } from '../utils/stores/types';
import {
  DefaultContextMenuProps,
  PlasmicContextMenu,
} from './plasmic/map_map/PlasmicContextMenu';

export interface ContextMenuProps extends DefaultContextMenuProps {}

function ContextMenu_(props: ContextMenuProps, ref: HTMLElementRefOf<'div'>) {
  const [visible, setVisible] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  //const [expansionVariant, setExpansionVariant] = useState<string | undefined>(undefined);
  const [context, setContext] = useState<ContextMenuContext | undefined>(
    undefined
  );
  const [rawPosition, setRawPosition] = useState({ x: 0, y: 0 });
  const [item, setItem] = useState<FolderData | MapData | undefined>(undefined);
  const [oldItem, setOldItem] = useState<FolderData | MapData | undefined>(item);
  const { selectedMapIds, selectedFolderIds } = dashboardStore;
  const [isExpanded, setIsExpanded] = useState(true); // right now, we're not doing expansion (influencing isMetadataHidden and expansionVariant
 
  const menuRef = useRef<HTMLDivElement>(null);
  const iconSelectorRef = useRef<HTMLDivElement>(null);

  // mutations
  const { mutate: deleteFolder } = useDeleteFolder();
  const { mutate: deleteMap } = useDeleteMapFromBackend();
  const { mutate: deleteMapsAndFolders } =
    useDeleteMultipleMapsAndFoldersFromBackend();

  const generalStoreState = useSnapshot(generalStore);

  const handleDelete = () => {
    // TODO: add a confirmation dialog

    setVisible(false);

    // console.log('handleDelete');
    // console.log('selectedMapIds', selectedMapIds);
    // console.log('selectedFolderIds', selectedFolderIds);

    // check if there is only one folder selected
    if (selectedFolderIds.length === 1 && selectedMapIds.length === 0) {
      const folderId = selectedFolderIds[0];
      deleteFolder(
        { id: folderId },
        {
          onSuccess: () =>
            console.log(`Folder ${folderId} deleted successfully`),
          onError: error =>
            console.error(`Error deleting folder ${folderId}:`, error),
        }
      );
      /*deleteMapsAndFolders({ selectedFolderIds }, {
        onSuccess: () => console.log(`Map ${selectedFolderIds} deleted successfully`),
        onError: (error) => console.error(`Error deleting map ${selectedFolderIds}:`, error),
      });*/

      dashboardStore.selectedFolderIds = [];
      return;
    }

    // check if there is only one map selected
    if (selectedMapIds.length === 1 && selectedFolderIds.length === 0) {
      const mapId = selectedMapIds[0];
      /*deleteMap(
        { id: mapId },
        {
          onSuccess: () => console.log(`Map ${mapId} deleted successfully`),
          onError: (error) => console.error(`Error deleting map ${mapId}:`, error),
        }
      );*/ // not using this because using the other endpoint is faster than solving a rerendering issue

      deleteMapsAndFolders({ selectedMapIds });

      dashboardStore.selectedMapIds = [];
      setVisible(false);
      return;
    }

    // check if there are multiple maps or folders selected
    if (selectedMapIds.length >= 1 || selectedFolderIds.length >= 1) {
      // console.log('about to delete multiple maps and folders');
      deleteMapsAndFolders({ selectedMapIds, selectedFolderIds });

      dashboardStore.selectedMapIds = [];
      dashboardStore.selectedFolderIds = [];
    }
  };

  // Folder/Map Title renaming and Icon Changing
  const editActionHandlers = {
    rename: () => {
      dashboardStore.largeItems.itemToRename = item;
    },
    'change icon': () => {
      setContextMenuForItem({
        position: rawPosition,
        itemPassed: item,
        context: 'customComponent',
        mode: 'updatingEmojiAndIcon'
      });
    },
  };

  const handleItemEditAction = (action: keyof typeof editActionHandlers) => {
    const totalSelectedItems = selectedFolderIds.length + selectedMapIds.length;

    if (totalSelectedItems !== 1) {
      toast.error('Please select only one item');
      setVisible(false);
      return;
    }

    editActionHandlers[action]();
    setVisible(false);
  };

  const closeContextMenu = () => {
    setVisible(false);
    setContext(undefined);
    setContextMenuForItem(undefined);
    setOldItem(undefined)
    setItem(undefined)
    generalStore.openContextMenuForItem = undefined
  };

  const resolvedType =
    item?.folderId && !(item as MapData).mapId ? 'folder' : 'map';
  const resolvedId =
    (item as FolderData)?.folderId || (item as MapData)?.mapId || '';

  const { editItemIcon } = useChangeItemIcon();

  // For changing item icon
  const handleSelectIcon = async (icon: {
    type: string;
    name: string;
    color: string;
  }) => {
    const newItem = { ...item, icon }
    setContextMenuForItem({
      position: rawPosition,
      itemPassed: newItem,
      context: 'customComponent',
      mode: 'updatingEmojiAndIcon'
    });
    await editItemIcon(resolvedType, resolvedId, icon);

    closeContextMenu()
  };

  const outsideClickMenuRef = useClickOutside(async () => {
    if (!oldItem) {
      closeContextMenu()
      return
    }
    
    if (oldItem?.icon?.color !== item?.icon?.color) {
      await editItemIcon(resolvedType, resolvedId, item?.icon)      
    }
    closeContextMenu();
    return;
  });

  const { updateItem } = useUpdateQuickAccess();

  // Quick Access Action
  const handleQuickAccess = async () => {
    await updateItem(resolvedType, resolvedId, item.isInQuickAccess || false);

    closeContextMenu();
  };

  const changeIconColor = (iconColor: string) => {
    if (item.icon && item.icon?.type === 'icon') {
      const newItem = {...item, icon: {...item.icon, color: iconColor}}
      setContextMenuForItem({
        position: rawPosition,
        itemPassed: newItem,
        context: 'customComponent',
        mode: 'updatingEmojiAndIcon'
      });
    }
  }

  //this is where we get the item data that we need and react to the opening of the menu
  // (from any file in the app that imports this function)
  React.useEffect(() => {
    const initializeContextMenu = () => {
      const { position, itemPassed, context } =
        generalStoreState.openContextMenuForItem;

      // event.preventDefault();
      // setter fn that drives the context variant
      setContext(context);
      // setter for item information
      setItem(itemPassed);
      setOldItem(item)
      // setter for rawPosition from the store
      setRawPosition(position);

      // not sure what this is for
      const menu = menuRef.current;
      if (!menu) return;

      const iconSelector = iconSelectorRef.current;
      if (!iconSelector) return;

      // iconSeectorWidth = iconSelector.offsetWidth;
      // iconSelectorHeight = iconSelector.offsetHeight;

      // positioning
      let x = position.x;
      let y = position.y;

      // Icon selector positioning
      let iconSelectorX = position.x;
      let iconSelectorY = position.y;

      // console.log('x, y', x, y);

      const { innerWidth, innerHeight, scrollX, scrollY } = window;

      // Calculate available space on each side
      const spaceRight = innerWidth - x;
      const spaceBottom = innerHeight - y;
      const spaceLeft = x;
      const spaceTop = y;

      // Determine the best position based on available space
      if (spaceRight < 150 && spaceLeft > spaceRight) {
        x = x - 150; // Shift left if not enough space on the right
      }

      if (spaceBottom < 200 && spaceTop > spaceBottom) {
        y = y - 200; // Shift up if not enough space at the bottom
      }

      if (spaceBottom < 380 && spaceTop > spaceBottom) {
        if (innerWidth <= 1440) {
          iconSelectorX = 800; // Set specific position for smaller screens
        } else {
          iconSelectorX = iconSelectorX - 380; // Shift up for larger screens
          if (iconSelectorX + 380 > innerWidth) {
            iconSelectorX = innerWidth - iconSelector.offsetWidth;
            iconSelectorX = innerWidth - 380;
          }
        }
      }

      if (spaceBottom < 570 && spaceTop > spaceBottom) {
        if (innerWidth <= 1440) {
          iconSelectorY = 55; // Set specific position for smaller screens
        } else {
          iconSelectorY -= 570; // Shift up for larger screens
          if (iconSelectorY + 570 > innerWidth) {
            iconSelectorY = innerWidth - iconSelector.offsetWidth;
            iconSelectorY = innerWidth - 570;
            window.scrollBy(0, 570);
          }
        }
      }

      // to make sure the menu is not partially outside the screen
      // when it is opened near one of the edges
      if (x + 150 > innerWidth) {
        x = innerWidth - menu.offsetWidth; //unfortunately the menu.offsetWidth/Height values are always 0
        x = innerWidth - 150;
      }
      if (y + 200 > innerHeight) {
        y = innerHeight - menu.offsetHeight; //unfortunately the menu.offsetWidth/Height values are always 0
        y = innerHeight - 200;
        window.scrollBy(0, 200);
      }

      let top = y + scrollY;
      let left = x + scrollX;

      let iconSelectorTop = iconSelectorY + scrollY;
      let iconSelectorLeft = iconSelectorX + scrollX;

      setMenuPosition({ x: left, y: top });
      setRawPosition({ x: iconSelectorLeft, y: iconSelectorTop });
      setVisible(true);
    };

    if (generalStoreState.openContextMenuForItem !== undefined) {
      initializeContextMenu();
    }

    // return () => {
    //   setContextMenuForItem(undefined);
    // };
  }, [generalStoreState]);

  return (
    <PlasmicContextMenu
      root={{ ref }}
      {...props}
      style={{
        zIndex: 1000,
        position: 'absolute',
        top: menuPosition.y,
        left: menuPosition.x,
        width: 'fit-content',
      }}
      // variants
      context={context}
      isHidden={!visible}
      isMetadataHidden={!isExpanded}
      // expanded should take the value of context, but only if isExpanded
      //expansionVariant={isExpanded ? context : undefined}

      customComponentSlot={
        <div ref={iconSelectorRef}>
          {context === 'customComponent' ? (
            <EmojiAndIconSelectorWrapper onIconSelect={handleSelectIcon} onIconColorSelect={(iconColor) => changeIconColor(iconColor)} />
          ) : null}
        </div>
      }
      isInQuickAccess={item?.isInQuickAccess || false}
      // overrides
      menuContainer={{ ref: outsideClickMenuRef }}
      menu={{ ref: menuRef }}
      title={
        dashboardStore.selectedMapIds.length > 1 ||
        dashboardStore.selectedFolderIds.length > 1
          ? `${dashboardStore.selectedMapIds.length + dashboardStore.selectedFolderIds.length} items selected`
          : item?.name
      }
      changeIconButton={{
        //@ts-ignore
        onClick: () => handleItemEditAction('change icon'),
      }}
      renameButton={{
        //@ts-ignore
        onClick: () => handleItemEditAction('rename'),
      }}
      addToQuickAccessButton={{
        //@ts-ignore
        onClick: handleQuickAccess,
      }}
      //createdAtDate={item?.createdAt} // TODO: ensure that we actually get that
      showMoreButton={{
        //@ts-ignore onPointerDown
        onPointerDown: () =>
          setIsExpanded(true) /*setExpandedVariant(context)*/,
      }}
      isMultipleLargeItemsSelected={false}
      deleteButton={{
        //@ts-ignore
        onClick: handleDelete as React.MouseEventHandler<HTMLButtonElement>,
      }}
    />
  );
}

const ContextMenu = React.forwardRef(ContextMenu_);
export default ContextMenu;
