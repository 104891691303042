// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import { HTMLElementRefOf } from '@plasmicapp/react-web';
import * as React from 'react';
import { dashboardStore } from 'utils/stores/dashboardStore';
import { setContextMenuForItem } from 'utils/stores/generalStore';
import { FolderData, MapData } from 'utils/stores/types';
import {
  DefaultDropdownItemProps,
  PlasmicDropdownItem,
} from './plasmic/map_map/PlasmicDropdownItem';

export interface DropdownItemProps extends DefaultDropdownItemProps {
  handleClick?: Function;
  style?: React.CSSProperties;
  highlight?: boolean;
  onSelect?: (event: React.MouseEvent) => void | undefined;
  itemPassed?: FolderData | MapData;
  expandingFunction?: () => void;
  iconNameContainerClick?: () => void;
}

function DropdownItem_(
  {
    handleClick,
    style,
    highlight,
    onSelect,
    itemPassed,
    expandingFunction,
    iconNameContainerClick,
    ...props
  }: DropdownItemProps,
  ref: HTMLElementRefOf<'div'>
) {
  return (
    <div
      style={{
        width: '100%',
        overflow: 'hidden',
        // backgroundColor: 'red',
        // borderWidth: 2,
        // borderColor: 'white',
      }}
    >
      <PlasmicDropdownItem
        style={{
          ...style,
          backgroundColor: highlight ? 'rgba(255,255,255,0.3)' : undefined,
          borderRadius: '0.5em',
          // width: '100%',
          // overflow: 'hidden',
        }}
        indentationTreeContainer={{
          style: {},
        }}
        onClick={() => {
          handleClick && handleClick();
        }}
        root={{ ref }}
        onContextMenu={event => {
          event.preventDefault();
          if (
            dashboardStore.selectedMapIds.length === 0 &&
            dashboardStore.selectedFolderIds.length === 0 &&
            onSelect
          ) {
            onSelect(event);
            setContextMenuForItem({
              position: { x: event.clientX, y: event.clientY },
              itemPassed,
              context: 'quickAccess',
            });
          }
        }}
        iconAndName={{
          onClick: () => iconNameContainerClick && iconNameContainerClick(),
        }}
        rightIconContainer={{
          onClick: () => expandingFunction && expandingFunction(),
        }}
        {...props}
      />
    </div>
  );
}

const DropdownItem = React.forwardRef(DropdownItem_);
export default DropdownItem;
