// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type CommunicationChatIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function CommunicationChatIcon(props: CommunicationChatIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 24 24"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        opacity={".25"}
        d={
          "M3 17.275V9.4c0-2.24 0-3.36.436-4.216a4 4 0 011.748-1.748C6.04 3 7.16 3 9.4 3h5.2c2.24 0 3.36 0 4.216.436a4 4 0 011.748 1.748C21 6.04 21 7.16 21 9.4v1.2c0 2.24 0 3.36-.436 4.216a4 4 0 01-1.748 1.748C17.96 17 16.84 17 14.6 17h-4.24c-.502 0-.753 0-.988.058a2 2 0 00-.589.25c-.205.13-.379.31-.727.67l-.929.963c-1.29 1.337-1.935 2.006-2.49 2.056a1.5 1.5 0 01-1.272-.513C3 20.062 3 19.133 3 17.274z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default CommunicationChatIcon;
/* prettier-ignore-end */
