import { HTMLElementRefOf } from '@plasmicapp/react-web';
import Tooltip from 'CoreComponents/Tooltip/Tooltip';
import 'css/tooltip.css';
import * as React from 'react';
import {
  DefaultActionBarButtonProps,
  PlasmicActionBarButton,
} from './plasmic/map_map/PlasmicActionBarButton';

export interface ActionBarButtonProps extends DefaultActionBarButtonProps {
  tooltipText?: string;
  shortCutLabel?: string;
  onClick: () => void;
  activateMethod?: 'hover' | 'click';
  tooltipContent?: React.ReactNode;
  closeOnClick?: boolean;
  tooltipColor?: string;
  showArrow?: boolean;
  tooltipInnerClass?: string;
  tooltipOuterClass?: string;
}

function ActionBarButton_(
  {
    tooltipText,
    shortCutLabel,
    activateMethod = 'hover',
    tooltipContent,
    closeOnClick,
    tooltipColor,
    showArrow,
    tooltipInnerClass,
    tooltipOuterClass,
    ...props
  }: ActionBarButtonProps,
  ref: HTMLElementRefOf<'div'>
) {
  return (
    <Tooltip
      activateMethod={activateMethod}
      tooltipText={tooltipText}
      shortCutLabel={shortCutLabel}
      tooltipContent={tooltipContent}
      closeOnClick={closeOnClick}
      tooltipColor={tooltipColor}
      showArrow={showArrow}
      tooltipInnerClass={tooltipInnerClass}
      tooltipOuterClass={tooltipOuterClass}
    >
      {setTriggerRef => (
        <PlasmicActionBarButton
          actionBarButton={{ ref: setTriggerRef }}
          {...props}
        />
      )}
    </Tooltip>
  );
}

const ActionBarButton = React.forwardRef(ActionBarButton_);
export default ActionBarButton;
