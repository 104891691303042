import { useOs } from '@mantine/hooks';
import { HTMLElementRefOf } from '@plasmicapp/react-web';
import {
  moreOptions,
  searchOptions,
} from 'constants/actionBar/actionBarMenuOptions';
import { ActionBarButtonProps } from 'PlasmicComponents/ActionBarButton';
import ShortcutLabel from 'PlasmicComponents/ShortcutLabel';
import * as React from 'react';
import { handleCopy } from 'utils/copyCutAndPaste/copy';
import { hotKeyStateStore } from 'utils/stores/hotKeyStatesStore';
import { creatingAreaMode } from 'utils/stores/mapMode/modesStore';
import { mapshotStore, toggleMapshotMode } from 'utils/stores/mapshotStore';
import { useSnapshot } from 'valtio';
import { getMapID } from 'views/core/mapView';
import {
  DefaultActionBarProps,
  PlasmicActionBar,
} from './plasmic/map_map/PlasmicActionBar';

export interface ActionBarProps extends DefaultActionBarProps {}

type KeyTypes = 'alt' | 'cmd' | 'ctrl' | 'shi' | 'opt' | 'off';

function ActionBar_(props: ActionBarProps, ref: HTMLElementRefOf<'div'>) {
  const mapId = getMapID();
  const hotKeyStateStoreSnapshot = useSnapshot(hotKeyStateStore);
  const createAreaMode = useSnapshot(creatingAreaMode);
  const reactiveSnapshotStore = useSnapshot(mapshotStore);


  const os = useOs();

  function resolveKey(key: KeyTypes): KeyTypes {
    if (os !== 'macos') {
      if (key === 'cmd') return 'ctrl';
      if (key === 'opt') return 'alt';
    }
    return key;
  }

  function returnMenuOptions(type: 'search' | 'more') {
    const options =
      type === 'search' ? searchOptions : type === 'more' ? moreOptions : [];
    return (
      <div>
        {options.map((item, i) => (
          <div
            key={i}
            className={`py-2 px-2 cursor-pointer hover:bg-white/5 transition-all duration-200 border-b border-black/20 -ml-2`}
            onClick={item?.trigger}
          >
            <div className={!item?.active && 'opacity-40'}>
              {item.text}{' '}
              {!item?.active && <span className="text-sm">(coming soon)</span>}{' '}
              {item?.shortcut && (
                <ShortcutLabel>{item?.shortcut}</ShortcutLabel>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div>
      <PlasmicActionBar
        set={'initial'}
        homeButton={
          {
            tooltipText: 'Dashboard',
            onClick: () => (window.location.href = '/dashboard/overview'),
          } as ActionBarButtonProps
        }
        insertModeButton={
          {
            activeState: hotKeyStateStoreSnapshot.current.isMetaActive
              ? resolveKey('cmd')
              : hotKeyStateStoreSnapshot.current.isCtrlActive
                ? resolveKey('ctrl')
                : resolveKey('off'),
            tooltipText: 'Insert Mode',
            shortCutLabel: resolveKey('cmd'),
            onClick: () => {
              hotKeyStateStore.current.resetOnMouseMove = false;

              hotKeyStateStore.current.isMetaActive =
                !hotKeyStateStore.current.isMetaActive;
              hotKeyStateStore.current.isCtrlActive =
                !hotKeyStateStore.current.isCtrlActive;
              hotKeyStateStore.current.isModActive =
                !hotKeyStateStore.current.isModActive;
            },
          } as ActionBarButtonProps
        }
        shiftButton={{
          activeState: hotKeyStateStoreSnapshot.current.isShiftActive
            ? resolveKey('shi')
            : resolveKey('off'),
          onClick: () => {
            hotKeyStateStore.current.resetOnMouseMove = false;

            hotKeyStateStore.current.isShiftActive =
              !hotKeyStateStore.current.isShiftActive;
          },
          tooltipText: 'Shift',
          shortCutLabel: 'shift',
        }}
        copyButton={
          {
            activeState: hotKeyStateStoreSnapshot.current.isAltActive
              ? resolveKey('opt')
              : resolveKey('off'),
            onClick: () => {
              handleCopy(mapId);
              hotKeyStateStore.current.resetOnMouseMove = false;

              hotKeyStateStore.current.isAltActive =
                !hotKeyStateStore.current.isAltActive;
            },
            tooltipText: 'Duplicate',
            shortCutLabel: resolveKey('opt'),
          } as ActionBarButtonProps
        }
        areaCreationButton={
          {
            activeState: createAreaMode.isActive ?
              'areaCreationMode' : 'off',
            onClick: () => {
              if (createAreaMode.isActive) {
                creatingAreaMode.reset();
              } else {
                creatingAreaMode.activate();
              }
            },
            tooltipText: 'Area Creation',
            shortCutLabel: `${resolveKey('cmd')} + shift + A`,
          } as ActionBarButtonProps
        }
        screenshotCreationButton={
          {
            activeState: reactiveSnapshotStore.mapshotMode ?
              'screenshotCreationMode' : 'off',
            onClick: () => {
              // updates the mapshotStore and the mapmodesStore
              toggleMapshotMode()
            },
            tooltipText: 'Mapshot (Screenshot) Creation',
            shortCutLabel: `${resolveKey('cmd')} + shift + M`,
          } as ActionBarButtonProps
        }
        searchButton={{
          activateMethod: 'click',
          tooltipContent: <div>{returnMenuOptions('search')}</div>,
          tooltipColor: '#222',
          closeOnClick: true,
          showArrow: false,
          tooltipInnerClass: '!p-0 w-56 text-sm',
          tooltipOuterClass: '!border-[#444] rounded-xl',
        }}
        moreButton={{
          activateMethod: 'click',
          tooltipContent: <div>{returnMenuOptions('more')}</div>,
          tooltipColor: '#222',
          closeOnClick: true,
          showArrow: false,
          tooltipInnerClass: '!p-0 w-56 text-sm',
          tooltipOuterClass: '!border-[#444] rounded-xl',
        }}
        floatingBar={{
          overflowShow: true,
        }}
        root={{ ref }}
        {...props}
      />
    </div>
  );
}

const ActionBar = React.forwardRef(ActionBar_);
export default ActionBar;
