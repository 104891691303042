import { useHotkeys } from '@mantine/hooks';
import { HTMLElementRefOf } from '@plasmicapp/react-web';
import { useGetUserFolderStructure } from 'api/queries';
import FolderMapIconRenderer from 'components/folderMapIconRenderer/FolderMapIconRenderer';
import SimpleTextInput from 'CoreComponents/SimpleTextInput/SimpleTextInput';
import DropdownItem from 'PlasmicComponents/DropdownItem';
import DropdownItemGroup from 'PlasmicComponents/DropdownItemGroupDup';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { getRecentMapsMapFolderSearchItem } from 'utils/helpers/getRecent';
import {
  folderSearchQueries,
  setMapFolderSearchQuery,
  toggleMapFolderSearchView,
} from 'utils/stores/dashboardStore';
import { getMapPreview } from 'utils/stores/mapshotStore';
import { FolderData, MapData } from 'utils/stores/types';
import { useSnapshot } from 'valtio';
import { FolderStructure } from 'views/dashboard/DashboardView';
import {
  DefaultMapFolderSearchNewProps,
  PlasmicMapFolderSearchNew,
} from './plasmic/map_map/PlasmicMapFolderSearchNew';

export type GetAllMapsListResponse = {
  data: { maps: MapData[] };
  isLoading: boolean;
};

export interface MapFolderSearchNewProps
  extends DefaultMapFolderSearchNewProps {}

function MapFolderSearchNew_(
  props: MapFolderSearchNewProps,
  ref: HTMLElementRefOf<'div'>
) {
  const navigate = useNavigate();
  const queries = useSnapshot(folderSearchQueries);
  const inputRef = React.useRef(null);
  const { data: folderStructure, isLoading: folderLoading } =
    useGetUserFolderStructure() as FolderStructure;

  const folders = folderStructure?.root?.folders || [];
  const maps = folderStructure?.root?.maps || [];

  const containerRef = React.useRef(null);

  const [usersFolders, setUsersFolders] = React.useState([
    getRecentMapsMapFolderSearchItem(folderStructure?.root),
    ...folders,
  ]);
  const [usersMaps, setUsersMaps] = React.useState(maps);

  const [altNavList, setAltNavList] = React.useState<{
    folders: FolderData[];
    maps: MapData[];
  } | null>(null);
  const [expandedFolderGroups, setExpandedFolderGroups] = React.useState([]);

  const [active, setActive] = React.useState(null);

  useHotkeys([
    [
      'Escape',
      e => {
        handleEsc(e);
      },
    ],
  ]);

  function removeAlt(id: string) {
    if (!id.startsWith('alt-')) return id;
    return id.split('alt-')[1];
  }

  function handleSearch(q: string) {
    // if (!q) return;
    const res = searchFolders(folders, q, folders);
    const resMaps = searchMaps(maps || [], q, maps || []);
    setUsersFolders(res);
    setUsersMaps(resMaps);
    handleSetActive(0);
  }

  function handleEsc(e = null) {
    if (e && e?.stopPropagation && e?.preventDefault) {
      e?.stopPropagation();
      e?.preventDefault();
    }
    setMapFolderSearchQuery('');
    toggleMapFolderSearchView();
  }

  function handleSetActive(dir: number) {
    const activeType = active?.type;
    const navFolders = altNavList ? altNavList?.folders || [] : usersFolders;
    const navMaps = altNavList ? altNavList?.maps || [] : usersMaps;
    if (dir === 0) {
      // set it to first item
      const firstItem = navFolders?.length
        ? {
            idx: 0,
            type: 'folder',
            id: navFolders[0].folderId,
            item: navFolders[0],
          }
        : navMaps?.length
          ? { idx: 0, type: 'map', id: navMaps[0].mapId, item: navMaps[0] }
          : null;
      setActive(firstItem);
    }
    if (dir < 0) {
      // moving up
      let previousItem = null;

      if (activeType === 'map') {
        if (navMaps[active?.idx - 1]) {
          previousItem = {
            idx: active?.idx - 1,
            type: 'map',
            id: navMaps[active?.idx - 1].mapId,
            item: navMaps[active?.idx - 1],
          };
        } else {
          previousItem = {
            idx: navFolders.length - 1,
            type: 'folder',
            id: navFolders[navFolders.length - 1].folderId,
            item: navFolders[navFolders.length - 1],
          };
        }
      }
      if (activeType === 'folder') {
        if (navFolders[active?.idx - 1]) {
          previousItem = {
            idx: active?.idx - 1,
            type: 'folder',
            id: navFolders[active?.idx - 1].folderId,
            item: navFolders[active?.idx - 1],
          };
        }
      }
      if (!previousItem) return;
      setActive(previousItem);
    }
    if (dir > 0) {
      // moving down
      let nextItem = null;

      if (activeType === 'folder') {
        if (navFolders[active?.idx + 1]) {
          nextItem = {
            idx: active?.idx + 1,
            type: 'folder',
            id: navFolders[active?.idx + 1].folderId,
            item: navFolders[active?.idx + 1],
          };
        } else if (navMaps[0]) {
          nextItem = {
            idx: 0,
            type: 'map',
            id: navMaps[0].mapId,
            item: navMaps[0],
          };
        }
      }
      if (activeType === 'map') {
        if (navMaps[active?.idx + 1]) {
          nextItem = {
            idx: active?.idx + 1,
            type: 'map',
            id: navMaps[active?.idx + 1].mapId,
            item: navMaps[active?.idx + 1],
          };
        }
      }
      if (!nextItem) return;
      setActive(nextItem);
    }
  }

  function searchFolders(
    searchingFolders: FolderData[],
    query: string,
    allFolders: FolderData[]
  ): FolderData[] {
    if (!query)
      return [
        getRecentMapsMapFolderSearchItem(folderStructure.root),
        ...allFolders,
      ];

    const results: FolderData[] = [];
    searchingFolders.forEach(folder => {
      let folderResult: FolderData;
      if (folder.name.toLowerCase().includes(query.toLowerCase())) {
        folderResult = { ...folder, forceShowChildren: false };
      } else {
        // Recursively search subfolders
        if (folder.subfolders.length > 0) {
          const subfolderResults = searchFolders(
            folder.subfolders,
            query,
            allFolders
          );

          const mapsResults = searchMaps(
            folder?.maps || [],
            query,
            folder?.maps || []
          );

          if (subfolderResults?.length || mapsResults?.length)
            folderResult = {
              ...folder,
              subfolders: subfolderResults || [],
              maps: mapsResults || [],
              forceShowChildren: true,
            };
        }
      }
      if (folderResult) results.push(folderResult);
    });

    return results;
  }

  function searchMaps(
    searchingMaps: MapData[],
    query: string,
    allMaps: MapData[]
  ): MapData[] {
    if (!query) return allMaps;

    const results: MapData[] = [];
    searchingMaps.forEach(map => {
      if (map?.name?.toLowerCase()?.includes(query?.toLowerCase()))
        results.push(map);
    });

    return results;
  }

  function openFolder({ folders, id, returnValue = false }) {
    if (!Array.isArray(folders)) return !returnValue ? undefined : folders;

    function navigateToFolder(
      passedId: string = ''
      // forceCloseChildren: boolean
    ) {
      if (!passedId) return !returnValue ? undefined : folders;
      passedId = removeAlt(passedId);

      passedId?.toLowerCase() === 'root-folder'
        ? navigate(`/dashboard`)
        : navigate(`/dashboard/folder/${passedId}`);

      handleEsc();

      // return !returnValue
      //   ? undefined
      //   : forceCloseChildren
      //     ? { ...folders, forceShowChildren: false }
      //     : folders;
    }

    if (removeAlt(id) !== id) {
      return navigateToFolder(id);
      // return navigateToFolder(id, false);
    }

    const opened = folders?.map(folder => {
      if (removeAlt(folder?.folderId) !== folder?.folderId) {
        return navigateToFolder(folder?.folderId);
        // return navigateToFolder(folder?.folderId, true);
      }

      if (folder.folderId === id) {
        setActive({
          // ...active,
          idx: folder?.forceShowChildren
            ? (usersFolders || []).findIndex(
                f => f?.folderId === folder?.folderId
              ) || 0
            : 0,
          id,
          type: 'folder',
          item: folder,
        });
        if (!folder?.subfolders?.length && !folder?.maps?.length) {
          navigateToFolder(folder?.folderId);
          return folder;
          // return navigateToFolder(folder?.folderId, true);
        }
        setAltNavList(
          folder?.forceShowChildren
            ? null
            : {
                folders: [
                  folder,
                  ...(folder?.subfolders || []).map(f => ({
                    ...f,
                    folderId: `alt-${f?.folderId}`,
                  })),
                ],
                maps: [
                  ...(folder?.maps || []).map(m => ({
                    ...m,
                    mapId: `alt-${m?.mapId}`,
                  })),
                ],
              }
        );

        return {
          ...folder,
          forceShowChildren: !folder?.forceShowChildren,
        };
      }

      return { ...folder, forceShowChildren: false };
    });

    return !returnValue ? undefined : opened;
  }

  function onEnter(item, openFolderShouldReturnValue = true) {
    item.id = item?.folderId
      ? item?.folderId
      : item?.mapId
        ? item?.mapId
        : item?.id;

    if (item?.type === 'folder' || item?.folderId) {
      const res = openFolder({
        folders: usersFolders,
        id: item?.id,
        returnValue: openFolderShouldReturnValue,
      });
      if (!res) return;
      setUsersFolders(res);
      setExpandedFolderGroups(prev => [...prev, item?.id]);
    }
    if (item?.type === 'map' || item?.mapId) {
      navigate('/map/' + removeAlt(item?.id));
      handleEsc(null);
    }
  }

  const scrollToItem = itemId => {
    const item = document.getElementById(itemId);
    item?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  };

  function returnFolder(
    folder: FolderData,
    level = 0,
    parentLevel: number = undefined,
    hasChildren?: boolean
  ) {
    const folderHasChildren = (passedFolder: FolderData) => {
      const hasQuickAccessFolders = passedFolder?.subfolders?.length !== 0;

      const hasQuickAccessMaps = passedFolder?.maps?.length !== 0;

      return hasQuickAccessFolders || hasQuickAccessMaps;
    };
    return (
      <div id={`folder-${folder?.folderId}`}>
        <DropdownItemGroup
          handleClick={() => {
            onEnter(folder, true);
          }}
          highlight={active?.id === folder?.folderId}
          expandedFolderGroups={expandedFolderGroups}
          setExpandedFolderGroups={setExpandedFolderGroups}
          level={`_${level}` as '_0'}
          parentLevel={parentLevel}
          forceShowChildren={!!folder?.forceShowChildren}
          folder={folder}
          isInSidebar={false}
          hasChildren={hasChildren || folderHasChildren(folder)}
          body={
            <div
              style={{
                width: '100%',
              }}
            >
              {(folder?.subfolders || []).map((subfolder, idx) => (
                <div key={idx}>
                  {returnFolder(
                    altNavList?.folders?.find(
                      f => f?.folderId === `alt-${subfolder?.folderId}`
                    ) || subfolder,
                    level + 1,
                    level,
                    folderHasChildren(subfolder)
                  )}
                </div>
              ))}
              {(folder?.maps || []).map((map, idx) => (
                <div key={idx}>
                  {returnMap(
                    altNavList?.maps?.find(
                      m => m?.mapId === `alt-${map?.mapId}`
                    ) || map
                  )}
                </div>
              ))}
            </div>
          }
        />
      </div>
    );
  }

  function returnMap(map: MapData) {
    return (
      <div
        style={{
          // width: 200,
          padding: '0px 8px',
        }}
        id={`map-${map?.mapId}`}
      >
        <DropdownItem
          highlight={active?.id === map?.mapId}
          isLeftIconHidden
          style={{
            // width: 184,
            flexDirection: 'row',
            justifyContent: 'flex-start',
          }}
          handleClick={() => {
            onEnter(map);
          }}
          leftIcon2={
            map?.icon ? (
              map?.icon?.name ? (
                <FolderMapIconRenderer
                  icon={{
                    name: map?.icon?.name || '',
                    type: map?.icon?.type || '',
                    color: map?.icon?.color || '',
                  }}
                />
              ) : (
                (`${map?.icon}` as string)
              )
            ) : undefined
          }
          // isLeftIconHidden={!map?.icon}
          name={
            <div
              style={{
                width: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                cursor: 'pointer',
              }}
              title={map?.name}
            >{`${map?.name}`}</div>
          }
        />
      </div>
    );
  }

  React.useEffect(() => {
    scrollToItem(`${active?.type}-${active?.id}`);
  }, [active]);

  React.useEffect(() => {
    setUsersFolders([
      getRecentMapsMapFolderSearchItem(folderStructure?.root),
      ...(folderStructure?.root?.folders || []),
    ]);
    setUsersMaps(folderStructure?.root?.maps || []);
  }, [folderStructure, maps, folderLoading]);

  React.useEffect(() => {
    if (!folderLoading) inputRef.current?.focus && inputRef.current?.focus();
  }, [folderLoading]);

  React.useEffect(() => {
    handleSearch(queries?.mapFolderSearch || '');
  }, [queries?.mapFolderSearch]);

  React.useEffect(() => {
    if (active) return;
    if (usersFolders?.length) {
      setActive({
        idx: 0,
        type: 'folder',
        id: usersFolders[0].folderId,
        item: usersFolders[0],
      });
    } else if (usersMaps?.length) {
      setActive({
        idx: 0,
        type: 'map',
        id: usersMaps[0].mapId,
        item: usersMaps[0],
      });
    }
  }, [usersFolders, usersMaps]);

  React.useEffect(() => {
    function handleKeyDown(e) {
      if (e.key === 'ArrowUp') {
        e.preventDefault();
        handleSetActive(-1);
      }
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        handleSetActive(1);
      }
      if (e.key === 'Enter') {
        e.preventDefault();
        onEnter(active);
      }
    }

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [active, usersFolders, usersMaps]);

  return (
    <PlasmicMapFolderSearchNew
      id="folder-search-modal"
      onClickContainer={() => {
        toggleMapFolderSearchView();
      }}
      mapSearchWindow={{
        onClick: e => {
          e?.preventDefault();
          e?.stopPropagation();
        },
      }}
      style={{
        zIndex: 50,
        position: 'fixed',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        left: '50%',
        width: '100vw',
      }}
      previewContainer={{
        style: {
          zIndex: -1,
          overflow: 'hidden',
        },
      }}
      previewSlot={
        <div
          style={{
            background: '#1e1d1d',
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {active?.type === 'map' && getMapPreview(active?.id) && (
            <img src={getMapPreview(active?.id)} />
          )}
        </div>
      }
      searchTextInputSlot={
        <SimpleTextInput
          autoFocus={true}
          className="!text-white"
          ref={inputRef}
          value={queries.mapFolderSearch}
          isDisabled={folderLoading}
          // isDisabled={folderLoading || mapLoading}
          onChange={e => {
            // if (e.target.key === 'Enter') {
            //   e.preventDefault();
            //   return onEnter(item);
            // }
            setMapFolderSearchQuery(e?.target?.value || '');
            handleSearch(e?.target?.value || '');
          }}
          onKeyDown={e => {
            if (e.key === 'Escape') handleEsc(e);
          }}
        />
      }
      sidebarSlot={
        <div
          style={{
            // overflow: 'visible',
            overflowY: 'scroll',
            overflowX: 'clip',
            paddingBottom: 10,
            marginBottom: -10,
            // backgroundColor: 'red',
            width: '100%',
          }}
          ref={containerRef}
        >
          {/* {folderLoading || mapLoading ? ( */}
          {folderLoading ? (
            <div className="text-white">Fetching folders & maps...</div>
          ) : (
            !usersFolders?.length &&
            !usersMaps?.length && (
              <div className="text-white">No folders/maps to show.</div>
            )
          )}
          {!folderLoading &&
            // !mapLoading &&
            usersFolders?.map((folder, idx) => (
              <div key={idx}>{returnFolder(folder)}</div>
            ))}
          {!folderLoading &&
            // !mapLoading &&
            usersMaps?.map((map, idx) => <div key={idx}>{returnMap(map)}</div>)}
        </div>
      }
      sidebar={{
        style: {
          width: 200,
          overflowY: 'scroll',
          // overflow: 'hidden',
        },
      }}
      root={{ ref }}
      minimizeBtnContainer={{
        onClick: () => {
          toggleMapFolderSearchView();
        },
      }}
      closeBtnContainer={{
        onClick: () => {
          setMapFolderSearchQuery('');
          toggleMapFolderSearchView();
        },
      }}
      {...props}
    />
  );
}

const MapFolderSearchNew = React.forwardRef(MapFolderSearchNew_);
export default MapFolderSearchNew;
