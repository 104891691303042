import html2canvas from 'html2canvas';
import { toast } from 'react-toastify';
import {
  currentMapStateStore,
  IPosition,
  zoomToContent,
} from 'utils/stores/mapStore';
import { proxy } from 'valtio';
import { updateMapMode } from './mapmodesStore';

type mapshotStoreType = {
  mapshotMode: boolean;
};

const snapshotStoreInitialState: mapshotStoreType = {
  mapshotMode: false,
};

export const mapshotStore = proxy<mapshotStoreType>(
  snapshotStoreInitialState
);

export function setMapshotMode(val: boolean) {
  mapshotStore.mapshotMode = val;
}

export function toggleMapshotMode() {
  const currentMode = mapshotStore.mapshotMode;

  // TODO: this doesn't change the cursor to crosshair as I've hoped.
  //  And we will need ot retire the mapmodesStore in favor of teh modesStore at some point
  updateMapMode('mapshotCreation', !currentMode);
  mapshotStore.mapshotMode = !currentMode;
}

//.............................. taking screenshots ...........................

/**
 * Function to convert dataURI to blob
 * @param dataURI | string | the data URI of the file/image.
 * */
export function dataURItoBlob(dataURI: string) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  var ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  var blob = new Blob([ab], { type: mimeString });
  return blob;
}

/**
 * Function to take screenshots and save them to Clipboard, ready to be pasted.
 * @param quality Number | This has to be from 0 - 1. It determines the quality of an image while converting it to dataUrl.
 * @param x Number | This refers to the left point of the bounding box
 * @param y Number | This refers to the right point of the bounding box
 * @param width Number | This refers to the width of the screenshot
 * @param height Number | This refers to the height of the screenshot
 * */
export async function takeMapshotAndSaveToClipboard({
  quality = 1,
  x,
  y,
  width,
  height,
  silent = false,
  callBack = () => null,
}: {
  quality?: number;
  x: number;
  y: number;
  width: number;
  height: number;
  silent?: boolean;
  callBack?: (_: string) => void;
}) {
  // Crop the screenshot to remove borders
  const borderWidth = 4;
  x += borderWidth;
  y += borderWidth;
  width -= borderWidth * 2;
  height -= borderWidth * 2;

  // Display loading toast
  let toastID;

  if (!silent) {
    toastID = toast.loading('Saving screenshot to clipboard.');
  }

  // Ensure all images are fully loaded
  const images = Array.from(document.images);
  await Promise.all(
    images.map(img => {
      if (img.complete) return Promise.resolve();
      return new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject;
      });
    })
  );

  const canvasWrapper = document.querySelector(
    '#canvas-wrapper'
  ) as HTMLElement;
  if (!canvasWrapper) {
    if (!silent) {
      toast.error('Canvas wrapper element not found');
    }
    console.error('Canvas wrapper element not found');
    return;
  }

  const canvas = await html2canvas(canvasWrapper, {
    x,
    y,
    width,
    height,
    useCORS: true,
    allowTaint: true,
    removeContainer: true,
    logging: true, // Enable logging to debug issues
  });

  try {
    // Make sure quality is between 1 and 0
    quality = quality > 1 ? 1 : quality < 0 ? 0 : quality;

    // Convert the canvas to a data URL (in jpeg, because the quality arg only works for image/jpeg and image/webp)
    const dataURL = canvas.toDataURL('image/jpeg', quality);

    if (typeof ClipboardItem === 'undefined' && !silent) {
      return toast.error('Enable clipboard on your browser.');
    }

    // Convert image to PNG (because navigator.clipboard.write only works for image/png)
    let blobImage;
    const img = new Image();
    img.onload = function () {
      canvas.width = img.width;
      canvas.height = img.height;
      // Create context and draw image
      const ctx = canvas.getContext('2d');
      ctx?.drawImage(img, 0, 0);
      // Convert the image to PNG format
      const pngUrl = canvas.toDataURL('image/png');
      // Pass the converted PNG URL to the callback function
      if (!pngUrl) return;
      callBack && callBack(pngUrl); // return the blob image via the callback.

      blobImage = dataURItoBlob(pngUrl);

      // Copy the image to clipboard
      if (ClipboardItem && !silent) {
        navigator.clipboard
          .write([
            new ClipboardItem({
              'image/png': blobImage,
            }),
          ])
          .then(() => {
            // Play screenshot sound
            if (!silent) {
              const soundEffect = new Audio('/screenshot-soundeffect.mp4');
              soundEffect.volume = 0.4;
              soundEffect.play();
            }
          })
          .catch(e => {
            if (!silent) toast.error(`${e}`);
          });
      }
    };
    img.src = dataURL;
  } catch (e) {
    if (!silent) toast.error(`${e}`);
  } finally {
    if (!silent) toast.dismiss(toastID);
  }
}

export function takeMapshotForPreview(
  mapId: string,
  initialCanvasState: {
    initialCanvasPosition: IPosition;
    initialCanvasTransform: string;
    scale: number;
  },
  viewPortTopLeft: any,
  viewPortBottomRight: any,
  viewPortWidth: any,
  viewPortHeight: any,
  silent: boolean = false,
  callBack = (_: string) => null
) {
  if (!mapId) return;
  zoomToContent(mapId, initialCanvasState);
  takeMapshotAndSaveToClipboard({
    x: window.scrollX,
    y: window.scrollY,
    width: window.innerWidth,
    height: window.innerHeight,
    silent,
    callBack,
  });
}

export const getMapPreview = (mapId, map = null) => {
  function updateAndReturnUrl(url) {
    if (!url || !mapId) return;
    try {
      currentMapStateStore[mapId].preview = {
        url,
        lastUpdated: new Date().toISOString(),
      };
    } catch (e) {}
    return url;
  }

  const previewUrl =
    currentMapStateStore[mapId]?.preview?.url ||
    updateAndReturnUrl(map?.preview?.url);
  const prev = previewUrl ?? undefined;
  return prev;
};
