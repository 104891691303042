import { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../CustomAccordion';
import SingleThemeSettingItem from './SingleThemeSettingItem';
import useDimension from 'utils/hooks/useScreenDimension';
import { cn } from 'lib/utils';
import { Button } from 'ShadcnComponents/Button';

export interface ThemeSettingsProps {
  key: string;
  label: string;
  value: string;
  type: 'input' | 'color-picker';
  onChange?: (color: string) => void;
}

// Initial settings to map keys to labels and types
const initialThemeSettingsMap = {
  canvasBackgroundColor: {
    label: 'Canvas Background Color',
    type: 'color-picker',
  },
  defaultItemWidth: { label: 'Default Item width', type: 'input' },
  defaultColor: {
    label: 'Default Color (Text & Border)',
    type: 'color-picker',
  },
  fontSize: { label: 'Font Size', type: 'input' },
  // fontFamily: { label: 'Font Family', type: 'input' }
};

const transformBackendData = data => {
  return Object.keys(initialThemeSettingsMap).map(key => ({
    key,
    label: initialThemeSettingsMap[key]?.label || key,
    type: initialThemeSettingsMap[key]?.type,
    value: data[key].toString(),
  }));
};

const ThemeSection = () => {
  const { height } = useDimension();
  const [openSection, setOpenSection] = useState(true);
  const [themeSettings, setThemeSettings] = useState<ThemeSettingsProps[]>([]);
  const [initialSettings, setInitialSettings] = useState<ThemeSettingsProps[]>(
    []
  );
  const [isFormModified, setIsFormModified] = useState(false);

  const handleChange = (key: string, newValue: string) => {
    const updatedSettings = themeSettings.map(theme =>
      theme.key === key ? { ...theme, value: newValue } : theme
    );

    setThemeSettings(updatedSettings);

    const dirty = updatedSettings.some(
      (setting, idx) => setting.value !== initialSettings[idx].value
    );
    setIsFormModified(dirty);
  };

  const handleSave = () => {
    // Transform data back to backend format
    // Handle the saving to database.

    // ! Ask if this section is per map or global
    const updatedData = themeSettings.reduce((acc, setting) => {
      acc[setting.key] = setting.value;
      return acc;
    }, {});

    console.log('Save changes', updatedData);
    setIsFormModified(false);
  };

  // const { data: myGlobalStyles } = useGetMyGlobalStyles();

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const transformedData = transformBackendData(myGlobalStyles);
  //     setThemeSettings(transformedData);
  //     setInitialSettings(transformedData);
  //   };

  //   if (myGlobalStyles) {
  //     fetchData();
  //   }
  // }, [myGlobalStyles]);

  useEffect(() => {
    if (height > 1200) setOpenSection(true);
    else setOpenSection(false);
  }, [height]);

  return (
    <Accordion
      className="text-white rounded-xl bg-black"
      style={{
        padding: '8px',
      }}
    >
      <AccordionItem>
        <AccordionTrigger
          onClick={() => setOpenSection(!openSection)}
          isOpen={openSection}
        >
          <div></div>
        </AccordionTrigger>

        <AccordionContent
          style={{
            background: '#131313',
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px',
          }}
          heading={{ title: 'Theme Settings for this Map', personal: false }}
          className="rounded-b-xl py-3 px-2"
          isOpen={openSection}
        >
          <div
            className={cn({
              block: openSection,
              hidden: !openSection,
            })}
          >
            <div
              style={{
                flexWrap: 'wrap',
                rowGap: '8px',
                columnGap: '25px',
              }}
              className={cn('pb-4 mt-1 flex flex-wrap justify-center')}
            >
              {themeSettings.map((theme, idx) => (
                <SingleThemeSettingItem
                  key={idx}
                  theme={theme}
                  onChange={newValue => handleChange(theme.key, newValue)}
                />
              ))}
            </div>

            <div className="flex justify-center">
              {isFormModified && (
                <Button
                  style={{
                    width: 'fit-content',
                    background: '#78E08F',
                    color: 'white',
                    margin: 'auto',
                  }}
                  className="w-fit"
                  onClick={handleSave}
                >
                  Save Changes
                </Button>
              )}
            </div>
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export default ThemeSection;
