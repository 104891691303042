import Preview from 'CoreComponents/preview/Preview';
import { useParams } from 'react-router-dom';
import { currentMapContentStore } from 'utils/stores/mapStore';
import { useSnapshot } from 'valtio';

export default function RenderPreviews() {
  const mapId = useParams().mapId!;
  const currentStackState = useSnapshot(currentMapContentStore[mapId]).value;

  return (currentStackState?.previews || [])?.map((preview: any, index) => (
    <Preview key={preview.previewId} preview={preview} previewIndex={index} />
  ));
}
