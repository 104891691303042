// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type ImageAddFilledIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function ImageAddFilledIcon(props: ImageAddFilledIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 24 24"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M19 15a1 1 0 011 1v2h2a1 1 0 110 2h-2v2a1 1 0 11-2 0v-2h-2a1 1 0 110-2h2v-2a1 1 0 011-1zM3.436 5.184C3 6.04 3 7.16 3 9.4v5.2c0 .213.234.343.415.231l1.935-1.197.079-.048c.33-.205.66-.41 1.035-.507a2.5 2.5 0 01.998-.05c.382.058.732.23 1.08.4l.084.04.528.257c.53.258.593.275.635.28a.5.5 0 00.212-.017c.04-.013.1-.04.58-.382l2.505-1.79.093-.066c.384-.276.77-.552 1.215-.67a2.498 2.498 0 011.186-.026c.45.1.847.36 1.242.619l.096.062 1.824 1.189c.077.05.167.075.258.075h1.2c.28 0 .42 0 .527-.055a.5.5 0 00.218-.218C21 12.62 21 12.48 21 12.2V9.4c0-2.24 0-3.36-.436-4.216a4 4 0 00-1.748-1.748C17.96 3 16.84 3 14.6 3H9.4c-2.24 0-3.36 0-4.216.436a4 4 0 00-1.748 1.748zM9 11a2 2 0 100-4 2 2 0 000 4z"
        }
        fill={"currentColor"}
      ></path>

      <path
        d={
          "M15.826 13.412c.181.118.169.39-.014.504A5.996 5.996 0 0013 19v1.2c0 .28 0 .42-.055.527a.5.5 0 01-.218.218C12.62 21 12.48 21 12.2 21H9.4c-2.24 0-3.36 0-4.216-.436a4 4 0 01-1.748-1.748 3.306 3.306 0 01-.298-.925c-.029-.158-.043-.237-.027-.326a.535.535 0 01.096-.217c.056-.072.133-.12.288-.215l2.907-1.797c.465-.288.52-.31.56-.32a.5.5 0 01.2-.01c.04.006.097.022.589.261l.528.258.09.043c.374.184.75.368 1.159.421a2.5 2.5 0 001.06-.088c.394-.121.735-.365 1.073-.608l.082-.058 2.505-1.79c.547-.39.616-.42.662-.433a.5.5 0 01.237-.005c.046.01.117.038.68.405z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default ImageAddFilledIcon;
/* prettier-ignore-end */
