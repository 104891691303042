import React from 'react';
import { useNavigate } from 'react-router-dom';

export function PersistedView() {
  const [persistedRoute] = React.useState('/dashboard');

  console.log('PersistedView', persistedRoute);
  // TODO    []    we want to save the mapId to local storage

  const navigate = useNavigate();
  React.useEffect(() => {
    console.log('navigating to', persistedRoute);
    navigate(persistedRoute ? persistedRoute : '/dashboard');
  }, [navigate, persistedRoute]);
}
