import { WebviewWindow } from '@tauri-apps/api/window';
import { toast } from 'react-toastify';

export default function openInNewDesktopWindow(
  windowTitle: string = 'MapMap',
  windowId: string,
  url: string
) {
  const webview = new WebviewWindow(`${windowId}`, {
    url,
    title: `${windowTitle}`,
  });
  webview.once('tauri://error', function (e) {
    toast((e?.payload as string) || 'Error opening window');
  });
}
