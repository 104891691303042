import { useDocumentVisibility, useInterval, useNetwork } from '@mantine/hooks';
import SyncingModal from 'CoreComponents/SyncingModal';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import useMapSyncingStateMachine from 'utils/hooks/stateMachine/useMapSyncingStateMachine';

import { useGetMyGlobalStyles } from 'api/queries';
import ThemeSection from 'components/themeAndStyleSettings/theme/ThemeSection';
import ThemeAndStyleModal from 'components/themeAndStyleSettings/ThemeAndStyleModal';
import { overlayMenuWrapperProps } from 'CoreComponents/hotkeyProvider';
import { OverlayMenuWrapper } from 'CoreComponents/Overlays/OverlayMenuWrapper';
import {
  EVENTS,
  TRIGGERS,
} from 'utils/hooks/stateMachine/mapSynincincStateMachineTypes';
import { dashboardStore, toggleThemeModal } from 'utils/stores/dashboardStore';
import { useSnapshot } from 'valtio';
import { CanvasWrapper } from '../../CoreComponents/Canvas/canvasWrapper';
import { useMapBeacon } from '../../utils/hooks/useMapBeacon';
import {
  addMapBeingLoaded,
  generalStore,
} from '../../utils/stores/generalStore';
import { storeStatus } from '../../utils/stores/mapStore';
import { themeStore } from '../../utils/stores/themeStore';

// this makes MAPID accessible through out the the app - Especially in function that are called outside
// react component
// testing this for now - second option is to have it in a valtio store
// might do that if this isn't very reliable
let MAPID: string | null = null;
export function getMapID() {
  if (!MAPID) {
    return null;
  }

  return MAPID;
}

export function useStoreStatus() {
  const { mapStateReady, mapContentReady } = useSnapshot(storeStatus);
  return {
    isBothStoresReady: mapStateReady && mapContentReady,
    mapStateReady,
    mapContentReady,
  };
}

// this should only contain logic about hydrating and syncing the map
const MapView: React.FC = () => {
  const { mapId } = useParams<{ mapId: string }>();
  if (!mapId) {
    return <div>No map ID provided</div>;
  }
  const { mapsBeingCreated, mapsBeingLoaded } = useSnapshot(generalStore);
  const dashboardStateStore = useSnapshot(dashboardStore);
  const { isBothStoresReady, mapStateReady, mapContentReady } =
    useStoreStatus();
  // console.log('mapId', mapId);
  const [machine, send] = useMapSyncingStateMachine(mapId);
  const networkStatus = useNetwork();
  const pageVisibility = useDocumentVisibility();
  // the name of the variable below does not reflect its current use, but for now we are keeping the name
  // because manual hydration will be a thing
  const [isHydrationAttemptFinished, setIsHydrationAttemptFinished] =
    useState(false);

  useMapBeacon(mapId);

  useGetMyGlobalStyles();

  const interval = useInterval(
    () => {
      if (machine.value !== 'idle') return;
      send({ type: EVENTS.PREPARECOMPARISON, trigger: TRIGGERS.INTERVAL });
    },
    1000 * 60 * 3
  );

  const initializeRef = useRef(false);
  const eventSentRef = useRef(false);
  const [isMapReady, setIsMapReady] = useState(false);

  useLayoutEffect(() => {
    if (mapId && !initializeRef.current) {
      addMapBeingLoaded(mapId);

      MAPID = mapId;

      initializeRef.current = true;
    }
  }, [mapId]);

  useEffect(() => {
    if (!mapId || eventSentRef.current) return;
    async function initializeMap() {
      send({ type: EVENTS.PREPARECOMPARISON, trigger: TRIGGERS.LOAD });
      setIsHydrationAttemptFinished(true);
      eventSentRef.current = true;
    }

    if (isBothStoresReady) {
      initializeMap().then(r => r);
    }
  }, [
    mapId,
    isBothStoresReady,
    mapStateReady,
    mapContentReady,
    mapsBeingCreated,
  ]);

  useEffect(() => {
    if (machine.context.isReadyToRender) {
      setIsMapReady(true);
    }

    // if (
    //   machine.context.isReadyToRender === true &&
    //   mapsBeingLoaded.includes(mapId!)
    // ) {
    //   console.log(
    //     '!!! 🚨️should never be true: machine.context.isReadyToRender === true && mapsBeingLoaded.includes(mapId!))'
    //   );
    // }
  }, [machine.context, mapsBeingLoaded, mapId]);

  useEffect(() => {
    if (pageVisibility === 'visible') return;
    if (machine.value !== 'idle') return;
    //send({ type: EVENTS.SAVEMAP });
    send({ type: EVENTS.PREPARECOMPARISON, trigger: TRIGGERS.VISIBILITY });
  }, [pageVisibility]);

  useEffect(() => {
    setTimeout(
      () => {
        interval.start();
      },
      1000 * 60 * 3
    );
    return interval.stop;
  }, [interval]);

  const previousNetworkStatus = useRef(networkStatus.online);

  useEffect(() => {
    if (
      previousNetworkStatus.current === false &&
      networkStatus.online === true
    ) {
      if (machine.value !== 'idle') return;
      send({ type: EVENTS.PREPARECOMPARISON, trigger: TRIGGERS.NETWORK });
    }
    previousNetworkStatus.current = networkStatus.online;
  }, [networkStatus.online]);

  const isSyncingAnimationRunning =
    machine.context.syncing &&
    (machine.context.trigger === TRIGGERS.NETWORK ||
      machine.context.trigger === TRIGGERS.LOAD);

  if (!isHydrationAttemptFinished || !isMapReady || !isBothStoresReady) {
    return (
      <div
        className="absolute inset-0 w-full h-full"
        style={{
          backgroundColor: themeStore.canvasBackgroundColor,
        }}
      >
        <SyncingModal isOpen={true} />
      </div>
    );
  }

  return (
    <>
      <SyncingModal isOpen={isSyncingAnimationRunning} />
      <CanvasWrapper />
      {dashboardStateStore.themeStyleShortcutModal && (
        <OverlayMenuWrapper
          {...overlayMenuWrapperProps}
          width={0.65}
          stickyBottom={<ThemeSection />}
          closeModal={toggleThemeModal}
        >
          <ThemeAndStyleModal />
        </OverlayMenuWrapper>
      )}
    </>
  );
};

export { MapView };
