import { ChevronDown, ChevronRight, ChevronUpIcon } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Button } from 'ShadcnComponents/Button';
import { Input } from '../../ShadcnComponents/Input';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../ShadcnComponents/Table';
import SingleShortcutItem from './SingleShortcutItem';
import { hotKeysStore } from 'utils/stores/hotKeysStore';

const ShortcutOverView = props => {
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedShortcut, setExpandedShortcut] = useState<string | null>(null);
  const hotkeys = hotKeysStore;

  const [sections, setSections] = useState([
    {
      section: 'Global Shortcuts',
      desc: 'These are shortcuts that are can be used both on the dashboard layout and the map view',
      items: hotkeys.globalHotkeys || [],
    },
    {
      section: 'Map Shortcuts',
      desc: 'These are shortcuts are specific to just the map view for an open map',
      items: hotkeys.mapHotkeys || [],
    },
    {
      section: 'Item Shortcuts',
      desc: 'These are shortcuts are specific to just the map view for an open map. They shortcuts that directly affect selected items on a map',
      items: hotkeys.itemHotkeys || [],
    },
  ]);

  const toggleExplanation = (shortcut: string) => {
    setExpandedShortcut(prevExpanded =>
      prevExpanded === shortcut ? null : shortcut
    );
  };

  const [openSections, setOpenSections] = useState<string[]>([]);

  const toggleSection = (section: string) => {
    setOpenSections(prev =>
      prev.includes(section)
        ? prev.filter(s => s !== section)
        : [...prev, section]
    );
  };

  const getFilteredSections = () => {
    if (!searchTerm) return sections;

    return sections
      .map(section => ({
        ...section,
        items: Object.values(section.items).filter(item =>
          item.label.toLowerCase().includes(searchTerm.toLowerCase())
        ),
      }))
      .filter(section => section.items.length > 0);
  };

  useEffect(() => {
    if (searchTerm) {
      const sectionsWithResults = getFilteredSections().map(s => s.section);
      setOpenSections(sectionsWithResults);
    } else {
      setOpenSections([]);
    }
  }, [searchTerm]);

  return (
    <div className="w-full mx-auto py-8 px-4 md:px-6">
      <div className="mb-6 w-full">
        <Input
          placeholder="Search shortcuts..."
          value={searchTerm}
          type="text"
          onChange={e => setSearchTerm(e.target.value)}
          className="w-full !border !border-solid !border-[#151518] max-w-md focus:outline-none bg-[#09090b] !text-white"
        />
      </div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Function</TableHead>
            <TableHead>Shortcut</TableHead>
            <TableHead>Alternative Shortcut</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {getFilteredSections().map(section => (
            <React.Fragment key={section.section}>
              <TableRow className="bg-muted/50 border-b border-b-[#151518] pb-2">
                <TableCell colSpan={3}>
                  <div className="">
                    <div
                      // variant="ghost"
                      className="p-0 w-full items-start hover:bg-transparent hover:text-white "
                      onClick={() => toggleSection(section.section)}
                    >
                      <div className="flex space-x-3 items-center w-full">
                        <div className="space-y-1 text-left flex-1">
                          <div className="font-medium">{section.section}</div>
                          <div className="font-medium text-muted-foreground text-sm">
                            {section.desc}
                          </div>
                        </div>

                        <div className="ml-auto">
                          {openSections.includes(section.section) ? (
                            <ChevronUpIcon className="mr-2 h-4 w-4" />
                          ) : (
                            <ChevronDown className="mr-2 h-4 w-4" />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </TableCell>
              </TableRow>

              {openSections.includes(section.section) &&
                Object.values(section.items).map((shortcut, idx) => (
                  <React.Fragment key={idx}>
                    <TableRow
                      className={`${
                        expandedShortcut !== shortcut.shortcut
                          ? `border-b border-b-[${props.borderColor}] border-t-0`
                          : `border-b-0 border-t-0`
                      }`}
                    >
                      <TableCell className="font-medium">
                        <div className="flex items-center justify-between">
                          <span className="text-xs">{shortcut.label}</span>
                          {shortcut.description && (
                            <Button
                              variant="outline"
                              size="sm"
                              onClick={() =>
                                toggleExplanation(shortcut.shortcut)
                              }
                              className="ml-2 text-xs hover:bg-[#151518] hover:text-white"
                            >
                              <div className="flex space-x-2 items-center">
                                <span>Explanation</span>
                                <div>
                                  {expandedShortcut === shortcut.shortcut ? (
                                    <ChevronDown className="ml-2 h-4 w-4" />
                                  ) : (
                                    <ChevronRight className="ml-2 h-4 w-4" />
                                  )}
                                </div>
                              </div>
                            </Button>
                          )}
                        </div>
                      </TableCell>
                      <TableCell>
                        <SingleShortcutItem
                          handleSaveShortcut={() => null}
                          key={'shortcut'}
                          shortcut={shortcut.shortcut || ''}
                          hideEditButton={true}
                        />
                      </TableCell>
                      <TableCell>
                        <SingleShortcutItem
                          handleSaveShortcut={() => null}
                          key={'shortAlternative'}
                          shortcut={shortcut.alternativeShortcut || ''}
                          hideEditButton={true}
                        />
                      </TableCell>
                    </TableRow>
                    {expandedShortcut === shortcut.shortcut &&
                      shortcut.description && (
                        <TableRow className="border-b border-b-[#27272a]">
                          <TableCell colSpan={3} className="bg-muted/50">
                            <div className="p-2 text-xs">
                              {shortcut.description}
                            </div>
                          </TableCell>
                        </TableRow>
                      )}
                  </React.Fragment>
                ))}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default ShortcutOverView;
