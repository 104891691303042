import { ClipboardStore } from 'utils/stores/mapmapClipboardStore';
import { processSelectedItems } from './copyCutHelpers';
import { deleteContent, resetSelected } from 'utils/stores/mapStore';

export function handleCut(mapId: string) {
  ClipboardStore.action = 'cut';
  const { stringifiedData, areaMap } = processSelectedItems(mapId);

  if (stringifiedData) {
    ClipboardStore.clipboard = stringifiedData;

    const areas = Array.from(areaMap.values());

    let specificEncompassingItemsToBeDeleted: string[];

    // This helps the edge case where you don't cut everything within
    // the selected area
    if (areas.length) {
      specificEncompassingItemsToBeDeleted = areas.flatMap(area =>
        area.stacks.flatMap(stack => stack.items.map(item => item.itemId))
      );
    }

    deleteContent(mapId, {
      encompassingItemsToBeRemoved: specificEncompassingItemsToBeDeleted,
    });

    resetSelected(mapId);
  }
}
