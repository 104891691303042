// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type MapMapLogoGreenTransparentIconProps =
  React.ComponentProps<"svg"> & {
    title?: string;
  };

export function MapMapLogoGreenTransparentIcon(
  props: MapMapLogoGreenTransparentIconProps
) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 59 62"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M.797 10.589L.142 57.468a2.816 2.816 0 002.956 2.884l6.869-.263c1.655-.064 3.016-1.459 3.04-3.115l.25-17.917c.037-2.672 3.284-4.135 5.147-2.317l6.737 6.574c1.154 1.127 3.052 1.054 4.24-.163l3.853-3.949c1.187-1.216 1.214-3.115.06-4.242L5.943 8.271C4.082 6.454.834 7.916.797 10.589z"
        }
        fill={"url(#KicQVpPbrIv1a)"}
      ></path>

      <path
        d={
          "M58.75 8.676l-.66 47.163c-.022 1.657-1.383 3.051-3.039 3.115l-8.117.312a2.816 2.816 0 01-2.956-2.885l.234-16.757a2.859 2.859 0 00-.687-1.917l-6.396-7.396c-.937-1.084-.914-2.731.055-3.888L53.496 6.935c1.837-2.194 5.292-1.049 5.254 1.741z"
        }
        fill={"url(#KicQVpPbrIv1b)"}
      ></path>

      <defs>
        <linearGradient
          id={"KicQVpPbrIv1a"}
          x1={".581"}
          y1={"26.018"}
          x2={"35.447"}
          y2={"28.264"}
          gradientUnits={"userSpaceOnUse"}
        >
          <stop stopColor={"#136434"}></stop>

          <stop offset={".21"} stopColor={"#137834"} stopOpacity={".8"}></stop>

          <stop offset={".412"} stopColor={"#138234"} stopOpacity={".8"}></stop>

          <stop offset={".566"} stopColor={"#139134"} stopOpacity={".8"}></stop>

          <stop offset={"1"} stopColor={"#13A034"} stopOpacity={".8"}></stop>
        </linearGradient>

        <linearGradient
          id={"KicQVpPbrIv1b"}
          x1={"35.557"}
          y1={"24.551"}
          x2={"58.53"}
          y2={"25.618"}
          gradientUnits={"userSpaceOnUse"}
        >
          <stop stopColor={"#136434"}></stop>

          <stop offset={".21"} stopColor={"#137834"} stopOpacity={".8"}></stop>

          <stop offset={".412"} stopColor={"#138234"} stopOpacity={".8"}></stop>

          <stop offset={".566"} stopColor={"#139134"} stopOpacity={".8"}></stop>

          <stop offset={"1"} stopColor={"#13A034"} stopOpacity={".8"}></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default MapMapLogoGreenTransparentIcon;
/* prettier-ignore-end */
