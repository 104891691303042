import { StackData } from 'utils/stores/types';

import React, { ReactNode, useRef } from 'react';

import { CSSProperties } from '@stitches/react';
import { InsertMode } from 'utils/stores/mapMode/modesStore';
import { renderNoteVersion } from './Item/noteHelpers';
import { resetSelected } from 'utils/stores/mapStore';

interface StackProps {
  stack: StackData;
  stackIndex: number;
  selectoRef: React.MutableRefObject<any>;
  insertMode: Readonly<InsertMode>;
  isTitleNote?: boolean;
  useSelectionV2: boolean;
  mapID: string;
}

const Stack = ({
  stack,
  stackIndex,
  selectoRef,
  insertMode,

  useSelectionV2,
  mapID,
}: StackProps) => {
  const stackRef = useRef(null);

  // const isVisible = useIntersection(stackRef);
  return (
    <StackContainer
      mapID={mapID}
      position={stack.position}
      // insertModeActive={true}
      // insertModeActive={true}
      insertModeActive={stack.items.length > 1 && insertMode.isActive}
    >
      <div
        ref={stackRef}
        id={stack.stackId}
        data-index={stackIndex}
        data-drag={stack.stackId}
        className={` ${stack.isContainedInArea} s3k`}
      >
        {stack.items.map((item, index) =>
          renderNoteVersion(
            {
              item: item,
              parentID: stack.stackId,
              lastInteraction: item.lastInteraction,

              selectoRef,
              isContainedInArea: stack.isContainedInArea,
              totalItemsInStack: stack.items.length,
              itemIndexPosition: index,
              isTitleNote: stack.isTitleNote,
              insertModeActive: insertMode.isActive,
              parentPostion: { x: stack.position.x, y: stack.position.y },

              useSelectionV2: useSelectionV2,

              parentLastInteraction: stack.lastInteraction,
              isSelected: false,
              parentType: 'stack',
            },

            mapID
          )
        )}
      </div>
    </StackContainer>
  );
};

export function StackContainer({
  children,
  position,
  insertModeActive,
  mapID,
}: {
  children: ReactNode;
  insertModeActive: boolean;
  mapID: string;
  position: { x: number; y: number };
}) {
  const childStyle: CSSProperties = !insertModeActive
    ? {}
    : {
        maxWidth: 'auto',
        background: '#ffffff1f',
        backdropFilter: 'blur(3px)',
        borderRadius: '12px',
        padding: '8px',

        border: '0.5px solid #ffffff24',
      };

  const parentStyle: CSSProperties = !insertModeActive
    ? {
        position: 'absolute',
        top: `${position.y}px`,
        left: `${position.x}px`,
        width: 'auto',
        height: 'auto',
      }
    : {
        position: 'absolute',
        top: `${position.y - 14.5}px`, // derived value from the css total computed box size
        left: `${position.x - 14.5}px`,
        width: 'auto',
        height: 'auto',
        boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.465)',
        padding: '6px',
        borderRadius: '16px',
      };

  return (
    <div
      style={parentStyle}
      onClick={e => {
        e.stopPropagation;
        resetSelected(mapID);
      }}
    >
      <div style={childStyle}>{children}</div>
    </div>
  );
}

export default Stack;
