import { useRef, useEffect } from 'react';
import ResultFrame from 'PlasmicComponents/ResultFrame';
import MapTextSearchLabel, { resultColors } from './MapTextSearchLabel';
import ResultItem from 'PlasmicComponents/ResultItem';
import GeneralSkeleton from 'components/loader/skeletons/GeneralSkeleton/GeneralSkeleton';
import { StackData } from 'utils/stores/types';

interface MapTextSearchSidebarProps {
  stacksResult: { [key: string]: { [key: string]: StackData } };
  searchResultLoading: boolean;
  selectedResult: StackData;
  searchText: string;
  handleResultSelection: (x: StackData, y: number) => void;
}

export default function MapTextSearchSidebar({
  stacksResult,
  searchResultLoading,
  selectedResult,
  searchText,
  handleResultSelection,
}: MapTextSearchSidebarProps) {
  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    if (selectedResult && selectedResult.stackId) {
      const selectedItemIndex = itemRefs.current.findIndex(
        ref => ref?.dataset?.stackid === selectedResult.stackId
      );
      if (selectedItemIndex !== -1 && itemRefs.current[selectedItemIndex]) {
        itemRefs.current[selectedItemIndex].scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      }
    }
  }, [selectedResult]);

  return (
    <div className="w-full">
      {!stacksResult && !searchResultLoading ? (
        <div className="text-center">No result yet</div>
      ) : (
        <div className="w-full">
          {searchResultLoading ? (
            <SidebarResultSkeleton />
          ) : (
            <>
              {Object.entries(stacksResult).length === 0 ? (
                <div className="text-center">No result for {searchText}</div>
              ) : (
                <div
                  className="flex flex-col w-full"
                  style={{
                    gap: '4px',
                    transition: 'opacity 0.3s ease-in-out',
                  }}
                >
                  {Object.entries(stacksResult).map(([key, value], idx) => {
                    const randomColor = resultColors[idx % resultColors.length];
                    return (
                      <ResultFrame
                        key={idx}
                        borderColor={randomColor.background[0].color}
                        labelSlot={
                          <div
                            className="text-xs"
                            style={{
                              color: randomColor.textColor,
                              fontWeight: 500,
                            }}
                          >
                            <MapTextSearchLabel
                              colors={randomColor.background}
                              text={key}
                            />
                          </div>
                        }
                        resultItems={Object.entries(value).map(
                          ([itemKey, itemValue], index) => {
                            return (
                              <ResultItem
                                ref={el =>
                                  (itemRefs.current[idx * 100 + index] = el)
                                }
                                data-stackid={itemValue.stackId}
                                onClick={() =>
                                  handleResultSelection(
                                    itemValue,
                                    idx * 100 + index
                                  )
                                }
                                isHighlighted={
                                  selectedResult
                                    ? itemValue.stackId ===
                                      selectedResult?.stackId
                                    : false
                                }
                                key={index}
                                textContent={itemKey}
                              />
                            );
                          }
                        )}
                      />
                    );
                  })}
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}

function SidebarResultSkeleton() {
  return (
    <div
      className="w-full h-fit grid"
      style={{ gap: '10px', transition: 'opacity 0.3s ease-in-out' }}
    >
      {[...Array(4)].map((_, idx) => {
        return (
          <div key={idx}>
            <GeneralSkeleton
              height={28}
              width={80}
              style={{
                borderStartStartRadius: '8px',
                borderStartEndRadius: '8px',
              }}
            />
            <GeneralSkeleton height={100} style={{ marginTop: '1px' }} />
          </div>
        );
      })}
    </div>
  );
}
