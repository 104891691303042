export const TRIGGERS = {
  NETWORK: 'network',
  LOAD: 'load',
  VISIBILITY: 'visibility',
  INTERVAL: 'interval',
  MANUAL: 'manual',
  NONE: '',
} as const;

export type SyncTrigger = (typeof TRIGGERS)[keyof typeof TRIGGERS];

export const EVENTS = {
  LOADMAP: 'LOADMAP',
  SAVEMAP: 'SAVEMAP',
  MERGEMAPS: 'MERGEMAPS',
  COMPAREMAPS: 'COMPAREMAPS',
  IDLE: 'IDLE',
  ERROR: 'ERROR',
  PREPARECOMPARISON: 'PREPARECOMPARISON'
} as const;

export type MapSyncingEvent = (typeof EVENTS)[keyof typeof EVENTS];

export const RETRY_EVENTS = {
  RETRYCOMPAREMAPS: 'RETRYCOMPAREMAPS',
  RETRYLOADMAP: 'RETRYLOADMAP',
  RETRYSAVEMAP: 'RETRYSAVEMAP',
  RETRYMERGEMAPS: 'RETRYMERGEMAPS',
} as const;

export type RetryEvent = (typeof RETRY_EVENTS)[keyof typeof RETRY_EVENTS];
