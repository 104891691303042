import {
  addItemToSelected,
  getItems,
  handleShiftSelection,
  removeItemFromSelected,
  setSelected,
} from 'utils/stores/mapStore';
import React from 'react';
import Selecto from 'react-selecto';
import { getPrevSelectPos, setPrevSelectPos } from '../stores/gestureStore';
import { mapModesStore, updateMapMode } from 'utils/stores/mapmodesStore';
import getSelectedIDs from './Selection/selectionUtils';
import selectedContentsStore, {
  SelectedContent,
} from 'utils/stores/Selection/selectedContentStore';

export function handleOnSelect(
  e: React.MouseEvent,
  selectedContent: SelectedContent
) {
  const isSelected = selectedContentsStore.isSelected(
    selectedContent.contentID
  );

  if (isSelected) {
    selectedContentsStore.unselect(selectedContent.contentID);
    return;
  }

  if (!isSelected) {
    selectedContentsStore.select(selectedContent);
    return;
  }
}

const handleSelectContent = (
  e: React.MouseEvent,
  selectedItem: SelectedContent,
  isSelected: boolean,
  mapId: string,
  selectoRef: React.RefObject<Selecto | null>
) => {
  // This is to not allow the selection/clicking
  // that happens when multiple items are dropped

  const selectedItemLength = getSelectedIDs();

  // click on items while holding shift and modifier key to add multiple items to selected array
  if (e.ctrlKey || e.metaKey) {
    return handleSelectionWithModKey(selectedItem, isSelected, mapId);
  }
  if (e.shiftKey) {
    return handleSelectionWithShiftKey(
      selectedItem,
      isSelected,
      selectedItemLength.length,
      mapId,
      e,
      selectoRef
    );
  }

  setSelected(selectedItem, mapId);
  setPrevSelectPos({
    x: e.clientX,
    y: e.clientY,
  });
};

const handleSelectionWithModKey = (
  SelectedItem: SelectedContent,
  isSelected: boolean,
  mapID: string
) => {
  if (isSelected) removeItemFromSelected(SelectedItem, mapID);
  if (!isSelected) addItemToSelected(SelectedItem, mapID);
};

const handleSelectionWithShiftKey = (
  selectedItem: SelectedContent,
  isSelected: boolean,
  selectedItemLength: number,
  mapId: string,
  e: React.MouseEvent,
  selectoRef: React.RefObject<Selecto | null>
) => {
  const mousePos = {
    x: e.clientX,
    y: e.clientY,
  };

  const selected = getSelectedIDs();

  selectedItemLength = selected.length;

  // To select multiple items
  // in between the imaginary box that is drawn between shift click A and shift click B
  if (isSelected) {
    removeItemFromSelected(selectedItem, mapId);
  } else {
    if (selectedItemLength === 0) {
      handleShiftSelection(selectedItem, mousePos, mapId);
      return;
    }

    // const selectedItems: string[] = [];
    // const sel = getSelected(mapId);
    // const lastSelected = sel[sel.length - 1];
    //
    // if (lastSelected) selectedItems.push(lastSelected);

    const selectableEls = document.querySelectorAll('.selectable-target');
    const prevSelectedPos = getPrevSelectPos();

    if (!prevSelectedPos) return;

    const minTop = Math.min(mousePos.y, prevSelectedPos.y);
    const maxBottom = Math.max(mousePos.y, prevSelectedPos.y);
    const minLeft = Math.min(mousePos.x, prevSelectedPos.x);
    const maxRight = Math.max(mousePos.x, prevSelectedPos.x);

    // use the bounds of the first and last selected item to get all items in between
    const itemsInBetween = Array.from(selectableEls).filter(
      (el: HTMLElement) => {
        const bounds = el.getBoundingClientRect();

        return (
          bounds.bottom > minTop && // using an offset  to account for the border
          bounds.top < maxBottom &&
          bounds.right >= minLeft &&
          bounds.left <= maxRight
        );
      }
    );

    const itemsInBetweenIDs = itemsInBetween.reduce((acc, el) => {
      const ID = el.getAttribute('id');
      if (!ID) {
        return acc;
      }
      return [...acc, ID];
    }, [] as string[]);

    const itemsInBetweenData = getItems(mapId, itemsInBetweenIDs);

    itemsInBetweenData.forEach(item => {
      addItemToSelected(selectedItem, mapId);
      selectoRef.current?.setSelectedTargets(
        selectoRef.current.getSelectableElements().filter(el => {
          const ID = el.getAttribute('id');
          return itemsInBetweenIDs.includes(ID ?? '');
        })
      );
    });
    setPrevSelectPos(mousePos);
  }
  selectoRef.current.setSelectedTargets([]);
  return;
};

export default handleSelectContent;
