import getSelectedIDs from 'utils/helpers/Selection/selectionUtils';
import { getArea } from 'utils/mapStoreFN/mapStoreFN_areas';
import {
  getParentIdFromItemID,
  getStack,
  getStackAndItem,
} from 'utils/mapStoreFN/mapStoreFN_stacks';
import { currentMapStateStore } from 'utils/stores/mapStore';
import { ClipboardStore } from 'utils/stores/mapmapClipboardStore';
import {
  AreaData,
  ImageItemData,
  ItemData,
  NoteItemData,
} from 'utils/stores/types';

interface Position {
  x: number;
  y: number;
}

export interface ClipboardStackDataProps {
  stackId: string;
  position: Position;
  items: ItemData[];
  originalItems?: ItemData[];
}

export interface ClipboardAreaDataProps {
  areaId: string;
  position: Position;
  stacks: ClipboardStackDataProps[];
  areaData: AreaData;
}

export type ClipboardItemProps =
  | ClipboardStackDataProps
  | ClipboardAreaDataProps;

export async function exportTextToClipboard(
  data: (ClipboardStackDataProps | ClipboardAreaDataProps)[]
) {
  let combinedText = '';
  let imageBlob: Blob | null = null;

  const processItem = async (i: any) => {
    if (
      'content' in i &&
      'textContent' in i.content &&
      i.content.textContent === '_IMAGE'
    ) {
      const url = i.content.slateContent[0].url;

      if (url.startsWith('data:image/')) {
        const base64Data = url.split(',')[1];
        if (base64Data) {
          const byteCharacters = atob(base64Data);
          const byteNumbers = new Array(byteCharacters.length);
          for (let j = 0; j < byteCharacters.length; j++) {
            byteNumbers[j] = byteCharacters.charCodeAt(j);
          }
          const byteArray = new Uint8Array(byteNumbers);
          imageBlob = new Blob([byteArray], { type: 'image/png' });
        }
      } else {
        combinedText += `Image: ${url}\n\n`;
      }
    } else if ('content' in i && 'textContent' in i.content) {
      combinedText += `${i.content.textContent}\n\n`;
    }
  };

  for (const item of data) {
    if ('items' in item) {
      for (const i of item.items) {
        await processItem(i);
      }
    } else if ('stacks' in item) {
      for (const stack of item.stacks) {
        for (const i of stack.items) {
          await processItem(i);
        }
      }
    }
  }

  try {
    if (imageBlob && combinedText) {
      const clipboardItem = new ClipboardItem({
        'text/plain': new Blob([combinedText], { type: 'text/plain' }),
        'image/png': imageBlob,
      });
      await navigator.clipboard.write([clipboardItem]);
    } else if (combinedText) {
      await navigator.clipboard.writeText(combinedText);
    } else if (imageBlob) {
      const clipboardItem = new ClipboardItem({
        'image/png': imageBlob,
      });
      await navigator.clipboard.write([clipboardItem]);
    }
  } catch (error) {
    if (combinedText) {
      await navigator.clipboard.writeText(combinedText);
    }
  }
}

export function processSelectedItems(mapId: string) {
  const selectedIDs = getSelectedIDs();

  const itemsIDs = selectedIDs.filter(itemId => itemId.includes('item_'));

  const areaIDs = selectedIDs.filter(areaId => areaId.includes('area_'));

  const stackMap = new Map<string, ClipboardStackDataProps>();
  const areaMap = new Map<string, ClipboardAreaDataProps>();

  processItems(itemsIDs, stackMap, mapId);
  processAreas(areaIDs, stackMap, areaMap, mapId);

  const itemsToBeSaved = [
    ...Array.from(stackMap.values()),
    ...Array.from(areaMap.values()),
  ];

  // Export copied texts to clipboard
  exportTextToClipboard(itemsToBeSaved);

  const stringifiedData = JSON.stringify(itemsToBeSaved);

  return { stringifiedData, stackMap, areaMap };
}

function processItems(
  itemsIDs: string[],
  stackMap: Map<string, ClipboardStackDataProps>,
  mapId: string
) {
  itemsIDs.forEach(itemId => {
    const { stackId, itemData, originalItems } = getItemStackData(
      itemId,
      mapId
    );

    if (!stackMap.has(stackId)) {
      stackMap.set(stackId, {
        stackId: stackId,
        position: itemData.position,
        items: [],
        originalItems: originalItems,
      });
    }

    stackMap.get(stackId)!.items.push(itemData);
  });

  sortStackItems(stackMap);
}

function processAreas(
  areaIDs: string[],
  stackMap: Map<string, ClipboardStackDataProps>,
  areaMap: Map<string, ClipboardAreaDataProps>,
  mapId: string
) {
  areaIDs.forEach(areaId => {
    const { areaData, stacksInArea } = getAreaData(areaId, stackMap, mapId);

    if (!areaMap.has(areaId)) {
      areaMap.set(areaId, {
        areaId,
        stacks: stacksInArea,
        position: areaData.position,
        areaData,
      });
    }
  });
}

function sortStackItems(stackMap: Map<string, ClipboardStackDataProps>) {
  stackMap.forEach(stack => {
    stack.items.sort((a: ItemData, b: ItemData) => {
      return (
        stack.originalItems.findIndex(
          (item: ItemData) => item.itemId === a.itemId
        ) -
        stack.originalItems.findIndex(
          (item: ItemData) => item.itemId === b.itemId
        )
      );
    });
    delete stack.originalItems;
  });
}

function getItemStackData(itemId: string, mapId: string) {
  const stackId = getParentIdFromItemID(itemId);

  // get itemData
  const { stackData, itemData } = getStackAndItem(itemId, mapId);

  itemData.position = stackData.position;

  return { stackId, itemData, originalItems: stackData.items };
}

function getAreaData(
  areaId: string,
  stackMap: Map<string, ClipboardStackDataProps>,
  mapId: string
) {
  const areaData = getArea(areaId, mapId);
  const stacksInArea = [];

  if (
    Array.from(stackMap.keys()).some(stackId =>
      areaData.encompassingStacks.includes(stackId)
    )
  ) {
    // Duplicating the area with some items in it not all
    const filteredStackMap = Array.from(stackMap.values()).filter(stack =>
      areaData.encompassingStacks.includes(stack.stackId)
    );
    if (!filteredStackMap.length) return;

    filteredStackMap.forEach(stack => {
      stacksInArea.push(stack);
      // Remove the specific stack from the stackMap, not to have duplicate stack data saved
      stackMap.delete(stack.stackId);
    });
  } else {
    if (!areaData.encompassingStacks.length) return;
    // Just duplicating the area without any item in it selected
    areaData.encompassingStacks.forEach((stackId: string) => {
      const stackData = getStack(stackId, mapId);

      const newStack = {
        stackId: stackData?.stackId,
        position: stackData.position,
        items: [...stackData.items],
      };

      stacksInArea.push(newStack);
    });
  }

  return { areaData, stacksInArea };
}
